import axios from 'axios'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { motion } from 'framer-motion'
import { getInstance } from 'api/apiInstance'
import useUser from 'hooks/useUser'

const ValidarCedula = ({ form, handleChange, stepUp, setForm, variants }) => {
  const { persona } = form ?? {}
  const [validando, setValidando] = useState(false)
  const { savetaxInfo } = useUser()

  const validCedula = async () => {
    setValidando(true)
    try {

      const request = await getInstance('admin')
      console.log(persona)
      const response = await request.post(`users/actualiza-password`, {
        username: savetaxInfo.cia.ruc,
        password: persona.nueva,
        })
      console.log(response)
      toast.success(`Se cambio la contraseña exitosamente`)
      /*setForm((prev) => ({
        ...prev,
        persona: {
          ...prev.persona,
          nombreCia: response.data.nombre,
          tipoPersona: +response.data.tipoIdentificacion,
        },
      }))*/
      stepUp()
      /*if (response.data.tipoIdentificacion === '01') {
        
       
      } else {
        toast.error('Esta cédula es una persona juridica y por el momento no es valida para adquirir una cuenta')
      }*/

    } catch (error) {
      console.error(error.message)
      toast.error('No se pudo cambiar la contraseña')
    }
    setValidando(false)
  }

  return (
    <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
      <div className="Form registroForm">
        <div id="carruselRegistro" className="carrusel step01">
          <div className="slide validcedulaSlide">
            <div className="steps">
              <span>
                Paso <b>1</b> de 3
              </span>
            </div>
            <h4>Cambio de contraseña</h4>
            <p>Para completar el registro de su cuenta, tiene que cambiar su contraseña</p>

            <label htmlFor="cedula" className="text validCedula" style={{ width: '100%' }}>Nueva Contraseña
              <input
                name="persona.nueva"
                value={persona.nueva}
                onChange={handleChange}
                className="validCedulaInput"
                placeholder="Nueva Contraseña"
                type="password"
                id="cedula"
              />
            </label>
            <label htmlFor="cedula" className="text validCedula" style={{ width: '100%' }}>Repetir Contraseña
              <input
                name="persona.confirmar"
                value={persona.confirmar}
                onChange={handleChange}
                className="validCedulaInput"
                placeholder="Confirmar Contraseña"
                type="password"
                id="cedula"
              />
            </label>
          </div>
        </div>
      </div>

      <div className="actionFixed">
        {validando ? (
          <CircularProgress />
        ) : (
          <button className="btnRegistro" onClick={validCedula} >
            Cambiar contraseña
          </button>
        )}
      </div>
    </motion.div>
  )
}

export default ValidarCedula
