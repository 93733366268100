// Import Modules
import React from 'react'

// Import StyleSheet
import './Migracion.css'

// Import Components
import EmptyFile from './EmptyFile'

const DocumentosRecibidosMigracion = () => {
  return (
    <div className="Migracion">
      <EmptyFile />
    </div>
  )
}

export default DocumentosRecibidosMigracion
