/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */
// Import Modules
import React, { useContext, useState, useEffect } from 'react'

// Import Components
import Buscador from './Buscador/Buscador.jsx'
import NotificationModal from './NotificationsModal/NotificationsModal.jsx'
import ShortCutsModal from './ShortCutsModal/ShortCutsModal.jsx'
import HelpAccess from './HelpAccess/HelpAccess.jsx'
import KeyView from 'components/KeyView/KeyView.jsx'

// import Controllers
import { listenAccesoDirecto } from './controllers'

// Import context
import Global from 'contexts/Global/Global'
import useSectionTitle from 'hooks/useSectionTitle'
import useUser from 'hooks/useUser'

// Import StyleSheet
import './TopBar.css'
import UserModal from './UserModal/UserModal.jsx'

const TopBar = () => {
  const globalContext = useContext(Global)
  const { notifications } = useUser()
  const { getTitle } = useSectionTitle()

  const toggleSearch = () => {
    let Search = document.querySelector('.Search')
    if (Search.className.includes('toggled')) {
      Search.className = 'Search'
    } else {
      Search.className = 'Search toggled'
    }
  }

  const hideAllModals = () => {
    let modalNofications = document.querySelector('.modalNofications')
    let modalShortcut = document.querySelector('.modalShortcut')
    let userModal = document.querySelector('.userModal')
    userModal.className = 'userModal toggled'
    modalShortcut.className = 'modalShortcut toggled'
    modalNofications.className = 'modalNofications toggled'
  }

  const handleModalNotifications = (event) => {
    const modalNofications = document.querySelector('.modalNofications')
    const overloadNofications = document.querySelector('.overloadNofications')
    if (modalNofications.className.includes('toggled')) {
      hideAllModals()
      overloadNofications.className = 'overloadNofications'
      setTimeout(() => {
        modalNofications.className = 'modalNofications'
      }, 40)
    } else {
      modalNofications.className = 'modalNofications toggled'
      setTimeout(() => {
        overloadNofications.className = 'overloadNofications hidden'
      }, 500)
    }
  }

  const handleModalShortcuts = (event) => {
    let target = event.target
    let modalShortcut = document.querySelector('.modalShortcut')
    const overloadShortcut = document.querySelector('.overloadShortcut')
    if (modalShortcut.className.includes('toggled')) {
      hideAllModals()
      overloadShortcut.className = 'overloadShortcut'
      setTimeout(() => {
        modalShortcut.className = 'modalShortcut'
      }, 40)
    } else {
      hideAllModals()
      setTimeout(() => {
        overloadShortcut.className = 'overloadShortcut hidden'
      }, 500)
    }

    // if (target.className.includes('toggled')) {
    //   target.className = 'createContentShortcut'
    // } else {
    //   target.className = 'createContentShortcut toggled'
    // }
  }

  const handleModalUser = (event) => {
    let target = event.target
    const userModal = document.querySelector('.userModal')
    const overloadUser = document.querySelector('.overloadUser')
    if (userModal.className.includes('toggled')) {
      hideAllModals()
      overloadUser.className = 'overloadUser'
      setTimeout(() => {
        userModal.className = 'userModal'
      }, 40)
    } else {
      hideAllModals()
      setTimeout(() => {
        overloadUser.className = 'overloadUser hidden'
      }, 500)
    }

    // if (target.className.includes('toggled')) {
    //   target.className = 'userMenu'
    // } else {
    //   target.className = 'userMenu toggled'
    // }
  }

  useEffect(() => {
    var keyboard = {}

    /*    document.addEventListener('keydown', (event) => {
         let key = event.key
         keyboard[key] = true
         listenAccesoDirecto(keyboard, undefined, event)
       })
       document.addEventListener('keyup', (event) => {
         let key = event.key
         keyboard[key] = false
       }) */
  }, [])

  return (
    <div className="TopBar" formavalaible={globalContext.formAvailable ? 'yes' : 'no'}>
      <h2 className="titleSection">{getTitle()}</h2>
      <div className="searchGeneral" onClick={toggleSearch}>
        <div className="optionsSearchGeneral">
          <button className="option btnSearch">
            <i className="bi bi-search"></i>
          </button>
        </div>
        <input type="text" placeholder="Buscar" />
        {/*  <div className="optionsSearchGeneral">
            <span>Ctrl + R</span>
          </div> */}
      </div>
      <div className="optionsTopBar">
        <div className="notifications">
          <button className="btnNotifications" onClick={handleModalNotifications}>
            <i className="bi bi-bell iconPrimary"></i>
            {notifications?.length > 0 && <span className="numberNotification">{notifications?.length}</span>}
          </button>
        </div>

        {/*    <div className="createContentShortcut">
          <button className="btnCreateContent" onClick={handleModalShortcuts}>
            <i className="bi bi-plus-circle iconPrimary"></i>
            <i className="bi bi-chevron-down chevronSubmenu"></i>
          </button>
        </div> */}
        <div className="userMenu">
          {globalContext.userTransfer.cia && (
            <div className="transfer">
              <i className="bi bi-arrow-repeat"></i>
            </div>
          )}
          <button className="btnUser" onClick={handleModalUser}>
            <i className="bi bi-person-circle iconPrimary"></i>
            <i className="bi bi-chevron-down chevronSubmenu"></i>
          </button>
        </div>
      </div>

      {/* <KeyView /> */}
      <Buscador />
      <HelpAccess />
      <NotificationModal onToggle={handleModalNotifications} />
      <ShortCutsModal onToggle={handleModalShortcuts} />
      <UserModal onToggle={handleModalUser} />
      <div className="alertContainer toggled">
        <div className="Alert">
          <h3 className="TitleAlert"></h3>
          <p className="descriptionAlert"></p>
          <div className="buttonsAlert">
            <button className="closeAlert">No</button>
            <button className="ConfirmAlert">Si</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
