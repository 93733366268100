import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { GET_CABYS, GET_DATOS_GEOGRAFICOS } from 'constants/actionTypes'
import thunkMiddleware from 'redux-thunk'
import administracion from './reducers/administracion'
import documentos from './reducers/documentos'
import facturas from './reducers/facturas'
import misc from './reducers/misc'
import clientes from './reducers/personas'
import productos from './reducers/productos'
import user from './reducers/users'

const combinedReducer = combineReducers({
  user,
  administracion,
  clientes,
  productos,
  facturas,
  documentos,
  misc,
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined
  }

  return combinedReducer(state, action)
}

export default configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
  devTools: {
    actionSanitizer: (action) => {
      return action.type === GET_CABYS
        ? { ...action, payload: '<<LONG_BLOB>>' }
        : action.type === GET_DATOS_GEOGRAFICOS
        ? { ...action, payload: '<<LONG_BLOB>>' }
        : action
    },
    stateSanitizer: (state) => {
      return state.administracion.cabys
        ? {
            ...state,
            administracion: {
              ...state.administracion,
              cabys: '<<LONG_BLOB>>',
            },
          }
        : state
    },
  },
})
