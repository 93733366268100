import CircularProgress from 'components/CircularProgress/CircularProgress'
import React, { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { getInstance } from 'api/apiInstance'
import useUser from 'hooks/useUser'

const ActualizaContrasenia = () => {
  // const { persona } = form ?? {}
  const navigate = useNavigate()
  const [validando, setValidando] = useState(false)
  const { savetaxInfo } = useUser()
  const [form, setForm] = useState({
    persona: {
      pais: {
        idPais: 1,
      },
    },
    actividadEconomica: {},
    hacienda: {},
  })

  const validCedula = async () => {
    setValidando(true)
    try {
      const request = await getInstance('admin')
      console.log(form.persona)
      const response = await request.post(`users/actualiza-password`, {
        username: savetaxInfo.cia.ruc,
        password: form.persona.nueva,
      })
      console.log(response)
      toast.success(`Se cambio la contraseña exitosamente`)
      navigate('/escritorio')
    } catch (error) {
      console.error(error.message)
      toast.error('No se pudo cambiar la contraseña')
    }
    setValidando(false)
  }

  const handleChange = useCallback(
    (e) => {
      setForm((prev) => ({ ..._.set(prev, e.target.name, e.target.value) }))
      console.log(form)
    },
    [form]
  )

  return (
    <div className="Usuarios">
      <div className="Form registroForm">
        <div id="carruselRegistro" className="carrusel step01">
          <div className="slide validcedulaSlide">
            <h4>Cambio de contraseña</h4>

            <label htmlFor="cedula" className="text validCedula" style={{ width: '100%' }}>
              Nueva Contraseña
              <input
                name="persona.nueva"
                value={form.persona.nueva}
                onChange={handleChange}
                className="validCedulaInput"
                placeholder="Nueva Contraseña"
                type="password"
                id="cedula"
              />
            </label>
            <label htmlFor="cedula" className="text validCedula" style={{ width: '100%' }}>
              Repetir Contraseña
              <input
                name="persona.confirmar"
                value={form.persona.confirmar}
                onChange={handleChange}
                className="validCedulaInput"
                placeholder="Confirmar Contraseña"
                type="password"
                id="cedula"
              />
            </label>
          </div>
        </div>
      </div>

      <div className="actionFixed">
        {validando ? (
          <CircularProgress />
        ) : (
          <button className="btnRegistro" onClick={validCedula}>
            Cambiar contraseña
          </button>
        )}
      </div>
    </div>
  )
}

export default ActualizaContrasenia
