import { deleteUsuario } from 'actions/users'
import Pagination from 'components/common/Pagination'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUsers } from '../../../actions/users'
import { ReactComponent as Create } from '../../../assets/create.svg'
import { ReactComponent as Delete } from '../../../assets/delete.svg'
import { ReactComponent as Edit } from '../../../assets/edit.svg'
import { ReactComponent as Search } from '../../../assets/search.svg'
import useUser from '../../../hooks/useUser'
import './Usuarios.css'
import UsuariosKeyview from './UsuariosKeyview'

const Usuarios = () => {
  const dispatch = useDispatch()
  const { savetaxInfo, users, isLoadingUsers } = useUser()
  const [usuariosFiltrados, setUsuariosFiltrados] = useState([])
  const [filtro, setFiltro] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [active, setActive] = useState(false)
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(undefined)

  const handleOnClose = () => {
    setActive(false)
    setUsuarioSeleccionado(undefined)
  }

  const clearFilter = () => {
    setFiltro('')
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setFiltro(value)
    setUsuariosFiltrados(
      value
        ? users.filter(
            (client) =>
              client.nombreUsuario.toUpperCase().includes(value.toUpperCase()) || client.username.toString().includes(value)
          )
        : users
    )
  }

  useEffect(() => {
    if (isLoadingUsers && savetaxInfo.cia.codCia) {
      dispatch(getUsers(savetaxInfo.cia.codCia))
    }

    setUsuariosFiltrados(users)
  }, [dispatch, users, savetaxInfo.cia.codCia, isLoadingUsers])

  const actualizarUser = async() => {
    dispatch(getUsers(savetaxInfo.cia.codCia))
  }


  return (
    <>
      <div className="Usuarios">
        <div className="navSection">
          <b className="title">Filtra Por:</b>
          <label htmlFor="" style={{ width: '70%' }}>
            Nombre
            <div className="search">
              <input type="text" value={filtro} name="search" placeholder="Nombre" onChange={handleFilter} />
              <button>
                <Search />
              </button>
            </div>
          </label>
          <div className="sortBy">
            <label htmlFor="" style={{ width: '150px' }}>
              Limpiar Filtros
              <button onClick={clearFilter}>Limpiar</button>
            </label>
          </div>
          <button className="addContent" onClick={() => setActive(true)}>
            Agregar Usuario
            <Create />
          </button>
        </div>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1">
                <b>Usuario</b>
              </li>
              <li className="col-1">
                <b>Nombres</b>
              </li>
              <li className="col-2">
                <b>Identificacion </b>
              </li>
              <li className="col-4">
                <b>Rol</b>
              </li>
              <li className="col-1">
                <b>Estado</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>
          {isLoadingUsers ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Usuarios</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {usuariosFiltrados.length ? (
                  usuariosFiltrados.slice(50 * currentPage - 50, 50 * currentPage).map((usuario) => (
                    <li className="itemBodyList" key={usuario.usuarioPK.idUsuario}>
                      <ul className="contentItemBodyList">
                        <li className="col-1">
                          <span>{usuario.username}</span>
                        </li>
                        <li className="col-1">
                          <b>{usuario.nombreUsuario}</b>
                        </li>
                        <li className="col-2">
                          <span>{usuario.identificacion}</span>
                        </li>
                        <li className="col-4">
                          <span>{usuario.usuarioRolList?.[0].rol?.nombreRol}</span>
                        </li>
                        <li className="col-1">
                          <span className={usuario.estado === 'A' ? 'activo' : 'desactivo'}>
                            {usuario.estado === 'A' ? 'Activo' : 'Inactivo'}
                          </span>
                        </li>
                        <li className="col-1">
                          <button
                            className="btnEditItem"
                            onClick={() => {
                              setActive(true)
                              setUsuarioSeleccionado({ ...usuario, isEdit: true })
                            }}
                          >
                            <Edit />
                          </button>
                          <button
                            className="btnDelete"
                            onClick={() => {
                              createAlertConfirm('Eliminar Usuario', '¿Está seguro de querer eliminar este usuario?', () =>
                                dispatch(deleteUsuario(usuario))
                              )
                            }}
                          >
                            <Delete />
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        {!isLoadingUsers && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={usuariosFiltrados}/>}
      </div>
      {active && <UsuariosKeyview usuario={usuarioSeleccionado} onClose={handleOnClose} actualizarUser={actualizarUser} />}
    </>
  )
}

export default Usuarios
