import { getClients, getTiposClientes } from 'actions/personas'
import Pagination from 'components/common/Pagination'
import FormPersonas from 'components/Form/FormPersonas/FormPersonas'
import useClientes from 'hooks/useClientes'
import useUser from 'hooks/useUser'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ReactComponent as Create } from '../../assets/create.svg'
import { ReactComponent as Edit } from '../../assets/edit.svg'
import { ReactComponent as Search } from '../../assets/search.svg'
import './Personas.css'

const Personas = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { clientes, tiposClientes, isLoadingClientes } = useClientes()
  const { search } = useLocation()
  const searchParams = Object.fromEntries(new URLSearchParams(search))
  const { personaID } = searchParams

  const lookedItem = useMemo(() => {
    const item = clientes[Number(_.findKey(clientes, (i) => i.clientePK.idCliente === +personaID))] ?? null
    if (item) return { ...item, isEdit: true }
    else return undefined
  }, [clientes, personaID])

  const [personasFiltradas, setPersonasFiltradas] = useState([])
  const [filtros, setFiltros] = useState({
    search: '',
    estado: '',
    tipo: '',
  })
  const [currentPage, setCurrentPage] = useState(1)

  const [persona, setPersona] = useState(lookedItem ?? undefined)
  const [activeForm, setActiveForm] = useState(lookedItem ? true : false)

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setPersonasFiltradas(filterPersonas(name, value))
    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleOnMouseOverItem = (event) => {
    event.preventDefault()
    let target = event.target
    if (target.attributes['inhover']) {
      target.attributes['inhover'].value = 'hover'
    }
  }

  const handleOnMouseLeaveItem = (event) => {
    event.preventDefault()
    let target = event.target
    if (target.attributes['inhover']) {
      target.attributes['inhover'].value = 'out'
    }
  }

  const filterForEstado = (personasMatch, value) => {
    let result = personasMatch
    if (value.length) {
      result = result.filter((persona) => persona.estado === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (persona) =>
          persona.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) || persona.ruc.includes(filtros.search)
      )
    }
    if (result.length && filtros.tipo.length) {
      result = result.filter((persona) => {
        if (persona.tipoCliente) {
          return persona.tipoCliente.idTipoCliente === filtros.tipo
        } else {
          return false
        }
      })
    }
    return result
  }

  const filterForTipo = (personasMatch, value) => {
    let result = personasMatch
    if (value.length) {
      result = result.filter((persona) => {
        if (persona.tipoCliente) {
          return persona.tipoCliente.idTipoCliente === Number(value)
        } else {
          return false
        }
      })
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (persona) =>
          persona.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) || persona.ruc.includes(filtros.search)
      )
    }
    if (result.length && filtros.estado.length) {
      if (filtros.estado === 'D') {
        result = result.filter((persona) => !persona.estado)
      } else {
        result = result.filter((persona) => persona.estado === filtros.estado)
      }
    }
    return result
  }

  const filterForSearch = (personasMatch, value) => {
    let result = personasMatch
    if (value.length) {
      result = result.filter(
        (persona) => persona.nombreCliente.toUpperCase().includes(value.toUpperCase()) || persona.ruc.includes(value)
      )
    }

    if (result.length && filtros.tipo.length) {
      result = result.filter((persona) => {
        if (persona.tipoCliente) {
          return persona.tipoCliente.idTipoCliente === filtros.tipo
        } else {
          return false
        }
      })
    }

    if (result.length && filtros.estado.length) {
      if (filtros.estado === 'D') {
        result = result.filter((persona) => !persona.estado)
      } else {
        result = result.filter((persona) => persona.estado === filtros.estado)
      }
    }
    return result
  }

  const filterPersonas = (name, value) => {
    let personasMatch = clientes
    if (personasMatch.length) {
      if (name === 'estado') {
        return filterForEstado(personasMatch, value)
      } else if (name === 'tipo') {
        return filterForTipo(personasMatch, value)
      } else if (name === 'search') {
        return filterForSearch(personasMatch, value)
      } else if (name === 'clear') {
        return personasMatch
      }
    } else {
      return []
    }
  }

  const clearFilters = () => {
    setFiltros({
      search: '',
      estado: '',
      tipo: '',
    })
    setPersonasFiltradas(clientes)
  }

  const reloadPersonas = async (event) => {
    event.preventDefault()
    document.querySelector('.Personas .bodyList').className = 'bodyList hidden'
    document.querySelector('.Personas .loadContentTable').className = 'loadContentTable'
    document.querySelector('.Personas .paginationTable').className = 'paginationTable hidden'
  }

  const handleAdd = () => {
    setActiveForm(true)
  }

  const hadleEdit = (persona) => {
    setPersona({ ...persona, isEdit: true })
    setActiveForm(true)
  }

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      let key = event.key
      if (key === 'e') {
        if (document.querySelector('.contentItemBodyList[inhover="hover"]')) {
          document.querySelector('.contentItemBodyList[inhover="hover"]').querySelector('.btnEditItem').click()
        }
      }
    })
  }, [])

  useEffect(() => {
    if (isLoadingClientes) {
      dispatch(getClients(savetaxInfo.cia.codCia))
    }

    setPersonasFiltradas(clientes)
  }, [dispatch, savetaxInfo, clientes, isLoadingClientes])

  useEffect(() => {
    if (!tiposClientes.length) dispatch(getTiposClientes)
  }, [dispatch, tiposClientes])

  return (
    <>
      <div className="Personas" onScroll={hadleScrollSection}>
        <button className="reloadTable hidden" onClick={reloadPersonas}></button>
        <div className="navSection">
          <b className="title">Filtra Por:</b>
          <label htmlFor="" style={{ width: '250px' }}>
            Nombre o Identidad
            <div className="search">
              <input
                autoComplete="off"
                type="text"
                name="search"
                placeholder="Nombre o Identidad"
                value={filtros.search.toUpperCase()}
                onChange={hadleFilter}
              />
              <button>
                <Search />
              </button>
            </div>
          </label>
          <div className="sortBy">
            <label htmlFor="" style={{ width: '180px' }}>
              Tipo
              <select name="tipo" id="" value={filtros.tipo} onChange={hadleFilter}>
                <option value="">Todos los Tipos</option>
                {tiposClientes.map((tipo) => (
                  <option key={tipo.idTipoCliente} value={tipo.idTipoCliente}>
                    {tipo.nombreTipoCliente}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="" style={{ width: '180px' }}>
              Estado
              <select name="estado" id="" value={filtros.estado} onChange={hadleFilter}>
                <option value="">Todos los Estados</option>
                <option value="A">Activo</option>
                <option value="D">Inactivo</option>
              </select>
            </label>
            <label htmlFor="" style={{ width: '150px' }}>
              Limpiar Filtros
              <button onClick={clearFilters}>Limpiar</button>
            </label>
          </div>
          <button className="addContent" onClick={handleAdd}>
            Agregar Persona
            <Create />
          </button>
        </div>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1-2">
                <b>ID</b>
              </li>
              <li className="col-5">
                <b>Nombre</b>
              </li>
              <li className="col-2">
                <b>Número Identidad</b>
              </li>
              <li className="col-1">
                <b>Tipo</b>
              </li>
              <li className="col-1">
                <b>Estado</b>
              </li>
              <li className="col-1-2">
                <b></b>
              </li>
            </ul>
          </div>

          {isLoadingClientes && (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando personas</span>
            </div>
          )}

          <div className="bodyList">
            <ul>
              {personasFiltradas.length ? (
                personasFiltradas
                  .sort((a, b) => b.clientePK.idCliente - a.clientePK.idCliente)
                  .slice(50 * currentPage - 50, 50 * currentPage)
                  .map((persona) => (
                    <li key={persona.clientePK.idCliente} className="itemBodyList">
                      <ul
                        className="contentItemBodyList"
                        inhover="out"
                        onMouseOver={handleOnMouseOverItem}
                        onMouseOut={handleOnMouseLeaveItem}
                      >
                        <li className="col-1-2">
                          <span>{persona.clientePK.idCliente}</span>
                        </li>
                        <li className="col-5 noAction">
                          <b>{persona.nombreCliente.toUpperCase()}</b>
                        </li>
                        <li className="col-2 noAction">
                          <span>{persona.ruc}</span>
                        </li>
                        <li className="col-1 noAction">
                          <span>{persona.tipoCliente ? persona.tipoCliente.nombreTipoCliente : 'Sin definir'}</span>
                        </li>
                        <li className="col-1 noAction">
                          <span className={persona.estado === 'A' ? 'activo' : 'desactivo'}>
                            {persona.estado === 'A' ? 'Activo' : 'Inactivo'}
                          </span>
                        </li>
                        <li className="col-1-2">
                          <button
                            className="btnEditItem"
                            onClick={() => hadleEdit(persona)}
                            identificador={persona.clientePK.idCliente}
                          >
                            <Edit />
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))
              ) : (
                <li className="emptyResultTable">
                  <span>No hay Resultados</span>
                </li>
              )}
            </ul>
          </div>
        </div>

        {!isLoadingClientes && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={personasFiltradas} />}
      </div>
      {activeForm && (
        <FormPersonas
          onClose={() => {
            setPersona(undefined)
            setActiveForm(false)
          }}
          persona={persona}
        />
      )}
    </>
  )
}

export default Personas
