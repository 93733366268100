import axios from 'axios'
import { GET_DOCUMENTOS_RECIBIDOS, GET_DOCUMENTS_EMITIDOS, UPDATE_DOCUMENT_RECIBIDO } from '../constants/actionTypes'
import { getInstance } from 'api/apiInstance'

export const getDocumentosRecibidos = (cia) => {
  return async (dispatch) => {
    const request = await getInstance('documentos')
    const response = await request.get(`doc-recibido/find-by-cod-cia/${cia}`)

    dispatch({
      type: GET_DOCUMENTOS_RECIBIDOS,
      payload: {
        documentosRecibidos: response?.data,
        finalizo: true,
      },
    })
  }
}

export const getDocumentosEmitidos = (cia) => {
  return async (dispatch) => {
    const request = await getInstance('fe')
    const response = await request.get(`vta-movm/find-by-cod-cia-and-ref/${cia}`)

    dispatch({
      type: GET_DOCUMENTS_EMITIDOS,
      payload: {
        documentosEmitidos: response?.data,
        finalizo: true,
      },
    })
  }
}

export const updateDocumentRecibido = (document) => async (dispatch) => {
  try {
    const request = await getInstance('documentos')
    const res = await request.post(`doc-recibido/emitir-doc-recibido`, document)
    const payload = await res.data

    console.log(payload)
    dispatch({
      type: UPDATE_DOCUMENT_RECIBIDO,
      payload,
    })
  } catch (err) {
    console.log(err)
  }
}
