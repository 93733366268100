// Import Modules
import React from 'react'
import { Routes, Route } from 'react-router-dom'

// Import Components
import Escritorio from '../Escritorio/Escritorio'
import Recuperar from '../Recuperar/Recuperar'
import NotFound from '../NotFound/NotFound'
import Ingresar from '../Ingresar/Ingresar'
import Registro from '../Registro/Registro'
import Productos from '../Productos/Productos'
import Companias from '../Administracion/Companias/Companias'
import Cajas from '../Administracion/Cajas/Cajas'
import Cabys from '../Administracion/Cabys/Cabys'
import Consecutivos from '../Administracion/Consecutivos/Consecutivos'
import Sucursales from '../Administracion/Sucursales/Sucursales'
import Usuarios from '../Administracion/Usuarios/Usuarios'
import ActividadesEconomicas from '../Administracion/ActividadesEconomicas/ActividadesEconomicas'
import PorrataTemporal from '../IVA/PorrataTemporal/PorrataTemporal'
import DeclaracionDelIVA from '../IVA/DeclaracionDelIVA/DeclaracionDelIVA'
import Personas from '../Personas/Personas'
// import NotaDebito from '../Facturacion/NotaDebito/NotaDebito'
import DocumentosEmitidos from '../Documentos/DocumentosEmitidos/DocumentosEmitidos'
import DocumentosRecibidos from '../Documentos/DocumentosRecibidos/DocumentosRecibidos'
import DocumentosEmitidosMigracion from '../Documentos/Migracion/DocumentosEmitidos'
import DocumentosRecibidosMigracion from '../Documentos/Migracion/DocumentosRecibidos'
import Aduana from '../Inventarios/Aduana/Aduana'
import AjustesNegativos from '../Inventarios/AjustesNegativos/AjustesNegativos'
import AjustesPositivos from '../Inventarios/AjustesPositivos/AjustesPositivos'
import AuxiliarDeCompras from '../Inventarios/AuxiliarDeCompras/AuxiliarDeCompras'
import Bodegas from '../Inventarios/Bodegas/Bodegas'
import Compras from '../Inventarios/Compras/Compras'
import Egresos from '../Inventarios/Egresos/Egresos'
import Existencias from '../Inventarios/Existencias/Existencias'
import Importaciones from '../Inventarios/Importaciones/Importaciones'
import Ingresos from '../Inventarios/Ingresos/Ingresos'
import Kardex from '../Inventarios/Kardex/Kardex'
import Movimientos from '../Inventarios/Movimientos/Movimientos'
import RemesaAlExterior from '../Inventarios/RemesaAlExterior/RemesaAlExterior'
import CreditosBancarios from '../Renta/CreditosBancarios/CreditosBancarios'
import PagoParcial from '../Renta/PagoParcial/PagoParcial'
import CreditosFamiliares from '../Renta/CreditosFamiliares/CreditosFamiliares'
import Propiedad from '../Renta/Propiedad/Propiedad'
import RetencionDePago from '../Renta/RetencionDePago/RetencionDePago'
import Salarios from '../Renta/Salarios/Salarios'
import SaldoAFavor from '../Renta/SaldoAFavor/SaldoAFavor'
import PerdidasFiscales from '../Renta/PerdidasFiscales/PerdidasFiscales'
import Configuraciones from '../Configuraciones/Configuraciones'
import Notification from '../Notification/Notification'
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import App from 'App'
import LectorPDF from 'components/Documentos/LectorPDF/LectorPDF'
import MethodsPages from 'components/Configuraciones/Methods/MethodsPages'
import HistorialPages from 'components/Configuraciones/History/HistorialPages'
import FormMethod from 'components/Configuraciones/Methods/FormMethod'
import AssignRouteIndex from './AssignRouteIndex'
import FacturaElectronicas from 'components/Facturacion/FacturaElectronicas/FacturaElectronicas'
import FacturaElectronicaDeCompra from 'components/Facturacion/FacturaElectronicaDeCompra/FacturaElectronicaDeCompra'
import TiquetaElectronica from 'components/Facturacion/TiquetaElectronica/TiquetaElectronica'
import FacturaElectronicaPorExportacion from 'components/Facturacion/FacturaElectronicaPorExportacion/FacturaElectronicaPorExportacion'
import ProformaElectronica from 'components/Facturacion/ProformaElectronica/ProformaElectronica'
import NotaCredito from 'components/Facturacion/NotaCredito/NotaCredito'
import NotaDebito from 'components/Facturacion/NotaDebito/NotaDebito'
import TerminosCondiciones from 'components/TerminosCondiciones/TerminosCondiciones'
import Paquetes from 'components/Planes/Paquetes'
import Inicial from 'components/Inicial/Inicial'
import ValidarCedula from 'components/Inicial/Pasos/ValidarCedula'
import ActualizaContrasenia from 'components/Administracion/Usuarios/ActualizaContrasenia'

const RouterApp = () => {
  return (
    <div className="router">
      <Routes>
        <Route path="/" element={<App />}>
          <Route element={<AssignRouteIndex />} index />

          <Route element={<PublicRoute element={<Registro />} />} path="/registro" />

          <Route element={<PublicRoute element={<Ingresar />} />} path="/ingresar" />

          <Route element={<PublicRoute element={<Recuperar />} />} path="/recuperar" />

          <Route element={<PrivateRoute element={<Escritorio />} />} path="/escritorio" />

          <Route element={<PrivateRoute element={<Notification />} />} path="/notificaciones" />

          <Route element={<PrivateRoute element={<Productos />} />} path="/productos" />

          <Route element={<PrivateRoute element={<Personas />} />} path="/personas" />

          <Route element={<PrivateRoute element={<Companias />} />} path="/companias" />

          <Route element={<PrivateRoute element={<Cajas />} />} path="/cajas" />

          <Route element={<PrivateRoute element={<Cabys />} />} path="/cabys" />

          <Route element={<PrivateRoute element={<Consecutivos />} />} path="/consecutivos" />

          <Route element={<PrivateRoute element={<Usuarios />} />} path="/usuarios" />

          <Route element={<PrivateRoute element={<Sucursales />} />} path="/sucursales" />

          <Route element={<PrivateRoute element={<ActividadesEconomicas />} />} path="/actividadesEconomicas" />

          <Route element={<PrivateRoute element={<FacturaElectronicaDeCompra />} />} path="/facturasElectronicasDeCompra" />

          <Route
            element={<PrivateRoute element={<FacturaElectronicaPorExportacion />} />}
            path="/facturasElectronicasPorExportacion"
          />

          <Route element={<PrivateRoute element={<FacturaElectronicas />} />} path="/facturasElectronicas" />

          <Route element={<PrivateRoute element={<NotaCredito />} />} path="/notasCredito" />

          <Route element={<PrivateRoute element={<NotaDebito />} />} path="/notasDebito" />

          <Route element={<PrivateRoute element={<ProformaElectronica />} />} path="/proformasElectronicas" />

          <Route element={<PrivateRoute element={<TiquetaElectronica />} />} path="/tiquetasElectronicas" />

          <Route element={<PrivateRoute element={<DocumentosEmitidos />} />} path="/documentosEmitidos">
            <Route element={<LectorPDF sectionLector="documentosEmitidos" />} path=":idDocumento" />
          </Route>

          <Route element={<PrivateRoute element={<DocumentosRecibidos />} />} path="/documentosRecibidos">
            <Route element={<LectorPDF sectionLector="documentosRecibidos" />} path=":idDocumento" />
          </Route>

          <Route element={<PrivateRoute element={<DocumentosEmitidosMigracion />} />} path="/migracionDocumentosEmitidos" />

          <Route element={<PrivateRoute element={<DocumentosRecibidosMigracion />} />} path="/migracionDocumentosRecibidos" />

          <Route element={<PrivateRoute element={<Aduana />} />} path="/aduana" />

          <Route element={<PrivateRoute element={<AjustesNegativos />} />} path="/ajustesNegativos" />

          <Route element={<PrivateRoute element={<Escritorio />} />} path="/escritorio" />

          <Route element={<PrivateRoute element={<AjustesPositivos />} />} path="/ajustesPositivos" />

          <Route element={<PrivateRoute element={<AuxiliarDeCompras />} />} path="/auxiliarDeCompras" />

          <Route element={<PrivateRoute element={<Bodegas />} />} path="/bodegas" />

          <Route element={<PrivateRoute element={<Compras />} />} path="/compras" />

          <Route element={<PrivateRoute element={<Egresos />} />} path="/egresos" />

          <Route element={<PrivateRoute element={<Existencias />} />} path="/existencias" />

          <Route element={<PrivateRoute element={<Importaciones />} />} path="/importaciones" />

          <Route element={<PrivateRoute element={<Ingresos />} />} path="/ingresos" />

          <Route element={<PrivateRoute element={<Kardex />} />} path="/kardex" />

          <Route element={<PrivateRoute element={<RemesaAlExterior />} />} path="/remesaAlExterior" />

          <Route element={<PrivateRoute element={<Movimientos />} />} path="/movimientos" />

          <Route element={<PrivateRoute element={<PorrataTemporal />} />} path="/prorrataTemporal" />

          <Route element={<PrivateRoute element={<DeclaracionDelIVA />} />} path="/declaracionDelIVA" />

          <Route element={<PrivateRoute element={<CreditosBancarios />} />} path="/creditosBancarios" />

          <Route element={<PrivateRoute element={<CreditosFamiliares />} />} path="/creditosFamiliares" />

          <Route element={<PrivateRoute element={<PagoParcial />} />} path="/pagoParcial" />

          <Route element={<PrivateRoute element={<PerdidasFiscales />} />} path="/perdidasFiscales" />

          <Route element={<PrivateRoute element={<Propiedad />} />} path="/propiedad" />

          <Route element={<PrivateRoute element={<RetencionDePago />} />} path="/retencionDePago" />

          <Route element={<PrivateRoute element={<Salarios />} />} path="/salarios" />

          <Route element={<PrivateRoute element={<SaldoAFavor />} />} path="/saldoAFavor" />

          <Route element={<PrivateRoute element={<Configuraciones />} />} path="/configuraciones" />

          <Route element={<PrivateRoute element={<Paquetes />} />} path="/paquetes" />

          <Route element={<PrivateRoute element={<Inicial />} />} path="/configuracionInicial" />

          <Route element={<PrivateRoute element={<MethodsPages />} />} path="/metodosPago">
            <Route element={<FormMethod />} path="editar/:idMetodoDePago" />

            <Route element={<FormMethod />} path="agregar" />
          </Route>

          <Route element={<PrivateRoute element={<HistorialPages />} />} path="/historialPagos" />

          <Route path="*" element={<NotFound />} />

          <Route path="/terminosYCondiciones" element={<TerminosCondiciones />} />
          <Route path="/actualizaPassword" element={<ActualizaContrasenia />} />
        </Route>
      </Routes>
    </div>
  )
}

export default RouterApp
