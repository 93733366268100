import { createActividadEconomica, updateActividadEconomica } from 'actions/administracion'
import { getCatalogoActividades } from 'actions/misc'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import Text from 'components/FormField/Text'
import KeyView from 'components/KeyView/KeyView'
import useMisc from 'hooks/useMisc'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

const ActividadesEconomicasKeyview = ({ onClose, actividad }) => {
  const dispatch = useDispatch()
  const { isEdit } = actividad ?? {}
  const { savetaxInfo } = useUser()
  const { catalogoActividades } = useMisc()

  const [actividadEconomica, setActividadEconomica] = useState(
    actividad ?? {
      actividadEconomica: {
        codigo: '',
        nombre: '',
      },
      actividadEconomicaCiaPK: {
        codCia: savetaxInfo.cia.codCia,
        codActividad: '',
        correlativo: '',
      },
      descripcion: '',
      estado: '',
    }
  )
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (actividadEconomica.actividadEconomica.nombre || actividadEconomica.actividadEconomica.codigo) {
      setLoading(true)
      if (isEdit) {
        dispatch(updateActividadEconomica(actividadEconomica)).then((r) => {
          if (r) {
            onClose()
          }

          setLoading(false)
        })
      } else {
        dispatch(createActividadEconomica(actividadEconomica)).then((r) => {
          if (r) {
            onClose()
          }

          setLoading(false)
        })
      }
    } else {
      toast.error('Selecciona actividad económica')
    }
  }

  useEffect(() => {
    if (savetaxInfo?.cia?.ruc) {
      dispatch(getCatalogoActividades(savetaxInfo?.cia.ruc))
    }
  }, [dispatch, savetaxInfo?.cia?.ruc])

  return (
    <KeyView title="Agregar Actividad Económica" onClose={onClose}>
      <label className="text">
        Actividad Económica
        <Select
          isDisabled={isEdit}
          required
          options={
            catalogoActividades?.length
              ? catalogoActividades?.map((c) => ({
                  label: c.nombre,
                  value: c.codigo,
                }))
              : []
          }
          value={
            actividadEconomica?.actividadEconomica?.codigo
              ? {
                  value: actividadEconomica?.actividadEconomica?.codigo ?? '',
                  label: actividadEconomica?.actividadEconomica?.nombre ?? '',
                }
              : ''
          }
          onChange={(e) => {
            setActividadEconomica((prev) => ({
              ...prev,
              actividadEconomica: {
                nombre: e.label,
                codigo: e.value,
              },
              actividadEconomicaCiaPK: {
                ...prev.actividadEconomicaCiaPK,
                codActividad: e.value,
              },
            }))
          }}
          placeholder="Buscar..."
        />
      </label>

      <Text
        label="Nombre comercial"
        placeholder="Ventas de electrónicos..."
        value={actividadEconomica?.descripcion ?? ''}
        onChange={(e) => setActividadEconomica((prev) => ({ ...prev, descripcion: e.target.value }))}
      />

      <label className="text">
        Estado
        <Select
          isSearchable={false}
          options={[
            { label: 'Activo', value: 'A' },
            { label: 'Inactivo', value: 'I' },
          ]}
          value={
            actividadEconomica?.estado
              ? {
                  value: actividadEconomica?.estado,
                  label: actividadEconomica?.estado === 'I' ? 'Inactivo' : actividadEconomica?.estado === 'A' ? 'Activo' : '',
                }
              : ''
          }
          onChange={(e) => setActividadEconomica((prev) => ({ ...prev, estado: e.value }))}
          placeholder="Seleccione..."
        />
      </label>

      {loading ? <CircularProgress /> : <button onClick={handleSubmit}>{isEdit ? 'Actualizar' : 'Guardar'}</button>}
    </KeyView>
  )
}

export default ActividadesEconomicasKeyview
