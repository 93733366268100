import { deleteConsecutivo, getConsecutivos } from 'actions/administracion'
import { ReactComponent as Create } from 'assets/create.svg'
import { ReactComponent as Delete } from 'assets/delete.svg'
import { ReactComponent as Edit } from 'assets/edit.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import Pagination from 'components/common/Pagination'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './Consecutivos.css'
import ConsecutivosKeyview from './ConsecutivosKeyview'

const Consecutivos = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { consecutivos, isLoadingConsecutivos } = useAdmin()

  const [consecutivosFiltrados, setConsecutivosFiltrados] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filtros, setFiltros] = useState({
    search: '',
    estado: '',
  })
  const [active, setActive] = useState(false)
  const [consecutivoSelected, setConsecutivoSelected] = useState(undefined)

  const clearFilters = () => {
    setConsecutivosFiltrados(consecutivos)
    setFiltros({
      search: '',
      estado: '',
    })
  }

  const filterForEstado = (consecutivosMatch, value) => {
    let result = consecutivosMatch
    if (value.length) {
      result = result.filter((consecutivo) => consecutivo?.caja?.sucursal?.estado === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (consecutivo) =>
          consecutivo.nombreSucursal.toUpperCase().includes(filtros.search.toUpperCase()) ||
          consecutivo.establecimiento.includes(filtros.search)
      )
    }
    return result
  }

  const filterForSearch = (consecutivosMatch, value) => {
    let result = consecutivosMatch

    if (value.length) {
      result = result.filter(
        (consecutivo) =>
          consecutivo?.caja?.sucursal?.nombreSucursal.toUpperCase().includes(value.toUpperCase()) ||
          consecutivo?.caja?.sucursal?.establecimiento.toString().includes(value)
      )
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((consecutivo) => consecutivo.estado === filtros.estado)
    }
    return result
  }

  const filterConsecutivos = (name, value) => {
    if (consecutivos.length) {
      if (name === 'estado') {
        return filterForEstado(consecutivos, value)
      } else if (name === 'search') {
        return filterForSearch(consecutivos, value)
      } else if (name === 'clear') {
        return consecutivos
      }
    } else {
      return []
    }
  }

  const handleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
    setConsecutivosFiltrados(filterConsecutivos(name, value))
  }

  const handleOnClose = () => {
    setActive(false)
    setConsecutivoSelected(undefined)
  }

  useEffect(() => {
    if (isLoadingConsecutivos) {
      dispatch(getConsecutivos(savetaxInfo.cia.codCia))
    }

    setConsecutivosFiltrados(consecutivos)
  }, [dispatch, savetaxInfo.cia.codCia, consecutivos, isLoadingConsecutivos])

  return (
    <>
      <div className="Consecutivos">
        <div className="navSection">
          <b className="title">Filtra Por:</b>

          <label htmlFor="" style={{ width: '300px' }}>
            Nombre
            <div className="search">
              <input type="text" value={filtros.search} name="search" placeholder="Nombre " onChange={handleFilter} />
              <button>
                <Search />
              </button>
            </div>
          </label>

          <div className="sortBy">
            <label htmlFor="estado" style={{ width: '150px' }}>
              Estado
              <select id="estado" value={filtros.estado} onChange={handleFilter} name="estado">
                <option value="">Todos los Estados</option>
                <option value="A">Activo</option>
                <option value="I">Inactivo</option>
              </select>
            </label>

            <label htmlFor="" style={{ width: '150px' }}>
              Limpiar Filtros
              <button onClick={clearFilters}>Limpiar</button>
            </label>
          </div>


        </div>

        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-2">
                <b>Còdigo</b>
              </li>
              <li className="col-3">
                <b>Documento Electrònico</b>
              </li>
              <li className="col-1">
                <b>Sucursal</b>
              </li>
              <li className="col-1">
                <b>Caja</b>
              </li>
              <li className="col-1">
                <b>Consecutivo</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>

          {isLoadingConsecutivos ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Consecutivos</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {consecutivosFiltrados.length ? (
                  consecutivosFiltrados.slice(50 * currentPage - 50, 50 * currentPage).map((consecutivo) => (
                    <li className="itemBodyList" key={consecutivo.mantoFormularioPK?.idMantoForm}>
                      <ul className="contentItemBodyList">
                        <li className="col-1-2">
                          <b>{consecutivo.idTipoDoctoVta?.codigoSriAts}</b>
                        </li>
                        <li className="col-3">
                          <span>{consecutivo.idTipoDoctoVta?.nombreDoctoVta}</span>
                        </li>
                        <li className="col-1">
                          <span>{consecutivo.caja?.sucursal.establecimiento}</span>
                        </li>
                        <li className="col-1">
                          <span>{consecutivo.caja?.puntoEmision}</span>
                        </li>
                        <li className="col-1">
                          <span>{consecutivo.numActual}</span>
                        </li>
                        <li className="col-1">
                          <button
                            className="btnEditItem"
                            onClick={() => {
                              setActive(true)
                              setConsecutivoSelected({ ...consecutivo, isEdit: true })
                            }}
                          >
                            <Edit />
                          </button>
                          <button
                            className="btnDelete"
                            onClick={() => {
                              createAlertConfirm(
                                'Eliminar Consecutivo',
                                '¿Está seguro de querer eliminar este consecutivo?',
                                () => dispatch(deleteConsecutivo(consecutivo))
                              )
                            }}
                          >
                            <Delete />
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        {!isLoadingConsecutivos && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={consecutivosFiltrados} />}
      </div>
      {active && <ConsecutivosKeyview consecutivo={consecutivoSelected} onClose={handleOnClose} />}
    </>
  )
}

export default Consecutivos
