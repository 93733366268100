/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import './FacturaElectronicas.css'
import useFacturas from 'hooks/useFacturas'
import useUser from 'hooks/useUser'
import TableItem from './TableItem'
import logo from 'assets/logo_black.png'
import Filters from './Filters'
import { useDispatch } from 'react-redux'
import { getFacturasElectronicas } from 'actions/facturas'
import FormFacturasElectronicas from 'components/Facturacion/FormFactura/FormFacturasElectronicas'
import { CREATE_FACTURA_ELECTRONICA } from 'constants/actionTypes'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import facturas from 'reducers/facturas'
import { getInstance } from 'api/apiInstance'

const FacturaElectronicas = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { isLoadingFacturasElectronicas, facturasElectronicas } = useFacturas()
  const { search } = useLocation()
  const searchParams = Object.fromEntries(new URLSearchParams(search))
  const { facturaID } = searchParams

  const lookedItem = useMemo(() => {
    const item = facturasElectronicas[Number(_.findKey(facturasElectronicas, (i) => i.vtaMovmPK.numDocto === +facturaID))] ?? null
    if (item) return { ...item, isEdit: true }
    else return undefined
  }, [facturasElectronicas, facturaID])

  const [facturasFiltradas, setFacturasFiltradas] = useState(facturasElectronicas ?? [])
  const [currentPage, setCurrentPage] = useState(1)
  const [open, setOpen] = useState(lookedItem ? true : false)
  const [editFactura, setEditFactura] = useState(lookedItem ?? {})
  const [modal, setModal] = useState({
    boolean: false,
    data: {},
  })

  const createAlertConfirm = (title, description, callback) => {
    let TitleAlert = document.querySelector('.TitleAlert')
    let descriptionAlert = document.querySelector('.descriptionAlert')
    let ConfirmAlert = document.querySelector('.ConfirmAlert')
    let alertContainer = document.querySelector('.alertContainer')
    alertContainer.className = 'alertContainer'
    TitleAlert.innerHTML = title
    descriptionAlert.innerHTML = description
    let controller = () => {
      callback()
      closeAlert()
      ConfirmAlert.removeEventListener('click', controller)
    }

    ConfirmAlert.addEventListener('click', controller)
  }

  const closeAlert = (event) => {
    let alertContainer = document.querySelector('.alertContainer')
    alertContainer.className = 'alertContainer toggled'
  }

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  const nextPage = () => {
    setCurrentPage((p) => p + 1)
  }

  const prevPage = () => {
    setCurrentPage((p) => p - 1)
  }

  const seeFactura = (factura) => {
    setOpen(true)
    setEditFactura({ ...factura, isEdit: true })
  }

  const copyFactura = async (factura) => {
    if (factura.idMoneda.idMoneda === 2) {
      const request = await getInstance('fe')
      const tasaCambio = await request.get(`vta-movm/consulta-tasa-cambio-dolar`)

      const { value } = tasaCambio.data
      console.log(tasaCambio)
      console.log('Anterior ' + factura.tasaCambio)
      factura.tasaCambio = tasaCambio.data

      setEditFactura({
        ...factura,
        isEdit: false,
        isCopy: true,
      })
      setOpen(true)
    } else {
      setEditFactura({
        ...factura,
        isEdit: false,
        isCopy: true,
      })
      setOpen(true)
    }

    /* setEditFactura({
      ...factura,
      isEdit: false,
      isCopy: true,
    })*/
    /*if (factura.idMoneda.idMoneda === 2) {
      
    } else {
      setEditFactura({
        ...factura,
        isEdit: false,
        isCopy: true,
      })
    }*/
  }

  const handleClose = () => {
    setOpen(false)
    setEditFactura(undefined)
  }

  useEffect(() => {
    const cia = savetaxInfo?.cia?.codCia

    if (!cia) return

    if (!facturasElectronicas?.length) {
      //dispatch(getFacturasElectronicas(cia))
    }

    setFacturasFiltradas(facturasElectronicas)
  }, [savetaxInfo?.cia?.codCia, dispatch, facturasElectronicas])

  const Actualizar = () => {
    //console.log('aaaa')
    setFacturasFiltradas(dispatch(getFacturasElectronicas(savetaxInfo?.cia?.codCia)))
  }

  useEffect(() => {
    setFacturasFiltradas(facturasElectronicas)
  }, [facturasElectronicas])

  return (
    <>
      <div className="FacturaElectronicas" onScroll={hadleScrollSection}>
        <div className="navSection">
          <Filters setter={setFacturasFiltradas} />
          <button className="addContent" onClick={Actualizar} style={{ width: '20%' }}>
            Actualizar
            <i className="bi bi-arrow-clockwise"></i>
          </button>
          <button className="addContent" onClick={() => setOpen(true)} style={{ width: '20%' }}>
            Agregar Facturas
            <i className="bi bi-plus-circle-fill"></i>
          </button>
        </div>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-2">
                <b>Cliente</b>
              </li>
              {/* <li className="col-1">
                <b>Prefijo</b>
              </li> */}
              <li className="col-1">
                <b>Factura</b>
              </li>
              <li className="col-1">
                <b>Moneda</b>
              </li>
              <li className="col-1">
                <b>Monto</b>
              </li>
              <li className="col-1">
                <b>Emisión</b>
              </li>
              <li className="col-2">
                <b>Estado en Hacienda</b>
              </li>
              <li className="col-2">
                <b>Estado del Documento</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>
          {isLoadingFacturasElectronicas ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Facturas Electronicas</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {facturasFiltradas.length > 0 &&
                  facturasFiltradas
                    //.sort((a, b) => b?.vtaMovmPK?.numDocto - a?.vtaMovmPK?.numDocto)
                    .slice(50 * currentPage - 50, 50 * currentPage)
                    .map((factura, index) => (
                      <TableItem
                        key={'factura-' + index}
                        factura={factura}
                        createAlertConfirm={createAlertConfirm}
                        onClickSee={() => seeFactura(factura)}
                        onCopy={() => copyFactura(factura)}
                      />
                    ))}
                {!facturasFiltradas.length && (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        {!isLoadingFacturasElectronicas && facturasFiltradas.length > 50 && (
          <div className="paginationTable">
            <div className="controllers">
              {currentPage > 1 && (
                <button className="previousPage" onClick={prevPage}>
                  <i className="bi bi-chevron-left"></i>
                </button>
              )}
              <span>
                Página {currentPage} de {Math.ceil(facturasFiltradas.length / 50)}
              </span>
              {facturasFiltradas.length > 50 && !(currentPage * 50 >= facturasFiltradas.length) && (
                <button className="nextPage" onClick={nextPage}>
                  <i className="bi bi-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {modal.boolean && (
        <section className="modal-factura">
          <button onClick={() => setModal(false)} className="close-modal-fac">
            X
          </button>
          <div className="container-title-modal">
            <h2 className="title-modal-fac">Gracias por usar</h2>
            <img src={logo} alt="logo" width={200} />
          </div>
          <p className="text-modal-fac">Su factura fue cargada con exito!</p>
          <button
            className="btn-modal-fac"
            onClick={() => {
              const newUrl = `/documentosEmitidos/${modal.data?.vtaMovmPK?.numDocto}`
              window.location.href = newUrl
            }}
          >
            Ver documento
          </button>
        </section>
      )}
      {open && (
        <FormFacturasElectronicas
          editFactura={editFactura}
          handleClose={handleClose}
          tipoFactura={lookedItem ? 3 : 2}
          facturaActionType={CREATE_FACTURA_ELECTRONICA}
          setModal={setModal}
          setFacturas={setFacturasFiltradas}
          facturas={facturasElectronicas}
          title="Factura electrónica"
        />
      )}
    </>
  )
}

export default FacturaElectronicas
