// Import Modules
import axios from 'axios'
import moment from 'moment'
import React from 'react'

// Import context
import useUser from 'hooks/useUser'
import './Notification.css'

const { REACT_APP_API_NOTIFICATION_URL } = process.env

const Notificaciones = () => {
  const { savetaxInfo, notifications } = useUser()

  const checkNotification = (index, event) => {
    event.preventDefault()
    let newNotifications = notifications.filter((notification, i) => i !== index)
    axios.delete(`${REACT_APP_API_NOTIFICATION_URL}/notifications/deleteNitifications/${savetaxInfo.cia.ruc}`, {
      data: newNotifications,
    })
  }

  return (
    <div className="Notificaciones">
      <div className="mainNotification">
        <div className="containerNotifications">
          {!notifications?.length > 0 && (
            <div className="emptyNotifications">
              <i className="bi bi-bell-slash"></i>
              <h4>No hay Notificaciones</h4>
              <p>.</p>
            </div>
          )}
          {notifications?.length > 0 && (
            <div className="sectionNotification">
              <div className="itemsSectionNotification">
                {notifications.reverse().map((item, index) => (
                  <div key={`item-number-${index}`} className="item">
                    <a href={item.payload.urlTarget} target="_blank" rel="noreferrer">
                      <div className="info">
                        <b>{item.payload.title}</b>
                        <p>{item.payload.body}</p>
                      </div>
                      <span className="date">{moment(item.date).format('L')}</span>
                      <button className="checkNotification" onClick={(event) => checkNotification(index, event)}>
                        <i className="bi bi-check-lg"></i>
                      </button>
                      {item.type !== null && (
                        <div className={`typeNotification ${item.type}`}>
                          {item.type === 'llavePorVencer' && <i className="bi bi-clock-fill"></i>}
                          {item.type === 'llaveVencida' && <i className="bi bi-calendar-x-fill"></i>}
                          {item.type === 'documentoRecibido' && <i className="bi bi-file-earmark-plus-fill"></i>}
                          {item.type === 'migracionDocumentos' && <i className="bi bi-arrow-repeat"></i>}
                          {item.type === 'documentoAceptado' && <i className="bi bi-check-circle-fill"></i>}
                          {item.type === 'documentoRechazado' && <i className="bi bi-x-circle-fill"></i>}
                          {item.type !== 'llavePorVencer' &&
                            item.type !== 'llaveVencida' &&
                            item.type !== 'documentoRecibido' &&
                            item.type !== 'documentoRechazado' &&
                            item.type !== 'migracionDocumentos' &&
                            item.type !== 'documentoAceptado' && <i className="bi bi-chat-left-text-fill"></i>}
                        </div>
                      )}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Notificaciones
