import React from 'react'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { motion } from 'framer-motion'
import Text from 'components/FormField/Text'
import { fileToBase64 } from 'helpers/files'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router-dom'
import { getInstance } from 'api/apiInstance'
import useUser from 'hooks/useUser'

const Planes = ({ form, setForm, stepUp, stepDown, variants, handleChange }) => {
  const { hacienda } = form ?? {}
  const { savetaxInfo } = useUser()


  const handleSubmit = async () => {
    await new Promise((res) => setTimeout(res, 5000))
    try {

      const request = await getInstance('admin')
      console.log(hacienda)
      const response = await request.post(`cia/config-inicial`, {
        codCia: savetaxInfo.cia.codCia,
        base64Firma: hacienda.base64Firma,
        usuarioATV: hacienda.usuarioATV,
        contraseniaATV: hacienda.contraseniaATV,
        pinFirma: hacienda.pinFirma,

      })
      console.log(response)
      toast.success('Configuracion Inicial finalizada')
      //Navigate('/escritorio')

    } catch (error) {
      console.error(error.message)
      toast.error('No se pudo configurar completamente')
    }

  }

  return (
    <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
      <div className="Form registroForm">
        <div className="carrusel step01">
          <div className="slide planesStep">
            <div className="steps">
              <span>Paso</span> <b>3</b> <span>de 3</span>
            </div>
            <h4>
              <button className="backButton" onClick={stepDown}>
                <Down />
              </button>
              Cargar Datos de Hacienda
            </h4>

            <div className="formComprobantes">
              <Text label="Usuario de Ingreso (Generado por ATV)" onChange={handleChange}
                name="hacienda.usuarioATV"
                value={hacienda.usuarioATV} />
              <Text
                label="Contraseña de Ingreso (Generado por ATV)"
                onChange={handleChange}
                name="hacienda.contraseniaATV"
                value={hacienda.contraseniaATV}
              />
              <Text
                label="Contraseña firma electrónica"
                onChange={handleChange}
                name="hacienda.pinFirma"
                value={hacienda.pinFirma}
              />

              <label htmlFor="cargarFirma" className="text span2">
                Cargar Firma
                <input
                  type="file"
                  id="cargarFirma"
                  onChange={async (e) => {
                    const base64a = await fileToBase64(e.target.files[0])
                    setForm((prev) => ({
                      ...prev,
                      hacienda: {
                        ...prev.hacienda,
                        base64Firma: base64a
                      },
                    }))
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="actionFixed">
        <button className="btnRegistro btnPlanes" onClick={handleSubmit}>
          Guardar
        </button>
      </div>
    </motion.div>
  )
}

export default Planes
