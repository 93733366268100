/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import React, { useEffect, useState } from 'react'
import FormPersonas from 'components/Form/FormPersonas/FormPersonas'
import { ReactComponent as Create } from 'assets/create.svg'
import { ReactComponent as Edit } from 'assets/edit.svg'
import _ from 'lodash'
import useClientes from 'hooks/useClientes'

const Receptor = ({ factura, setFactura, setSection, action }) => {
  const { isEdit } = factura
  const [persona, setPersona] = useState()
  const [activeForm, setActiveForm] = useState()
  const { clientes, wasAdded } = useClientes()

  const handleAdd = () => {
    setActiveForm(true)
  }

  const hadleEdit = () => {
    setPersona({ ...factura.cliente, isEdit: true })
    setActiveForm(true)
  }

  useEffect(() => {
    if (clientes && clientes.length > 0 && !wasAdded) {
      setFactura((x) => ({
        ...x,
        cliente: (x.cliente = clientes.find(({ clientePK }) => clientePK?.idCliente === x?.cliente?.clientePK?.idCliente)),
      }))
    }
  }, [clientes])

  return (
    <>
      <div className="sectionForm">
        <div className="titleSectionForm" style={{ width: '100%' }}>
          <h3> {action === 'CREATE_FACTURA_COMPRA' ? 'Datos del Emisor' : 'Datos del Receptor'}</h3>
        </div>

        {!isEdit && (
          <div className="actionsSection" style={{ width: '100%' }}>
            <div
              className="searchGeneral btnSection"
              style={{ width: '35%' }}
              onClick={() => setSection('facturaElectronica/SearchClient')}
            >
              <div className="optionsSearchGeneral">
                <button className="option btnSearch">
                  <i className="bi bi-search"></i>
                </button>
              </div>
              <input
                type="text"
                placeholder={action === 'CREATE_FACTURA_COMPRA' ? 'Buscar proveedor' : 'Buscar cliente'}
                style={{ width: '50%' }}
              />
              {/* <div className="optionsSearchGeneral">
                <span>Ctrl + Shift + C</span>
              </div> */}
            </div>
            {_.isEmpty(factura.cliente) ? (
              <button className="addContent" onClick={handleAdd}>
                Agregar Persona
                <Create />
              </button>
            ) : (
              <>
                <button className="addContent" onClick={handleAdd}>
                  Agregar Persona
                  <Create />
                </button>
                <button className="addContent" onClick={hadleEdit}>
                  Editar Persona
                  <Edit />
                </button>
              </>
            )}
          </div>
        )}

        <label htmlFor="nombreDescripcion" className="text" style={{ width: '15%' }}>
          ID Cliente
          {isEdit ? (
            <input
              disabled={true}
              value={factura.cliente.clientePK?.idCliente}
              type="text"
              placeholder="Identificación cliente"
              id="nombreDescripcion"
            />
          ) : (
            <input
              disabled={true}
              value={factura.cliente?.clientePK ? factura.cliente?.clientePK.idCliente : ''}
              type="text"
              placeholder="Identificación cliente"
              id="nombreDescripcion"
            />
          )}
        </label>
        <label htmlFor="nombreDescripcion" className="text" style={{ width: '40%' }}>
          Nombre
          {isEdit ? (
            <input
              disabled={true}
              value={factura.cliente.nombreCliente}
              type="text"
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          ) : (
            <input
              disabled={true}
              value={factura.cliente?.nombreCliente}
              type="text"
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          )}
        </label>
        <label htmlFor="nombreDescripcion" className="text" style={{ width: '20%' }}>
          Número Identidad
          {isEdit ? (
            <input
              disabled={true}
              value={factura.cliente.ruc}
              type="text"
              placeholder="Número Identidad"
              id="nombreDescripcion"
            />
          ) : (
            <input
              disabled={true}
              value={factura.cliente?.ruc}
              type="text"
              placeholder="Número Identidad"
              id="nombreDescripcion"
            />
          )}
        </label>
        <label htmlFor="nombreDescripcion" className="text" style={{ width: '25%' }}>
          Tipo Documento
          {isEdit ? (
            <input
              disabled={true}
              value={factura.cliente.tipoDoctoPersona?.nombreDoctoPersona}
              type="text"
              placeholder="Número Identidad"
              id="nombreDescripcion"
            />
          ) : (
            <input
              disabled={true}
              value={factura.cliente?.tipoDoctoPersona ? factura.cliente?.tipoDoctoPersona.nombreDoctoPersona : ''}
              type="text"
              placeholder="Número Identidad"
              id="nombreDescripcion"
            />
          )}
        </label>
        {factura.cliente?.pais && (
          <label htmlFor="nombreDescripcion" className="text" style={{ width: '33%' }}>
            País
            <input
              disabled={true}
              value={factura.cliente?.pais.nombrePais}
              type="text"
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          </label>
        )}
        {factura.cliente?.departamento && (
          <label htmlFor="nombreDescripcion" className="text" style={{ width: '33%' }}>
            Provincia
            <input
              disabled={true}
              value={factura.cliente?.departamento.nombreDepartamento}
              type="text"
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          </label>
        )}
        {factura.cliente?.idMunicipio && (
          <label htmlFor="nombreDescripcion" className="text" style={{ width: '33%' }}>
            Cantón
            <input
              disabled={true}
              value={factura.cliente?.idMunicipio.nombreMunicipio}
              type="text"
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          </label>
        )}
        {factura.cliente?.distrito && (
          <label htmlFor="nombreDescripcion" className="text" style={{ width: '30%' }}>
            Distrito
            <input
              disabled={true}
              value={factura.cliente?.distrito.nombre}
              type="text"
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          </label>
        )}
        {factura.cliente?.barrio && (
          <label htmlFor="nombreDescripcion" className="text" style={{ width: '30%' }}>
            Barrio
            <input
              disabled={true}
              value={factura.cliente?.barrio.nombre}
              type="text"
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          </label>
        )}
        <label
          htmlFor="nombreDescripcion"
          className="text"
          style={{ width: Number(factura.cliente?.pais?.idPais) === 1 || factura.cliente?.pais == null ? '40%' : '67%' }}
        >
          Dirección
          {isEdit ? (
            <input disabled={true} value={factura.cliente.direccion} type="text" placeholder="Direccion" id="nombreDescripcion" />
          ) : (
            <input
              disabled={true}
              value={factura.cliente?.direccion}
              type="text"
              placeholder="Direccion"
              id="nombreDescripcion"
            />
          )}
        </label>
        <label htmlFor="nombreDescripcion" className="text" style={{ width: '30%' }}>
          Teléfono
          {isEdit ? (
            <input disabled={true} value={factura.cliente.telefono} type="text" placeholder="Telefono" id="nombreDescripcion" />
          ) : (
            <input disabled={true} value={factura.cliente?.telefono} type="text" placeholder="Telefono" id="nombreDescripcion" />
          )}
        </label>
        <label htmlFor="nombreDescripcion" className="text" style={{ width: '30%' }}>
          Celular
          {isEdit ? (
            <input disabled={true} value={factura.cliente.celular} type="text" placeholder="Celular" id="nombreDescripcion" />
          ) : (
            <input disabled={true} value={factura.cliente?.celular} type="text" placeholder="Celular" id="nombreDescripcion" />
          )}
        </label>
        <label htmlFor="nombreDescripcion" className="text" style={{ width: '40%' }}>
          Correo
          {isEdit ? (
            <input disabled={true} value={factura.cliente.correo} type="text" placeholder="Correo" id="nombreDescripcion" />
          ) : (
            <input disabled={true} value={factura.cliente?.correo} type="text" placeholder="Correo" id="nombreDescripcion" />
          )}
        </label>
        <label htmlFor="nombreDescripcion" className="text" style={{ width: '60%' }}>
          Correo Adicionales
          {isEdit ? (
            <input
              disabled={true}
              value={factura.cliente.correosAdicionales}
              type="text"
              placeholder="Correo"
              id="nombreDescripcion"
            />
          ) : (
            <input
              disabled={true}
              value={factura.cliente?.correosAdicionales}
              type="text"
              placeholder="Correo"
              id="nombreDescripcion"
            />
          )}
        </label>
      </div>
      {activeForm && (
        <FormPersonas
          onClose={() => {
            setPersona(undefined)
            setActiveForm(false)
          }}
          persona={persona}
        />
      )}
    </>
  )
}

export default Receptor
