import React, { useState, useEffect } from 'react'
import { ReactComponent as Search } from '../../../assets/search.svg'
import './Cabys.css'

// Import context
import useAdmin from 'hooks/useAdmin'
import Pagination from 'components/common/Pagination'

const Cabys = () => {
  const { cabys, isLoadingCabys } = useAdmin()

  const [cabysFitrados, setCabysFitrados] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filtro, setFiltro] = useState('')

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  const handleOnMouseOverItem = (event) => {
    event.preventDefault()
    let target = event.target
    if (target.attributes['inhover']) {
      target.attributes['inhover'].value = 'hover'
    }
  }

  const handleOnMouseLeaveItem = (event) => {
    event.preventDefault()
    let target = event.target
    if (target.attributes['inhover']) {
      target.attributes['inhover'].value = 'out'
    }
  }

  const filterForSearch = (cabysFiltrados, value) => {
    let result = cabysFiltrados
    if (value.length) {
      result = result.filter(
        (caby) =>
          caby.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          caby.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          caby.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          caby.descripcion3.toUpperCase().includes(value.toUpperCase()) ||
          caby.descripcion4.toUpperCase().includes(value.toUpperCase()) ||
          caby.descripcion5.toUpperCase().includes(value.toUpperCase()) ||
          caby.codigo.includes(value)
      )
    }
    return result
  }

  const filterProducto = (name, value) => {
    let cabysMatch = cabys
    if (cabysMatch.length) {
      if (name === 'search') {
        return filterForSearch(cabysMatch, value)
      } else if (name === 'clear') {
        return cabysMatch
      }
    } else {
      return []
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setFiltro(value)
    setCabysFitrados(filterProducto(name, value))
  }

  const clearFilter = () => setFiltro('')

  useEffect(() => {
    setCabysFitrados(cabys)
  }, [cabys])

  return (
    <div className="Cabys" onScroll={hadleScrollSection}>
      <div className="navSection">
        <b className="title">Filtra Por:</b>
        <label htmlFor="" style={{ width: '350px' }}>
          Nombre o Codigo
          <div className="search">
            <input
              autoComplete="off"
              type="text"
              name="search"
              placeholder="Nombre o Identidad"
              value={filtro.toUpperCase()}
              onChange={hadleFilter}
            />
            <button>
              <Search />
            </button>
          </div>
        </label>
        <div className="sortBy">
          <label htmlFor="" style={{ width: '150px' }}>
            Limpiar Filtros
            <button onClick={clearFilter}>Limpiar</button>
          </label>
        </div>
        <span className="countTable">
          <b>{cabysFitrados.length.toLocaleString('es')}</b> Cabys resultados
        </span>
      </div>
      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-3">
              <b>Nombre</b>
            </li>
            <li className="col-2-2">
              <b>Codigo</b>
            </li>
            <li className="col-4">
              <b>Descripciones</b>
            </li>
            <li className="col-1">
              <b>Tarifa</b>
            </li>
            <li className="col-1-2"></li>
          </ul>
        </div>

        {isLoadingCabys ? (
          <div className="loadContentTable">
            <div className="snippet"></div>
            <span>Cargando Cabys</span>
          </div>
        ) : (
          <div className="bodyList">
            <ul>
              {cabysFitrados.length ? (
                cabysFitrados.slice(50 * currentPage - 50, 50 * currentPage).map((caby) => (
                  <li className="itemBodyList" key={caby.codigo}>
                    <ul
                      className="contentItemBodyList"
                      inhover="out"
                      onMouseOver={handleOnMouseOverItem}
                      onMouseOut={handleOnMouseLeaveItem}
                    >
                      <li className="col-3 noAction">
                        <b>{caby.descripcion.toUpperCase()}</b>
                      </li>
                      <li className="col-2-2 noAction">
                        <span>{caby.codigo}</span>
                      </li>
                      <li className="col-4 noAction">
                        <span>
                          {caby.descripcion1}, {caby.descripcion2}, {caby.descripcion3}, {caby.descripcion4}, {caby.descripcion5}
                        </span>
                      </li>
                      <li className="col-1 noAction">
                        <span>{caby.impuesto}</span>
                      </li>
                      <li className="col-1-2"></li>
                    </ul>
                  </li>
                ))
              ) : (
                <li className="emptyResultTable">
                  <span>No hay Resultados</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      {!isLoadingCabys && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={cabysFitrados} />}
    </div>
  )
}
export default Cabys
