import { useLocation } from 'react-router-dom'

const useSectionTitle = () => {
  const { pathname } = useLocation()

  const getTitle = () => {
    const path = pathname.substring(1)

    switch (path) {
      case 'escritorio':
        return 'Escritorio'
      case 'companias':
        return 'Mantenimiento de Compañias'
      case 'actividadesEconomicas':
        return 'Actividades Económicas'
      case 'usuarios':
        return 'Usuarios'
      case 'consecutivos':
        return 'Consecutivos'
      case 'sucursales':
        return 'Sucursales'
      case 'cajas':
        return 'Cajas'
      case 'cabys':
        return 'Cabys'
      case 'facturasElectronicas':
        return 'Factura Electrónica'
      case 'tiquetasElectronicas':
        return 'Tiquete Electrónico'
      case 'facturasElectronicasPorExportacion':
        return 'Factura Electrónica de Exportación'
      case 'facturasElectronicasDeCompra':
        return 'Factura Electrónica de Compra'
      case 'proformasElectronicas':
        return 'Proformas Electrónicas'
      case 'notasCredito':
        return 'Notas de Crédito'
      case 'notasDebito':
        return 'Notas de Débito'
      case 'documentosEmitidos':
        return 'Documentos Emitidos'
      case 'documentosRecibidos':
        return 'Documentos Recibidos'
      case 'personas':
        return 'Personas'
      case 'productos':
        return 'Productos'
      case 'configuraciones':
        return 'Configuraciones'
      case 'ayuda/productos':
        return 'Documentación Productos'
      case 'ayuda/personas':
        return 'Documentación Personas'
      case 'ayuda/accesosDirectosCombinacionesTeclado':
        return 'Accesos Directos y Combinaciones de Teclado'
      case 'ayuda/personalizarEscritorio':
        return 'Personalizar Escritorio'
      case 'ayuda/personalizarNavegacion':
        return 'Personalizar Navegacion'
      default:
        return 'Escritorio'
    }
  }

  return { getTitle }
}

export default useSectionTitle
