import { toast } from 'react-toastify'
import { fetchTotales } from '../../Sidebar/Sidebar'

const useExoneraciones = () => {
  const exonerarProducto = (product, docExonerado) => {
    const { poseeCabys, cabys, numeroDocumento, fechaEmision, nombreInstitucion, porcentajeExoneracion, tipoDocumento } =
      docExonerado

    let exonerar = true
    const { cabys: productCabys, iva, descuento, precioVenta, precioVtaUnit, cantidad } = product
    const { codigo } = productCabys

    if (poseeCabys) {
      exonerar = cabys.findIndex((el) => el === codigo) >= 0
    }

    let totalExonerado = exonerar
      ? ((+precioVenta ?? +precioVtaUnit) * +cantidad - descuento) * (+porcentajeExoneracion / 100)
      : 0

    if (totalExonerado > iva) {
      totalExonerado = iva
    }

    return {
      ...product,
      exonerarIva: exonerar,
      documentoExoneracion: numeroDocumento,
      fechaExoneracion: fechaEmision,
      institucionExonera: nombreInstitucion,
      porcentajeExonera: +porcentajeExoneracion.toFixed(2),
      idTipoDoctoExonera: tipoDocumento,
      montoExonera: totalExonerado,
    }
  }

  const calcularExoneraciones = (factura, docExonerado) => {
    const { vtaDetmList: products } = factura

    return {
      ...factura,
      vtaDetmList: products?.map((product) => exonerarProducto(product, docExonerado)),
      docExonerado,
    }
  }

  const limpiarExoneraciones = async (factura, setFactura) => {
    const { vtaDetmList: products, docExonerado } = factura
    if (!docExonerado?.numeroDocumento) {
      toast.info('No hay exoneracion que limpiar')
      return
    }

    const originalProducts = products?.map((product) => {
      const { subtotal, iva, otrosImpuestos, descuento } = product

      return {
        ...product,
        total: subtotal + iva + otrosImpuestos - descuento,
        exonerarIva: undefined,
        documentoExoneracion: undefined,
        fechaExoneracion: undefined,
        institucionExonera: undefined,
        porcentajeExonera: undefined,
        idTipoDoctoExonera: undefined,
        montoExonera: undefined,
      }
    })

    await fetchTotales(
      {
        ...factura,
        vtaDetmList: originalProducts,
        docExonerado: undefined,
      },
      setFactura
    )
  }

  return {
    exonerarProducto,
    calcularExoneraciones,
    limpiarExoneraciones,
  }
}

export default useExoneraciones
