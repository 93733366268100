import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'
import { getInstance } from 'api/apiInstance'

const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const downloadFile = async (file, type = 'pdf') => {
  if (!(file.prefijo || file.referencia)) {
    return toast.info('Esta factura todavia no esta generada')
  }

  try {
    toast.info('Descargando archivo...', {
      toastId: `${file.vtaMovmPK.codCia}/${file.vtaMovmPK.idTipoDoctoVta}/${file.vtaMovmPK.idSucursal}/${file.vtaMovmPK.numDocto}`,
      hideProgressBar: true,
    })
    const request = await getInstance('', null, 'blob')
    const res = await request.get(
      `vta-movm/${type}/${file.vtaMovmPK.codCia}/${file.vtaMovmPK.idTipoDoctoVta}/${file.vtaMovmPK.idSucursal}/${file.vtaMovmPK.numDocto}`
    )

    saveAs(res.data, `Factura Electronica ${file.prefijo} ${file.referencia}.${type}`)
    setTimeout(
      () =>
        toast.update(
          `${file.vtaMovmPK.codCia}/${file.vtaMovmPK.idTipoDoctoVta}/${file.vtaMovmPK.idSucursal}/${file.vtaMovmPK.numDocto}`,
          { type: toast.TYPE.SUCCESS, render: 'Descargado exitosamente', hideProgressBar: false }
        ),
      300
    )
  } catch (error) {
    console.error(error.message)
    toast.update(
      `${file.vtaMovmPK.codCia}/${file.vtaMovmPK.idTipoDoctoVta}/${file.vtaMovmPK.idSucursal}/${file.vtaMovmPK.numDocto}`,
      { type: toast.TYPE.ERROR, render: 'Error al descargar el archivo', hideProgressBar: false }
    )
  }
}

export { fileToBase64, downloadFile }
