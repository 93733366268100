import { updateProducto, uploadProducto } from 'actions/productos'
import React from 'react'
import { useDispatch } from 'react-redux'

const ProductoSidebar = ({ product, setProduct, onClose }) => {
  const dispatch = useDispatch()
  const { isEdit } = product

  const handleSubmit = () => {
    if (isEdit) {
      dispatch(updateProducto(product, onClose))
    } else {
      dispatch(uploadProducto(product, onClose))
    }
  }

  return (
    <>
      <div className="actionForm">
        <button className="saveForm" onClick={handleSubmit} style={{ marginRight: 'auto', marginLeft: '0px' }}>
          {isEdit ? 'Actualizar' : 'Guardar'}
        </button>
      </div>

      <label htmlFor="CodigodeProductoInterno" className="text">
        Código de Producto Interno
        <input
          disabled={true}
          type="text"
          value={product?.productoPK?.idProducto ?? ''}
          placeholder="Código de Producto Interno"
          id="CodigodeProductoInterno"
        />
      </label>

      <label htmlFor="CabysCodigo" className="text">
        Cabys Código
        <input type="text" placeholder="Cabys Código" id="CabysCodigo" disabled={true} value={product?.cabys?.codigo ?? ''} />
      </label>

      <label htmlFor="CabysDescripcion" className="text">
        Cabys Descripción
        <textarea
          value={product?.cabys?.descripcion ?? ''}
          name=""
          placeholder="Cabys Código"
          id="CabysDescripcion"
          cols="30"
          rows="5"
          disabled={true}
        ></textarea>
      </label>

      <label htmlFor="manejaInventario" className="checkbox" hidden={true}>
        
        <input
        hidden={true}
          type="checkbox"
          onChange={() => {
            setProduct((prev) => ({
              ...prev,
              manejaInventario: !prev.manejaInventario,
            }))
          }}
          value={product?.manejaInventario ?? false}
          checked={product?.manejaInventario ?? false}
          id="manejaInventario"
        />
      </label>
    </>
  )
}

export default ProductoSidebar
