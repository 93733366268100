import React, { useCallback, useEffect, useState } from 'react'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { Select } from '../EncabezadoYReceptor/Encabezado'
import useMisc from 'hooks/useMisc'
import { useDispatch } from 'react-redux'
import { getTarifas, getTiposDeIva, getTiposDocExonera } from 'actions/misc'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import { toast } from 'react-toastify'
import axios from 'axios'
import DatePicker from 'react-date-picker'

const IVA = ({ factura, handleChange, setFactura, setSection }) => {
  const dispatch = useDispatch()

  const { isEdit } = factura
  const { tiposDeIva, tarifas, isLoadingTarifas, isLoadingTiposDeIva, tiposDocExonera } = useMisc()

  const [calculator, setCalculator] = useState(false)
  const [baseImponible, setBaseImponible] = useState(false)
  const [exoDoc, setExoDoc] = useState('')
  const [loadingExo, setLoadingExo] = useState(false)
  const [isSearchExoneraDoc, setIsSearchExoneraDoc] = useState(false)
  const [fechaExoneracion, setFechaExoneracion] = useState(new Date())

  useEffect(() => {
    dispatch(getTiposDocExonera)
    if (isLoadingTiposDeIva) {
      dispatch(getTiposDeIva)
    }
  }, [dispatch, isLoadingTiposDeIva])

  useEffect(() => {
    if (isLoadingTarifas) {
      dispatch(getTarifas)
    }
  }, [dispatch, isLoadingTarifas])

  const handleBack = useCallback(() => {
    setSection('facturaElectronica/DetallesFactura')
  }, [setSection])

  const handleChangeTipoIva = useCallback(
    (e, tarifas) => {
      const value = e.target.value
      console.log("handleChangeTipoIva", value, factura?.productEnLinea?.impuesto?.tipoImpuesto?.idTipoImpuesto)
      let impuestox = tarifas.filter((item) => item.impuestoPK.idImpuesto == value)
      console.log("handleChangeTipoIva sdd", impuestox)
      setFactura((prev) => ({
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          impuesto: {
            ...prev.productEnLinea.impuesto,
            tasa: impuestox[0].tasa,
            impuestoPK: {
              idTipoImpuesto: factura?.productEnLinea?.impuesto?.tipoImpuesto?.idTipoImpuesto,
              idImpuesto: value
            }
          }
        },
      }))
    },
    [setFactura]
  )

  const handleChangeMotivoExonera = useCallback(
    (e) => {
      const value = e.target.value
      setFactura((prev) => ({
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          idTipoDoctoExonera: {
            id: value,
          },
        },
      }))
    },
    [setFactura]
  )

  const handleChangePorcExonera = useCallback(
    (e) => {
      const value = e.target.value
      setFactura((prev) => ({
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          porcentajeExonera: value,
          exonerarIva: value !== '' && value > 0 ? true : false,
        },
      }))
    },
    [setFactura]
  )

  const getMotivoExoneraByCodeApi = (code) => {
    let motivoExonera = tiposDocExonera.filter((item) => item.code == code)
    return motivoExonera.length > 0 ? motivoExonera[0] : {}
  }

  const handleSearchExo = async () => {
    setLoadingExo(true)

    try {
      const exoneracion = await axios(
        `https://api.hacienda.go.cr/fe/ex?autorizacion=${factura?.productEnLinea?.documentoExoneracion}`
      )
      console.log(exoneracion)
      setFactura((prev) => ({
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          exonerarIva: true,
          documentoExoneracion: exoneracion?.data?.numeroDocumento,
          fechaExoneracion: exoneracion?.data?.fechaEmision,
          institucionExonera: exoneracion?.data?.nombreInstitucion,
          porcentajeExonera: +exoneracion?.data?.porcentajeExoneracion.toFixed(2),
          idTipoDoctoExonera: getMotivoExoneraByCodeApi(exoneracion?.data?.tipoDocumento.code),
        },
      }))
      setIsSearchExoneraDoc(true)
      setLoadingExo(false)
      setFechaExoneracion(new Date(exoneracion?.data?.fechaEmision))
    } catch (error) {
      toast.warning('El documento no es válido')
      console.error(error.message)
      setLoadingExo(false)
      setIsSearchExoneraDoc(false)
    }
  }

  const _handleSetDateExonera = useCallback(
    (value) => {
      setFechaExoneracion(value)
      setFactura((prev) => ({
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          fechaExoneracion: new Date(value).toISOString().slice(0, 10) + 'T00:00:00',
        },
      }))
    },
    [setFactura]
  )

  const getPorcentajeExonera = (value) => {
    return value === undefined ? '' : value
  }

  return (
    <div className="sectionForm">
      <div className="BackSection" onClick={handleBack}>
        <Down /> Atrás
      </div>
      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Calcular IVA</h3>
      </div>
      <div className="dropDownSection" onClick={() => setCalculator((prev) => !prev)}>
        <span>Calcular IVA</span>
        <hr />
        <button>
          <i className="bi bi-chevron-down"></i>
        </button>
      </div>

      {calculator && (
        <div className="dropDownItem">
          <Select
            id="tipoIVA"
            label="Tipo IVA"
            name={'productEnLinea.impuesto.tipoImpuesto.idTipoImpuesto'}
            onChange={handleChange}
            disabled={isEdit || isLoadingTiposDeIva}
            value={factura?.productEnLinea?.impuesto?.tipoImpuesto?.idTipoImpuesto ?? ''}
            width={'40%'}
          >
            {tiposDeIva.map((tipo) => (
              <option key={Math.random().toString(36).slice(-10)} value={tipo.idTipoImpuesto}>
                {tipo.descripcion}
              </option>
            ))}
          </Select>
          <Select
            id="codigoDeTarifa"
            label="Código de Tarifa"
            //name={'productEnLinea.impuesto.impuestoPK.idImpuesto'}
            onChange={(e) => handleChangeTipoIva(e, tarifas[factura?.productEnLinea?.impuesto?.tipoImpuesto?.idTipoImpuesto])}
            disabled={isEdit || isLoadingTarifas}
            value={factura?.productEnLinea?.impuesto?.impuestoPK?.idImpuesto ?? ''}
            width={'40%'}
          >
            {tarifas[factura?.productEnLinea?.impuesto?.tipoImpuesto?.idTipoImpuesto].map((tarifa) => (
              <option key={Math.random().toString(36).slice(-10)} value={tarifa.impuestoPK.idImpuesto}>
                {tarifa.codigo} {tarifa.descripcion}
              </option>
            ))}
          </Select>
          <label htmlFor="baseImponible" className="text" style={{ width: '20%' }}>
            Base Imponible
            <input type="text" value={factura?.productEnLinea?.impuesto?.base} placeholder="Base Imponible" name="productEnLinea.impuesto.base" onChange={handleChange} />
          </label>
        </div>
      )}
      <div className="dropDownSection" id="datosReceptor" onClick={() => setBaseImponible((prev) => !prev)}>
        <span>Exonerar IVA</span>
        <hr />
        <button>
          <i className="bi bi-chevron-down"></i>
        </button>
      </div>
      {baseImponible && (
        <div className="dropDownItem">
          <div className="actionsSection" style={{ width: '100%' }}>
            <label htmlFor="BuscarDescripción" className="text" style={{ width: '51%' }}>
              Documento de Exoneración
              <div className="searchForm">
                <input
                  value={factura?.productEnLinea?.documentoExoneracion ?? ''}
                  name={'productEnLinea.documentoExoneracion'}
                  onChange={handleChange}
                  disabled={isEdit}
                  type="text"
                  autoComplete="off"
                  placeholder="Documento de Exoneración"
                  id="BuscarDescripción"
                />
                <button
                  disabled={loadingExo}
                  className="searchPersonaForID"
                  onClick={handleSearchExo}
                  style={{ cursor: 'pointer' }}
                >
                  <i className="bi bi-search"></i>
                </button>
                {loadingExo && <CircularProgress />}
              </div>
            </label>
          </div>

          <label htmlFor="fechaExoneracion" className="text" style={{ width: '50%' }}>
            Fecha de Exoneración
            {/*      <input
              value={factura?.productEnLinea?.fechaExoneracion ? getDateExoneraParse(factura?.productEnLinea?.fechaExoneracion) : ''}
              type="text"
              placeholder="Fecha de Exoneración"
              readOnly={isEdit || isSearchExoneraDoc}
              name={"productEnLinea.fechaExoneracion"}
              onChange={handleChange}
              id="fechaExoneracion"
            /> */}
            <div className="box-fechaExoneracion">
              <DatePicker value={fechaExoneracion} onChange={(value) => _handleSetDateExonera(value)} format="dd/M/y" />
            </div>
          </label>

          {/*    <label htmlFor="motivoExoneracion" className="text" style={{ width: '70%' }}>
            Motivo de Exoneración
              <input
              value={factura?.productEnLinea.idTipoDoctoExonera?.descripcion ?? ''}
              type="text"
              placeholder="Motivo de Exoneración"
              id="motivoExoneracion"
            />

         
          </label> */}

          <Select
            id="motivoExoneracion"
            label="Motivo de Exoneración"
            name={'productEnLinea.idTipoDoctoExonera.id'}
            onChange={handleChangeMotivoExonera}
            value={factura?.productEnLinea?.idTipoDoctoExonera?.id ?? ''}
            disabled={isEdit}
            width={'50%'}
          >
            {tiposDocExonera.map((tipo) => (
              <option key={Math.random().toString(36).slice(-10)} value={tipo.id}>
                {tipo.nombre}
              </option>
            ))}
          </Select>

          <label htmlFor="institucionExo" className="text" style={{ width: '50%' }}>
            Institución de Exoneración
            <input
              value={factura?.productEnLinea?.institucionExonera ?? ''}
              readOnly={isEdit || isSearchExoneraDoc}
              type="text"
              onChange={handleChange}
              name={'productEnLinea.institucionExonera'}
              placeholder="Institución de Exoneración"
              id="institucionExo"
            />
          </label>

          <label htmlFor="porcentajeExo" className="text" style={{ width: '50%' }}>
            Porcentaje de Exoneración
            <input
              value={
                isSearchExoneraDoc
                  ? `${factura?.productEnLinea?.porcentajeExonera}%`
                  : getPorcentajeExonera(factura?.productEnLinea?.porcentajeExonera)
              }
              type="number"
              readOnly={isEdit || isSearchExoneraDoc}
              placeholder="Porcentaje de Exoneración"
              id="porcentajeExo"
              name={'productEnLinea.porcentajeExonera'}
              onChange={handleChangePorcExonera}
            />
          </label>
        </div>
      )}
    </div>
  )
}

export default IVA
