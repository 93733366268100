import React from 'react'
import { saveAs } from 'file-saver'

import { getURL } from 'api/apiInstance'


const NavigationButton = ({ children, section, setSection, activeSection }) => (
  <button className={`btnSection ${activeSection === section ? 'active' : ''}`} onClick={() => setSection(section)}>
    {children}
  </button>
)

const FormNavigation = ({ factura, section, setSection, action }) => {
  const { isEdit } = factura

  return (
    <div className="navigationForm" style={{ width: '100%' }}>
      <NavigationButton section={'facturaElectronica/Encabezado'} setSection={setSection} activeSection={section}>
        Encabezado
      </NavigationButton>
      <NavigationButton section={'facturaElectronica/Receptor'} setSection={setSection} activeSection={section}>
        {action === 'CREATE_FACTURA_COMPRA' ? 'Datos del Emisor' : 'Datos del Receptor'}
      </NavigationButton>
      <NavigationButton section={'facturaElectronica/DetallesFactura'} setSection={setSection} activeSection={section}>
        Lineas Detalle
      </NavigationButton>
      <NavigationButton section={'facturaElectronica/OtrosCargos'} setSection={setSection} activeSection={section}>
        Otros Cargos
      </NavigationButton>
      <NavigationButton section={'facturaElectronica/Contingencia'} setSection={setSection} activeSection={section}>
        Contingencia
      </NavigationButton>

      {isEdit && (
        <div className="setOptions">
          <span
            onClick={(event) =>
              saveAs(
                `${getURL("fe")}vta-movm/pdf/` +
                  factura.vtaMovmPK.codCia +
                  '/' +
                  factura.vtaMovmPK.idTipoDoctoVta +
                  '/' +
                  factura.vtaMovmPK.idSucursal +
                  '/' +
                  factura.vtaMovmPK.numDocto +
                  '',
                `Factura Electronica ${factura.prefijo}  ${factura.referencia}.pdf`,
                event
              )
            }
            className="formDownloadButton"
            title="Descargar PDF"
          >
            <i className="bi bi-filetype-pdf"></i>
            Descargar PDF
          </span>
          <span
            className="formDownloadButton"
            onClick={(event) =>
              saveAs(
                `${getURL("fe")}vta-movm/xml/` +
                  factura.vtaMovmPK.codCia +
                  '/' +
                  factura.vtaMovmPK.idTipoDoctoVta +
                  '/' +
                  factura.vtaMovmPK.idSucursal +
                  '/' +
                  factura.vtaMovmPK.numDocto +
                  '',
                `XML Factura Electronica ${factura.prefijo} ${factura.referencia}.xml`,
                event
              )
            }
            title="Descargar XML"
          >
            <i className="bi bi-filetype-key"></i>
            Descargar XML
          </span>
        </div>
      )}
    </div>
  )
}

export default FormNavigation
