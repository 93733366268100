import { GET_PRODUCTOS, UPLOAD_PRODUCTO, UPDATE_PRODUCTO } from 'constants/actionTypes'

const initialState = {
  isLoadingProductos: true,
  productos: [],
}

const handles = {
  [GET_PRODUCTOS]: (state, { payload = {} }) => {
    return { ...state, isLoadingProductos: false, productos: payload?.productos }
  },
  [UPLOAD_PRODUCTO]: (state, { payload = {} }) => ({ ...state, productos: [payload?.producto, ...state.productos] }),
  [UPDATE_PRODUCTO]: (state, { payload = {} }) => ({
    ...state,
    productos: state.productos.map((p) =>
      Number(p?.productoPK?.idProducto) === Number(payload?.producto?.productoPK?.idProducto) ? payload?.producto : p
    ),
  }),
}

const productos = (state = initialState, action) => {
  const { type } = action

  return handles[type] ? handles[type](state, action) : state
}

export default productos
