import useMisc from 'hooks/useMisc'
import { getInstance } from 'api/apiInstance'

export const useGetPersona = () => {
  const { paises, provincias, cantones, distritos, barrios } = useMisc()

  const getPersona = async (form) => {
    const { persona } = form
    const tipoPersona = String(persona.tipoPersona).replace('0', '')
    const pais = paises.find((pais) => pais.idPais === persona.pais.idPais)
    const departamento = provincias.find((provincia) => String(provincia.idDepartamento) === persona.provincia)
    const idMunicipio = cantones.find((canton) => String(canton.idMunicipio) === persona.canton)
    const distrito = distritos.find((distrito) => String(distrito.idDistrito) === persona.distrito)
    const barrio = barrios.find((barrio) => String(barrio.idBarrio) === persona.barrio)

    const body = {
      ...persona,
      tipoPersona,
      pais,
      departamento,
      idMunicipio,
      barrio,
      distrito,
      idLicencia: '1',
      //fechaResolucion: new Date().toString(),
    }

    body.razonSocial = ''
    body.valIva = ''
    body.idLicencia = '1'
    if (!('celular' in body) || !body.celular) {
      body.celular = ''
    }
    body.fax = ''
    body.identificacionRepresentante = ''
    body.representanteLegal = ''
    body.nombreLogo = ''
    body.primeraVez = true

    console.log('body', body)

    const request = await getInstance()
    return await request.post(`cia/crear-cuenta`, body).then((response) => response.data)
  }

  return getPersona
}
