import { getActividadesEconomicas } from 'actions/administracion'
import { updateDocumentRecibido } from 'actions/documentos'
import { ReactComponent as Cancel } from 'assets/cancel.svg'
import { impuestos } from 'constants/tarifas'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
const KeyviewDocumentosRecibidos = ({ open, onClose, type, documents, selectedId }) => {
  const dispatch = useDispatch()
  const document = documents[selectedId]
  const { actividadesEconomicas, isLoadingActividadesEconomicas } = useAdmin()
  const title = type === 'accept' ? 'Aceptar documento' : 'Rechazar documento'
  const [form, setForm] = useState({})
  const { savetaxInfo } = useUser()
  const [actividadesEconomicasFiltradas, setActividadesEconomicasFiltradas] = useState([])
  const [actividadValue, setActividadValue] = useState(actividadesEconomicasFiltradas ? actividadesEconomicasFiltradas[0] : '')

  useEffect(() => {
    if (isLoadingActividadesEconomicas) {
      dispatch(getActividadesEconomicas(savetaxInfo.cia.codCia))
    }

    setActividadesEconomicasFiltradas(actividadesEconomicas)
    if (actividadValue) {
      setForm({ ...form, actividadEconomicaCia: actividadValue })
    }
  }, [
    form,
    dispatch,
    actividadesEconomicas,
    actividadValue,
    savetaxInfo.cia.codCia,
    isLoadingActividadesEconomicas,
    actividadesEconomicasFiltradas,
  ])

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleChangeActividad = (e) => {
    const code = +e.target.value
    // find actividad en lista de actividades
    const actividadEconomicaCia = actividadesEconomicas.find(({ actividadEconomica }) => actividadEconomica.codigo === code)
    setActividadValue(actividadEconomicaCia)
    setForm({ ...form, actividadEconomicaCia })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const descripcionSri = type === 'accept' ? 'ACEPTADO' : 'RECHAZADO'
    const codigoMensaje = type === 'accept' ? '1' : '3'
    const body = {
      ...document,
      descripcionSri,
      codigoMensaje,
      ...form,
    }
    console.log(body)
    dispatch(updateDocumentRecibido(body))
    onClose()
  }

  const renderForm = () => {
    switch (type) {
      case 'accept':
        return (
          <>
            <label htmlFor="" style={{ width: '35%' }} className="togglebled">
              Condición de Impuesto
              <select
                name="idCondicionImpuesto"
                id="idCondicionImpuesto"
                value={form.idCondicionImpuesto ?? ''}
                onChange={handleChange}
              >
                <option value="">Seleccionar</option>
                {impuestos.map((imp) => (
                  <option key={imp.value} value={imp.value}>
                    {imp.label}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="" style={{ width: '35%' }} className="togglebled">
              Actividad Economica
              <select
                name="actividadEconomicaCia"
                id="actividadEconomicaCia"
                value={actividadValue}
                onChange={handleChangeActividad}
                aria-label={actividadValue?.actividadEconomica?.nombre}
              >
                {actividadesEconomicasFiltradas?.map(({ actividadEconomica: actividad }) => (
                  <option key={actividad.codigo} value={actividad.codigo}>
                    {actividad.nombre}
                  </option>
                ))}
              </select>
            </label>
            <button className="confirmButton" type="submit">
              CONFIRMAR
            </button>
          </>
        )
      case 'reject':
        return (
          <>
            <label htmlFor="" style={{ width: '35%' }} className="togglebled">
              Motivo Rechazo
              <textarea
                name="motivoRechazo"
                id="motivoRechazo"
                value={form.motivoRechazo ?? ''}
                onChange={handleChange}
                cols="30"
                rows="10"
              ></textarea>
            </label>
            <button className="confirmButton" type="submit">
              CONFIRMAR
            </button>
          </>
        )
      default:
        return null
    }
  }

  return (
    <div className={`keyView ${open ? '' : 'toggled'}`}>
      <header className="keyview-content">
        <h3>
          <span className="title">{title}</span>
          <button className="closeButton" onClick={onClose}>
            <Cancel />
          </button>
        </h3>
        <ul className="metadata">
          <li className="tipoDocumento">
            <b>Tipo Documento:</b> <span className="dato">{document?.tipoDoctoVta?.nombreDoctoVta ?? ''}</span>
          </li>
          <li className="claveDocumento">
            <b>Clave:</b> <span className="dato">{document?.clave ?? ''}</span>
          </li>
        </ul>
        <form onSubmit={handleSubmit}>{renderForm()}</form>
      </header>
    </div>
  )
}
export default KeyviewDocumentosRecibidos
