import {
  GET_MONEDAS,
  GET_METODOS_DE_PAGO,
  GET_CONDICIONES_DE_VENTA,
  GET_UNIDADES_DE_MEDIDA,
  GET_OTROS_CARGOS,
  GET_TIPOS_IVA,
  GET_TIPOS_DOC_EXONERA,
  GET_TIPOS_IMPUESTOS,
  GET_TIPOS_DOCS,
  GET_TARIFAS,
  GET_CATALOGO_ACTIVIDADES,
  GET_DATOS_GEOGRAFICOS,
  GET_CODIGOS_REFERENCIA,
} from '../constants/actionTypes'

const initialState = {
  monedas: [],
  metodosDePago: [],
  condicionesDeVenta: [],
  unidadesDeMedida: [],
  otrosCargos: [],
  tiposDeIva: [],
  tiposDocExonera: [],
  tiposImpuestos: [],
  tiposDocs: [],
  catalogoActividades: [],
  paises: [],
  provincias: [],
  cantones: [],
  distritos: [],
  barrios: [],
  isLoadingTarifas: true,
  isLoadingTiposDeIva: true,
  isLoadingDatosGeograficos: true,
  tarifas: undefined,
  codigosReferencia: [],
}

const handles = {
  [GET_MONEDAS]: (state, { payload = {} }) => {
    return { ...state, monedas: payload?.monedas }
  },
  [GET_METODOS_DE_PAGO]: (state, { payload = {} }) => {
    return { ...state, metodosDePago: payload?.metodosDePago }
  },
  [GET_CONDICIONES_DE_VENTA]: (state, { payload = {} }) => {
    return { ...state, condicionesDeVenta: payload?.condicionesDeVenta }
  },
  [GET_UNIDADES_DE_MEDIDA]: (state, { payload = {} }) => {
    return { ...state, unidadesDeMedida: payload?.unidadesDeMedida }
  },
  [GET_OTROS_CARGOS]: (state, { payload = {} }) => {
    return { ...state, otrosCargos: payload?.otrosCargos }
  },
  [GET_TIPOS_IVA]: (state, { payload = {} }) => {
    return { ...state, tiposDeIva: payload?.tiposDeIva, isLoadingTiposDeIva: false }
  },
  [GET_TIPOS_DOC_EXONERA]: (state, { payload = {} }) => {
    return { ...state, tiposDocExonera: payload?.tiposDocExonera }
  },
  [GET_TIPOS_IMPUESTOS]: (state, { payload = {} }) => {
    return { ...state, tiposImpuestos: payload?.tiposImpuestos }
  },
  [GET_TIPOS_DOCS]: (state, { payload = {} }) => {
    return { ...state, tiposDocs: payload?.tiposDocs }
  },
  [GET_CODIGOS_REFERENCIA]: (state, { payload = {} }) => {
    return { ...state, codigosReferencia: payload?.codigosReferencia }
  },
  [GET_TARIFAS]: (state, { payload = {} }) => {
    return { ...state, tarifas: { ...payload?.tarifas }, isLoadingTarifas: false }
  },
  [GET_CATALOGO_ACTIVIDADES]: (state, { payload = {} }) => {
    return { ...state, catalogoActividades: payload.actividades }
  },
  [GET_DATOS_GEOGRAFICOS]: (state, { payload = {} }) => {
    return {
      ...state,
      isLoadingDatosGeograficos: false,
      paises: payload?.paises,
      provincias: payload?.provincias,
      cantones: payload?.cantones,
      distritos: payload?.distritos,
      barrios: payload?.barrios,
    }
  },
}

const misc = (state = initialState, action) => {
  const { type } = action

  return handles[type] ? handles[type](state, action) : state
}

export default misc
