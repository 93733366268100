// Import Modules
import React, { useEffect, useState } from 'react'
import { parseDates } from 'helpers/date'

// Import StyleSheet
import './Resumen.css'


const Resumen = ({ docs, date }) => {
  const [sumas, setSumas] = useState({
    transitorio: 0,
    tarifaReducida1: 0,
    tarifaReducida2: 0,
    tarifaReducida4: 0,
    tarifaGeneral13: 0,
    iva0: 0,
    iva1: 0,
    iva2: 0,
    iva4: 0,
    iva13: 0,
    ivaD0: 0,
    ivaD1: 0,
    ivaD2: 0,
    ivaD4: 0,
    ivaD13: 0,
    exo0: 0,
    exo1: 0,
    exo2: 0,
    exo4: 0,
    exo13: 0,
  })
  const Total = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      /* let iva = e.iva / e.tasaCambio */
      let cambio = e.subtotal * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.subtotal
  }, 0)

  const TotalIva = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.iva * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.iva
  }, 0)

  const TotalExonerado = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      /* let iva = e.iva / e.tasaCambio */
      let cambio = e.totalExonerado * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totalExonerado
  }, 0)

  const TotalIvaD = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.totalIvaDevuelto * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totalIvaDevuelto
  }, 0)

  useEffect(() => {
    const calcularSumas = () => {
      const nuevasSumas = {
        transitorio: 0,
        tarifaReducida1: 0,
        tarifaReducida2: 0,
        tarifaReducida4: 0,
        tarifaGeneral13: 0,
        iva0: 0,
        iva1: 0,
        iva2: 0,
        iva4: 0,
        iva13: 0,
        ivaD0: 0,
        ivaD1: 0,
        ivaD2: 0,
        ivaD4: 0,
        ivaD13: 0,
        exo0: 0,
        exo1: 0,
        exo2: 0,
        exo4: 0,
        exo13: 0,
      }

      docs.map((e) => {
        if (e.tasaCambio) {
          let cambio = e.subtotal * e.tasaCambio
          let iva = e.iva * e.tasaCambio
          let ivaD = e.totalIvaDevuelto * e.tasaCambio
          let exo = e.totalExonerado * e.tasaCambio

          e.vtaDetmList.map((item) => {
            if (item?.impuesto?.descripcion === 'TARIFA 0%(EXENTO)') {
              nuevasSumas.transitorio += cambio
              nuevasSumas.iva0 += iva
              nuevasSumas.ivaD0 += ivaD
              nuevasSumas.exo0 += exo
            } else if (item?.impuesto?.descripcion === 'TARIFA REDUCIDA 1%') {
              nuevasSumas.tarifaReducida1 += cambio
              nuevasSumas.iva1 += iva
              nuevasSumas.ivaD1 += ivaD
              nuevasSumas.exo1 += exo
            } else if (item?.impuesto?.descripcion === 'TARIFA REDUCIDA 2%') {
              nuevasSumas.tarifaReducida2 += cambio
              nuevasSumas.iva2 += iva
              nuevasSumas.ivaD2 += ivaD
              nuevasSumas.exo2 += exo
            } else if (item?.impuesto?.valor === 'TARIFA REDUCIDA 4%') {
              nuevasSumas.tarifaReducida4 += cambio
              nuevasSumas.iva4 += iva
              nuevasSumas.ivaD4 += ivaD
              nuevasSumas.exo4 += exo
            } else if (item?.impuesto?.descripcion === 'TARIFA GENERAL 13%') {
              nuevasSumas.tarifaGeneral13 += cambio
              nuevasSumas.iva13 += iva
              nuevasSumas.ivaD13 += ivaD
              nuevasSumas.exo13 += exo
            } else {
              if (item?.impuesto?.descripcion === 'TARIFA 0%(EXENTO)') {
                nuevasSumas.transitorio += e.subtotal
                nuevasSumas.iva0 += e.iva
                nuevasSumas.ivaD0 += e.totalIvaDevuelto
                nuevasSumas.exo0 += e.totalExonerado
              } else if (item?.impuesto?.descripcion === 'TARIFA REDUCIDA 1%') {
                nuevasSumas.tarifaReducida1 += e.subtotal
                nuevasSumas.iva1 += e.iva
                nuevasSumas.ivaD1 += e.totalIvaDevuelto
                nuevasSumas.exo1 += e.totalExonerado
              } else if (item?.impuesto?.descripcion === 'TARIFA REDUCIDA 2%') {
                nuevasSumas.tarifaReducida2 += e.subtotal
                nuevasSumas.iva2 += e.iva
                nuevasSumas.ivaD2 += e.totalIvaDevuelto
                nuevasSumas.exo2 += e.totalExonerado
              } else if (item?.impuesto?.descripcion === 'TARIFA REDUCIDA 4%') {
                nuevasSumas.tarifaReducida4 += e.subtotal
                nuevasSumas.iva4 += e.iva
                nuevasSumas.ivaD4 += e.totalIvaDevuelto
                nuevasSumas.exo4 += e.totalExonerado
              } else if (item?.impuesto?.descripcion === 'TARIFA GENERAL 13%') {
                nuevasSumas.tarifaGeneral13 += e.subtotal
                nuevasSumas.iva13 += e.iva
                nuevasSumas.ivaD13 += e.totalIvaDevuelto
                nuevasSumas.exo13 += e.totalExonerado
              }
            }
          })
        }
      })

      setSumas(nuevasSumas)
    }

    calcularSumas()
  }, [docs])

  return (
    <>
      {date && (
        <div className="listSection Resumen">
          <div className="headList">
            <ul>
              <li className="col-4">
                <b>Impuesto</b>
              </li>
              <li className="col-4">
                <b>Venta</b>
              </li>
              <li className="col-4">
                <b>IVA</b>
              </li>
              <li className="col-4">
                <b>IVA Devuelto</b>
              </li>
              <li className="col-4">
                <b>Monto exoneracion</b>
              </li>
              <li className="col-4">
                <b>Monto en dolares</b>
              </li>
            </ul>
          </div>

          <div className="bodyList">
            <ul>
              {docs.length > 0 &&
                docs.map((doc, index) => (
                  <li className="itemBodyList">
                    <ul className="contentItemBodyList">
                      <li className="col-4">
                        <span>{doc.impuesto ? doc.impuesto.descripcion : 'TOTALES '}</span>
                      </li>
                      <li className="col-4">
                        <span>
                          {doc.totalVentas.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      </li>
                      <li className="col-4">
                        <span>
                          {doc.porcentajeProporcion.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      </li>
                      <li className="col-4">
                        <span>
                          {doc.ivaDevuelto.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      </li>
                      <li className="col-4">
                        <span>
                          {doc.montoExonerado.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      </li>
                      <li className="col-4">
                        <span>
                          {doc.montoDolares.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </span>
                      </li>
                    </ul>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Resumen
