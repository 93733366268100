import { GET_DOCUMENTOS_RECIBIDOS, GET_DOCUMENTS_EMITIDOS, UPDATE_DOCUMENT_RECIBIDO } from '../constants/actionTypes'

const initialState = {
  documentosRecibidos: [],
  documentosEmitidos: [],
  isLoadingDocumentosRecibidos: true,
  isLoadingDocumentosEmitidos: true,
}

const handles = {
  [GET_DOCUMENTOS_RECIBIDOS]: (state, { payload = {} }) => {
    return { ...state, isLoadingDocumentosRecibidos: false, documentosRecibidos: payload?.documentosRecibidos }
  },
  [GET_DOCUMENTS_EMITIDOS]: (state, { payload = {} }) => {
    return { ...state, isLoadingDocumentosEmitidos: false, documentosEmitidos: payload?.documentosEmitidos }
  },
  [UPDATE_DOCUMENT_RECIBIDO]: (state, { payload = {} }) => {
    return {
      ...state,
      documentosRecibidos: state.documentosRecibidos.map((doc) =>
        +doc.docRecibidosPK.id === +payload.docRecibidosPK.id ? payload : doc
      ),
    }
  },
}

const documentos = (state = initialState, action) => {
  const { type } = action

  return handles[type] ? handles[type](state, action) : state
}

export default documentos
