// Import Modules
import React, { useEffect, useState } from 'react'

// Import StyleSheet
import './Resumen.css'

const Resumen = ({ docs, date }) => {
  console.log(docs)
  const Total = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      /* let iva = e.iva / e.tasaCambio */
      let cambio = e.totServ1 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totServ1
  }, 0)
  const Total1 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      /* let iva = e.iva / e.tasaCambio */
      let cambio = e.totServ1 || e.tot1 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totServ1 || e.tot1
  }, 0)
  const Total2 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.totServ2 || e.tot2 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totServ2 || e.tot2
  }, 0)
  const Total4 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      /* let iva = e.iva / e.tasaCambio */
      let cambio = e.totServ4 || e.tot4 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totServ4 || e.tot4
  }, 0)
  const Total13 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      /* let iva = e.iva / e.tasaCambio */
      let cambio = e.totServ13 || e.tot13 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totServ13 || e.tot13
  }, 0)

  const TotalIva = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.totServ8 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.totServ8
  }, 0)
  const TotalIva1 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.tarifaServ1 ? e.tarifaServ1 : e.tarifa1 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.tarifaServ1 ? e.tarifaServ1 : e.tarifa1
  }, 0)
  const TotalIva2 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.tarifaServ2 ? e.tarifaServ2 : e.tarifa2 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.tarifaServ2 ? e.tarifaServ2 : e.tarifa2
  }, 0)
  const TotalIva4 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.tarifaServ4 ? e.tarifaServ4 : e.tarifa4 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.tarifaServ4 ? e.tarifaServ4 : e.tarifa4
  }, 0)
  const TotalIva13 = docs.reduce((accumulator, e) => {
    if (e.tasaCambio) {
      let cambio = e.tarifaServ13 || e.tarifa13 * e.tasaCambio
      return accumulator + cambio
    }
    return accumulator + e.tarifaServ13 || e.tarifa13
  }, 0)

  return (
    <>
      {date && (
        <div className="listSection Resumen">
          <div className="headList">
            <ul>
              <li className="col-4">
                <b>Impuesto</b>
              </li>
              <li className="col-4">
                <b>Venta</b>
              </li>
              <li className="col-4">
                <b>IVA</b>
              </li>
            </ul>
          </div>
          <div className="bodyList">
            <ul>
              <li className="itemBodyList">
                <ul className="contentItemBodyList">
                  <li className="col-4">
                    <span>TRANSITORIO 0%</span>
                  </li>
                  <li className="col-4">
                    <span>₡{Total.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                  <li className="col-4">
                    <span>₡{TotalIva.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                </ul>
              </li>
              <li className="itemBodyList">
                <ul className="contentItemBodyList">
                  <li className="col-4">
                    <span>TARIFA REDUCIDA 1%</span>
                  </li>
                  <li className="col-4">
                    <span>₡{Total1.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                  <li className="col-4">
                    <span>₡{TotalIva1.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                </ul>
              </li>
              <li className="itemBodyList">
                <ul className="contentItemBodyList">
                  <li className="col-4">
                    <span>TARIFA REDUCIDA 2%</span>
                  </li>
                  <li className="col-4">
                    <span>₡{Total2.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                  <li className="col-4">
                    <span>₡{TotalIva2.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                </ul>
              </li>
              <li className="itemBodyList">
                <ul className="contentItemBodyList">
                  <li className="col-4">
                    <span>TARIFA REDUCIDA 4%</span>
                  </li>
                  <li className="col-4">
                    <span>₡{Total.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                  <li className="col-4">
                    <span>₡{TotalIva.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                </ul>
              </li>
              <li className="itemBodyList">
                <ul className="contentItemBodyList">
                  <li className="col-4">
                    <span>TARIFA GENERAL 13%</span>
                  </li>
                  <li className="col-4">
                    <span>₡{Total13.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                  <li className="col-4">
                    <span>₡{TotalIva13.toLocaleString('es', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </li>
                </ul>
              </li>
              <li className="itemBodyList">
                <ul className="contentItemBodyList">
                  <li className="col-4">
                    <span>TOTAL</span>
                  </li>
                  <li className="col-4">
                    <span>
                      ₡
                      {(Total1 + Total2 + Total4 + Total13).toLocaleString('es', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </li>
                  <li className="col-4">
                    <span>
                      ₡
                      {(TotalIva1 + TotalIva2 + TotalIva4 + TotalIva13).toLocaleString('es', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default Resumen
