import { createConsecutivo, getCajas, getSucursales, updateConsecutivo } from 'actions/administracion'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import { Select } from 'components/Facturacion/FormFactura/EncabezadoYReceptor/Encabezado'
import Text from 'components/FormField/Text'
import KeyView from 'components/KeyView/KeyView'
import tipoDocsVta from 'constants/tipoDocsVta'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const ConsecutivosKeyview = ({ consecutivo: C, onClose }) => {
  const dispatch = useDispatch()
  const { isEdit } = C ?? {}
  const { savetaxInfo } = useUser()
  const { sucursales, cajas, isLoadingCajas, isLoadingSucursales } = useAdmin()
  const cia = savetaxInfo.cia.codCia

  const [consecutivo, setConsecutivo] = useState(
    C ?? {
      caja: {
        cajaPK: {},
        sucursal: {},
      },
      idTipoDoctoVta: {},
      mantoFormularioPK: {
        codCia: cia,
      },
      numActual: '',
    }
  )
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {

    e.preventDefault()

    if (!consecutivo?.idTipoDoctoVta?.idTipoDoctoVta) {
      toast.error('Tipo de documento es obligatorio')
      return
    }else if (!consecutivo?.caja?.cajaPK?.idSucursal) {
      toast.error('Sucursal es obligatorio')
      return
    }else if (!consecutivo?.caja?.cajaPK?.idCaja) {
      toast.error('Caja es obligatorio')
      return
    }else if (!consecutivo?.numActual) {
      toast.error('consecutivo es obligatorio')
      return
    }

    if (isEdit) {
      dispatch(updateConsecutivo(consecutivo)).then((r) => {
        if (r) onClose()

        setLoading(false)
      })
    } else {
      dispatch(createConsecutivo(consecutivo)).then((r) => {
        if (r) onClose()

        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (isLoadingCajas) dispatch(getCajas(savetaxInfo.cia.codCia))
  }, [dispatch, isLoadingCajas, savetaxInfo.cia.codCia])

  useEffect(() => {
    if (isLoadingSucursales) dispatch(getSucursales(savetaxInfo.cia.codCia))
  }, [dispatch, isLoadingSucursales, savetaxInfo.cia.codCia])

  return (
    <KeyView title="Agregar Consecutivo" onClose={onClose}>
      <Select
        label="* Tipo de documento"
        disabled={isEdit}
        name="sucursal.sucursalPK.idSucursal"
        value={consecutivo?.idTipoDoctoVta?.idTipoDoctoVta ?? ''}
        onChange={(e) => {
          if (!e.target.value) return setConsecutivo((prev) => ({ ...prev, idTipoDoctoVta: {} }))
          const doc = e.target[e.target.selectedIndex].dataset.doc
          setConsecutivo((prev) => ({ ...prev, idTipoDoctoVta: JSON.parse(doc) }))
        }}
        id="sucursal"
        width="30%"
      >
        {tipoDocsVta.map((doc) => (
          <option key={doc.idTipoDoctoVta} data-doc={JSON.stringify(doc)} value={doc.idTipoDoctoVta}>
            {doc.nombreDoctoVta}
          </option>
        ))}
      </Select>

      <Select
        label="* Sucursal"
        value={consecutivo?.caja?.cajaPK?.idSucursal ?? ''}
        onChange={(e) => {
          if (!e.target.value)
            return setConsecutivo((prev) => ({
              ...prev,
              caja: {
                cajaPK: {
                  ...prev.caja.cajaPK,
                  idSucursal: '',
                },
                sucursal: {},
              },
            }))

          const sucursal = JSON.parse(e.target[e.target.selectedIndex].dataset.sucursal)
          setConsecutivo((prev) => ({
            ...prev,
            caja: {
              cajaPK: {
                ...prev.caja.cajaPK,
                idSucursal: sucursal?.sucursalPK?.idSucursal,
              },
              sucursal: sucursal,
            },
          }))
        }}
        id="sucursal"
        width="30%"
      >
        {sucursales.map((sucursal) => (
          <option
            key={sucursal.sucursalPK.idSucursal}
            data-sucursal={JSON.stringify(sucursal)}
            value={sucursal.sucursalPK.idSucursal}
          >
            {sucursal.nombreSucursal}
          </option>
        ))}
      </Select>

      <Select
        label="* Caja"
        value={consecutivo?.caja?.cajaPK?.idCaja ?? ''}
        onChange={(e) => {
          if (!e.target.value)
            return setConsecutivo((prev) => ({
              ...prev,
              caja: {
                ...prev.caja,
                cajaPK: {
                  ...prev.caja.cajaPK,
                  idCaja: '',
                },
              },
            }))

          const caja = JSON.parse(e.target[e.target.selectedIndex].dataset.caja)

          setConsecutivo((prev) => ({
            ...prev,
            caja: {
              ...prev.caja,
              ...caja,
            },
          }))
        }}
        id="caja"
        width="20%"
      >
        {cajas
          .filter(
            (caja) => caja?.sucursal?.sucursalPK?.idSucursal === Number(consecutivo?.caja?.sucursal?.sucursalPK?.idSucursal)
          )
          .map((caja) => (
            <option key={caja.cajaPK.idCaja} data-caja={JSON.stringify(caja)} value={caja.cajaPK.idCaja}>
              {caja.puntoEmision}
            </option>
          ))}
      </Select>

      <Text
        label="* Consecutivo"
        placeholder="0001"
        name="consecutivo"
        value={consecutivo?.numActual}
        onChange={(e) =>
          setConsecutivo((prev) => ({
            ...prev,
            numActual: e.target.value,
          }))
        }
      />

      {loading ? <CircularProgress /> : <button onClick={handleSubmit}>{isEdit ? 'Actualizar' : 'Guardar'}</button>}
    </KeyView>
  )
}

export default ConsecutivosKeyview
