import React from 'react'

const Contingencias = ({ factura, handleChange }) => {
  const { isEdit } = factura

  return (
    <div className="sectionForm">
      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Contingencias</h3>
      </div>

      <label htmlFor="consecutivoContingencia" className="text" style={{ width: '50%' }}>
        Consecutivo Contingencia
        <input
          disabled={isEdit}
          onChange={handleChange}
          name="consecutivoContingencia"
          value={factura?.consecutivoContingencia ?? ''}
          type="text"
          placeholder="Consecutivo Contingencia"
          id="consecutivoContingencia"
        />
      </label>
      <label htmlFor="fechaContingencia" className="text" style={{ width: '50%' }}>
        Fecha de Contingencia
        <input
          disabled={isEdit}
          onChange={handleChange}
          name="fechaContingencia"
          value={factura?.fechaContingencia ?? ''}
          type="text"
          placeholder="Fecha de Contingencia"
          id="fechaContingencia"
        />
      </label>
      <label htmlFor="motivoContingencia" className="text" style={{ width: '100%' }}>
        Motivo Contingencia
        <textarea
          disabled={isEdit}
          onChange={handleChange}
          value={factura?.motivoContingencia ?? ''}
          name="motivoContingencia"
          cols="30"
          rows="8"
          id="motivoContingencia"
        />
      </label>
    </div>
  )
}

export default Contingencias
