/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// Import Modules
import React, { useState, useEffect, useMemo } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { useLocation } from 'react-router-dom'

// Import Components
import FormFacturasElectronicas from '../FormFactura/FormFacturasElectronicas'

// Import Handlers
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import { saveAs } from 'file-saver'
import { CREATE_FACTURA_COMPRA } from 'constants/actionTypes'
import _ from 'lodash'
import logo from 'assets/logo_black.png'

// Import Hooks
import { useDispatch } from 'react-redux'
import useUser from 'hooks/useUser'
import useFacturas from 'hooks/useFacturas'
import { deleteFactura, getFacturasElectronicasPorCompra } from 'actions/facturas'

// Import StyleSheet
import './FacturaElectronicaDeCompra.css'

import { getURL } from 'api/apiInstance'


const FacturaElectronicaDeCompra = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { isLoadingFacturasElectronicasPorCompra, facturasElectronicasPorCompra } = useFacturas()
  const { search } = useLocation()
  const searchParams = Object.fromEntries(new URLSearchParams(search))
  const { facturaID } = searchParams

  const lookedItem = useMemo(() => {
    const item =
      facturasElectronicasPorCompra[
        Number(_.findKey(facturasElectronicasPorCompra, (i) => i.vtaMovmPK.numDocto === +facturaID))
      ] ?? null
    if (item) return { ...item, isEdit: true }
    else return undefined
  }, [facturasElectronicasPorCompra, facturaID])

  const [dateState, setDateState] = useState(null)
  const [facturasElectronicasFiltradas, setFacturasElectronicasFiltradas] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [open, setOpen] = useState(lookedItem ? true : false)
  const [editFactura, setEditFactura] = useState(lookedItem ?? {})
  const [modal, setModal] = useState({
    boolean: false,
    data: {},
  })

  const [filtros, setFiltros] = useState({
    search: '',
    estadoDocumento: '',
    estadoHacienda: '',
  })

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  const handleDropDownFilter = (event) => {
    let dropDownFilter = document.querySelector('.dropDownFilter')
    let target = event.target
    if (dropDownFilter.className.includes('toggled')) {
      dropDownFilter.className = 'dropDownFilter'
      target.className = 'toggledFilters toggled'
    } else {
      dropDownFilter.className = 'dropDownFilter toggled'
      target.className = 'toggledFilters'
    }
  }

  const filterForDate = (documentosMatched, value) => {
    let result = documentosMatched
    if (value) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(value[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(value[1]).getTime()
      )
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros.search))
      )
    }

    if (result.length && filtros.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros.estadoDocumento
      })
    }

    if (result.length && filtros.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estadoHacienda
        }
      })
    }
    return result
  }

  const filterForSearch = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(value))
      )
    }

    if (result.length && filtros.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros.estadoDocumento
      })
    }

    if (result.length && filtros.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estadoHacienda
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterEstadoDocumento = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        return doc.status == value
      })
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros.search))
      )
    }

    if (result.length && filtros.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estadoHacienda
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterEstadoHacienda = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === value
        }
      })
    }

    if (result.length && filtros.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros.estadoDocumento
      })
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros.search))
      )
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterDocumentos = (name, value) => {
    let documentosMatched = facturasElectronicasPorCompra
    if (documentosMatched.length) {
      if (name === 'search') {
        return filterForSearch(documentosMatched, value)
      } else if (name === 'estadoDocumento') {
        return filterEstadoDocumento(documentosMatched, value)
      } else if (name === 'estadoHacienda') {
        return filterEstadoHacienda(documentosMatched, value)
      } else if (name === 'clear') {
        return documentosMatched
      } else if (name === 'date') {
        return filterForDate(documentosMatched, value)
      }
    } else {
      return []
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
    setFacturasElectronicasFiltradas(filterDocumentos(name, value))
  }

  const updateRangeDate = (date) => {
    setDateState(date)
    setFacturasElectronicasFiltradas(filterDocumentos('date', date))
  }

  const clearFilters = () => {
    setFiltros({
      search: '',
      estadoDocumento: '',
      estadoHacienda: '',
    })
    setDateState(null)
    setFacturasElectronicasFiltradas(facturasElectronicasPorCompra)
  }

  const seeFactura = (factura) => {
    setOpen(true)
    setEditFactura({ ...factura, isEdit: true })
  }

  const handleClose = () => {
    setOpen(false)
    setEditFactura(undefined)
  }

  const handleDownload = (factura) => {

    saveAs(
      `${getURL("fe")}vta-movm/pdf/` +
        factura.vtaMovmPK.codCia +
        '/' +
        factura.vtaMovmPK.idTipoDoctoVta +
        '/' +
        factura.vtaMovmPK.idSucursal +
        '/' +
        factura.vtaMovmPK.numDocto +
        '',
      `Factura Electronica ${factura.prefijo} ${factura.referencia}.pdf`
    )
  }

  useEffect(() => {
    if (!facturasElectronicasPorCompra.length) {
      dispatch(getFacturasElectronicasPorCompra(savetaxInfo.cia.codCia))
    }

    setFacturasElectronicasFiltradas(facturasElectronicasPorCompra)
  }, [savetaxInfo, dispatch])

  const Actualizar = () => {
    setFacturasElectronicasFiltradas(dispatch(getFacturasElectronicasPorCompra(savetaxInfo?.cia?.codCia)))
  }

  return (
    <>
      <div className="FacturaElectronicas" onScroll={hadleScrollSection}>
        <div className="navSection">
          <button className="toggledFilters" onClick={handleDropDownFilter}>
            <i className="bi bi-chevron-down"></i>
          </button>
          <div className="dropDownFilter toggled" style={{ width: '65%' }}>
            <label htmlFor="" style={{ width: '35%' }}>
              Nombre Emisor o Referencia
              <div className="search">
                <input
                  value={filtros.search.toUpperCase()}
                  type="text"
                  placeholder="Nombre o cedula de Emidor"
                  onChange={hadleFilter}
                  name="search"
                />
                <button>
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </label>
            <label htmlFor="" style={{ width: '25%' }}>
              Estado de Receptor
              <select name="estadoDocumento" id="" value={filtros.estadoDocumento} onChange={hadleFilter}>
                <option value="">Todos los Estados</option>
                <option value="G">Generado</option>
                <option value="R">Registrado</option>
                <option value="A">Anulado</option>
              </select>
            </label>
            <label htmlFor="" style={{ width: '25%' }}>
              Estado Hacienda
              <select name="estadoHacienda" id="" value={filtros.estadoHacienda} onChange={hadleFilter}>
                <option value="">Todos los Estados</option>
                <option value="PENDIENTE">Pendiente</option>
                <option value="ACEPTADO">Aceptado</option>
                <option value="RECHAZADO">Rechazado</option>
              </select>
            </label>
            <label htmlFor="" className="togglebled">
              Rango de fecha
              <DateRangePicker onChange={(date) => updateRangeDate(date)} value={dateState} />
            </label>
            <label htmlFor="" style={{ width: '15%' }} onClick={clearFilters}>
              Limpiar Filtros
              <button className="clearFilterButton">Limpiar</button>
            </label>
          </div>
          <button className="addContent" onClick={Actualizar} style={{ width: '20%' }}>
            Actualizar
            <i className="bi bi-arrow-clockwise"></i>
          </button>
          <button className="addContent" onClick={() => setOpen(true)} style={{ width: '18%' }}>
            Agregar Factura
            <i className="bi bi-plus-circle-fill"></i>
          </button>
        </div>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-2">
                <b>Emisor</b>
              </li>
              <li className="col-1">
                <b>Prefijo</b>
              </li>
              <li className="col-1">
                <b>Factura</b>
              </li>
              <li className="col-1">
                <b>Moneda</b>
              </li>
              <li className="col-1">
                <b>Monto</b>
              </li>
              <li className="col-1">
                <b>Emisión</b>
              </li>
              <li className="col-1">
                <b>Est Hacienda</b>
              </li>
              <li className="col-1">
                <b>Est Doc</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>

          {isLoadingFacturasElectronicasPorCompra ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Facturas Electronicas</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {facturasElectronicasFiltradas.length > 0 &&
                  facturasElectronicasFiltradas.slice(0, 50).map((factura, index) => (
                    <li className="itemBodyList">
                      <ul className="contentItemBodyList">
                        <li className="col-2">
                          <span>{factura.cliente.nombreCliente}</span>
                        </li>
                        <li className="col-1">
                          <span>{factura.prefijo}</span>
                        </li>
                        <li className="col-1">
                          <b>{factura.referencia}</b>
                        </li>
                        <li className="col-1">
                          <span>{factura.idMoneda.nombreCorto}</span>
                        </li>
                        <li className="col-1">
                          <span>{factura.total.toLocaleString('es')}</span>
                        </li>
                        <li className="col-1">
                          <span>
                            {new Date(factura.fechaEmision).getDate()}/{new Date(factura.fechaEmision).getMonth() + 1}/
                            {new Date(factura.fechaEmision).getFullYear()}
                          </span>
                        </li>
                        <li className="col-1">
                          <span
                            className={
                              (factura.descripcionSri === 'ACEPTADO' && 'done') ||
                              (!factura.descripcionSri && 'panding') ||
                              (factura.descripcionSri === 'RECHAZADO' && 'cancel')
                            }
                          >
                            {factura.descripcionSri ? factura.descripcionSri : 'PENDIENTE'}
                          </span>
                        </li>
                        <li className="col-1">
                          <span className={(factura.status === 'G' && 'info') || (factura.status === 'A' && 'cancel')}>
                            {factura.status === 'G' && 'GENERADO'}
                            {factura.status === 'A' && 'ANULADO'}
                            {factura.status === 'R' && 'REGISTRADO'}
                          </span>
                        </li>
                        <li className="col-1">
                          <button className="btnEditItem" onClick={() => seeFactura(factura)}>
                            <i className="bi bi-eye"></i>
                          </button>
                          <button
                            className="btnDelete"
                            onClick={() =>
                              createAlertConfirm(
                                `¿Estás seguro de eliminar ${factura.referencia}?`,
                                `Podras eliminar el documento ${factura.referencia}, esta accion no es puede ser revertida`,
                                () => {
                                  dispatch(deleteFactura(factura))
                                }
                              )
                            }
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                          <button className="btnEditItem" onClick={() => handleDownload(factura)}>
                            <i className="bi bi-file-earmark-text"></i>
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))}

                {!facturasElectronicasFiltradas.length && (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        {!isLoadingFacturasElectronicasPorCompra && facturasElectronicasFiltradas.length > 0 && (
          <div className="paginationTable">
            <div className="controllers">
              <button className="previousPage hidden">
                <i className="bi bi-chevron-left"></i>
              </button>
              <span>
                Página {currentPage} de {Math.ceil(facturasElectronicasFiltradas.length / 50)}
              </span>
              {facturasElectronicasFiltradas.length > 50 && (
                <button className="nextPage">
                  <i className="bi bi-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {modal.boolean && (
        <section className="modal-factura">
          <button onClick={() => setModal(false)} className="close-modal-fac">
            X
          </button>
          <div className="container-title-modal">
            <h2 className="title-modal-fac">Gracias por usar</h2>
            <img src={logo} alt="logo" width={200} />
          </div>
          <p className="text-modal-fac">Su Factura de Compra fue cargada con exito!</p>
          <button
            className="btn-modal-fac"
            onClick={() => {
              const newUrl = `/documentosEmitidos/${modal.data?.vtaMovmPK?.numDocto}`
              window.location.href = newUrl
            }}
          >
            Ver documento
          </button>
        </section>
      )}
      {open && (
        <FormFacturasElectronicas
          editFactura={editFactura}
          handleClose={handleClose}
          tipoFactura={7}
          setModal={setModal}
          facturaActionType={CREATE_FACTURA_COMPRA}
          title="Factura electrónica de compra"
        />
      )}
    </>
  )
}

export default FacturaElectronicaDeCompra
