import { toast } from 'react-toastify'

const initializeFactura = (actividadesEconomicas, condicionesDeVenta, monedas, cajas, sucursales, userCia) => {
  return {
    productEnLinea: {},
    impuestoEnLinea: {
      factor: 0.0,
      monto: 0.0,
      montoPorcentaje: '',
      otroImpuestoVtaDetmPK: {},
      tipoImpuesto: {},
    },
    descuentoEnLinea: {
      descripcion: '',
      descuentoVtaDemPK: {},
      monto: 0.0,
      porcentaje: 0.0,
      valorPorcentaje: 'M',
    },
    otroCargoEnLinea: {
      detalle: '',
      identificacion: '',
      monto: 0.0,
      nombre: '',
      otroCargoVtaMovmPK: {},
      porcentaje: 0.0,
      tipoDoctoOtrosCargos: {},
    },
    cliente: {},
    tipoDoctoVta: {},
    caja: cajas[0],
    actividadEconomicaCia: actividadesEconomicas[0],
    condicionVenta: condicionesDeVenta[0],
    idMoneda: monedas[1],
    sucursal: sucursales[0],
    comentario: '',
    consecutivoContingencia: '',
    descuento: '',
    estadoFactura: '',
    motivoContingencia: '',
    nota: '',
    status: '',
    tiempo: '',
    tipoVenta: '',
    fechaContingencia: '',
    iva: 0,
    tasaCambio: 1,
    fechaCreacion:
      new Date(Date.now()).getDate() + '/' + (new Date(Date.now()).getMonth() + 1) + '/' + new Date(Date.now()).getFullYear(),
    vtaDetmList: [],
    otroCargoVtaMovmList: [],
    cajas: [],
    tarifas: [],
    formaPagoList: [],
    otroImpuestoVtaDetmList: [],
    descuentoVtaDemList: [],
    actionEnLinea: 'add',
    actionEnLineaOtrosCargos: 'add',
    actionEnLineaDescuentos: 'add',
    openTasaCambio: false,
    total: 0,
    totalExento: 0,
    totalExonerado: 0,
    totalGravados: 0,
    totalIvaDevuelto: 0,
    totalMercExento: 0,
    totalMercExonerado: 0,
    totalMercGravados: 0,
    totalOtrosCargos: 0,
    totalOtrosImpuestos: 0,
    totalSrvExento: 0,
    totalSrvExonerado: 0,
    totalSrvGravados: 0,
    subtotal: 0,
    subtotalExento: 0,
    vtaMovmPK: {
      codCia: userCia,
      idSucursal: sucursales[0].sucursalPK.idSucursal,
      idTipoDoctoVta: 2,
      numDocto: 0,
    },
  }
}

export const validateFactura = (factura) => {
  if (!factura.sucursal.sucursalPK) {
    toast.error('El campo Sucursal es requerido, selecciona una sucursal')
    return false
  } else if (!factura.caja.cajaPK) {
    toast.error('El campo Caja es requerido, selecciona una caja')
    return false
  } else if (!factura.actividadEconomicaCia) {
    toast.error('El campo Actividades Economicas es requerido, selecciona una actividad')
    return false
  } else if (!factura.idMoneda.idMoneda) {
    toast.error('El campo Moneda es requerido, selecciona una moneda')
    return false
  } else if (!factura.formaPagoList.length) {
    toast.error('El campo Medios de Pago es requerido, selecciona al menos un Medio de Pago')
    return false
  } else if (!factura.cliente.clientePK) {
    toast.error('Selecciona a un cliente es requerido')
    return false
  } else if (!factura.vtaDetmList.length) {
    toast.error('No hay productos agregados, agrega al menos un producto')
    return false
  }

  return true
}

export default initializeFactura
