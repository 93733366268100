/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'

// Import Componets
import Cards from 'react-credit-cards'
import Toggle from 'react-toggle'

// Import Hooks
import useUser from 'hooks/useUser'

const FormMethod = () => {
  let params = useParams()
  let navigate = useNavigate()
  const { savetaxInfo } = useUser()
  const [method, setMethod] = useState({
    codCia: '',
    id: '',
    nombre: '',
    predeterminada: false,
    ultimosDigitos: '',
    vencimiento: '',
    numeroCompleto: '',
    cvc: '',
  })

  const handleOnChange = (event) => {
    setMethod({
      ...method,
      [event.target.name]: event.target.value,
    })
  }

  const createMethod = async () => {
    try {
      if (!savetaxInfo?.usuarioPK?.codCia) return toast.error('El codigo CIA no esta definido')
      if (!method?.nombre) return toast.error('El nombre del metodo de pago no esta definido')
      if (!method?.vencimiento) return toast.error('La fecha de vencimiento no esta definida')
      if (!method?.numeroCompleto) return toast.error('El Nro de tarjeta no esta definido')
      if (!method?.cvc) return toast.error('El cvc no esta definido')

      await axios.post('https://test.facturacionsavetaxcr.com/savetax/api/v1/metodo-pago', {
        codCia: savetaxInfo.usuarioPK.codCia,
        nombre: method.nombre,
        predeterminada: method.predeterminada,
        ultimosDigitos: method.numeroCompleto.slice(method.numeroCompleto.length - 4),
        vencimiento: method.vencimiento,
        numeroCompleto: method.numeroCompleto,
        codigoSegurida: method.cvc,
      })

      toast.success('Metodo de pago guardado')
      navigate('/metodosPago')
    } catch (error) {
      console.error(error)
    }
  }

  const handleSetMethod = async (cia) => {
    let response = await axios.get(`https://test.facturacionsavetaxcr.com/savetax/api/v1/metodo-pago/find-by-cod-cia/${cia}`)
    if (!response?.data?.length) return
    let methods = response.data
    let currentMethod = methods.find((m) => m.id === Number(params.idMetodoDePago))
    if (currentMethod)
      setMethod({
        ...method,
        ...currentMethod,
      })
  }

  useEffect(() => {
    if (savetaxInfo?.usuarioPK?.codCia && params?.idMetodoDePago) {
      handleSetMethod(savetaxInfo.usuarioPK.codCia)
    }
  }, [savetaxInfo, params.idMetodoDePago])
  return (
    <div className="FormContainer FormMethod">
      <div className="Form">
        <div className="FormTopBar">
          <div className="FormTitle">
            <h4>{params?.idMetodoDePago ? `Editar Metodo de Pago #${params?.idMetodoDePago}` : 'Crear Metodo de Pago'}</h4>
          </div>

          <div className="optionsFormTopBar">
            <Link to={'/'} className="helpForm" tabIndex="-1">
              <i className="bi bi-question-circle"></i>
            </Link>
            <Link to={'/metodosPago'} className="closeForm" tabIndex="-1">
              <i className="bi bi-x-lg"></i>
            </Link>
          </div>
        </div>
        <div className="FormContent">
          {!params.idMetodoDePago && (
            <div className="cardContainer">
              <Cards
                focused=""
                cvc={method.cvc}
                expiry={method.vencimiento}
                name={method.nombre}
                number={method.numeroCompleto}
              />
            </div>
          )}
          <div className="groupInputs">
            <label htmlFor="nombreTitular" className="text" style={{ width: '100%' }}>
              Nombre del Titular
              <input
                onChange={handleOnChange}
                name="nombre"
                type="text"
                value={method.nombre}
                placeholder="Nombre"
                id="nombreTitular"
                disabled={!params.idMetodoDePago ? false : true}
              />
            </label>
            {!params.idMetodoDePago && (
              <label htmlFor="numTarjeta" className="text" style={{ width: '50%' }}>
                Número de Tarjeta
                <input
                  name="numeroCompleto"
                  value={method.numeroCompleto}
                  onChange={handleOnChange}
                  type="number"
                  placeholder="Número"
                  id="numTarjeta"
                />
              </label>
            )}
            <label htmlFor="expiry" className="text" style={{ width: '50%' }}>
              Fecha Vencimiento
              <input
                name="vencimiento"
                onChange={handleOnChange}
                type="month"
                placeholder="YYYYMM"
                id="expiry"
                value={
                  method.vencimiento.includes('-')
                    ? method.vencimiento
                    : `${method.vencimiento.slice(3)}-${method.vencimiento.slice(0, 2)}`
                }
              />
            </label>
            {!params.idMetodoDePago && (
              <label htmlFor="cvc" className="text" style={{ width: '30%' }}>
                CVC
                <input
                  maxLength="4"
                  name="cvc"
                  value={method.cvc}
                  onChange={handleOnChange}
                  type="text"
                  placeholder="CVC"
                  id="cvc"
                />
              </label>
            )}

            <label htmlFor="cvc" className="text" style={{ width: '30%' }}>
              Predeterminado
              <Toggle className="toggleCustom" checked={method.predeterminada} name="predeterminada" onChange={handleOnChange} />
            </label>

            {!params.idMetodoDePago && (
              <button className="saveForm" onClick={createMethod}>
                Agregar
                <i className="bi bi-plus-circle-fill"></i>
              </button>
            )}
            {params.idMetodoDePago && (
              <button className="saveForm">
                Guardar
                <i className="bi bi-save2-fill"></i>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormMethod
