import React, { useCallback, useMemo, useEffect } from 'react'
import { toast } from 'react-toastify'
import { ReactComponent as Search } from 'assets/search.svg'
import { ReactComponent as Edit } from 'assets/edit.svg'
import { ReactComponent as Visibility } from 'assets/visibility2.svg'
import { fetchTotales } from '../Sidebar/Sidebar'
import useCalcs from './useCalcs'
import { getUnidadesDeMedida } from 'actions/misc'
import useMisc from 'hooks/useMisc'
import { useDispatch } from 'react-redux'
import useUser from 'hooks/useUser'

const LineaDetalles = ({ factura, setSection, setFactura, handleChange }) => {
  const dispatch = useDispatch()
  const { isEdit } = factura
  const { unidadesDeMedida } = useMisc()
  const { savetaxInfo } = useUser()

  const { getTotalDescontado, getImpuestos } = useCalcs(factura)

  const descuentosLinea = useMemo(() => {
    const { isEdit, productEnLinea } = factura ?? {}
    const { descuento } = productEnLinea ?? {}
    return getTotalDescontado()
  }, [factura, getTotalDescontado])

  const impuestosLinea = useMemo(() => getImpuestos(factura), [factura, getImpuestos])

  const ivaLinea = useMemo(() => {
    const { isEdit } = factura ?? {}
    const { precioVenta, impuesto, cantidad, iva } = factura?.productEnLinea ?? {}

    console.log('ivaLinea ', factura?.productEnLinea)

    return isEdit
      ? factura?.productEnLinea?.iva
      : ((precioVenta ?? 0) * (cantidad ?? 0) - getTotalDescontado() + impuestosLinea) * +(impuesto?.tasa ?? 0)
  }, [factura, getTotalDescontado, impuestosLinea])

  const totalLinea = useMemo(() => {
    const { productEnLinea, isEdit } = factura ?? {}

    const impuestos = impuestosLinea
    const descuentos = descuentosLinea
    const iva = ivaLinea

    /* return (
      (productEnLinea?.precioVtaUnit ?? productEnLinea?.precioVenta * productEnLinea?.cantidad) - descuentos + impuestos + iva
    ) */
    console.log('totalLinea ', productEnLinea?.precioVenta * productEnLinea?.cantidad - descuentos + impuestos + iva)
    return isEdit ? factura?.total : productEnLinea?.precioVenta * productEnLinea?.cantidad - descuentos + impuestos + iva
  }, [factura, descuentosLinea, impuestosLinea, ivaLinea])

  const parseLinea = useCallback(() => {
    const { productEnLinea: linea } = factura
    return {
      exonerarIva: linea?.exonerarIva,
      documentoExoneracion: linea?.documentoExoneracion,
      fechaExoneracion: linea?.fechaExoneracion,
      idTipoDoctoExonera: linea?.idTipoDoctoExonera,
      institucionExonera: linea?.institucionExonera,
      porcentajeExonera: linea?.porcentajeExonera,
      cabys: {
        codigo: linea?.cabys?.codigo,
      },
      producto: {
        productoPK: linea?.productoPK,
      },
      impuesto: {
        ...linea?.impuesto,
        impuestoPK: linea?.impuesto?.impuestoPK,
      },
      unidadMedida: {
        unidadMedidaPK: linea?.unidadMedida?.unidadMedidaPK,
      },
      cantidad: linea?.cantidad,
      precioVtaUnit: linea?.precioVenta,
      subtotal: linea?.cantidad * linea?.precioVenta,
      descuentoVtaDemList: factura.descuentoVtaDemList,
      montoExonera: 0.0,
      exentoIva: false,
      ...linea,
      otroImpuestoVtaDetmList: linea?.otroImpuestoVtaDetmList,
      otrosImpuesto: impuestosLinea,
      descuento: descuentosLinea,
      iva: ivaLinea,
      total: totalLinea,
      isLoadingTotales: false,
      descripcion: linea?.nombreProducto,
    }
  }, [factura, impuestosLinea, descuentosLinea, ivaLinea, totalLinea])

  const handleAddLinea = useCallback(async () => {
    const newRow = parseLinea()
    const exist =
      factura?.vtaDetmList?.findIndex((el) => el.productoPK?.idProducto === factura?.productEnLinea?.productoPK?.idProducto) >= 0

    if (newRow?.total < 0) {
      return toast.error('No se puede agregar un item negativo con total negativo')
    }

    console.log('handleAddLinea ', newRow)
    console.log('Exonera: ' + newRow.exonerarIva)
    if (newRow.exonerarIva) {
      console.log(newRow.impuesto.tasa)
      newRow.montoExonera = (newRow.iva * (newRow.porcentajeExonera / (newRow.impuesto.tasa * 100.0))).toFixed(2)
      newRow.total = newRow.subtotal + newRow.iva - newRow.montoExonera + newRow.otrosImpuesto
    }

    setFactura((prev) => {
      return {
        ...prev,
        productEnLinea: null,
        total: (prev?.total ?? 0) + +newRow.total,
        vtaDetmList: /*exist
          ? prev?.vtaDetmList?.map((el) =>
            el?.productoPK?.idProducto === prev?.productEnLinea?.productoPK?.idProducto ? newRow : el
          )
          : */
          [...prev.vtaDetmList, newRow],
        isLoadingTotales: true,
      }
    })

    console.log('Lineadetalles ', factura)

    await fetchTotales(
      {
        ...factura,
        productEnLinea: null,
        total: (factura?.total ?? 0) + +newRow.total,
        vtaDetmList: /*exist
          ? factura?.vtaDetmList?.map((el) =>
            el?.productoPK?.idProducto === factura?.productEnLinea?.productoPK?.idProducto ? newRow : el
          )
          :*/ [...factura.vtaDetmList, newRow],
      },
      setFactura
    )
  }, [factura, setFactura, parseLinea])

  const handleEditLinea = useCallback(
    (product) => {
      setFactura((prev) => ({
        ...prev,
        productEnLinea: { ...product, editingProduct: true },
      }))
    },
    [setFactura]
  )

  const updateLinea = async () => {
    const newRow = parseLinea()

    if (newRow?.total < 0) {
      return toast.error('No se puede agregar un item negativo con total negativo')
    }

    console.log('updateLinea ', newRow)
    let dsctoCalculate = 0
    let total = 0
    let subtotal = 0
    let vtaDetmListTemp = factura?.vtaDetmList?.map((linea) =>
      linea?.productoPK?.idProducto === factura?.productEnLinea?.productoPK?.idProducto ? newRow : linea
    )

    console.log('updateLinea VTA ', factura?.vtaDetmList)

    vtaDetmListTemp.map((linea) => {
      console.log('updateLinea VTA LINEA', linea.descuento)
      dsctoCalculate += linea.descuento
    })

    vtaDetmListTemp.map((linea) => {
      total += linea.total
    })

    vtaDetmListTemp.map((linea) => {
      linea.subtotal = linea?.cantidad * linea?.precioVenta
      //linea.descuentoVtaDemList = ""
      subtotal += linea?.cantidad * linea?.precioVenta - linea?.descuento
      if (linea.exonerarIva) {
        console.log(linea.impuesto.tasa)
        linea.montoExonera = (linea.iva * (linea.porcentajeExonera / (linea.impuesto.tasa * 100.0))).toFixed(2)
        linea.total = linea.subtotal + linea.iva - linea.montoExonera + linea.otrosImpuesto
      }
      return linea
    })

    console.log('updateLinea dsctoSOSS ', dsctoCalculate, total, subtotal)
    setFactura((prev) => ({
      ...prev,
      descuento: dsctoCalculate,
      total: total,
      subtotal: subtotal,
      //productEnLinea: {},
      vtaDetmList: vtaDetmListTemp,
      isLoadingTotales: true,
    }))
    await fetchTotales(
      {
        ...factura,
        descuento: dsctoCalculate,
        total: total,
        subtotal: subtotal,
        //productEnLinea: {},
        vtaDetmList: vtaDetmListTemp,
        isLoadingTotales: false,
      },
      setFactura
    )
  }

  const handleDeleteLinea = useCallback(
    async (id) => {
      setFactura((prev) => ({
        ...prev,
        productEnLinea: prev?.productEnLinea?.productoPK?.idProducto === id ? {} : { ...prev.productEnLinea },
        vtaDetmList: prev?.vtaDetmList?.filter((linea) => linea?.productoPK?.idProducto !== id),
        isLoadingTotales: true,
      }))
      await fetchTotales(
        {
          ...factura,
          productEnLinea: factura?.productEnLinea?.productoPK?.idProducto === id ? {} : { ...factura.productEnLinea },
          vtaDetmList: factura?.vtaDetmList?.filter((linea) => linea?.productoPK?.idProducto !== id),
        },
        setFactura
      )
    },
    [factura, setFactura]
  )

  const handleCleanLine = useCallback(() => {
    setFactura((prev) => ({
      ...prev,
      productEnLinea: {},
    }))
  }, [setFactura])

  const handleChangePrice = useCallback(
    (e) => {
      const value = e.target.value
      setFactura((prev) => ({
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          precioVtaUnit: value,
          precioVenta: value,
          otroImpuestoVtaDetmList: prev?.productEnLinea?.otroImpuestoVtaDetmList?.map((item) => ({
            ...item,
            monto: (+value * prev?.productEnLinea?.cantidad - getTotalDescontado(+value)) * (+item.porcentaje / 100),
          })),
        },
      }))
    },
    [getTotalDescontado, setFactura]
  )

  const handleChangeQuantity = useCallback(
    (e) => {
      const value = e.target.value
      setFactura((prev) => ({
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          cantidad: +value,
          otroImpuestoVtaDetmList: prev?.productEnLinea?.otroImpuestoVtaDetmList?.map((item) => ({
            ...item,
            monto: (prev?.productEnLinea?.precioVenta * +value - getTotalDescontado()) * (+item.porcentaje / 100),
          })),
        },
      }))
    },
    [setFactura, getTotalDescontado]
  )

  useEffect(() => {
    if (!unidadesDeMedida.length) dispatch(getUnidadesDeMedida(savetaxInfo.cia.codCia))
  }, [unidadesDeMedida, savetaxInfo, dispatch])

  return (
    <div className="sectionForm">
      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Lineas de Detalle</h3>
      </div>
      <div className="actionsSection" style={{ width: '100%' }}>
        {!isEdit && (
          <>
            <div
              className="searchGeneral btnSection active"
              style={{ width: '38%' }}
              onClick={() => setSection('facturaElectronica/BuscarProductos')}
            >
              <div className="optionsSearchGeneral">
                <button className="option btnSearch">
                  <Search />
                </button>
              </div>
              <input type="text" placeholder="Buscar Productos" style={{ width: '45%' }} />
              {/*   <div className="optionsSearchGeneral">
                <span>Ctrl + Shift + P</span>
              </div> */}
            </div>
          </>
        )}
        <button className="clearFormLineaDetalle" onClick={handleCleanLine}>
          Limpiar Formulario
        </button>
      </div>
      <label htmlFor="CODProducto" className="text" style={{ width: '20%' }}>
        Cod Producto
        <input
          readOnly
          disabled={true}
          value={
            factura?.productEnLinea?.productoPK?.idProducto ?? factura?.productEnLinea?.producto?.productoPK?.idProducto ?? ''
          }
          type="text"
          placeholder="Cod Producto"
          id="CODProducto"
        />
      </label>
      <label htmlFor="cabysCodigo" className="text" style={{ width: '30%' }}>
        Cabys Código
        <input
          readOnly
          disabled={true}
          value={factura?.productEnLinea?.cabys?.codigo ?? ''}
          type="text"
          placeholder="Cabys Código"
          id="cabysCodigo"
        />
      </label>
      <label htmlFor="cabysDescripcion" className="text" style={{ width: '50%' }}>
        Cabys Descripción
        <input
          readOnly
          disabled={true}
          value={factura?.productEnLinea?.cabys?.descripcion ?? ''}
          type="text"
          placeholder="Cabys Descripción"
          id="cabysDescripcion"
        />
      </label>
      <label htmlFor="nombreDescripcion" className="text" style={{ width: '40%' }}>
        Nombre/Descripción
        <input
          value={factura?.productEnLinea?.nombreProducto ?? factura?.productEnLinea?.producto?.nombreProducto ?? ''}
          type="text"
          onChange={handleChange}
          placeholder="Nombre/Descripción"
          name='nombreDescripcion'
          id="nombreDescripcion"

        />
      </label>
      <label htmlFor="undMedida" className="text" style={{ width: '20%' }}>
        Und Medida
        <select
          id="undMedida"
          placeholder="Und Medida"
          onChange={(e) => {
            const selectedValue = e.target.value
            setFactura((prev) => ({
              ...prev,
              productEnLinea: {
                ...prev.productEnLinea,
                unidadMedida: {
                  ...prev.productEnLinea.unidadMedida,
                  unidadMedidaPK:{
                    ...prev.productEnLinea.unidadMedida.unidadMedidaPK,
                    idUnidad: selectedValue,
                  },
                },
              },
            }))
          }}
          value={factura?.productEnLinea?.unidadMedida?.unidadMedidaPK?.idUnidad ?? ''}
        >
          <option>Seleccione</option>
          {unidadesDeMedida.map((unidad) => (
            <option
              key={unidad.unidadMedidaPK.idUnidad}
              data-unidad={JSON.stringify(unidad)}
              value={unidad.unidadMedidaPK.idUnidad}  // Aquí establecemos el valor que coincide con el valor almacenado en el estado
            >
              {unidad.nombreUnidad} {unidad.representacion}
            </option>
          ))}
        </select>

      </label>

      <label htmlFor="IVA" className="text" style={{ width: '20%' }}>
        IVA
        <div
          className="searchGeneral btnSection inputEditable"
          onClick={() =>
            factura?.productEnLinea?.productoPK || (isEdit && factura?.productEnLinea?.producto?.productoPK)
              ? setSection('facturaElectronica/IVA')
              : toast.error('Selecciona un producto antes de editar el IVA', {
                toastId: 'toast-iva',
              })
          }
        >
          <div className="optionsSearchGeneral">
            <button className="option btnSearch">
              <Edit />
            </button>
          </div>
          <input type="text" placeholder="IVA" id="IVA" value={ivaLinea?.toLocaleString('es') ?? ''} readOnly />
        </div>
      </label>
      <label htmlFor="impuestos" className="text" style={{ width: '20%' }}>
        Impuestos
        <div
          className="searchGeneral btnSection inputEditable"
          onClick={() =>
            factura?.productEnLinea.productoPK || (isEdit && factura?.productEnLinea?.producto?.productoPK)
              ? setSection('facturaElectronica/Impuestos')
              : toast.error('Selecciona un producto antes de editar el los Impuestos', {
                toastId: 'toast-impuestos',
              })
          }
        >
          <div className="optionsSearchGeneral">
            <button className="option btnSearch">
              <Edit />
            </button>
          </div>
          <input
            id="impuestos"
            type="text"
            placeholder="Impuestos"
            readOnly
            value={impuestosLinea ? `${factura?.idMoneda?.signo ?? ''} ${impuestosLinea?.toLocaleString('es')}` : ''}
          />
        </div>
      </label>
      <label htmlFor="precio" className="text" style={{ width: '25%' }}>
        Precio
        <input
          onChange={(e) => handleChangePrice(e)}
          type="number"
          disabled={isEdit}
          value={factura?.productEnLinea?.precioVtaUnit ?? factura?.productEnLinea?.precioVenta ?? ''}
          name="productEnLinea.precioVenta"
          placeholder="Precio"
          id="precio"
        />
      </label>
      <label
        htmlFor="descuentos"
        className="text"
        style={{
          width: isEdit ? '30%' : '20%',
        }}
      >
        Descuentos
        <div
          className="searchGeneral btnSection inputEditable"
          onClick={() =>
            factura?.productEnLinea.productoPK || (isEdit && factura?.productEnLinea?.producto?.productoPK)
              ? setSection('facturaElectronica/Descuentos')
              : toast.error('Seleccione un producto antes de editar los descuentos', {
                toastId: 'toast-descuentos',
              })
          }
        >
          <div className="optionsSearchGeneral">
            <button className="option btnSearch">
              <Edit />
            </button>
          </div>
          <input
            type="text"
            placeholder="Descuentos"
            readOnly
            id="descuentos"
            value={descuentosLinea ? descuentosLinea?.toLocaleString('es') : ''}
          />
        </div>
      </label>
      <label htmlFor="cantidad" className="text" style={{ width: '15%' }}>
        Cantidad
        <input
          disabled={isEdit}
          onChange={handleChangeQuantity}
          min={1}
          value={Number(factura?.productEnLinea?.cantidad).toString() ?? ''}
          name="productEnLinea.cantidad"
          type="number"
          placeholder="Cantidad"
          id="cantidad"
        />
      </label>
      <label
        htmlFor="total"
        className="text"
        style={{
          width: isEdit ? '30%' : '20%',
        }}
      >
        Total
        <input
          readOnly
          disabled={true}
          value={factura?.productEnLinea?.impuesto ? `${factura?.idMoneda?.signo ?? ''} ${totalLinea?.toLocaleString('es')}` : ''}
          type="text"
          placeholder="Total"
          id="total"
        />
      </label>

      {!isEdit && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '0.25rem 1rem' }}>
          <button
            className="btnForm"
            style={{ width: '20' }}
            onClick={factura?.productEnLinea?.editingProduct ? updateLinea : handleAddLinea}
          >
            {factura?.productEnLinea?.editingProduct ? <i className="bi bi-arrow-repeat"></i> : <i className="bi bi-plus"></i>}
            {factura?.productEnLinea?.editingProduct ? 'Actualizar' : 'Agregar'}
          </button>
        </div>
      )}
      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-1-2">
              <b>Cod</b>
            </li>
            <li className="col-2">
              <b>Nombre</b>
            </li>
            <li className="col-1-2">
              <b>Cant</b>
            </li>
            <li className="col-1">
              <b>Precio</b>
            </li>
            <li className="col-2">
              <b>Descuento</b>
            </li>
            <li className="col-1">
              <b>Subtotal</b>
            </li>
            <li className="col-1">
              <b>IVA</b>
            </li>
            <li className="col-1">
              <b>Impto</b>
            </li>
            <li className="col-2">
              <b>Exonerado</b>
            </li>
            <li className="col-1">
              <b>Total</b>
            </li>
            <li className={isEdit ? 'col-1-2' : 'col-1'}>
              <b></b>
            </li>
          </ul>
        </div>
        <div className="bodyList">
          <ul>
            {factura?.vtaDetmList?.slice(0, 50).map((p) => (
              <li className="itemBodyList itembody" key={'lineaDetalle-' + p?.producto?.productoPK?.idProducto}>
                <ul className="contentItemBodyList" inhover="out">
                  <li className="col-1-2 noAction">
                    <span>{p?.producto?.productoPK?.idProducto}</span>
                  </li>
                  <li className="col-2 noAction">
                    <span>{p?.nombreProducto}</span>
                  </li>
                  <li className="col-1-2 noAction">
                    <span>{p?.cantidad}</span>
                  </li>
                  <li className="col-1 noAction">
                    <span>{`${factura?.idMoneda?.signo ?? ''} ${p?.precioVtaUnit?.toLocaleString('es')}`}</span>
                  </li>
                  <li className="col-1 noAction">
                    <span>{`${factura?.idMoneda?.signo ?? ''} ${p?.descuento?.toLocaleString('es')}`}</span>
                  </li>
                  <li className="col-1 noAction">
                    <span>{`${factura?.idMoneda?.signo ?? ''} ${(p?.precioVtaUnit * p?.cantidad - p?.descuento)?.toLocaleString(
                      'es'
                    )}`}</span>
                  </li>
                  <li className="col-1 noAction">
                    <span>
                      {p?.iva ? `${factura?.idMoneda?.signo ?? ''} ${p?.iva?.toLocaleString('es')}` : '0.0'?.toLocaleString('es')}
                    </span>
                  </li>
                  <li className="col-1 noAction">
                    <span>
                      {factura?.idMoneda?.signo ?? ''} {getImpuestos(p)?.toLocaleString('es')}
                    </span>
                  </li>
                  <li className="col-1 noAction">
                    <span>{p?.montoExonera ? `${factura?.idMoneda.signo} ${p?.montoExonera}` : '0.0'?.toLocaleString('es')}</span>
                  </li>
                  <li className="col-1 noAction">
                    <span>
                      {factura?.idMoneda?.signo ?? ''} {p?.total?.toLocaleString('es')}
                    </span>
                  </li>
                  <li className="col-1">
                    {isEdit ? (
                      <button className="btnEditItem" onClick={() => handleEditLinea(p)}>
                        <Visibility />
                      </button>
                    ) : (
                      <>
                        <button className="btnEditItem" onClick={() => handleEditLinea(p)}>
                          <i className="bi bi-arrow-repeat"></i>
                        </button>
                        <button className="btnDelete" onClick={() => handleDeleteLinea(p?.productoPK?.idProducto)}>
                          <i className="bi bi-trash"></i>
                        </button>
                      </>
                    )}
                  </li>
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default LineaDetalles
