//import React from 'react'
import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../../firebase'
import './Recuperar.css'
import logo from '../../assets/logo_black.png'
import ilustratorRecuperar from '../../assets/ilustrator_recuperar.jpg'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { getInstance } from 'api/apiInstance'

const Recuperar = () => {
  const [form, setForm] = useState({
    email: ''
  })

  const handleChange = useCallback((e) => {
    console.log('Cambio ' + e.target.value)
    setForm((prev) => ({ ..._.set(prev, e.target.name, e.target.value) }))
  }, [])
  const handleLogin = async () => {
    console.log(form.email)
    try {
      const request = await getInstance('admin')
      const response = await request.post(`users/recuperar-contrasenia`, {
        username: form.email,
      })
      console.log(response)
      toast.success(response.data)
    } catch (error) {
      console.error(error.message)
      toast.error('No se pudo cambiar la contraseña')
    }
  }

  return (
    <div className="Ingresar">
      <div className="illustrationIngresar">
        <img src={ilustratorRecuperar} alt="" />
      </div>
      <div className="FormLoginContainer">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        {/* <h2 className='titleIngresar'>Bienvenido a SaveTax Facturador</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie dolor ligula, at tincidunt tortor eleifend eu.</p> */}
        <div className="Form">
          <h4>Recuperar Credenciales</h4>
          <p>
            Ingresa tu numero de cedula y te enviaremos una contrasena por defecto a tu correo electronico registrado</p>
          <label htmlFor="">
            Numero de Cedula
            <div className="field">
              <input type="text" placeholder="Numero de Cedula" className="email" name='email'
                value={form.email}
                onChange={handleChange} />
            </div>
          </label>
          <div className="optionsSecondaryForm">
            <Link to="/ingresar">Intentar Ingreso</Link>
          </div>
          <button className="btnIngresar" onClick={handleLogin}>
            Enviar Correo
          </button>
          <p className="registerLink">
            ¿No tienes una cuenta? <Link to="/registro">Registrate</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Recuperar
