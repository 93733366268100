import { toast } from 'react-toastify'

export const validatePersona = (persona) => {
  if (!persona.nombreCliente.trim()) {
    toast.error('El campo Nombre es obligatorio, ingrese un nombre')

    return false
  } else if (!persona.tipoCliente.nombreTipoCliente.trim()) {
    toast.error('El campo Tipo de Cliente es obligatorio, seleccione un Tipo de Cliente')

    return false
  } else if (!persona.telefono.trim()) {
    toast.error('El campo Telefono es obligatorio, digite un Télefono')

    return false
  } else if (!Number.isInteger(Number(persona.telefono))) {
    toast.error('El Télefono solo puede contener caracteres númericos, ingrese un Télefono valido')

    return false
  } else if (!persona.direccion.trim()) {
    toast.error('El campo Direccion es obligatorio, ingrese una Dirección')

    return false
  } else if (!persona.correo.trim()) {
    toast.error('El campo Correo es obligatorio, ingrese un Correo')

    return false
  } else if (!persona.correo.includes('@')) {
    toast.error('El correo tiene un formato invalido, ingrese un correo valido')

    return false
  } else if (!persona.ruc.trim()) {
    toast.error('El campo de Numero de identificacion es obligatorio, ingrese un Numero de identificacion')

    return false
  } else if (!persona.estado.trim()) {
    toast.error('El Estado es un campo obligatorio, seleccione un estado')

    return false
  } else if (!persona.tipoDoctoPersona?.codigoSri.trim()) {
    toast.error('El Tipo de Documento es un campo obligatorio, seleccione un Tipo de Documento')

    return false
  }

  return true
}
