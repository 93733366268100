import Text from 'components/FormField/Text'
import React from 'react'

const RegistroPymes = ({ company, setCompany }) => {
  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const handleChange = (event) => {
    const { value, name } = event.target
    setCompany({ ...company, [name]: value })
  }
  return (
    <div className="formComprobantes">

      <label htmlFor="logo" className="text span3">
        Cargar Documentos Emitidos
        <input
          accept="application/zip"
          name="logo"
          type="file"
          id="logo"
          onChange={async (e) => {
            const base64File = await fileToBase64(e.target.files[0])
            setCompany((prev) => ({
              ...prev,
              archivoDocEmitidos: base64File,
            }))
          }}
        />
      </label>
      <label htmlFor="logo" className="text span3">
        Cargar Documentos Recibidos
        <input
          accept="application/zip"
          name="logo"
          type="file"
          id="logo"
          onChange={async (e) => {
            const base64File = await fileToBase64(e.target.files[0])
            setCompany((prev) => ({
              ...prev,
              archivoDocRecibidos: base64File,
            }))
          }}
        />
      </label>
      <label htmlFor="logo" className="text span3">
        Una vez cargados los archivos de migracion, guardar la compañia y se le enviara un correo de confirmacion cuando los documentos esten cargados
      </label>
    </div>
  )
}

export default RegistroPymes
