import { GET_PRODUCTOS, UPLOAD_PRODUCTO, UPDATE_PRODUCTO } from '../constants/actionTypes'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getInstance } from 'api/apiInstance'

export const getProductos = (cia) => {
  return async (dispatch) => {
    const request = await getInstance('productos')
    const response = await request.get(`producto/find-by-cod-cia/${cia}`)

    dispatch({
      type: GET_PRODUCTOS,
      payload: {
        productos: response?.data,
      },
    })
  }
}

export const uploadProducto = (form, onClose) => async (dispatch) => {
  try {
    const request = await getInstance('productos')
    const response = await request.post(`producto`, JSON.stringify(form))

    dispatch({
      type: UPLOAD_PRODUCTO,
      payload: {
        producto: response?.data,
      },
    })
    toast.success('Producto agregado correctamente')
    onClose()
  } catch (e) {
    console.error(e.message)
    toast.error('Error al agregar el producto. Inténtelo más tarde')
  }
}

export const updateProducto = (form, onClose) => async (dispatch) => {
  try {
    const request = await getInstance('productos')
    const response = await request.put(`producto`, JSON.stringify(form))

    dispatch({
      type: UPDATE_PRODUCTO,
      payload: {
        producto: response?.data,
      },
    })
    toast.success('Producto actualizado correctamente')
    onClose()
  } catch (e) {
    console.error(e.message)
    toast.error('Error al agregar el producto. Inténtelo más tarde')
  }
}
