import React from 'react'
import './NotFound.css'
import { Link } from 'react-router-dom'
import PageNotFound from '../../assets/404.png'

const NotFound = () => {
  return (
    <div className="notFound">
      <img src={PageNotFound} alt="" />
      <h2>Página No Encontrada</h2>
      <p>La página solicitada no existe, puede regresar al escritorio o navegar a través de los componentes de navegación</p>
      <Link to="/escritorio">Regresar al Escritorio</Link>
    </div>
  )
}

export default NotFound
