const tipoDocsVta = [
  {
    codigoSriAts: '01',
    estado: 'A',
    etiqueta: 'FacturaElectronica',
    idTipoDoctoVta: 2,
    nombreCortoVta: 'FE',
    nombreDoctoVta: 'FACTURA ELECTRONICA',
    nombreReporte: 'rpFacturaElectronica',
    proforma: 'S',
    sumaResta: 'R',
    tipoDoctoAnula: 4,
    verificarExistencia: 'N',
    xmlSchema: 'https://cdn.comprobanteselectronicos.go.cr/xml-schemas/v4.3/facturaElectronica',
  },
  {
    codigoSriAts: '02',
    estado: 'A',
    etiqueta: 'NotaDebitoElectronica',
    idTipoDoctoVta: 3,
    nombreCortoVta: 'ND',
    nombreDoctoVta: 'NOTA DEBITO ELECTRONICA',
    nombreReporte: 'rpNotaDebito',
    proforma: 'N',
    sumaResta: 'R',
    verificarExistencia: 'N',
    xmlSchema: 'https://cdn.comprobanteselectronicos.go.cr/xml-schemas/v4.3/notaDebitoElectronica',
  },
  {
    codigoSriAts: '03',
    estado: 'A',
    etiqueta: 'NotaCreditoElectronica',
    idTipoDoctoVta: 4,
    nombreCortoVta: 'NC',
    nombreDoctoVta: 'NOTA CREDITO ELECTRONICA',
    nombreReporte: 'rpNotaCredito',
    proforma: 'N',
    sumaResta: 'R',
    verificarExistencia: 'N',
    xmlSchema: 'https://cdn.comprobanteselectronicos.go.cr/xml-schemas/v4.3/notaCreditoElectronica',
  },
  {
    codigoSriAts: '04',
    estado: 'A',
    etiqueta: 'TiqueteElectronico',
    idTipoDoctoVta: 5,
    nombreCortoVta: 'TE',
    nombreDoctoVta: 'TIQUETE ELECTRONICO',
    nombreReporte: 'rpTiqueteElectronico',
    proforma: 'N',
    sumaResta: 'R',
    tipoDoctoAnula: 4,
    verificarExistencia: 'N',
    xmlSchema: 'https://cdn.comprobanteselectronicos.go.cr/xml-schemas/v4.3/tiqueteElectronico',
  },
  {
    codigoSriAts: '08',
    estado: 'A',
    etiqueta: 'FacturaElectronicaCompra',
    idTipoDoctoVta: 7,
    nombreCortoVta: 'FEC',
    nombreDoctoVta: 'FACTURA ELECTRONICA DE COMPRA',
    nombreReporte: 'rpFacturaElectronicaCompra',
    proforma: 'N',
    sumaResta: 'S',
    tipoDoctoAnula: 4,
    verificarExistencia: 'N',
    xmlSchema: 'https://cdn.comprobanteselectronicos.go.cr/xml-schemas/v4.3/facturaElectronicaCompra',
  },
  {
    codigoSriAts: '09',
    estado: 'A',
    etiqueta: 'FacturaElectronicaExportacion',
    idTipoDoctoVta: 6,
    nombreCortoVta: 'FEE',
    nombreDoctoVta: 'FACTURA ELECTRONICA DE EXPORTACION',
    nombreReporte: 'rpFacturaElectronicaExportacion',
    proforma: 'S',
    sumaResta: 'R',
    verificarExistencia: 'N',
    xmlSchema: 'https://cdn.comprobanteselectronicos.go.cr/xml-schemas/v4.3/facturaElectronicaExportacion',
  },
]

export default tipoDocsVta
