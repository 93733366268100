import React, { useEffect, useMemo } from 'react'
import ReactSelect from 'react-select'
import useAdmin from 'hooks/useAdmin'
import useMisc from 'hooks/useMisc'
import { useDispatch } from 'react-redux'
import useUser from 'hooks/useUser'
import { getCodigosReferencia, getcondicionesDeVenta, getMetodosDePago, getMonedas } from 'actions/misc'
import { getActividadesEconomicas, getSucursales, getCajas } from 'actions/administracion'
import axios from 'axios'
import { getInstance } from 'api/apiInstance'
import useDocumentos from 'hooks/useDocumentos'
import { getDocumentosEmitidos } from 'actions/documentos'

export const Select = ({ id, label, name, disabled, value, onChange, width, children }) => (
  <label htmlFor={id} className="text" style={{ width: width }}>
    {label}
    <select disabled={disabled} name={name} value={value} onChange={onChange} id={id}>
      <option value="">Seleccione</option>
      {children}
    </select>
  </label>
)

const Encabezado = ({ factura, handleChange, setFactura, handleAddMedioDePage, tipoDocto }) => {
  const dispatch = useDispatch()

  const { isEdit } = factura
  const { savetaxInfo } = useUser()
  const cia = useMemo(() => savetaxInfo?.cia?.codCia, [savetaxInfo])
  const { sucursales, cajas, actividadesEconomicas } = useAdmin()
  const { monedas, condicionesDeVenta, metodosDePago, codigosReferencia } = useMisc()
  const { documentosEmitidos } = useDocumentos()

  const handleMonedaChange = async (e) => {
    //
    const moneda = JSON.parse(e.target[e.target.selectedIndex].dataset.moneda)
    const { idMoneda } = moneda
    if (!e.target.value)
      return setFactura((prev) => ({
        ...prev,
        idMoneda: '',
      }))
    if (+idMoneda === 1) {
      setFactura((prev) => ({
        ...prev,
        tasaCambio: 1,
        openTasaCambio: false,
        idMoneda: moneda,
      }))
    } else if (+idMoneda === 2) {
      const request = await getInstance('fe')
      const tasaCambio = await request.get(`vta-movm/consulta-tasa-cambio-dolar`)

      const { value } = tasaCambio.data
      console.log(tasaCambio)
      setFactura((prev) => ({
        ...prev,
        tasaCambio: tasaCambio.data,
        openTasaCambio: false,
        idMoneda: moneda,
      }))
    } else {
      setFactura((prev) => ({
        ...prev,
        tasaCambio: '',
        openTasaCambio: true,
        idMoneda: moneda,
      }))
    }
  }

  useEffect(() => {
    if (!sucursales.length) {
      dispatch(getSucursales(cia))
      if (!isEdit) {
        console.log(sucursales[0])
        setFactura((prev) => ({
          ...prev,
          sucursal: sucursales[0],
        }))
      }
    } else {
      if (!isEdit) {
        setFactura((prev) => ({
          ...prev,
          sucursal: sucursales[0],
        }))
      }
    }
  }, [dispatch, sucursales, cia, setFactura, isEdit])

  useEffect(() => {
    if (!actividadesEconomicas.length) {
      dispatch(getActividadesEconomicas(cia))
      if (!isEdit) {
        setFactura((prev) => ({
          ...prev,
          actividadEconomicaCia: actividadesEconomicas[0],
        }))
      }
    } else {
      if (!isEdit) {
        setFactura((prev) => ({
          ...prev,
          actividadEconomicaCia: actividadesEconomicas[0],
        }))
      }
    }
  }, [dispatch, actividadesEconomicas, cia, setFactura, isEdit])

  useEffect(() => {
    if (!documentosEmitidos.length) {
      // console.log('Entro aca doc emitidos')
      dispatch(getDocumentosEmitidos(cia))
    }
  }, [dispatch, documentosEmitidos, cia])

  useEffect(() => {
    if (!codigosReferencia.length) {
      //console.log('Entro aca codigos referencia')
      dispatch(getCodigosReferencia())
    }
  }, [dispatch, codigosReferencia])
  useEffect(() => {
    if (!condicionesDeVenta.length) {
      dispatch(getcondicionesDeVenta(cia))
      if (!isEdit) {
        /*setFactura((prev) => ({
          ...prev,
          condicionVenta: condicionesDeVenta.filter((condicion) => condicion.nombre === 'CONTADO'),
        }))*/
      }
    } else {
      if (!isEdit) {
        /*setFactura((prev) => ({
          ...prev,
          condicionVenta: condicionesDeVenta.filter((condicion) => condicion.nombre === 'CONTADO'),
        }))*/
      }
    }
  }, [dispatch, condicionesDeVenta, cia, setFactura, isEdit])

  useEffect(() => {
    if (!metodosDePago.length) {
      dispatch(getMetodosDePago(cia))
    }
  }, [dispatch, metodosDePago, cia])

  useEffect(() => {
    if (!monedas.length) {
      console.log(factura.isCopy)
      dispatch(getMonedas)
      if (!isEdit) {
        if (!factura.idMoneda) {
          setFactura((prev) => ({
            ...prev,
            idMoneda: monedas[0],
          }))
        }
      }
    } else {
      if (!isEdit) {
        if (!factura.idMoneda) {
          setFactura((prev) => ({
            ...prev,
            idMoneda: monedas[0],
          }))
        }
      }
    }
  }, [dispatch, monedas, cia, setFactura, isEdit, factura.isCopy, factura.idMoneda])

  useEffect(() => {
    if (!cajas.length) {
      dispatch(getCajas(cia))
      if (!isEdit) {
        setFactura((prev) => ({
          ...prev,
          caja: cajas[0],
        }))
      }
    } else {
      if (!isEdit) {
      }
      setFactura((prev) => ({
        ...prev,
        caja: cajas[0],
      }))
    }
  }, [dispatch, cajas, cia, setFactura])

  return (
    <>
      <div className="sectionForm">
        <div className="titleSectionForm" style={{ width: '100%' }}>
          <h3>Encabezado </h3>
        </div>

        <label htmlFor="fecha" className="text" style={{ width: '15%' }}>
          Fecha
          <input
            disabled={true}
            value={
              isEdit
                ? `${new Date(factura?.fechaEmision).getDate()}/${new Date(factura?.fechaEmision).getMonth() + 1}/${new Date(
                    factura?.fechaEmision
                  ).getFullYear()}`
                : factura?.fechaCreacion
            }
            id="fecha"
            type="text"
            placeholder="Fecha"
          />
        </label>

        <Select
          label="Sucursal"
          disabled={isEdit}
          name="sucursal.sucursalPK.idSucursal"
          value={factura?.sucursal?.sucursalPK?.idSucursal ?? ''}
          onChange={(e) => {
            setFactura((prev) => ({
              ...prev,
              caja: {},
              sucursal: {
                ...prev.sucursal,
                sucursalPK: {
                  ...prev.sucursal.sucursalPK,
                  idSucursal: e.target.value,
                },
              },
              vtaMovmPK: {
                ...prev.vtaMovmPK,
                idSucursal: Number(e.target.value),
              },
            }))
          }}
          id="sucursal"
          width="30%"
        >
          {sucursales.map((sucursal) => (
            <option key={Math.random().toString(36).slice(-10)} value={sucursal.sucursalPK.idSucursal}>
              {sucursal.nombreSucursal}
            </option>
          ))}
        </Select>

        <Select
          label="Caja"
          disabled={isEdit}
          name="caja.puntoEmision"
          value={factura?.caja?.puntoEmision ?? ''}
          onChange={handleChange}
          id="caja"
          width="20%"
        >
          {cajas
            .filter((caja) => caja?.sucursal?.sucursalPK?.idSucursal === Number(factura?.sucursal?.sucursalPK?.idSucursal))
            .map((caja) => (
              <option key={Math.random().toString(36).slice(-10)} value={caja.puntoEmision}>
                {caja.puntoEmision}
              </option>
            ))}
        </Select>

        <Select
          label="Moneda"
          disabled={isEdit}
          value={factura?.idMoneda?.idMoneda ?? ''}
          id="moneda"
          onChange={handleMonedaChange}
          width="35%"
        >
          {monedas.map((moneda) => (
            <option
              key={Math.random().toString(36).slice(-10)}
              data-moneda={JSON.stringify(moneda, null, 2)}
              value={moneda.idMoneda}
            >
              {moneda.nombreMoneda}
            </option>
          ))}
        </Select>

        <label htmlFor="tasaDeCambio" className="text" style={{ width: '20%' }}>
          Tipo de Cambio
          <input
            disabled={isEdit || !factura?.openTasaCambio}
            value={factura?.tasaCambio}
            type="text"
            placeholder="Tipo de Cambio"
            id="tasaDeCambio"
            name="tasaCambio"
            onChange={handleChange}
            // onChange={(event) => onChangeTipoCambio(setformFacturaElectronicaState, event)}
          />
        </label>

        <Select
          label="Actividad Económica"
          disabled={isEdit}
          name="actividadEconomicaCia.actividadEconomica.codigo"
          value={factura?.actividadEconomicaCia?.actividadEconomica?.codigo}
          id="actividadEconomica"
          onChange={(e) => {
            setFactura((prev) => ({
              ...prev,
              actividadEconomicaCia: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad),
            }))
          }}
          width="80%"
        >
          {actividadesEconomicas.map((actividad) => (
            <option
              key={Math.random().toString(36).slice(-10)}
              data-actividad={JSON.stringify(actividad, null, 2)}
              value={actividad.actividadEconomica.codigo}
            >
              {actividad.actividadEconomica.nombre}
            </option>
          ))}
        </Select>

        <Select
          label="Condición de Venta"
          disabled={isEdit}
          value={factura?.condicionVenta?.codigo ?? ''}
          id="condicionVenta"
          name="condicionVenta.codigo"
          onChange={(e) => {
            setFactura((prev) => ({
              ...prev,
              condicionVenta: JSON.parse(e.target[e.target.selectedIndex].dataset.condicion),
            }))
          }}
          width="35%"
        >
          {condicionesDeVenta.map((condicion) => (
            <option
              key={Math.random().toString(36).slice(-10)}
              data-condicion={JSON.stringify(condicion, null, 2)}
              value={condicion.codigo}
            >
              {condicion.nombre}
            </option>
          ))}
        </Select>

        {factura?.condicionVenta?.nombre === 'CREDITO' && (
          <label htmlFor="plazo" className="text" style={{ width: '25%' }}>
            Días de Crédito
            <input
              name="plazo"
              onChange={handleChange}
              disabled={isEdit}
              type="number"
              value={factura?.plazo}
              placeholder="Días de Crédito"
              id="plazo"
            />
          </label>
        )}

        <label className="text" style={{ width: '40%' }}>
          Medios de Pago
          <ReactSelect
            placeholder="Seleccione"
            isDisabled={isEdit}
            isMulti
            name="formaPagoList"
            onChange={handleAddMedioDePage}
            value={
              factura?.formaPagoList
                ? factura?.formaPagoList.reduce((acc, v) => {
                    acc.push({
                      value: String(v.codigoSri),
                      label: v.nombre,
                    })
                    return acc
                  }, [])
                : factura?.formaPagoList
            }
            options={metodosDePago.reduce((acc, v) => {
              acc.push({
                value: String(v.codigoSri),
                label: v.nombre,
                ...v,
              })
              return acc
            }, [])}
          />
        </label>

        <label htmlFor="ordenDeCompra" className="text" style={{ width: '25%' }}>
          Orden de Compra
          <input
            disabled={isEdit}
            type="text"
            name="comentario"
            onChange={handleChange}
            value={factura?.comentario ?? ''}
            placeholder="Orden de Compra"
            id="ordenDeCompra"
          />
        </label>

        <label htmlFor="tiempo" className="text" style={{ width: '20%' }}>
          Número Interno
          <input
            disabled={isEdit}
            name="tiempo"
            onChange={handleChange}
            value={factura?.tiempo ?? ''}
            type="text"
            placeholder="Número Interno"
            id="tiempo"
          />
        </label>
        {(tipoDocto === 3 || tipoDocto === 4) && (
          <Select
            label="Referencia"
            disabled={isEdit}
            name="vtaMovm.vtaMovmPK.numDocto}"
            value={factura?.vtaMovm?.vtaMovmPK?.numDocto}
            id="vtaMovm"
            onChange={(e) => {
              setFactura((prev) => ({
                ...prev,
                vtaMovm: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad),
                actividadEconomicaCia: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).actividadEconomicaCia,
                caja: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).caja,
                cliente: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).cliente,
                condicionVenta: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).condicionVenta,
                formaPagoList: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).formaPagoList,
                idMoneda: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).idMoneda,
                otroCargoVtaMovmList: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).otroCargoVtaMovmList,
                sucursal: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).sucursal,

                tipoDoctoVta: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).tipoDoctoVta,
                vtaDetmList: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).vtaDetmList,

                descuento: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).descuento,
                iva: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).iva,
                subtotal: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).subtotal,
                subtotalExento: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).subtotalExento,
                tasaCambio: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).tasaCambio,
                tiempo: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).tiempo,
                total: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).total,
                totalExento: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalExento,
                totalExonerado: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalExonerado,
                totalGravados: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalGravados,
                totalIvaDevuelto: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalIvaDevuelto,
                totalMercExento: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalMercExento,
                totalMercExonerado: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalMercExonerado,
                totalMercGravados: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalMercGravados,
                totalOtrosCargos: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalOtrosCargos,
                totalOtrosImpuestos: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalOtrosImpuestos,
                totalSrvExento: JSON.parse(e.target[e.target.selectedIndex].dataset.actividad).totalSrvExento,
              }))
            }}
            width="80%"
          >
            {documentosEmitidos.map((ref) => (
              <option
                key={Math.random().toString(36).slice(-10)}
                data-actividad={JSON.stringify(ref, null, 2)}
                value={ref.vtaMovmPK.numDocto}
              >
                {ref.prefijo}-{ref.referencia}-{ref.tipoDoctoVta.codigoSriAts}-{ref.tipoDoctoVta.nombreDoctoVta}
              </option>
            ))}
          </Select>
        )}
        {(tipoDocto === 3 || tipoDocto === 4) && (
          <label htmlFor="razon" className="text" style={{ width: '100%' }}>
            Razon
            <input
              disabled={isEdit}
              name="razon"
              onChange={handleChange}
              value={factura?.razon ?? ''}
              type="text"
              placeholder="Razon"
              id="razon"
            />
          </label>
        )}

        {(tipoDocto === 3 || tipoDocto === 4) && (
          <Select
            label="Codigo Referencia"
            disabled={isEdit}
            value={factura?.codigoReferencia?.codigo ?? ''}
            id="codigoReferencia"
            name="codigoReferencia.codigo"
            onChange={(e) => {
              setFactura((prev) => ({
                ...prev,
                codigoReferencia: JSON.parse(e.target[e.target.selectedIndex].dataset.cr),
              }))
            }}
            width="35%"
          >
            {codigosReferencia.map((cr) => (
              <option key={Math.random().toString(36).slice(-10)} data-cr={JSON.stringify(cr, null, 2)} value={cr.codigo}>
                {cr.nombre}
              </option>
            ))}
          </Select>
        )}

        {isEdit && (
          <>
            <label htmlFor="numeroDeDocumento" className="text" style={{ width: '20%' }}>
              Número Documento
              <input
                disabled={true}
                value={factura?.referencia}
                type="text"
                placeholder="Número Documento"
                id="numeroDeDocumento"
              />
            </label>

            <label htmlFor="consecutivo" className="text" style={{ width: '15%' }}>
              Consecutivo
              <input disabled={true} value={factura?.prefijo} type="text" placeholder="Consecutivo" id="nombreDescripcion" />
            </label>

            <label htmlFor="clave" className="text" style={{ width: '50%' }}>
              Clave
              <input disabled={true} value={factura?.numeroAutorizacion} type="text" placeholder="Número Interno" id="clave" />
            </label>
            <div className="container-error">
              <label htmlFor="fecha" className="text" style={{ width: '20%' }}>
                Mensaje Hacienda:
              </label>
              {factura.erroresSri && <code className="errors-denied">{factura?.erroresSri}</code>}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Encabezado
