import { useCallback } from 'react'

const useCalcs = (factura) => {
  const getTotalDescontado = useCallback(
    (newPrice = null) => {
      const descuentos = factura?.productEnLinea?.descuentoVtaDemList
      if (descuentos?.length) {
        return descuentos.reduce((acc, i) => {
          if (i.monto) {
            return acc + +i.monto
          } else if (i.porcentaje) {
            return (
              acc + (newPrice ?? factura?.productEnLinea?.precioVenta * factura?.productEnLinea?.cantidad) * (i.porcentaje / 100)
            )
          }
          return acc
        }, 0)
      }
      return 0
    },
    [factura]
  )

  const getImpuestos = useCallback((el) => {
    const impuestos = el?.productEnLinea ? el?.productEnLinea?.otroImpuestoVtaDetmList ?? [] : el?.otroImpuestoVtaDetmList ?? []

    return impuestos?.reduce((acc, i) => acc + +i?.monto, 0)
  }, [])

  return {
    getTotalDescontado,
    getImpuestos,
  }
}

export default useCalcs
