import { getDatosGeograficos, getTiposDocs } from 'actions/misc'
import { updateClient, uploadClient } from 'actions/personas'
import { ReactComponent as Save } from 'assets/save.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import axios from 'axios'
import useMisc from 'hooks/useMisc'
import useUser from 'hooks/useUser'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Form from '../Form'
import '../Form.css'
import { validatePersona } from './configs'

const tipos = [
  {
    idTipoCliente: 1,
    nombreTipoCliente: 'Cliente',
  },
  {
    idTipoCliente: 2,
    nombreTipoCliente: 'Proveedor',
  },
  {
    idTipoCliente: 3,
    nombreTipoCliente: 'Cliente/Proveedor',
  },
]

const initialForm = (codCia) => ({
  correosAdicionales: '',
  identificador: '',
  estado: 'A',
  correo: '',
  nombreCliente: '',
  direccion: '',
  ruc: '',
  telefono: '',
  tipoCliente: {
    nombreTipoCliente: 'Cliente',
    idTipoCliente: 1,
  },
  departamento: {
    idDepartamento: null,
  },
  barrio: {
    idBarrio: null,
  },
  distrito: {
    idDistrito: null,
  },
  municipio: {
    idMunicipio: null,
  },
  pais: {
    idPais: 1,
  },
  tipoDoctoPersona: {
    codigoSri: '01',
    tipoDoctoPersonaPK: { codCia: codCia, idTipoDoctoPersona: 1 },
  },
  clientePK: {
    codCia: codCia,
  },
})

const FormPersonas = ({ persona = undefined, onClose }) => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const [form, setForm] = useState(persona ?? initialForm(savetaxInfo.cia.codCia))
  const { tiposDocs } = useMisc()
  const { paises, provincias, cantones, distritos, barrios, isLoadingDatosGeograficos } = useMisc()

  const handleChange = useCallback((e) => {
    setForm((prev) => ({ ..._.set(prev, e.target.name, e.target.value) }))
  }, [])

  const handleSubmit = useCallback(async () => {
    if (validatePersona(form)) {
      if (form.isEdit) {
        dispatch(
          updateClient(form, () => {
            setForm({ ...initialForm(savetaxInfo.cia.codCia) })
            onClose()
          })
        )
      } else {
        dispatch(
          uploadClient(form, () => {
            setForm({ ...initialForm(savetaxInfo.cia.codCia) })
            onClose()
          })
        )
      }
    }
  }, [dispatch, form, onClose, savetaxInfo.cia.codCia])

  const handleSearchCedula = useCallback(async () => {
    if (!form?.ruc.length) return toast('Ingrese una cédula válida', { type: 'error' })

    try {
      let response = await axios.get(`https://api.hacienda.go.cr/fe/ae?identificacion=${form?.ruc}`)
      let { data } = response

      setForm((prev) => ({
        ...prev,
        nombreCliente: data.nombre,
      }))
      if (!data.nombre) {
        toast('No hay titular de este número de identidad', { type: 'error' })
      } else {
        toast(`${data.nombre} fue establecido`, { type: 'success' })
      }
    } catch (e) {
      toast(`Hubo un error en el servidor, intentelo más tarde`, { type: 'error' })
    }
  }, [form.ruc])

  useEffect(() => {
    if (!persona) {
      setForm({ ...initialForm(savetaxInfo.cia.codCia) })
    }
  }, [dispatch, savetaxInfo, persona])

  useEffect(() => {
    if (!tiposDocs.length) {
      dispatch(getTiposDocs(savetaxInfo.cia.codCia))
    }
  }, [tiposDocs, dispatch, savetaxInfo])

  useEffect(() => {
    if (isLoadingDatosGeograficos) {
      dispatch(getDatosGeograficos)
    }
  }, [isLoadingDatosGeograficos, dispatch])
  return (
    <Form
      onClose={() => {
        onClose()
        setForm({ ...initialForm(savetaxInfo.cia.codCia) })
      }}
      title={persona ? 'EDITAR PERSONA' : 'AGREGAR PERSONA'}
    >
      <div className="FormPersonas workspace">
        <div className="mainForm">
          <label htmlFor="nombreDescripcion" className="text" style={{ width: '60%' }}>
            Nombre
            <input
              type="text"
              onChange={handleChange}
              name="nombreCliente"
              autoComplete="off"
              value={form?.nombreCliente ?? ''}
              placeholder="Nombre"
              id="nombreDescripcion"
            />
          </label>

          <label htmlFor="Tipo" className="text" style={{ width: '40%' }}>
            Tipo
            <select
              id="Tipo"
              name="tipoCliente.idTipoCliente"
              value={form?.tipoCliente?.idTipoCliente ?? 1}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  tipoCliente: {
                    ...prev.tipoCliente,
                    idTipoCliente: e.target.value,
                    nombreTipoCliente: e.target[e.target.selectedIndex].dataset.nombre,
                  },
                }))
              }}
            >
              {tipos.map((tipo) => (
                <option key={tipo.idTipoCliente} value={tipo.idTipoCliente} data-nombre={tipo.nombreTipoCliente}>
                  {tipo.nombreTipoCliente}
                </option>
              ))}
            </select>
          </label>

          <label htmlFor="País" className="text" style={{ width: Number(form?.pais?.idPais) === 1 ? '33%' : '50%' }}>
            País
            <select
              disabled={!paises.length}
              id="País"
              name="pais.idPais"
              value={form?.pais?.idPais ?? ''}
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  pais: {
                    ...prev.pais,
                    idPais: e.target.value,
                    nombrePais: e.target[e.target.selectedIndex].dataset.nombre,
                  },
                }))
                if (e.target.value !== 1) {
                  setForm((prev) => ({
                    ...prev,
                    departamento: null,
                    idMunicipio: null,
                    distrito: null,
                    barrio: null,
                  }))
                }
              }}
            >
              <option value="">Seleccione</option>
              {paises?.map((pais) => (
                <option key={pais.idPais} value={pais.idPais} data-nombre={pais.nombrePais}>
                  {pais.nombrePais}
                </option>
              ))}
            </select>
          </label>

          {Number(form?.pais?.idPais) === 1 && (
            <>
              <label htmlFor="Provincia" className="text" style={{ width: '33%' }}>
                Provincia
                <select
                  disabled={!provincias.length}
                  id="Provincia"
                  name="departamento.idDepartamento"
                  value={form?.departamento?.idDepartamento ?? ''}
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      departamento: {
                        ...prev.departamento,
                        idDepartamento: e.target.value,
                        nombreDepartamento: e.target[e.target.selectedIndex].dataset.nombre,
                      },
                    }))
                  }}
                >
                  <option value="">Seleccione</option>
                  {provincias?.map((departamento) => (
                    <option
                      key={departamento.idDepartamento}
                      value={departamento.idDepartamento}
                      data-nombre={departamento.nombreDepartamento}
                    >
                      {departamento.nombreDepartamento}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="Cantón" className="text" style={{ width: '33%' }}>
                Cantón
                <select
                  disabled={!cantones.length}
                  id="Cantón"
                  name="idMunicipio.idMunicipio"
                  value={form?.idMunicipio?.idMunicipio ?? ''}
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      idMunicipio: {
                        ...prev.idMunicipio,
                        idMunicipio: e.target.value,
                        nombreMunicipio: e.target[e.target.selectedIndex].dataset.nombre,
                      },
                    }))
                  }}
                >
                  <option value="">Seleccione</option>
                  {cantones
                    ?.filter((c) => +c?.idDepartamento?.idDepartamento === +(form?.departamento?.idDepartamento ?? 1))
                    ?.map((canton) => (
                      <option key={canton.idMunicipio} value={canton.idMunicipio} data-nombre={canton?.nombreMunicipio}>
                        {canton?.nombreMunicipio}
                      </option>
                    ))}
                </select>
              </label>

              <label htmlFor="Distrito" className="text" style={{ width: '33%' }}>
                Distrito
                <select
                  disabled={!distritos.length}
                  id="Distrito"
                  name="distrito.idDistrito"
                  value={form?.distrito?.idDistrito ?? ''}
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      distrito: {
                        ...prev.distrito,
                        idDistrito: e.target.value,
                        nombre: e.target[e.target.selectedIndex].dataset.nombre,
                      },
                    }))
                  }}
                >
                  <option value="">Seleccione</option>
                  {distritos
                    ?.filter((d) => +d.idMunicipio.idMunicipio === +(form?.idMunicipio?.idMunicipio ?? 101))
                    ?.map((distrito) => (
                      <option key={distrito.idDistrito} value={distrito.idDistrito} data-nombre={distrito.nombre}>
                        {distrito.nombre}
                      </option>
                    ))}
                </select>
              </label>

              <label htmlFor="Barrio" className="text" style={{ width: '33%' }}>
                Barrio
                <select
                  disabled={!barrios.length}
                  id="Barrio"
                  name="barrio.idBarrio"
                  value={form?.barrio?.idBarrio ?? ''}
                  onChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      barrio: {
                        ...prev.barrio,
                        idBarrio: e.target.value,
                        nombre: e.target[e.target.selectedIndex].dataset.nombre,
                      },
                    }))
                  }}
                >
                  <option value="">Seleccione</option>
                  {barrios
                    ?.filter((b) => +b?.idDistrito?.idDistrito === +(form?.distrito?.idDistrito ?? 10101))
                    ?.map((barrio) => (
                      <option key={barrio.idBarrio} value={barrio.idBarrio} data-nombre={barrio.nombre}>
                        {barrio.nombre}
                      </option>
                    ))}
                </select>
              </label>
            </>
          )}

          <label htmlFor="Teléfono" className="text" style={{ width: Number(form?.pais?.idPais) === 1 ? '33%' : '50%' }}>
            Teléfono
            <input
              autoComplete="off"
              type="text"
              placeholder="Teléfono"
              id="Teléfono"
              name="telefono"
              onChange={handleChange}
              value={form?.telefono ?? ''}
            />
          </label>

          <label htmlFor="Dirección" className="text" style={{ width: '100%' }}>
            Dirección
            <input
              autoComplete="off"
              type="text"
              placeholder="Dirección"
              id="Dirección"
              name="direccion"
              onChange={handleChange}
              value={form?.direccion ?? ''}
            />
          </label>

          <label htmlFor="Correo" className="text" style={{ width: '33%' }}>
            Correo
            <input
              autoComplete="off"
              type="text"
              placeholder="Correo"
              id="Correo"
              name="correo"
              onChange={handleChange}
              value={form?.correo ?? ''}
            />
          </label>

          <label htmlFor="CorreosAdicionales" className="text" style={{ width: '66%' }}>
            Correos Adicionales (separar por ;)
            <input
              autoComplete="off"
              type="text"
              placeholder="Correos Adicionales"
              id="CorreosAdicionales"
              name="correosAdicionales"
              onChange={handleChange}
              value={form?.correosAdicionales ?? ''}
            />
          </label>
        </div>

        <div className="sidebarForm">
          <label htmlFor="NúmeroIdentidad" className="text">
            Número Identidad
            <div className="searchForm">
              <input
                type="text"
                autoComplete="off"
                placeholder="Número Identidad"
                id="NúmeroIdentidad"
                name="ruc"
                onChange={handleChange}
                value={form?.ruc ?? ''}
              />
              <button className="searchPersonaForID" onClick={handleSearchCedula}>
                <Search />
              </button>
            </div>
          </label>

          <label htmlFor="Tipodocumento" className="text">
            Tipo documento
            <select
              disabled={!paises.length}
              id="Tipodocumento"
              onChange={(e) => {
                const obj = _.find(tiposDocs, (o) => o.codigoSri === e.target.value)
                const updatedForm = form
                _.set(updatedForm, 'tipoDoctoPersona.codigoSri', obj.codigoSri)
                _.set(updatedForm, 'tipoDoctoPersona.tipoDoctoPersonaPK', obj.tipoDoctoPersonaPK)
                setForm({ ...updatedForm })
              }}
              value={form?.tipoDoctoPersona?.codigoSri}
            >
              {tiposDocs.map((doc) => (
                <option key={doc.codigoSri} value={doc.codigoSri}>
                  {doc.nombreDoctoPersona}
                </option>
              ))}
            </select>
          </label>

          <label htmlFor="Estado" className="text">
            Estado
            <select id="Estado" name="estado" onChange={handleChange} value={form?.estado}>
              <option value="">Seleccione</option>
              <option value="A">Activo</option>
              <option value="D">Desactivo</option>
            </select>
          </label>

          <div className="actionForm">
            <button className="saveForm" style={{ marginLeft: 'auto' }} onClick={handleSubmit}>
              
              <span>Guardar</span>
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default FormPersonas
