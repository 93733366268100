import React from 'react'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { motion } from 'framer-motion'

const Planes = ({ form, setForm, stepUp, stepDown, variants }) => {
  const { persona, plan } = form

  const handleSelectPlan = (plan) => {
    setForm((prev) => ({
      ...prev,
      plan: {
        option: '01',
        ...plan,
      },
    }))
  }

  return (
    <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
      <div className="Form registroForm">
        <div className="carrusel step01">
          <div className="slide planesStep">
            <div className="steps">
              <span>Paso</span> <b>3</b> <span>de 4</span>
            </div>
            <h4>
              <button className="backButton" onClick={stepDown}>
                <Down />
              </button>
              Elige un Plan
            </h4>
            <p>Puedes seleccionar un plan de los que se ofrecen a continuación</p>

            {persona.tipoPersona === 1 && (
              <div className="tables">
                <table
                  className={`tablePlanes ${plan.nombre === 'personaFisica' ? 'active' : ''}`}
                  onClick={() =>
                    handleSelectPlan({
                      nombre: 'personaFisica',
                      mensual: 3000,
                      anual: 30000,
                    })
                  }
                >
                  <thead>
                    <tr>
                      <th className="persona">
                        <h4>Persona Física</h4>
                      </th>
                    </tr>
                    <tr className="priceTable">
                      <th className="persona" style={{ background: 'rgb(55, 56, 163)' }}>
                        <b>₡ 3.000</b>
                        <span>Pago Mensual</span>
                      </th>
                    </tr>
                    <tr className="priceTable">
                      <th className="persona">
                        <b>₡ 30.000</b>
                        <span>Pago Anual</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Reportes de documentos emitidos y recibidos. Emision ilimitada de documentos.</td>
                    </tr>
                    <tr>
                      <td>Recepción ilimitada de documentos y automática por correo.</td>
                    </tr>
                    <tr>
                      <td>24/7 desde la App móvil.</td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de importaciones <b>proximamente)</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de remesas al exterior <b>proximamente)</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de IVA <b>proximamente)</b>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table
                  className={`tablePlanes ${plan.nombre === 'planAsistido' ? 'active' : ''}`}
                  onClick={() =>
                    handleSelectPlan({
                      nombre: 'planAsistido',
                      mensual: 25000,
                      anual: 250000,
                    })
                  }
                >
                  <thead>
                    <tr>
                      <th className="asistido opacith">
                        <h4>Plan Asistido</h4>
                      </th>
                    </tr>
                    <tr className="priceTable">
                      <th className="asistido" style={{ background: 'rgb(200, 97, 38)' }}>
                        <b>₡ 25.000</b>
                        <span>Pago Mensual</span>
                      </th>
                    </tr>
                    <tr className="priceTable">
                      <th className="asistido">
                        <b>₡ 250.000</b>
                        <span>Pago Anual</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>24/7 desde la APP Móvil</td>
                    </tr>
                    <tr>
                      <td>Reportes de documentos emitidos y recibidos. Emision ilimitada de documentos.</td>
                    </tr>
                    <tr>
                      <td>Recepción ilimitada de documentos y automática por correo.</td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de importaciones <b>proximamente)</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de remesas al exterior <b>proximamente)</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de IVA <b>proximamente)</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {persona.tipoPersona === 2 && (
              <div className="tables">
                <table className="tablePlanes">
                  <thead>
                    <tr>
                      <th className="juridico">
                        <h4>Persona Juridica</h4>
                      </th>
                    </tr>
                    <tr className="priceTable">
                      <th className="juridico" style={{ background: 'rgb(165, 46, 46)' }}>
                        <b>₡ 3.000</b>
                        <span>Pago Mensual</span>
                      </th>
                    </tr>
                    <tr className="priceTable">
                      <th className="juridico">
                        <b>₡ 30.000</b>
                        <span>Pago Anual</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>24/7 desde la APP Móvil</td>
                    </tr>
                    <tr>
                      <td>Reportes de documentos emitidos y recibidos. Emision ilimitada de documentos.</td>
                    </tr>
                    <tr>
                      <td>Recepción ilimitada de documentos y automática por correo.</td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de importaciones <b>proximamente)</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de remesas al exterior <b>proximamente)</b>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Módulo de IVA <b>proximamente)</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="actionFixed">
        <button className="btnRegistro btnPlanes" onClick={stepUp} disabled={!plan.nombre}>
          Confirmar Plan
        </button>
      </div>
    </motion.div>
  )
}

export default Planes
