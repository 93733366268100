import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import ServerConfig from '../config/ServerConfig'

export function setAuthInitMiddleware(token, noAuth) {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    let headersData = {
      headers: {
        ...headers,
        'x-hasura-admin-secret': process.env.REACT_APP_HASURA_KEY,
      },
    }
    console.log(headersData)
    return headersData
  })

  const httpLink = createHttpLink({
    uri: ServerConfig.HASURA_URL,
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return client
}
