import React, { useCallback, useMemo } from 'react'
import { ReactComponent as Down } from 'assets/down-chevron.svg'

const Pagination = ({ currentPage, setPage, data }) => {
  const nextPage = useCallback(() => setPage((prev) => prev + 1), [setPage])
  const prevPage = useCallback(() => setPage((prev) => prev - 1), [setPage])

  const dataSize = useMemo(() => data.length, [data])

  return dataSize > 50 ? (
    <div className="paginationTable">
      <div className="controllers">
        {currentPage > 1 && (
          <button className="previousPage" onClick={prevPage}>
            <Down />
          </button>
        )}
        <span>
          Página {currentPage} de {Math.ceil(data.length / 50)}
        </span>
        {dataSize > 50 && !(currentPage * 50 >= data.length) && (
          <button className="nextPage" onClick={nextPage}>
            <Down />
          </button>
        )}
      </div>
    </div>
  ) : null
}

export default Pagination
