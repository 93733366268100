// Import Modules
import React, { useCallback, useState } from 'react'
import axios from 'axios'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'

// Import Hooks
import useUser from 'hooks/useUser'
import { validateFactura } from '../config'
import { deleteFactura, uploadFactura } from 'actions/facturas'
import { useDispatch } from 'react-redux'

// Import Components
import { Link } from 'react-router-dom'
import CircularProgress from 'components/CircularProgress/CircularProgress'

import { getInstance } from 'api/apiInstance'


export const fetchTotales = async (factura, setFactura) => {
  try {
    const request = await getInstance("fe")
    const response = await request.post(`vta-movm/calcula-total-vta-movm`,factura)

    console.log('fetchTotales ', factura)

    setFactura((prev) => ({
      ...prev,
      total: response?.data?.total,
      subtotal: response?.data?.subtotal,
      descuento: response?.data?.descuento,
      iva: response?.data?.iva,
      totalGravados: response?.data?.totalGravados,
      totalExonerado: response?.data?.totalExonerado,
      totalMercGravados: response?.data?.totalMercGravados,
      totalMercExento: response?.data?.totalMercExento,
      totalMercExonerado: response?.data?.totalMercExonerado,
      totalIvaDevuelto: response?.data?.totalIvaDevuelto,
      totalOtrosCargos: response?.data?.totalOtrosCargos,
      totalSrvExento: response?.data?.totalSrvExento,
      totalSrvExonerado: response?.data?.totalSrvExonerado,
      totalSrvGravados: response?.data?.totalSrvGravados,
      isLoadingTotales: false,
    }))
  } catch (e) {
    console.error(e.message)
  }
}

const Sidebar = ({ factura, setSection, handleChange, onClose, action, setModal }) => {
  const dispatch = useDispatch()
  const { isEdit } = factura
  const { savetaxInfo } = useUser()
  const [loading, setLoading] = useState(false)

  const handleDeleteRecipe = () => {
    createAlertConfirm(
      `¿Estás seguro de eliminar ${factura.referencia}?`,
      `Podras eliminar el documento ${factura.referencia}, esta accion no es puede ser revertida`,
      () => {
        dispatch(deleteFactura(factura)).then(() => {
          onClose()
        })
      }
    )
  }

  const handleSubmit = useCallback(async() => {
    setLoading(true)
    let facturaSend = { ...factura }
    //evaluando funcionalidad
    facturaSend.vtaDetmList.map((item) => {
      if (Array.isArray(item.descuentoVtaDemList)) {
        item.descuentoVtaDemList.map((itemDscto) => {
          itemDscto.porcentaje = 0
          itemDscto.valor = item.descuento
          itemDscto.monto = item.descuento
          return itemDscto
        })
      }
      return item
    })
    if (validateFactura({ ...facturaSend, caja: { cajaPK: { ...savetaxInfo?.cajero[0].cajeroPK } } })) {
      await dispatch(uploadFactura(action, { ...facturaSend, caja: { cajaPK: { ...savetaxInfo?.cajero[0].cajeroPK } } }, onClose, setModal))
      setLoading(false)
      return
    }else{
      setLoading(false)
      return
    }
  }, [action, onClose, dispatch, factura, savetaxInfo])

  const getPriceFormat = (price, decimals) => {
    return price.toLocaleString('es', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
  }

  return (
    <>
      <div className="actionForm">
        {isEdit ? (
          <>
            {(factura.status === 'G' || factura.status === 'R') && (
              <Link to={`/documentosEmitidos/${factura.vtaMovmPK.numDocto}`} className="saveForm renviarAction">
                <div className="buttonForm">
                  <i className="bi bi-file-earmark-text"></i>
                  Visualizar
                </div>
              </Link>
            )}
            <button className="saveForm anularAction" onClick={handleDeleteRecipe}>
              <div className="buttonForm">
                <i className="bi bi-trash"></i>
                Anular
              </div>
            </button>
          </>
        ) : (
          <>
          { loading ? <CircularProgress /> : 
            <button className="generarForm" onClick={handleSubmit}>
              <div className="buttonForm">
                <i className="bi bi-arrow-repeat"></i>
                Generar
              </div>
            </button>
          }
         
           
          </>
        )}
      </div>
      <label htmlFor="BuscarCodigo" className="text">
        Resumen de la Factura
        <>
          {factura.vtaDetmList.length > 0 ? (
            <ul className="cabySelecionadoList">
              {factura.totalSrvGravados > 0 ? (
                <li>
                  <b>Total Serv. Gravados</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalSrvGravados, 2)}
                  </span>
                </li>
              ) : null}
              {factura.totalGravados > 0 ? (
                <li>
                  <b>Total Gravados</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalGravados, 2)}
                  </span>
                </li>
              ) : null}
              {factura.totalSrvExento > 0 ? (
                <li>
                  <b>Total Serv. Exentos</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalSrvExento, 2)}
                  </span>
                </li>
              ) : null}
              {factura.totalSrvExonerado > 0 ? (
                <li>
                  <b>Total Serv. Exonerado</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalSrvExonerado, 2)}
                  </span>
                </li>
              ) : null}
              {factura.totalMercGravados > 0 ? (
                <li>
                  <b>Total Merc. Gravadas</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalMercGravados)}
                  </span>
                </li>
              ) : null}
              {factura.totalMercExento > 0 ? (
                <li>
                  <b>Total Merc. Exentas</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalMercExento, 2)}
                  </span>
                </li>
              ) : null}
              {factura.totalMercExonerado > 0 ? (
                <li>
                  <b>Total Merc. Exonerada</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalMercExonerado, 2)}
                  </span>
                </li>
              ) : null}
              {factura.totalExonerado > 0 ? (
                <li>
                  <b>Total Exonerado</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalExonerado, 2)}
                  </span>
                </li>
              ) : null}
              {factura.subtotal > 0 ? (
                <li>
                  <b>Total Venta</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.subtotal + factura?.descuento, 2)}
                  </span>
                </li>
              ) : null}
              <li>
                <b>Total Descuentos</b>
                <span>
                  {factura?.idMoneda.signo} {getPriceFormat(factura?.descuento, 2)}
                </span>
              </li>
              <li>
                <b>Total Venta Neta</b>
                <span>
                  {factura?.idMoneda.signo} {getPriceFormat(factura?.subtotal, 2)}
                </span>
              </li>
              <li>
                <b>Total Impuesto</b>
                <span>
                  {factura?.idMoneda.signo} {getPriceFormat(factura?.iva + factura?.totalOtrosImpuestos, 2)}
                </span>
              </li>
              {factura.totalIvaDevuelto > 0 ? (
                <li>
                  <b>Total IVA Devuelto</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalIvaDevuelto, 2)}
                  </span>
                </li>
              ) : null}
              {factura.totalOtrosCargos > 0 ? (
                <li>
                  <b>Total Otros Cargos</b>
                  <span>
                    {factura?.idMoneda.signo} {getPriceFormat(factura?.totalOtrosCargos, 2)}
                  </span>
                </li>
              ) : null}
              <li>
                <b>Total Comprobante</b>
                <span>
                  {factura?.idMoneda.signo} {getPriceFormat(factura?.total, 2)}
                </span>
              </li>
            </ul>
          ) : (
            <div className="totalesEmpty">
              <h4>No hay productos seleccionados</h4>
              <button onClick={() => setSection('facturaElectronica/DetallesFactura')}>Agregar Detalle</button>
            </div>
          )}
        </>
      </label>
      <label htmlFor="notaFactura" className="text">
        Nota
        <textarea
          value={factura?.nota}
          onChange={handleChange}
          name="nota"
          id="notaFactura"
          cols="30"
          rows="8"
          disabled={isEdit}
        />
      </label>
    </>
  )
}

export default Sidebar
