// Import Modules
import React, { useContext } from 'react'

// Import Componets
import VentasChart from './Charts/VentasChart'
import ComprasChart from './Charts/ComprasChart'
import IVAChart from './Charts/IVAChart'

// Import Context
import { initializeChartsDashboardContext } from '../ChartsDashboard'

// Import StyleSheet
import './styles.css'

const ChartDashboard = () => {
  const { metricType } = useContext(initializeChartsDashboardContext)
  return (
    <div className="ChartDashboard">
      {metricType === 'ventas' && <VentasChart />}
      {metricType === 'compras' && <ComprasChart />}
      {metricType === 'iva' && <IVAChart />}
    </div>
  )
}

export default ChartDashboard
