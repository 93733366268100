import { createSucursal, updateSucursal } from 'actions/administracion'
import Text from 'components/FormField/Text'
import KeyView from 'components/KeyView/KeyView'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { toast } from 'react-toastify'

const STATUS_LABELS = {
  A: 'Activo',
  I: 'Inactivo',
}

const validateForm = (form) => {
  const errors = []

  if (!form.nombreSucursal) errors.push('El nombre de la sucursal es requerido')

  if (!form.direccion) errors.push('La dirección es requerida')

  if (!form.telefono) errors.push('El teléfono es requerido')

  if (!form.establecimiento) errors.push('El establecimiento es requerido')

  return errors
}

const SucursalesForm = ({ open, handleClose, selectedId }) => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { sucursales } = useAdmin()

  const initialForm = useMemo(
    () => ({
      nombreSucursal: '',
      direccion: '',
      telefono: '',
      estado: 'A',
      establecimiento: '',
      sucursalPK: {
        codCia: savetaxInfo?.cia?.codCia,
        idSucursal: '',
      },
    }),
    [savetaxInfo]
  )

  const [form, setForm] = useState(initialForm)

  const handleChange = (e) => setForm((form) => ({ ...form, [e.target.name]: e.target.value }))

  const handleEstablecimientoChange = (e) => {
    if (e.target.value.length > 3) {
      toast.warn('El código de la sucursal no puede ser mayor a 3 caracteres')

      return
    }

    handleChange(e)
  }

  const handleStatusChange = (e) => setForm((form) => ({ ...form, estado: e.value }))

  const handleSubmit = () => {
    const action = selectedId ? updateSucursal : createSucursal
    const validation = validateForm(form)

    if (validation.length) {
      validation.forEach((error) => toast.error(error))

      return
    }

    dispatch(action(form))
    handleClose()
  }

  useEffect(() => {
    if (selectedId) {
      setForm(sucursales.find((sucursal) => sucursal.sucursalPK.idSucursal === selectedId))

      return
    }

    if (!open) setForm(initialForm)
  }, [selectedId, sucursales, initialForm, open])

  return (
    open && (
      <KeyView title="Agregar Sucursal" onClose={handleClose}>
        <Text label="Nombre de la sucursal" name="nombreSucursal" value={form.nombreSucursal} onChange={handleChange} />

        <Text label="Dirección" name="direccion" value={form.direccion} onChange={handleChange} />

        <Text onChange={handleChange} name="telefono" value={form.telefono} label="Telefono" />

        <Text
          onChange={handleEstablecimientoChange}
          name="establecimiento"
          value={form.establecimiento}
          label="Establecimiento"
        />

        <label className="text">
          Estado
          <Select
            isSearchable={false}
            options={[
              { label: 'Activo', value: 'A' },
              { label: 'Inactivo', value: 'I' },
            ]}
            value={{
              value: form.estado,
              label: STATUS_LABELS[form?.estado],
            }}
            onChange={handleStatusChange}
          />
        </label>

        <button onClick={handleSubmit}>{form.codCia?.idSucursal ? 'Actualizar' : 'Guardar'}</button>
      </KeyView>
    )
  )
}

export default SucursalesForm
