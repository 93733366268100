import { getTiposDocExonera } from 'actions/misc'
import axios from 'axios'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import useMisc from 'hooks/useMisc'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Select } from '../EncabezadoYReceptor/Encabezado'
import { fetchTotales } from '../Sidebar/Sidebar'
import useExoneraciones from './hooks/useExoneraciones'
import styles from './styles.module.css'

const IVAS = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 1,
    label: '1%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 4,
    label: '4%',
  },
  {
    value: 5,
    label: '5%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 7,
    label: '7%',
  },
  {
    value: 8,
    label: '8%',
  },
  {
    value: 9,
    label: '9%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 11,
    label: '11%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 13,
    label: '13%',
  },
]

const Item = ({ title, id, InputComponent }) => (
  <label htmlFor={id} className={`${styles.exoInfoItem} text`}>
    <strong>{title}</strong>
    {InputComponent ? InputComponent : <span>{'-'}</span>}
  </label>
)

const Exoneraciones = ({ factura, setFactura }) => {
  const dispatch = useDispatch()
  const { tiposDocExonera } = useMisc()
  const { isEdit, docExonerado } = factura
  const { numeroDocumento, fechaEmision, tipoDocumento, nombreInstitucion, porcentajeExoneracion } = docExonerado ?? {}
  const { calcularExoneraciones, limpiarExoneraciones } = useExoneraciones()

  const [docCode, setDocCode] = useState(numeroDocumento ?? '')
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (factura?.docExonerado?.numeroDocumento) {
      toast.info('Ya tienes un documento exonerado. Limpialo para agregar otro nuevo')
      setDocCode(factura?.docExonerado?.numeroDocumento)
      return
    }

    setIsLoading(true)

    try {
      const { vtaDetmList: products } = factura
      if (!products?.length) {
        toast.error('Agregue lineas de detalle para exonerar')
        setIsLoading(false)
        setFactura((prev) => ({ ...prev, docExonerado: {} }))
        return
      }

      const { data } = await axios(`https://api.hacienda.go.cr/fe/ex?autorizacion=${docCode}`)
      const { fechaVencimiento } = data

      const now = DateTime.now()
      const expireDate = DateTime.fromISO(fechaVencimiento)

      if (expireDate < now) {
        toast.error('El documento esta vencido')
        setIsLoading(false)
        setFactura((prev) => ({ ...prev, docExonerado: {} }))
        return
      }

      await fetchTotales(calcularExoneraciones(factura, data), setFactura)
      toast.success('Documento encontrado')
    } catch (error) {
      console.error(error)
      setFactura((prev) => ({ ...prev, docExonerado: {} }))
      toast.error('Documento no encontrado')
    }

    setIsLoading(false)
  }

  const handleChange = (e) => {
    const { value, name } = e.target

    setFactura((prev) => ({
      ...prev,
      docExonerado: {
        ...prev.docExonerado,
        [name]: value,
      },
    }))
  }

  const handleChangeTipoDoc = (e) => {
    const { value } = e
    const document = tiposDocExonera.find((doc) => doc.value === value)

    setFactura((prev) => ({
      ...prev,
      docExonerado: {
        ...prev.docExonerado,
        tipoDocumento: document,
      },
    }))
  }

  useEffect(() => {
    dispatch(getTiposDocExonera)
  }, [dispatch])

  return (
    <section className={styles.container}>
      <header className={styles.header}>
        <h3 className={styles.title}>Exonerar IVA</h3>
        <button className={styles.clean} onClick={() => limpiarExoneraciones(factura, setFactura)}>
          Limpiar exoneraciones
        </button>
      </header>

      <div className="dropDownItem">
        <form onSubmit={handleSubmit} className="actionsSection" style={{ width: '100%' }}>
          <label htmlFor="BuscarDescripción" className="text" style={{ width: '50%' }}>
            Documento de Exoneración
            <div className="searchForm">
              <input
                value={docCode}
                onChange={(e) => setDocCode(e.target.value)}
                disabled={isEdit}
                type="text"
                autoComplete="off"
                placeholder="Documento de Exoneración"
                id="BuscarDescripción"
              />
              <button type="submit" disabled={isLoading} className="searchPersonaForID" style={{ cursor: 'pointer' }}>
                <i className="bi bi-search"></i>
              </button>
            </div>
          </label>
          <div style={{ width: '10%' }}>{isLoading && <CircularProgress />}</div>
        </form>

        <div className={styles.exoInfo}>
          <Item
            title="Fecha de exoneración"
            id="fechaEmision"
            InputComponent={
              <input
                name="fechaEmision"
                id="fechaEmision"
                type="date"
                value={fechaEmision ? DateTime.fromISO(fechaEmision).toFormat('yyyy-MM-dd') : undefined}
                onChange={handleChange}
              />
            }
          />
          <Item
            title="Motivo de exoneración"
            InputComponent={
              <Select value={tipoDocumento?.codigo ?? ''} onChange={handleChangeTipoDoc} name="porcentajeExoneracion">
                {tiposDocExonera.map((doc) => (
                  <option value={doc.codigo} key={doc.codigo}>
                    {doc.nombre}
                  </option>
                ))}
              </Select>
            }
          />
          <Item
            title="Institución de exoneración"
            InputComponent={<input value={nombreInstitucion} name="nombreInstitucion" onChange={handleChange} />}
          />
          <Item
            title="Porcentaje de exoneración"
            value={porcentajeExoneracion ? `${porcentajeExoneracion}%` : undefined}
            InputComponent={
              <Select showEmpty={false} value={porcentajeExoneracion ?? 0} onChange={handleChange} name="porcentajeExoneracion">
                {IVAS.map((iva) => (
                  <option value={iva.value} key={iva.value}>
                    {iva.label}
                  </option>
                ))}
              </Select>
            }
          />
        </div>
      </div>
    </section>
  )
}

export default Exoneraciones
