/* eslint-disable no-unused-vars */
// Import Modules
import React, { useState } from 'react'

const KeyView = ({ title, onClose, children, close = true, className = ""}) => {
  const [closing, setClosing] = useState(close)

  return (
    <div className={`keyView ${className}`}>
      <h3>
        <span className="title">{title}</span>
        <button
          className="closeButton"
          onClick={() => {
            setClosing(true)
            setTimeout(() => {
              onClose()
            }, 500)
          }}
        >
          <i className="bi bi-x-lg"></i>
        </button>
      </h3>
      {children}
    </div>
  )
}

export default KeyView
