/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import React, { useState, useEffect, useContext, Fragment } from 'react'

// Import Context
import { initializeChartsDashboardContext } from '../../ChartsDashboard'

// Import Hooks
import useDocumentos from 'hooks/useDocumentos'
import { getDocumentosEmitidos } from 'actions/documentos'
import { useDispatch } from 'react-redux'
import useUser from 'hooks/useUser'

// Import Components
import { Line, Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, PointElement, LineElement, LinearScale, BarElement, Title, Tooltip, Legend)

ChartJS.defaults.color = localStorage.getItem('schemaColor') === 'schema_predeterminado' ? '#666' : '#fff'

const IVAChart = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { documentosRecibidos, isLoadingDocumentosRecibidos, documentosEmitidos, isLoadingDocumentosEmitidos } = useDocumentos()

  const { periodType, month, months, year } = useContext(initializeChartsDashboardContext)

  const [options, setOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          fontColor: localStorage.getItem('schemaColor') === 'schema_predeterminado' ? '#666' : '#fff',
          fontSize: 12,
        },
      },
      title: {
        display: true,
        text: 'IVA Soportado vs IVA Devengado',
      },
    },
  })
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'IVA Soportado',
        data: null,
        backgroundColor: 'rgb(61, 127, 242)',
        borderColor: 'rgb(61, 127, 242, 0.5)',
      },
      {
        label: 'IVA Devengado',
        data: null,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132, 0.5)',
      },
    ],
  })

  const filterDataForAnnuity = (year) => {
    let matchDocumentosRecibidosForYear = documentosRecibidos.filter(
      (m) => new Date(m.fechaEmision).getFullYear() === Number(year)
    )
    let matchDocumentosEmitidosForYear = documentosEmitidos.filter((m) => new Date(m.fechaEmision).getFullYear() === Number(year))
    let data1 = months.reduce((previousValue, currentvalue, position) => {
      let matches = matchDocumentosRecibidosForYear.filter((d) => {
        let date = new Date(d.fechaEmision)
        return date.getMonth() === position
      })
      let monto = matches.reduce((accumulated, currentMatch) => {
        return accumulated + currentMatch.montoImpuesto * currentMatch.tasaCambio
      }, 0)
      return [...previousValue, monto]
    }, [])
    let data2 = months.reduce((previousValue, currentvalue, position) => {
      let matches = matchDocumentosEmitidosForYear.filter((d) => {
        let date = new Date(d.fechaEmision)
        return date.getMonth() === position
      })
      let monto = matches.reduce((accumulated, currentMatch) => {
        return accumulated + currentMatch.iva * currentMatch.tasaCambio
      }, 0)
      return [...previousValue, monto]
    }, [])
    return [[...data1], [...data2]]
  }

  const filterDataForMonthly = (month, year) => {
    let days = getDaysForMonth(month)
    let matchDocumentosRecibidosForYearAndMonth = documentosRecibidos.filter((m) => {
      let date = new Date(m.fechaEmision)
      return date.getFullYear() === Number(year) && months[date.getMonth()] === month
    })
    let matchDocumentosEmitidosForYearAndMonth = documentosEmitidos.filter((m) => {
      let date = new Date(m.fechaEmision)
      return date.getFullYear() === Number(year) && months[date.getMonth()] === month
    })
    let data1 = days.reduce((previousValue, currentvalue) => {
      let matches = matchDocumentosRecibidosForYearAndMonth.filter((d) => {
        let date = new Date(d.fechaEmision)
        return date.getDate() === currentvalue
      })
      let monto = matches.reduce(
        (accumulated, currentMatch) => accumulated + currentMatch.montoImpuesto * currentMatch.tasaCambio,
        0
      )
      return [...previousValue, monto]
    }, [])

    let data2 = days.reduce((previousValue, currentvalue) => {
      let matches = matchDocumentosEmitidosForYearAndMonth.filter((d) => {
        let date = new Date(d.fechaEmision)
        return date.getDate() === currentvalue
      })
      let monto = matches.reduce((accumulated, currentMatch) => accumulated + currentMatch.iva * currentMatch.tasaCambio, 0)
      return [...previousValue, monto]
    }, [])
    return [[...data1], [...data2]]
  }

  const getDaysForMonth = (month) => {
    if (month === 'febrero') return Array.from(Array(28).keys()).map((d) => d + 1)
    if (month === 'abril' || month === 'junio' || month === 'septiembre') return Array.from(Array(30).keys()).map((d) => d + 1)
    return Array.from(Array(31).keys()).map((d) => d + 1)
  }

  const setLabelAndData = (label, data) => {
    let processedData = {
      labels: [...label],
      datasets: [
        {
          label: 'IVA Soportado',
          data: data[0],
          backgroundColor: 'rgb(61, 127, 242)',
          borderColor: 'rgb(61, 127, 242, 0.5)',
        },
        {
          label: 'IVA Devengado',
          data: data[1],
          backgroundColor: 'rgb(255, 99, 132)',
          borderColor: 'rgb(255, 99, 132, 0.5)',
        },
      ],
    }
    return processedData
  }

  useEffect(() => {
    if (!isLoadingDocumentosRecibidos && !isLoadingDocumentosEmitidos) {
      if (periodType === 'mensual') {
        let data = filterDataForMonthly(month, year)
        setData(setLabelAndData(getDaysForMonth(month), data))
      } else if (periodType === 'anual') {
        let data = filterDataForAnnuity(year)
        setData(setLabelAndData(months, data))
      }
    }
  }, [
    year,
    month,
    periodType,
    isLoadingDocumentosRecibidos,
    documentosRecibidos,
    isLoadingDocumentosEmitidos,
    documentosEmitidos,
  ])

  useEffect(() => {
    if (!documentosEmitidos.length) {
      dispatch(getDocumentosEmitidos(savetaxInfo.cia.codCia))
    }
  }, [dispatch, documentosEmitidos, savetaxInfo])

  return (
    <Fragment>
      {data.datasets[0].data === null && data.datasets[1].data === null && (
        <div className="loadContentChart">
          <div className="snippet"></div>
          <span>Cargando Datos de IVA</span>
        </div>
      )}
      {data.datasets[0].data !== null && data.datasets[1].data !== null && periodType === 'mensual' && (
        <Bar data={data} options={options} />
      )}
      {data.datasets[0].data !== null && data.datasets[1].data !== null && periodType === 'anual' && (
        <Line data={data} options={options} />
      )}
    </Fragment>
  )
}

export default IVAChart
