import {
  GET_MONEDAS,
  GET_METODOS_DE_PAGO,
  GET_CONDICIONES_DE_VENTA,
  GET_UNIDADES_DE_MEDIDA,
  GET_OTROS_CARGOS,
  GET_TIPOS_IVA,
  GET_TIPOS_DOC_EXONERA,
  GET_TIPOS_IMPUESTOS,
  GET_TIPOS_DOCS,
  GET_TARIFAS,
  GET_CATALOGO_ACTIVIDADES,
  GET_DATOS_GEOGRAFICOS,
  GET_CODIGOS_REFERENCIA,
} from '../constants/actionTypes'
import axios from 'axios'
import localforage from 'localforage'
import { getInstance, getURL } from 'api/apiInstance'

export const getMonedas = async (dispatch) => {
  const request = await getInstance()
  const response = await request.get(`moneda`)
  dispatch({
    type: GET_MONEDAS,
    payload: {
      monedas: response?.data,
    },
  })
}

export const getMetodosDePago = (cia) => async (dispatch) => {
  const request = await getInstance()
  const response = await request.get(`formapago/find-by-cod-cia/${cia}`)
  dispatch({
    type: GET_METODOS_DE_PAGO,
    payload: {
      metodosDePago: response?.data,
    },
  })
}

export const getcondicionesDeVenta = (cia) => async (dispatch) => {
  const request = await getInstance()
  const response = await request.get(`condicionventa/find-by-cod-cia/${cia}`)
  dispatch({
    type: GET_CONDICIONES_DE_VENTA,
    payload: {
      condicionesDeVenta: response?.data,
    },
  })
}

export const getUnidadesDeMedida = (cia) => async (dispatch) => {
  const response = await axios.get(`https://test.facturacionsavetaxcr.com/savetax/api/v1/unidadmedida/find-by-cod-cia/${cia}`)
  dispatch({
    type: GET_UNIDADES_DE_MEDIDA,
    payload: {
      unidadesDeMedida: response?.data,
    },
  })
}

export const getOtrosCargos = async (dispatch) => {
  const request = await getInstance('fe')
  const response = await request.get(`tipodoctootroscargos`)
  dispatch({
    type: GET_OTROS_CARGOS,
    payload: {
      otrosCargos: response?.data,
    },
  })
}

export const getTiposDeIva = async (dispatch) => {
  const request = await getInstance()
  const response = await request.get(`tipoimpuesto/find-by-iva`)
  dispatch({
    type: GET_TIPOS_IVA,
    payload: {
      tiposDeIva: response?.data,
    },
  })
}

export const getTiposDocExonera = async (dispatch) => {
  const request = await getInstance('fe')
  const response = await request.get(`tipodoctoexonera`)
  dispatch({
    type: GET_TIPOS_DOC_EXONERA,
    payload: {
      tiposDocExonera: response?.data,
    },
  })
}

export const getTiposImpuestos = async (dispatch) => {
  const request = await getInstance()
  const response = await request.get(`tipoimpuesto/find-otros-impuestos`)
  dispatch({
    type: GET_TIPOS_IMPUESTOS,
    payload: {
      tiposImpuestos: response?.data,
    },
  })
}

export const getTiposDocs = (cia) => async (dispatch) => {
  const request = await getInstance()

  const response = await request.get(`tipodoctopersona/find-by-cod-cia/${cia}`)

  dispatch({
    type: GET_TIPOS_DOCS,
    payload: {
      tiposDocs: response?.data,
    },
  })
}

export const getCodigosReferencia = () => {
  return async (dispatch) => {
    const request = await getInstance('fe')
    const response = await request.get(`codigoreferencia`)
    dispatch({
      type: GET_CODIGOS_REFERENCIA,
      payload: {
        codigosReferencia: response?.data,
      },
    })
  }

}

export const getTarifas = async (dispatch) => {
  const request = await getInstance()


  /*const [valorAgregado, calculoEspecial, bienesUsados] = await request.all(
    [`${getURL()}impuesto/find-by-tipo/1`, `${getURL()}impuesto/find-by-tipo/7`, `${getURL()}impuesto/find-by-tipo/8`].map(
      (url) => axios.get(url)
    )
  )*/

  const valorAgregado = await request.get(`impuesto/find-by-tipo/1`)
  const calculoEspecial = await request.get(`impuesto/find-by-tipo/7`)
  const bienesUsados = await request.get(`impuesto/find-by-tipo/8`)

  dispatch({
    type: GET_TARIFAS,
    payload: {
      tarifas: {
        1: valorAgregado.data,
        7: calculoEspecial.data,
        8: bienesUsados.data,
      },
    },
  })
}

export const getCatalogoActividades = (ruc) => async (dispatch) => {
  const response = await axios.get(
    `https://test.facturacionsavetaxcr.com/savetax/api/v1/actividadeconomica/find-by-identidad/${ruc}`
  )
  dispatch({
    type: GET_CATALOGO_ACTIVIDADES,
    payload: {
      actividades: response.data,
    },
  })
}

export const getDatosGeograficos = async (dispatch) => {
  let datosGeograficos = await localforage.getItem('datosGeograficos')
  const { paises, provincias, cantones, distritos, barrios } = datosGeograficos ?? {}
  const request = await getInstance()

  if (!(paises || provincias || cantones || distritos || barrios)) {
    const paises = await request.get('pais')

    const provincias = await request.get('departamento/find-by-pais/1')

    const cantonesResponse = await axios.all(
      provincias.data.map((p) => `${getURL()}municipio/find-by-depto/${p.idDepartamento}`).map((url) => request.get(url))
    )

    const cantones = cantonesResponse.map((r) => r.data).flat()

    const distritosResponse = await axios.all(
      cantones.map((c) => `${getURL()}distrito/find-by-municipio/${c.idMunicipio}`).map((url) => request.get(url))
    )

    const distritos = distritosResponse.map((r) => r.data).flat()

    const barriosResponse = await axios.all(
      distritos.map((d) => `${getURL()}barrio/find-by-distrito/${d.idDistrito}`).map((url) => request.get(url))
    )

    const barrios = barriosResponse.map((r) => r.data).flat()

    datosGeograficos = {
      paises: paises.data,
      provincias: provincias.data,
      cantones,
      distritos,
      barrios,
    }
    localforage.setItem('datosGeograficos', datosGeograficos)
  }

  dispatch({
    type: GET_DATOS_GEOGRAFICOS,
    payload: datosGeograficos,
  })
}
