import { ReactComponent as Cancel } from 'assets/cancel.svg'
import { ReactComponent as Cancelar } from 'assets/cancelar.svg'
import { ReactComponent as Checked } from 'assets/checked.svg'
import { ReactComponent as Chevron } from 'assets/down-chevron.svg'
import { ReactComponent as Dowload } from 'assets/download.svg'
import { ReactComponent as Key } from 'assets/key.svg'
import axios from 'axios'
import useUser from 'hooks/useUser'
import { getURL } from 'api/apiInstance'
import { Fragment, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

//pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString()


const base64Factory = async (doc, savetaxInfo) => {
  if (!doc) return ''

  let link = ''

  if (doc?.docRecibidosPK?.id) {
    const cia = savetaxInfo?.cia?.codCia
    const id = doc?.docRecibidosPK?.id
    link = `${getURL("documentos")}doc-recibido/pdf-base64/${cia}/${id}`
  } else {
    const cia = savetaxInfo?.cia?.codCia
    const tipoDoc = doc?.tipoDoctoVta?.idTipoDoctoVta
    const sucursal = doc?.sucursal?.sucursalPK?.idSucursal
    const numDoc = doc?.vtaMovmPK?.numDocto

    link = `${getURL("fe")}vta-movm/pdf-base64/${cia}/${tipoDoc}/${sucursal}/${numDoc}`
  }

  const res = await axios(link)

  return 'data:application/octet-stream;base64,' + res.data
}

export const base64XMLFactory = async (doc, savetaxInfo) => {
  if (!doc) return ''
  let link = ''

  if (doc?.docRecibidosPK?.id) {
    const cia = savetaxInfo?.cia?.codCia
    const id = doc?.docRecibidosPK?.id
    link = `${getURL("documentos")}doc-recibido/xml-base64/${cia}/${id}`
  } else {
    const cia = savetaxInfo?.cia?.codCia
    const tipoDoc = doc?.tipoDoctoVta?.idTipoDoctoVta
    const sucursal = doc?.sucursal?.sucursalPK?.idSucursal
    const numDoc = doc?.vtaMovmPK?.numDocto

    link = `${getURL("fe")}vta-movm/xml-base64/${cia}/${tipoDoc}/${sucursal}/${numDoc}`
  }

  const res = await axios(link)

  return 'data:application/octet-stream;base64,' + res.data
}

const documentFactory = (doc, savetaxInfo) => {
  if (!doc) return ''

  if (doc?.docRecibidosPK?.id) {
    const cia = savetaxInfo?.cia?.codCia
    const id = doc?.docRecibidosPK?.id

    return `${getURL("documentos")}doc-recibido/pdf/${cia}/${id}`
  }

  const cia = savetaxInfo?.cia?.codCia
  const tipoDoc = doc?.tipoDoctoVta?.idTipoDoctoVta
  const sucursal = doc?.sucursal?.sucursalPK?.idSucursal
  const numDoc = doc?.vtaMovmPK?.numDocto

  return `${getURL("fe")}vta-movm/pdf/${cia}/${tipoDoc}/${sucursal}/${numDoc}`
}

const DocumentReader = ({ documents, setSelectedId, selectedId, type, open, onClose, handleAcceptDoc, handleRejectDoc }) => {
  const { savetaxInfo } = useUser()
  const [numPages, setNumPages] = useState(0)
  const [loadingDoc, setLoadingDoc] = useState(true)
  const [base64, setBase64] = useState({
    pdf: '',
    xml: '',
  })

  // Derive state from props
  const documentIndex = selectedId
  const document = documents[documentIndex]
  const documentUrl = documentFactory(document, savetaxInfo)
  const isFirst = documentIndex === 0
  const isLast = documentIndex === documents.length - 1
  const title = document?.clave ?? document?.numeroAutorizacion
  const pagesNum = Array.from(new Array(numPages), (el, index) => index + 1)

  const onNextPdf = () => {
    setLoadingDoc(true)
    setSelectedId(documentIndex + 1)
  }

  const onPrevPdf = () => {
    setLoadingDoc(true)
    setSelectedId(documentIndex - 1)
  }

  const onCloseReader = () => {
    onClose()
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    const fetch = async () => {
      if (!document) return

      const pdf = await base64Factory(document, savetaxInfo)
      const xml = await base64XMLFactory(document, savetaxInfo)

      setBase64({ pdf, xml })
    }

    fetch()

    setNumPages(numPages)
    setLoadingDoc(false)
  }

  return (
    <div className={`readerPDF ${open ? '' : 'toggled'}`}>
      <div className="navReaderPDF">
        <h3 className="titleReader">{title}</h3>
        <div className="setActions">
          <a download={`${title}.pdf`} href={base64.pdf} className="downloadReader" title="Descargar PDF">
            <Dowload />
          </a>

          <a download={`${title}.xml`} href={base64.xml} className="keyReader" title="Descargar XML">
            <Key />
          </a>

          {type === 'recibidos' && (
            <>
              <button className="checkedReader" title="Aceptar Documento" onClick={(event) => handleAcceptDoc(document, event)}>
                <Checked />
              </button>
              <button className="cancelDoc" title="Rechazar Documento" onClick={(event) => handleRejectDoc(document, event)}>
                <Cancelar />
              </button>
            </>
          )}
        </div>
        <button className="closeReader" onClick={onCloseReader}>
          <Cancel />
        </button>
      </div>

      <div className="viewContainer">
        <div className="pagesSidebar">
          {pagesNum?.map((page, index) => (
            <Fragment key={`page-number-${index}`}>
              <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={page} />
              </Document>

              <p>Pagina {page}</p>
            </Fragment>
          ))}
        </div>

        {loadingDoc && (
          <div className="loadPDF">
            <div className="snippet"></div>
            <span className="porcent">Cargando...</span>
          </div>
        )}

        <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={1}  />
        </Document>

        {!isFirst && (
          <div className="pagination before" onClick={onPrevPdf}>
            <Chevron />
          </div>
        )}

        {!isLast && (
          <div className="pagination next" onClick={onNextPdf}>
            <Chevron />
          </div>
        )}
      </div>
    </div>
  )
}

export default DocumentReader
