// Import Modules
import React from 'react'

const ButtonFilterType = ({ filter, label, setSectionBuscador, sectionBuscador }) => {
  return (
    <button
      onClick={() => setSectionBuscador(filter)}
      className={sectionBuscador === filter ? 'capsulasButton active' : 'capsulasButton'}
    >
      {label}
    </button>
  )
}

export default ButtonFilterType
