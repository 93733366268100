import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import './SideBar.css'
import { ReactComponent as Chevron } from '../../assets/down-chevron.svg'
import logo from '../../assets/logo_white.png'
import logoBlack from '../../assets/logo_black.png'
import { useDispatch } from 'react-redux'

// Import Context
import Global from 'contexts/Global/Global'
import useUser from 'hooks/useUser'
import { getMenuAsignado } from 'actions/users'

const SideBar = () => {
  const globalContext = useContext(Global)
  const [ancho, setAncho] = useState(window.innerWidth)
  const { savetaxInfo, menu } = useUser()
  const [menuFiltrado, setMenuFiltrado] = useState([])
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)
  //const isAdmin = savetaxInfo?.menuAsignado
  //const isAdmin = savetaxInfo?.rolList.find((x) => x?.nombreRol === 'ADMINISTRADOR')

  const toggleSidebar = (event) => {
    let Sidebar = document.querySelector('.Sidebar')
    if (Sidebar.className.includes('toggled')) {
      Sidebar.className = 'Sidebar'
    } else {
      Sidebar.className = 'Sidebar toggled'
    }
  }

  const toggledSubMenu = (event) => {
    let target = event.target
    let itemMenuSidebarWithList = target.parentElement
    let itemMenuSidebarWithLists = document.querySelectorAll('.itemMenuSidebarWithList')

    if (itemMenuSidebarWithList.className.includes('submenuToggled')) {
      for (let item of itemMenuSidebarWithLists) {
        item.className = 'itemMenuSidebarWithList'
      }
      itemMenuSidebarWithList.className = 'itemMenuSidebarWithList'
    } else {
      for (let item of itemMenuSidebarWithLists) {
        item.className = 'itemMenuSidebarWithList'
      }
      itemMenuSidebarWithList.className = 'itemMenuSidebarWithList submenuToggled'
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setAncho(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    // Limpia el evento de cambio de tamaño al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    //console.log('Menu '+menuAsignado)
  }, []) // Se ejecuta solo al montar y desmontar el componente

  useEffect(() => {
    if (ancho > 1024) {
      let sidebar = document.querySelector('.Sidebar')
      if (sidebar) {
        sidebar.className = 'Sidebar'
      }
    } else {
      let sidebar = document.querySelector('.Sidebar')
      if (sidebar) {
        sidebar.className = 'Sidebar toggled'
      }
    }
    if (!loaded) {
      dispatch(getMenuAsignado(savetaxInfo))
      setLoaded(true)
    }
    /*s*/
  }, [ancho, dispatch, savetaxInfo, loaded, menu])

  return (
    <div className="Sidebar">
      <div className="logo">
        <Link to="/" tabIndex="-1">
          <img
            src={
              globalContext.conf.schemaColor === 'schema_predeterminado' || globalContext.conf.schemaColor === null
                ? logoBlack
                : logo
            }
            alt=""
          />
        </Link>
      </div>
      <ul className="menuSideBar">
        <li className="itemMenuSidebar">
          <NavLink to="/escritorio" activeclassname="linkLevelOneActive">
            Escritorio
          </NavLink>
        </li>

        <li className="itemMenuSidebarWithList">
          <button onClick={toggledSubMenu}>
            Administración
            <Chevron />
          </button>
          <ul>
            <li>
              <NavLink to="/companias" activeclassname="linkSubMenuActive">
                Mantenimiento de Compañias
              </NavLink>
            </li>
            <li>
              <NavLink to="/historialPagos" activeclassname="linkSubMenuActive">
                Pagos
              </NavLink>
            </li>
            <li>
              <NavLink to="/actividadesEconomicas" activeclassname="linkSubMenuActive">
                Actividades Económicas
              </NavLink>
            </li>
            <li>
              <NavLink to="/usuarios" activeclassname="linkSubMenuActive">
                Usuarios
              </NavLink>
            </li>
            <li>
              <NavLink to="/consecutivos" activeclassname="linkSubMenuActive">
                Consecutivos
              </NavLink>
            </li>
            <li>
              <NavLink to="/sucursales" activeclassname="linkSubMenuActive">
                Sucursales
              </NavLink>
            </li>
            <li>
              <NavLink to="/cajas" activeclassname="linkSubMenuActive">
                Cajas
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="itemMenuSidebarWithList">
          <button onClick={toggledSubMenu}>
            Facturación Electrónica
            <Chevron />
          </button>
          <ul>
            <li>
              <NavLink to="/facturasElectronicas" activeclassname="linkSubMenuActive">
                Factura Electrónica
              </NavLink>
            </li>
            <li>
              <NavLink to="/tiquetasElectronicas" activeclassname="linkSubMenuActive">
                Tiquete Electrónico
              </NavLink>
            </li>
            <li>
              <NavLink to="/facturasElectronicasPorExportacion" activeclassname="linkSubMenuActive">
                Factura Electrónica de Exportación
              </NavLink>
            </li>
            <li>
              <NavLink to="/facturasElectronicasDeCompra" activeclassname="linkSubMenuActive">
                Factura Electrónica de Compra
              </NavLink>
            </li>
            <li>
              <NavLink to="/proformasElectronicas" activeclassname="linkSubMenuActive">
                Proforma Electrónica
              </NavLink>
            </li>
            <li>
              <NavLink to="/notasCredito" activeclassname="linkSubMenuActive">
                Nota Crédito
              </NavLink>
            </li>
            <li>
              <NavLink to="/notasDebito" activeclassname="linkSubMenuActive">
                Nota Débito
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="itemMenuSidebarWithList">
          <button onClick={toggledSubMenu}>
            Documentos
            <Chevron />
          </button>
          <ul>
            <li>
              <NavLink to="/documentosEmitidos" activeclassname="linkSubMenuActive">
                Documentos Emitidos
              </NavLink>
            </li>
            <li>
              <NavLink to="/documentosRecibidos" activeclassname="linkSubMenuActive">
                Documentos Recibidos
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="itemMenuSidebar">
          <NavLink to="/personas" activeclassname="linkLevelOneActive">
            Clientes
          </NavLink>
        </li>
        <li className="itemMenuSidebarWithList">
          <button onClick={toggledSubMenu}>
            Productos
            <Chevron />
          </button>
          <ul>
            <li>
              <NavLink to="/productos" activeclassname="linkSubMenuActive">
                Productos
              </NavLink>
            </li>
          </ul>
        </li>

        {/*menu?.map((opcion) => (
          <li className="itemMenuSidebarWithList">
            <button onClick={toggledSubMenu}>
              {opcion.nombreOpcion}
              <Chevron />
            </button>
            <ul>
              {opcion.opcionesHijas.map((hija) => (
                <li>
                  <NavLink to={hija.link} activeclassname="linkSubMenuActive">
                    {hija.nombreOpcion}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>
        ))*/}

        {/* <li className='itemMenuSidebarWithList'>
                    <button onClick={toggledSubMenu}>
                        Inventarios
                        <Chevron />
                    </button>
                    <ul>
                        <li>
                            <NavLink to='/bodegas' activeClassName='linkSubMenuActive' >
                                Bodegas
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/compras' activeClassName='linkSubMenuActive' >
                               Compras
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/egresos' activeClassName='linkSubMenuActive' >
                                Egresos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/ingresos' activeClassName='linkSubMenuActive' >
                                Ingresos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/ajustesPositivos' activeClassName='linkSubMenuActive' >
                                Ajustes Positivos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/ajustesNegativos' activeClassName='linkSubMenuActive' >
                                Ajustes Negativos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/existencias' activeClassName='linkSubMenuActive' >
                                Existencias
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/movimientos' activeClassName='linkSubMenuActive' >
                                Movimientos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/kardex' activeClassName='linkSubMenuActive' >
                                Kardex
                            </NavLink>
                        </li>
                    </ul>
                </li>

                <li className='itemMenuSidebarWithList'>
                    <button onClick={toggledSubMenu}>
                        IVA
                        <Chevron />
                    </button>
                    <ul>
                        <li>
                            <NavLink to='/prorrataTemporal' activeClassName='linkSubMenuActive' >
                                Prorrata Temporal
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/declaracionDelIVA' activeClassName='linkSubMenuActive' >
                                Declaración del IVA
                            </NavLink>
                        </li>
                    </ul>
                </li>

                <li className='itemMenuSidebarWithList'>
                    <button onClick={toggledSubMenu}>
                        Renta
                        <Chevron />
                    </button>
                    <ul>
                        <li>
                            <NavLink to='/propiedad' activeClassName='linkSubMenuActive' >
                               Propiedad, Planta y Equipo
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/salarios' activeClassName='linkSubMenuActive' >
                                Salarios y pagos de seguridad social
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/creditosBancarios' activeClassName='linkSubMenuActive' >
                                Créditos Bancarios
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/retencionDePago' activeClassName='linkSubMenuActive' >
                                Retenciones en Pagos
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/creditosFamiliares' activeClassName='linkSubMenuActive' >
                                Créditos Familiares
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/perdidasFiscales' activeClassName='linkSubMenuActive' >
                                Pérdidas Fiscales
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/saldoAFavor' activeClassName='linkSubMenuActive' >
                                Saldos a Favor
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/pagoParcial' activeClassName='linkSubMenuActive' >
                                Pagos Parciales
                            </NavLink>
                        </li>
                    </ul>ca
                </li> */}

        <li className="itemMenuSidebarWithList" hidden="true">
          <button onClick={toggledSubMenu}>
            Configuraciones
            <Chevron />
          </button>
          <ul>
            <li>
              <NavLink to="/configuraciones" className={(navData) => (navData.isActive ? 'linkSubMenuActive' : '')}>
                General
              </NavLink>
            </li>
            <li>
              <NavLink to="/historialPagos" className={(navData) => (navData.isActive ? 'linkSubMenuActive' : '')}>
                Historial de Pagos
              </NavLink>
            </li>
            <li>
              <NavLink to="/metodosPago" className={(navData) => (navData.isActive ? 'linkSubMenuActive' : '')}>
                Metodos de Pagos
              </NavLink>
            </li>
          </ul>
        </li>

        <li className="itemMenuSidebarWithList" hidden="true">
          <button onClick={toggledSubMenu}>
            Ayuda
            <Chevron />
          </button>
          <ul>
            <li>
              <NavLink to="/ayuda/productos" activeclassname="linkSubMenuActive">
                Documentación Productos
              </NavLink>
            </li>
            <li>
              <NavLink to="/ayuda/personas" activeclassname="linkSubMenuActive">
                Documentación Personas
              </NavLink>
            </li>
            <li>
              <NavLink to="/ayuda/accesosDirectosCombinacionesTeclado" activeclassname="linkSubMenuActive">
                Accesos Directos y Combinaciones de Teclado
              </NavLink>
            </li>
            <li>
              <NavLink to="/ayuda/personalizarEscritorio" activeclassname="linkSubMenuActive">
                Personalizar Escritorio
              </NavLink>
            </li>
            <li>
              <NavLink to="/ayuda/personalizarNavegacion" activeclassname="linkSubMenuActive">
                Personalizar Navegacion
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
      <button className="toggleSidebar" onClick={toggleSidebar}>
        <div className="icon">
          <Chevron />
        </div>
        <span>Ocultar Barra Lateral</span>
      </button>
    </div>
  )
}

export default SideBar
