import { getDatosGeograficos } from 'actions/misc'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { motion } from 'framer-motion'
import useMisc from 'hooks/useMisc'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useGetPersona } from './formHelper'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import { createActividadEconomica, updateActividadEconomica } from 'actions/administracion'
import { getCatalogoActividades } from 'actions/misc'
import useUser from 'hooks/useUser'
import Text from 'components/FormField/Text'
import Select from 'react-select'

const InfoCliente = ({ form, stepUp, stepDown, handleChange, variants }) => {
  const dispatch = useDispatch()
  const { persona } = form
  const { paises, provincias, cantones, distritos, barrios, isLoadingDatosGeograficos } = useMisc()
  const [validando, setValidando] = useState(false)

  const { savetaxInfo } = useUser()
  const { catalogoActividades } = useMisc()

  const getPersona = useGetPersona()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoadingDatosGeograficos) {
      dispatch(getDatosGeograficos)
    }
  }, [isLoadingDatosGeograficos, dispatch])

  const saveUser = async () => {
    stepUp()
  }


  const [actividadEconomica, setActividadEconomica] = useState(
    {
      actividadEconomica: {
        codigo: '',
        nombre: '',
      },
      actividadEconomicaCiaPK: {
        codCia: savetaxInfo.cia.codCia,
        codActividad: '',
        correlativo: '',
      },
      descripcion: '',
      estado: '',
    }
  )
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (actividadEconomica.actividadEconomica.nombre || actividadEconomica.actividadEconomica.codigo) {
      setLoading(true)
      dispatch(createActividadEconomica(actividadEconomica)).then((r) => {
        if (r) {
          stepUp()
        }

        setLoading(false)
      })
    } else {
      toast.error('Selecciona actividad económica')
    }
  }

  useEffect(() => {
    if (savetaxInfo?.cia?.ruc) {
      dispatch(getCatalogoActividades(savetaxInfo?.cia.ruc))
      console.log(catalogoActividades)
    }
  }, [dispatch, savetaxInfo?.cia?.ruc])


  return (
    <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
      <div className="Form registroForm">
        <div className="carrusel step01">
          <div className="slide infoStep">
            <div className="steps">
              <span>
                Paso <b>2</b> de 3
              </span>
            </div>

            <h4>
              <button className="backButton" onClick={stepDown}>
                <Down />
              </button>
              Actividades Economicas
            </h4>

            <div className="FormInfo">
              <label className="text">
                Actividad Económica
                <Select
                  required
                  options={
                    catalogoActividades?.length
                      ? catalogoActividades?.map((c) => ({
                        label: c.nombre,
                        value: c.codigo,
                      }))
                      : []
                  }
                  value={
                    actividadEconomica?.actividadEconomica?.codigo
                      ? {
                        value: actividadEconomica?.actividadEconomica?.codigo ?? '',
                        label: actividadEconomica?.actividadEconomica?.nombre ?? '',
                      }
                      : ''
                  }
                  onChange={(e) => {
                    setActividadEconomica((prev) => ({
                      ...prev,
                      actividadEconomica: {
                        nombre: e.label,
                        codigo: e.value,
                      },
                      actividadEconomicaCiaPK: {
                        ...prev.actividadEconomicaCiaPK,
                        codActividad: e.value,
                      },
                    }))
                  }}
                  placeholder="Buscar..."
                />
              </label>

              <Text
                label="Nombre comercial"
                placeholder="Ventas de electrónicos..."
                value={actividadEconomica?.descripcion ?? ''}
                onChange={(e) => setActividadEconomica((prev) => ({ ...prev, descripcion: e.target.value }))}
              />

              <label className="text">
                Estado
                <Select
                  isSearchable={false}
                  options={[
                    { label: 'Activo', value: 'A' },
                    { label: 'Inactivo', value: 'I' },
                  ]}
                  value={
                    actividadEconomica?.estado
                      ? {
                        value: actividadEconomica?.estado,
                        label: actividadEconomica?.estado === 'I' ? 'Inactivo' : actividadEconomica?.estado === 'A' ? 'Activo' : '',
                      }
                      : ''
                  }
                  onChange={(e) => setActividadEconomica((prev) => ({ ...prev, estado: e.value }))}
                  placeholder="Seleccione..."
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="actionFixed">
        <button
          className="btnRegistro"
          onClick={() => {
            handleSubmit()
          }}
        >
          Guardar Datos
        </button>
      </div>
    </motion.div>
  )
}

export default InfoCliente
