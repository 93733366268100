/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import React, { createRef, useEffect, useMemo, useState } from 'react'

// Import Hooks
import useClientes from 'hooks/useClientes'
import { useDispatch } from 'react-redux'
import { getClients } from 'actions/personas'
import useUser from 'hooks/useUser'

// Import Components
import CircularProgress from 'components/CircularProgress/CircularProgress'
import axios from 'axios'
import { toast } from 'react-toastify'

const SearchClient = ({ setSection, setFactura, factura, action }) => {
  const dispatch = useDispatch()
  const { clientes, isLoadingClientes } = useClientes()
  const { savetaxInfo } = useUser()
  const cia = useMemo(() => savetaxInfo.cia.codCia, [savetaxInfo])

  const [clientesFiltrados, setClientesFiltrados] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [nameFilter, setNameFilter] = useState('')
  const [codeFilter, setCodeFilter] = useState('')
  const refNameSearch = createRef()

  const handleNameFilter = (e) => {
    setNameFilter(e.target.value)
    setCodeFilter('')
    setClientesFiltrados(
      clientes.filter(({ nombreCliente }) => nombreCliente.toUpperCase().includes(e.target.value.toLocaleUpperCase()))
    )
  }

  const handleRucFilter = (e) => {
    setCodeFilter(e.target.value)
    setNameFilter('')
    setClientesFiltrados(clientes.filter(({ ruc }) => String(ruc).includes(e.target.value)))
  }

  const handleSelectClient = async (client) => {
    console.log(client)

    try {
      let response = await axios.get(`https://api.hacienda.go.cr/fe/ae?identificacion=${client?.ruc}`)
      let { data } = response

      if (!data.nombre) {
        toast('No hay titular de este número de identidad', { type: 'error' })
      } else if (data?.situacion?.estado === 'Inscrito') {
        toast(`El cliente seleccionado puede recibir documentos para efectos fiscales`, { type: 'success' })
      } else {
        toast(
          'El cliente seleccionado debe recibir únicamente tiquetes electrónicos, caso contrario el documento será rechazado por la Administración Tributaria',
          { type: 'error' }
        )
      }
    } catch (e) {
      //toast(`Hubo un error en el servidor, intentelo más tarde`, { type: 'error' })
    }
    setSection('facturaElectronica/Receptor')
    setFactura((prev) => ({
      ...prev,
      cliente: client,
    }))
  }

  const nextPage = () => {
    setCurrentPage((p) => p + 1)
  }

  const prevPage = () => {
    setCurrentPage((p) => p - 1)
  }

  useEffect(() => {
    refNameSearch.current.focus()
    if (!clientes.length) {
      dispatch(getClients(cia))
    }
    setClientesFiltrados(
      action === 'CREATE_FACTURA_COMPRA' ? clientes.filter((x) => x?.tipoCliente?.idTipoCliente === 2) : clientes
    )
  }, [dispatch, cia, clientes])

  return (
    <>
      <div className="sectionForm">
        <div className="BackSection" onClick={() => setSection('facturaElectronica/Receptor')}>
          <i className="bi bi-chevron-left"></i> Atrás
        </div>

        <div className="titleSectionForm" style={{ width: '100%' }}>
          <h3> {action === 'CREATE_FACTURA_COMPRA' ? 'Buscar Proveedor' : 'Buscar Cliente'}</h3>
        </div>
        <label htmlFor="BuscarDescripción" className="text" style={{ width: '60%' }}>
          Buscar por Nombre
          <div className="searchForm">
            <input
              ref={refNameSearch}
              type="text"
              autoComplete="off"
              placeholder="Buscar Descripción o Categoria"
              id="BuscarDescripción"
              value={nameFilter.toUpperCase()}
              onChange={handleNameFilter}
            />
            <button className="searchPersonaForID">
              <i className="bi bi-search"></i>
            </button>
          </div>
        </label>
        <label htmlFor="BuscarCodigo" className="text" style={{ width: '40%' }}>
          Buscar por Nro Identificacion
          <div className="searchForm">
            <input
              type="text"
              autoComplete="off"
              placeholder="Buscar por Codigo"
              id="BuscarCodigo"
              value={codeFilter}
              onChange={handleRucFilter}
            />
            <button className="searchPersonaForID">
              <i className="bi bi-search"></i>
            </button>
          </div>
        </label>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1">
                <b>ID</b>
              </li>
              <li className="col-2">
                <b>Nro Identificacion</b>
              </li>
              <li className="col-6">
                <b>Nombre</b>
              </li>
              <li className="col-1-2">
                <b></b>
              </li>
            </ul>
          </div>
          <div className="bodyList">
            {isLoadingClientes ? (
              <CircularProgress />
            ) : (
              <ul>
                {clientesFiltrados.length > 0 ? (
                  clientesFiltrados.slice(50 * currentPage - 50, 50 * currentPage).map((persona) => (
                    <li
                      onDoubleClick={() => handleSelectClient(persona)}
                      className="itemBodyList"
                      key={Math.random().toString(36).slice(-10)}
                    >
                      <ul
                        className={persona.ruc === factura?.cliente?.ruc ? 'contentItemBodyList selected' : 'contentItemBodyList'}
                        inhover="out"
                      >
                        <li className="col-1 noAction" key={Math.random().toString(36).slice(-10)}>
                          <span>{persona.clientePK.idCliente}</span>
                        </li>
                        <li className="col-2 noAction" key={Math.random().toString(36).slice(-10)}>
                          <span>{persona.ruc}</span>
                        </li>
                        <li className="col-6 noAction" key={Math.random().toString(36).slice(-10)}>
                          <b>{persona.nombreCliente.toUpperCase()}</b>
                        </li>
                        <li className="col-1-2" key={Math.random().toString(36).slice(-10)}>
                          <button className="btnEditItem" onClick={() => handleSelectClient(persona)}>
                            <i className="bi bi-check-circle-fill"></i>
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Clientes</span>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
        {!isLoadingClientes && clientesFiltrados.length > 50 && (
          <div className="pagination">
            <div className="controllers">
              {currentPage > 1 && (
                <button className="previousPage" onClick={prevPage}>
                  <i className="bi bi-chevron-left"></i>
                </button>
              )}
              <span>
                Página {currentPage} de {Math.ceil(clientesFiltrados.length / 50)}
              </span>
              {clientesFiltrados.length > 50 && !(currentPage * 50 >= clientesFiltrados.length) && (
                <button className="nextPage" onClick={nextPage}>
                  <i className="bi bi-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default SearchClient
