import { deleteCaja, getCajas, getSucursales } from 'actions/administracion'
import { ReactComponent as Create } from 'assets/create.svg'
import { ReactComponent as Delete } from 'assets/delete.svg'
import { ReactComponent as Edit } from 'assets/edit.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import Pagination from 'components/common/Pagination'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './Cajas.css'
import CajasKeyview from './CajasKeyview'

const Cajas = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { cajas, isLoadingCajas, isLoadingSucursales } = useAdmin()

  const [filtros, setFiltros] = useState({
    search: '',
    estado: '',
  })
  const [cajasFiltradas, setCajasFiltradas] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedCaja, setSelectedCaja] = useState(undefined)
  const [active, setActive] = useState(false)

  const handleOnClose = () => {
    setActive(false)
    setSelectedCaja(undefined)
    dispatch(getCajas)
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setFiltros((prev) => ({ ...prev, [name]: value }))
    setCajasFiltradas(filterCajas(name, value))
  }

  const filterCajas = (name, value) => {
    let cajasMatch = cajas
    if (cajasMatch.length) {
      if (name === 'estado') {
        return filterForEstado(cajasMatch, value)
      } else if (name === 'search') {
        return filterForSearch(cajasMatch, value)
      } else if (name === 'clear') {
        return cajasMatch
      }
    } else {
      return []
    }
  }

  const filterForEstado = (cajasMatch, value) => {
    let result = cajasMatch
    if (value.length) {
      result = result.filter((caja) => caja.estado === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (caja) => caja.nombreCaja.toUpperCase().includes(filtros.search.toUpperCase()) || caja.username.includes(filtros.search)
      )
    }
    return result
  }

  const filterForSearch = (cajasMatch, value) => {
    let result = cajasMatch
    if (value.length) {
      result = result.filter((caja) => caja.nombreCaja.toUpperCase().includes(value.toUpperCase()))
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((caja) => caja.estado === caja.filtros.estado)
    }
    return result
  }

  const clearFilters = () => {
    setFiltros({
      search: '',
      estado: '',
    })
    setCajasFiltradas(cajas)
  }

  useEffect(() => {
    if (isLoadingCajas) dispatch(getCajas(savetaxInfo.cia.codCia))

    setCajasFiltradas(cajas)
  }, [dispatch, isLoadingCajas, savetaxInfo.cia.codCia, cajas])

  useEffect(() => {
    if (isLoadingSucursales) dispatch(getSucursales(savetaxInfo.cia.codCia))
  }, [dispatch, isLoadingSucursales, savetaxInfo.cia.codCia])

  const actualizarCajas= async() => {
    dispatch(getCajas(savetaxInfo.cia.codCia))
  }

  return (
    <>
      <div className="Cajas">
        <div className="navSection">
          <b className="title">Filtra Por:</b>
          <label htmlFor="" style={{ width: '300px' }}>
            Nombre
            <div className="search">
              <input type="text" value={filtros.search} name="search" placeholder="Nombre" onChange={hadleFilter} />
              <button>
                <Search />
              </button>
            </div>
          </label>
          <div className="sortBy">
            <label htmlFor="" style={{ width: '150px' }}>
              Limpiar Filtros
              <button onClick={clearFilters}>Limpiar</button>
            </label>
          </div>

          <button className="addContent" onClick={() => setActive(true)}>
            Agregar Caja
            <Create />
          </button>
        </div>

        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1">
                <b>Identificación </b>
              </li>
              <li className="col-3">
                <b>Sucursal</b>
              </li>
              <li className="col-3">
                <b>Nombre </b>
              </li>
              <li className="col-1">
                <b>Número Sucursal</b>
              </li>
              <li className="col-1">
                <b>Número Caja</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>

          {isLoadingCajas ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Cajas</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {cajasFiltradas.length ? (
                  cajasFiltradas
                    .sort((a, b) => a.sucursal.nombreSucursal - b.sucursal.nombreSucursal)
                    .slice(50 * currentPage - 50, 50 * currentPage)
                    .map((caja) => (
                      <li className="itemBodyList" key={caja?.cajaPK?.idCaja}>
                        <ul className="contentItemBodyList">
                          <li className="col-1">
                            <span>{caja?.cajaPK?.idCaja ?? '-'}</span>
                          </li>
                          <li className="col-3">
                            <b>{caja.sucursal.nombreSucursal}</b>
                          </li>
                          <li className="col-3">
                            <span>{caja.nombreCaja}</span>
                          </li>
                          <li className="col-1">
                            <span>{caja.sucursal.establecimiento}</span>
                          </li>
                          <li className="col-1">
                            <span>{caja.puntoEmision}</span>
                          </li>
                          <li className="col-1">
                            <button
                              className="btnEditItem"
                              onClick={() => {
                                setActive(true)
                                setSelectedCaja({ ...caja, isEdit: true })
                              }}
                            >
                              <Edit />
                            </button>
                            <button
                              className="btnDelete"
                              onClick={() => {
                                createAlertConfirm('Eliminar Caja', '¿Está seguro de querer eliminar esta caja?', () =>
                                  dispatch(deleteCaja(caja))
                                )
                              }}
                            >
                              <Delete />
                            </button>
                          </li>
                        </ul>
                      </li>
                    ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        {!isLoadingCajas && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={cajasFiltradas} />}
      </div>

      {active && <CajasKeyview caja={selectedCaja} onClose={handleOnClose} actualizarCajas={actualizarCajas} />}
    </>
  )
}

export default Cajas
