// Import Modules
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Cancel } from 'assets/cancel.svg'

// Import Context
import Global from 'contexts/Global/Global'

const HelpAccess = () => {
  const globalContext = useContext(Global)
  const handleHeloAccess = () => {
    let helpAccess = document.querySelector('.helpAccess')
    let helpAccessContainer = document.querySelector('.helpAccessContainer')
    if (helpAccess.className.includes('close')) {
      helpAccess.className = 'helpAccess'
      helpAccessContainer.className = 'helpAccessContainer'
    } else {
      helpAccess.className = 'helpAccess close'
      helpAccessContainer.className = 'helpAccessContainer toggled'
    }
  }
  return (
    <>
      <button className="helpAccess close" onClick={handleHeloAccess}>
        <span>?</span>
        <Cancel />
      </button>
      <div className="helpAccessContainer toggled">
        <h4>Ayuda</h4>
        <ul>
          <li>
            <a href="https://api.whatsapp.com/send?phone=50660276862" target="_blank" rel="noreferrer">
              Chat Whatsapp
            </a>
          </li>
          {globalContext.HelpPageAvailable && (
            <li>
              <Link to={globalContext.pathHelp}>Ayuda con esta Sección</Link>
            </li>
          )}
        </ul>
      </div>
    </>
  )
}

export default HelpAccess
