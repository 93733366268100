import axios from 'axios'
import localforage from 'localforage'
import { toast } from 'react-toastify'
import {
  CREATE_ACTIVIDAD_ECONOMICA,
  CREATE_ADMIN_USUARIO,
  CREATE_CAJA,
  CREATE_CONSECUTIVO,
  CREATE_SUCURSALES,
  DELETE_ACTIVIDAD_ECONOMICA,
  DELETE_ADMIN_USUARIO,
  DELETE_CAJA,
  DELETE_CONSECUTIVO,
  DELETE_SUCURSALES,
  GET_ACTIVIDADES_ECONOMICAS,
  GET_CABYS,
  GET_PAQUETE,
  GET_CAJAS,
  LOAD_CAJAS,
  GET_CONSECUTIVOS,
  GET_ROLES,
  GET_SUCURSALES,
  GET_USUARIOS,
  LOAD_SUCURSALES,
  LOAD_USUARIOS,
  UPDATE_ACTIVIDAD_ECONOMICA,
  UPDATE_ADMIN_USUARIO,
  UPDATE_CAJA,
  UPDATE_CONSECUTIVO,
  UPDATE_SUCURSALES,
  LOAD_CIA,
  GET_CIA,
  COMPRA_PAQUETE,
  ELIMINAR_PAQUETE,
} from '../constants/actionTypes'
import { CiaService } from 'backend/services/CiaService'
import { getInstance } from 'api/apiInstance'

const getCia = (getState) => getState().user.savetaxInfo.cia.codCia

export const getRoles = (cia) => async (dispatch) => {
  const request = await getInstance()
  const response = await request.get(`users/find-rol-by-cod-cia/${cia}`)
  dispatch({
    type: GET_ROLES,
    payload: {
      roles: response.data,
    },
  })
}

// SUCURSALES
export const getSucursales = (cia) => {
  return async (dispatch) => {
    const request = await getInstance()
    const response = await request.get(`sucursal/find-by-cod-cia/${cia}`)
    console.log('response', response)
    dispatch({
      type: GET_SUCURSALES,
      payload: {
        sucursales: response?.data,
      },
    })
  }
}

export const createSucursal = (sucursal) => async (dispatch, getState) => {
  toast.success('Sucursal creada correctamente')
  dispatch({
    type: CREATE_SUCURSALES,
    payload: {
      sucursal,
    },
  })

  const request = await getInstance()
  await request.post(`sucursal`, sucursal)

  dispatch(getSucursales(getCia(getState)))
}

export const updateSucursal = (sucursal) => async (dispatch, getState) => {
  toast.success('Sucursal actualizado correctamente')
  dispatch({
    type: UPDATE_SUCURSALES,
    payload: {
      sucursal,
    },
  })

  const request = await getInstance()
  await request.post(`sucursal/edit`, sucursal)

  dispatch(getSucursales(getCia(getState)))
}

export const deleteSucursal = (sucursal) => async (dispatch, getState) => {
  const id = sucursal.sucursalPK.idSucursal
  const cia = sucursal.sucursalPK.codCia

  toast.success('Se eliminó correctamente la sucursal')
  dispatch({
    type: DELETE_SUCURSALES,
    payload: {
      sucursal,
    },
  })

  const request = await getInstance()
  await request.delete(`sucursal/${cia}/${id}`)

  dispatch(getSucursales(getCia(getState)))
}

// ACTIVIDADES ECONOMICAS
export const getActividadesEconomicas = (cia) => {
  return async (dispatch) => {
    const request = await getInstance()
    const response = await request.get(`actividadeconomicacia/find-by-cod-cia/${cia}`)

    dispatch({
      type: GET_ACTIVIDADES_ECONOMICAS,
      payload: {
        actividadesEconomicas: response?.data,
      },
    })
  }
}

export const createActividadEconomica = (actividad) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post(`actividadeconomicacia`, actividad)

    dispatch({
      type: CREATE_ACTIVIDAD_ECONOMICA,
      payload: {
        actividadEconomica: actividad,
      },
    })
    toast.success('Actividad economica creada correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema creando la actividad economica')

    return false
  }
}

export const updateActividadEconomica = (actividad) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post(`actividadeconomicacia/edit`, actividad)

    dispatch({
      type: UPDATE_ACTIVIDAD_ECONOMICA,
      payload: {
        actividadEconomica: actividad,
      },
    })
    toast.success('Actividad económica actualizado correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema actualizando el actividad económica')

    return false
  }
}

export const deleteActividadEconomica = (actividad) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.delete(
      `actividadeconomicacia/${actividad?.actividadEconomicaCiaPK?.codCia}/${actividad?.actividadEconomicaCiaPK?.correlativo}/${actividad?.actividadEconomicaCiaPK?.codActividad}`
    )

    dispatch({
      type: DELETE_ACTIVIDAD_ECONOMICA,
      payload: {
        actividadEconomica: actividad,
      },
    })
    toast.success('Se eliminó correctamente la actividad económica')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema eliminando la actividad económica')

    return false
  }
}

// CAJAS
export const getCajas = (cia) => {
  return async (dispatch) => {
    /*dispatch({
      type: LOAD_CAJAS,
      payload: {},
    })*/

    const request = await getInstance()
    const response = await request.get(`caja/find-by-cia/${cia}`)

    dispatch({
      type: GET_CAJAS,
      payload: {
        cajas: response?.data,
      },
    })
  }
}

export const createCaja = (caja) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post(`caja`, caja)

    /*dispatch({
      type: CREATE_CAJA,
      payload: {
        caja: caja,
      },
    })*/
    toast.success('Caja creada correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema creando la caja')

    return false
  }
}

export const updateCaja = (caja) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post(`caja/edit`, caja)

    dispatch({
      type: UPDATE_CAJA,
      payload: {
        caja: caja,
      },
    })
    toast.success('Caja actualizada correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema actualizando la caja')

    return false
  }
}

export const deleteCaja = (caja) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.delete(`caja/${caja?.cajaPK?.codCia}/${caja?.cajaPK?.idSucursal}/${caja?.cajaPK?.idCaja}`)

    dispatch({
      type: DELETE_CAJA,
      payload: {
        caja: caja,
      },
    })
    toast.success('Se eliminó correctamente la caja')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema eliminando la caja')

    return false
  }
}

// USUARIOS
export const getUsuarios = async (dispatch) => {
  dispatch({
    type: LOAD_USUARIOS,
    payload: {},
  })

  const request = await getInstance()
  const response = await request.get(`cia/find-registros`)

  dispatch({
    type: GET_USUARIOS,
    payload: {
      usuarios: response?.data,
    },
  })
}

export const getCiaByCodigo = (codCia) => async (dispatch) => {
  dispatch({
    type: LOAD_CIA,
    payload: {},
  })

  const request = await getInstance()
  const response = await request.get(`cia/find-by-cod-cia/${codCia}`)

  dispatch({
    type: GET_CIA,
    payload: {
      usuarios: [response?.data],
    },
  })
}

export const createUsuario = (usuario) => async (dispatch) => {
  // Eliminar el campo "cia" del objeto "usuario"
  delete usuario.cia
  usuario.idLicencia = '1'
  usuario.tipoPersona = usuario.tipoDoctoPersona.tipoDoctoPersonaPK.idTipoDoctoPersona + ''
  if (!usuario.hasOwnProperty('celular')) {
    // Asignar un valor predeterminado a "celular" si no está presente
    usuario.celular = ''
  }

  if (!usuario.hasOwnProperty('fax')) {
    usuario.fax = ''
  }

  if (!usuario.hasOwnProperty('identificacionRepresentante')) {
    usuario.identificacionRepresentante = ''
  }

  if (!usuario.hasOwnProperty('representanteLegal')) {
    usuario.representanteLegal = ''
  }

  if (!usuario.hasOwnProperty('nombreLogo')) {
    usuario.nombreLogo = ''
  }

  if (!usuario.hasOwnProperty('valIva')) {
    usuario.valIva = '0'
  }

  usuario.primeraVez = true

  console.log('usuario', usuario)
  try {
    const request = await getInstance()
    const response = await request.post(`cia/crear-cuenta`, { ...usuario })

    //console.log("response",response)
    /*dispatch({
      type: CREATE_ADMIN_USUARIO,
      payload: {
        usuario: usuario,
      },
    })*/
    toast.success('Compañia creada correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema creando la compañia')

    return false
  }
}

export const updateUsuario = (usuario) => async (dispatch) => {
  try {
    const request = await getInstance()
    console.log(usuario)
    await request.post(`cia/edit`, { ...usuario })

    dispatch({
      type: UPDATE_ADMIN_USUARIO,
      payload: {
        usuario: usuario,
      },
    })
    toast.success('Compañia actualizada correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema actualizando la compañia')

    return false
  }
}

export const deleteUsuario = (usuario) => async (dispatch) => {
  const ciaService = new CiaService()
  try {
    await ciaService.delete(usuario.codCia)
    dispatch({
      type: DELETE_ADMIN_USUARIO,
      payload: {
        usuario: usuario,
      },
    })
    toast.success('Se eliminó correctamente el usuario')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema eliminando el usuario')

    return false
  }
}

// CONSECUTIVOS
export const getConsecutivos = (cia) => {
  return async (dispatch) => {
    const request = await getInstance()
    const response = await request.get(`manto-formulario/find-by-cod-cia/${cia}`)

    dispatch({
      type: GET_CONSECUTIVOS,
      payload: {
        consecutivos: response?.data,
      },
    })
  }
}

export const createConsecutivo = (consecutivo) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post(`manto-formulario`, consecutivo)

    dispatch({
      type: CREATE_CONSECUTIVO,
      payload: {
        consecutivo: consecutivo,
      },
    })
    toast.success('Consecutivo creada correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema creando el consecutivo')

    return false
  }
}

export const updateConsecutivo = (consecutivo) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.post(`manto-formulario/edit`, consecutivo)

    dispatch({
      type: UPDATE_CONSECUTIVO,
      payload: {
        consecutivo: consecutivo,
      },
    })
    toast.success('Consecutivo actualizado correctamente')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema actualizando el consecutivo')

    return false
  }
}

export const deleteConsecutivo = (consecutivo) => async (dispatch) => {
  try {
    const request = await getInstance()
    await request.delete(`manto-formulario/${consecutivo.mantoFormularioPK.codCia}/${consecutivo.mantoFormularioPK.idMantoForm}`)

    dispatch({
      type: DELETE_CONSECUTIVO,
      payload: {
        consecutivo: consecutivo,
      },
    })
    toast.success('Se eliminó correctamente el consecutivo')

    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema eliminando el consecutivo')

    return false
  }
}

export const getCabys = async (dispatch) => {
  let cabys = await localforage.getItem('cabys')
  if (!cabys) {
    const request = await getInstance()
    const response = await request.get('cabys')
    localforage.setItem('cabys', response?.data)
    cabys = response?.data
    return
  }

  dispatch({
    type: GET_CABYS,
    payload: {
      cabys: cabys,
    },
  })
}

export const getPaquete = async (dispatch) => {
  dispatch({
    type: LOAD_USUARIOS,
    payload: {},
  })
  
  const request = await getInstance()
  const response = await request.get('paquete')
  let paquete = response?.data
  dispatch({
    type: GET_PAQUETE,
    payload: {
      paquete: paquete,
    },
  })
}

export const compraPaquete = (paqueteRequest) => async (dispatch) => {
  try {
    const request = await getInstance()
    console.log(paqueteRequest)
    const response = await request.post(`cia/compra-paquete`, { ...paqueteRequest })

    dispatch({
      type: COMPRA_PAQUETE,
      payload: {
        paqueteRequest: paqueteRequest,
      },
    })
    if (response) {
      if (response?.data.respuesta) {
        toast.success(response?.data.mensaje)
      } else {
        toast.error(response?.data.mensaje)
      }
    }
    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema al comprar su paquete')
    return false
  }
}

export const eliminarPaquete = (paqueteRequest) => async (dispatch) => {
  try {
    const request = await getInstance()
    console.log(paqueteRequest)
    const response = await request.post(`cia/eliminar-paquete`, { ...paqueteRequest })

    dispatch({
      type: ELIMINAR_PAQUETE,
      payload: {
        paqueteRequest: paqueteRequest,
      },
    })
    if (response) {
      if (response?.data.respuesta) {
        toast.success(response?.data.mensaje)
      } else {
        toast.error(response?.data.mensaje)
      }
    }
    return true
  } catch (e) {
    console.error(e.message)
    toast.error('Hubo un problema al comprar su paquete')
    return false
  }
}
