import { deleteSucursal, getSucursales } from 'actions/administracion'
import Pagination from 'components/common/Pagination'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactComponent as Create } from '../../../assets/create.svg'
import { ReactComponent as Delete } from '../../../assets/delete.svg'
import { ReactComponent as Edit } from '../../../assets/edit.svg'
import { ReactComponent as Search } from '../../../assets/search.svg'
import './Sucursales.css'
import SucursalesForm from './SucursalesForm'

const Sucursales = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { sucursales, isLoadingSucursales } = useAdmin()
  const [filtros, setFiltros] = useState({
    search: '',
    estado: '',
  })
  const [sucursalesFiltradas, setSucursalesFiltradas] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [formOpen, setFormOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const clearFilters = () => {
    setFiltros({
      search: '',
      estado: '',
    })
    setSucursalesFiltradas(sucursales)
  }

  const filterForEstado = (sucursalesMatch, value) => {
    let result = sucursalesMatch
    if (value.length) {
      result = result.filter((sucursal) => sucursal.estado === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (sucursal) =>
          sucursal.nombreSucursal.toUpperCase().includes(filtros.search.toUpperCase()) ||
          sucursal.establecimiento.includes(filtros.search)
      )
    }
    return result
  }

  const filterForSearch = (sucursalesMatch, value) => {
    let result = sucursalesMatch
    if (value.length) {
      result = result.filter(
        (sucursal) =>
          sucursal.nombreSucursal.toUpperCase().includes(value.toUpperCase()) ||
          sucursal.establecimiento.toString().includes(value)
      )
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((sucursal) => sucursal.estado === sucursal.filtros.estado)
    }
    return result
  }

  const filterSucursales = (name, value) => {
    let sucursalesMatch = sucursales
    if (sucursalesMatch.length) {
      if (name === 'estado') {
        return filterForEstado(sucursalesMatch, value)
      } else if (name === 'search') {
        return filterForSearch(sucursalesMatch, value)
      } else if (name === 'clear') {
        return sucursalesMatch
      }
    } else {
      return []
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target

    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
    setSucursalesFiltradas(filterSucursales(name, value))
  }

  const handleCloseForm = () => {
    setFormOpen(false)
    setSelectedId(null)
  }
  const handleOpenForm = () => {
    setFormOpen(true)
    setSelectedId(null)
  }

  useEffect(() => {
    if (isLoadingSucursales) {
      dispatch(getSucursales(savetaxInfo.cia.codCia))
    }

    setSucursalesFiltradas(sucursales)
  }, [dispatch, savetaxInfo.cia.codCia, isLoadingSucursales, sucursales])

  return (
    <div className="Sucursales">
      <div className="navSection">
        <b className="title">Filtra Por:</b>
        <label htmlFor="" style={{ width: '300px' }}>
          Nombre o Establecimiento
          <div className="search">
            <input
              type="text"
              value={filtros.search}
              name="search"
              placeholder="Nombre o Establecimiento"
              onChange={hadleFilter}
            />
            <button>
              <Search />
            </button>
          </div>
        </label>
        <div className="sortBy">
          <label htmlFor="estado" style={{ width: '150px' }}>
            Estado
            <select id="estado" value={filtros.estado} onChange={hadleFilter} name="estado">
              <option value="">Todos los Estados</option>
              <option value="A">Activo</option>
              <option value="I">Inactivo</option>
            </select>
          </label>
          <label htmlFor="" style={{ width: '150px' }}>
            Limpiar Filtros
            <button onClick={clearFilters}>Limpiar</button>
          </label>
        </div>
        <button className="addContent" onClick={handleOpenForm} style={{ display: "none" }}>
          Agregar Sucursal
          <Create />
        </button>
      </div>

      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-1-2">
              <b>ID</b>
            </li>
            <li className="col-1">
              <b>Compañia</b>
            </li>
            <li className="col-3">
              <b>Nombre Sucursal</b>
            </li>
            <li className="col-2">
              <b>Dirección</b>
            </li>
            <li className="col-1">
              <b>Nro Sucursal</b>
            </li>
            <li className="col-1">
              <b>Estado</b>
            </li>
            <li className="col-1">
              <b></b>
            </li>
          </ul>
        </div>

        {isLoadingSucursales ? (
          <div className="loadContentTable">
            <div className="snippet"></div>
            <span>Cargando Sucursales</span>
          </div>
        ) : (
          <div className="bodyList">
            <ul>
              {sucursalesFiltradas.length ? (
                sucursalesFiltradas.slice(50 * currentPage - 50, 50 * currentPage).map((sucursal) => (
                  <li className="itemBodyList" key={sucursal.sucursalPK.idSucursal}>
                    <ul className="contentItemBodyList">
                      <li className="col-1-2">
                        <b>{sucursal.sucursalPK.idSucursal}</b>
                      </li>
                      <li className="col-1">
                        <span>{sucursal.sucursalPK.codCia}</span>
                      </li>
                      <li className="col-3">
                        <span>{sucursal.nombreSucursal}</span>
                      </li>
                      <li className="col-2">
                        <span>{sucursal.direccion}</span>
                      </li>
                      <li className="col-1">
                        <span>{sucursal.establecimiento}</span>
                      </li>
                      <li className="col-1">
                        <span className={sucursal.estado === 'A' ? 'activo' : 'desactivo'}>
                          {sucursal.estado === 'A' ? 'Activo' : 'Inactivo'}
                        </span>
                      </li>
                      <li className="col-1">
                        <button
                          className="btnEditItem"
                          onClick={() => {
                            setSelectedId(sucursal.sucursalPK.idSucursal)
                            setFormOpen(true)
                          }}
                        >
                          <Edit />
                        </button>
                        <button className="btnDelete" onClick={() => dispatch(deleteSucursal(sucursal))}>
                          <Delete />
                        </button>
                      </li>
                    </ul>
                  </li>
                ))
              ) : (
                <li className="emptyResultTable">
                  <span>No hay Resultados</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      {!isLoadingSucursales && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={sucursalesFiltradas} />}

      <SucursalesForm open={formOpen} handleClose={handleCloseForm} selectedId={selectedId} />
    </div>
  )
}

export default Sucursales
