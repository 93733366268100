import { createCaja, updateCaja } from 'actions/administracion'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import Text from 'components/FormField/Text'
import KeyView from 'components/KeyView/KeyView'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

const CajasKeyview = ({ onClose, caja: C , actualizarCajas}) => {
  const dispatch = useDispatch()
  const { isEdit } = C ?? {}
  const { savetaxInfo } = useUser()
  const { sucursales } = useAdmin()

  const [caja, setCaja] = useState(
    C ?? {
      cajaPK: {
        codCia: savetaxInfo.cia.codCia,
      },
      nombreCaja: '',
      puntoEmision: '',
      sucursal: {},
    }
  )
  const [loading, setLoading] = useState(false)

  const handleSucursalChange = useCallback((e) => {
    if (!e.target.value) {
      setCaja((prev) => ({
        ...prev,
        sucursal: {},
        cajaPK: {
          ...prev.cajaPK,
          idSucursal: '',
        },
      }))

      return
    }

    const sucursal = JSON.parse(e.target[e.target.selectedIndex].dataset.sucursal)
    setCaja((prev) => ({
      ...prev,
      sucursal: sucursal,
      cajaPK: {
        ...prev.cajaPK,
        idSucursal: e.target.value,
      },
    }))
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!caja?.cajaPK?.idSucursal) {
      toast.error('Sucursal es obligatorio')
      return
    }else if (!caja?.nombreCaja) {
      toast.error('Nombre de caja es obligatorio')
      return
    }else if (!caja?.puntoEmision) {
      toast.error('número de caja es obligatorio')
      return
    }

    setLoading(true)

    if (isEdit) {
      dispatch(updateCaja(caja)).then((r) => {
        if (r) {
          onClose()
        }
        actualizarCajas()
        setLoading(false)
      })
    } else {
      dispatch(createCaja(caja)).then((r) => {
        if (r) {
          onClose()
        }
        actualizarCajas()
        setLoading(false)
      })
    }

   
  }

  return (
    <KeyView title="Agregar Caja" onClose={onClose}>
      <label htmlFor="Estado" className="text">
        Sucursal
        <select disabled={isEdit} id="sucursal" onChange={handleSucursalChange} value={caja?.cajaPK?.idSucursal}>
          <option value="">Seleccione</option>
          {sucursales.map((sucursal, index) => (
            <option
              key={sucursal?.sucursalPK?.idSucursal}
              data-sucursal={JSON.stringify(sucursal)}
              value={sucursal?.sucursalPK?.idSucursal}
            >
              {sucursal?.nombreSucursal}
            </option>
          ))}
        </select>
      </label>

      <Text
        label="* Nombre de Caja"
        placeholder="Caja 001"
        name="nombreCaja"
        value={caja?.nombreCaja}
        onChange={(e) => setCaja((prev) => ({ ...prev, nombreCaja: e.target.value }))}
      />

      <Text
        label="* Número de Caja"
        placeholder="001"
        type="number"
        name="puntoEmisión"
        value={caja?.puntoEmision}
        onChange={(e) => setCaja((prev) => ({ ...prev, puntoEmision: e.target.value }))}
        onInput={(e) => {
          e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }}
      />

      {loading ? <CircularProgress /> : <button onClick={handleSubmit}>{isEdit ? 'Actualizar' : 'Guardar'}</button>}
    </KeyView>
  )
}

export default CajasKeyview
