import React, { useEffect, useState } from 'react'
import useMisc from 'hooks/useMisc'
import Toggle from 'react-toggle'
import { useDispatch } from 'react-redux'
import { getTiposImpuestos } from 'actions/misc'

const Impuesto = ({ impuesto, isEdit, factura, setFactura, tipoImpuesto }) => {
  const filterImpuesto = factura?.productEnLinea?.otroImpuestoVtaDetmList?.filter(
    (i) => i?.tipoImpuesto?.idTipoImpuesto === impuesto?.idTipoImpuesto
  )?.[0]

  const { porcentaje } = filterImpuesto ?? {}

  const [checked, setChecked] = useState(filterImpuesto ?? false)
  const [percentage, setPercentage] = useState(porcentaje ?? '')

  const handleOnChangeChecked = (id) => {
    setChecked((prev) => !prev)
    setPercentage('')
    /* console.log(percentage) */
    const impuesto = {
      factor: +percentage,
      porcentaje: +percentage,
      monto: (+percentage * (factura?.productEnLinea?.precioVenta * factura?.productEnLinea?.cantidad)) / 100,
      montoPorcentaje: 'P',
      otroImpuestoVtaDetmPK: {},
      tipoImpuesto: tipoImpuesto,
    }

    setFactura((prev) => ({
      ...prev,
      productEnLinea: {
        ...prev.productEnLinea,
        otroImpuestoVtaDetmList: checked
          ? prev?.productEnLinea?.otroImpuestoVtaDetmList?.filter((i) => i.tipoImpuesto.idTipoImpuesto !== id)
          : [...(prev?.productEnLinea?.otroImpuestoVtaDetmList ?? []), impuesto],
      },
    }))
  }

  const handleOnChangePercentage = (e, id) => {
    setPercentage(e.target.value)

    const impuesto = {
      factor: +e.target.value,
      porcentaje: +e.target.value,
      monto: (+e.target.value * (factura?.productEnLinea?.precioVenta * factura?.productEnLinea?.cantidad)) / 100,
      montoPorcentaje: 'P',
      otroImpuestoVtaDetmPK: {},
      tipoImpuesto: tipoImpuesto,
    }

    setFactura((prev) => {
      return {
        ...prev,
        productEnLinea: {
          ...prev.productEnLinea,
          otroImpuestoVtaDetmList: prev.productEnLinea.otroImpuestoVtaDetmList.map((i) =>
            i.tipoImpuesto.idTipoImpuesto === id ? impuesto : i
          ),
        },
      }
    })
  }

  return (
    <li className="itemBodyList" key={Math.random().toString(36).slice(-10)}>
      <ul className="contentItemBodyList">
        <li className="col-2" key={Math.random().toString(36).slice(-10)}>
          <Toggle disabled={isEdit} checked={checked} onChange={() => handleOnChangeChecked(impuesto.idTipoImpuesto)} />
        </li>

        <li className="col-4" key={Math.random().toString(36).slice(-10)}>
          <span>{impuesto.descripcion}</span>
        </li>

        <li className="col-2" key={Math.random().toString(36).slice(-10)}>
          <input
            value={percentage}
            disabled={isEdit || !checked}
            onChange={(e) => handleOnChangePercentage(e, impuesto.idTipoImpuesto)}
            type="number"
            placeholder="Porcentaje"
          />
        </li>

        <li className="col-2" key={Math.random().toString(36).slice(-10)}>
          <input value={filterImpuesto?.monto ?? ''} disabled={true} type="text" placeholder="Monto" id="nombreDescripcion" />
        </li>
      </ul>
    </li>
  )
}

const Impuestos = ({ factura, setSection, setFactura }) => {
  const dispatch = useDispatch()
  const { isEdit } = factura
  const { tiposImpuestos } = useMisc()

  useEffect(() => {
    if (!tiposImpuestos.length) {
      dispatch(getTiposImpuestos)
    }
  }, [dispatch, tiposImpuestos])

  return (
    <div className="sectionForm">
      <div className="BackSection" onClick={() => setSection('facturaElectronica/DetallesFactura')}>
        <i className="bi bi-chevron-left"></i> Atrás
      </div>

      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Impuestos</h3>
      </div>

      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-2">
              <b>Aplica</b>
            </li>
            <li className="col-4">
              <b>Descripción</b>
            </li>
            <li className="col-2">
              <b>Porcentaje</b>
            </li>
            <li className="col-2">
              <b>Monto</b>
            </li>
          </ul>
        </div>

        <div className="bodyList">
          <ul>
            {tiposImpuestos.map((t) => (
              <Impuesto
                impuesto={t}
                isEdit={isEdit}
                factura={factura}
                setFactura={setFactura}
                tipoImpuesto={tiposImpuestos.filter((imp) => imp.idTipoImpuesto === t.idTipoImpuesto)[0]}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Impuestos
