import React, { useEffect, useMemo, useState } from 'react'
import useProductos from 'hooks/useProductos'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import useUser from 'hooks/useUser'
import { useDispatch } from 'react-redux'
import { getProductos } from 'actions/productos'
import { createRef } from 'react'

const Product = ({ producto, selected, handleSelectProduct }) => (
  <li onDoubleClick={handleSelectProduct} className="itemBodyList" key={Math.random().toString(36).slice(-10)}>
    <ul className={selected ? 'contentItemBodyList selected' : 'contentItemBodyList'} inhover="out">
      <li className="col-1 noAction" key={Math.random().toString(36).slice(-10)}>
        <span>{producto.productoPK.idProducto}</span>
      </li>
      <li className="col-4 noAction" key={Math.random().toString(36).slice(-10)}>
        <b>{producto.nombreProducto.toUpperCase()}</b>
      </li>
      <li className="col-2 noAction" key={Math.random().toString(36).slice(-10)}>
        <span>{producto.existenciaGlobal && producto.existenciaGlobal}</span>
      </li>
      <li className="col-2 noAction" key={Math.random().toString(36).slice(-10)}>
        <span>{producto.precioVenta}</span>
      </li>
      <li className="col-1-2" key={Math.random().toString(36).slice(-10)}>
        <button className="btnEditItem" onClick={handleSelectProduct}>
          <i className="bi bi-check-circle-fill"></i>
        </button>
      </li>
    </ul>
  </li>
)

const BuscarProductos = ({ factura, setFactura, setSection }) => {
  const dispatch = useDispatch()
  const { productos, isLoadingProductos } = useProductos()
  const { savetaxInfo } = useUser()
  const cia = useMemo(() => savetaxInfo.cia.codCia, [savetaxInfo])

  const [productosFiltrados, setProductosFiltrados] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [nameFilter, setNameFilter] = useState('')
  const [codeFilter, setCodeFilter] = useState('')
  const refSearchProduct = createRef()

  const handleSelectProduct = (product) => {
    setSection('facturaElectronica/DetallesFactura')
    setFactura((prev) => ({
      ...prev,
      productEnLinea: { ...product, cantidad: 1 },
    }))
  }

  const handleNameFilter = (e) => {
    setNameFilter(e.target.value.toUpperCase())
    setCodeFilter('')
    setProductosFiltrados(
      productos.filter(({ nombreProducto }) => nombreProducto.toUpperCase().includes(e.target.value.toLocaleUpperCase()))
    )
  }

  const handleCodeFilter = (e) => {
    setCodeFilter(e.target.value)
    setNameFilter('')
    setProductosFiltrados(productos.filter(({ productoPK }) => String(productoPK.idProducto).includes(e.target.value)))
  }

  const nextPage = () => {
    setCurrentPage((p) => p + 1)
  }

  const prevPage = () => {
    setCurrentPage((p) => p - 1)
  }

  useEffect(() => {
    refSearchProduct.current.focus()
    if (!productos.length) {
      dispatch(getProductos(cia))
    }

    setProductosFiltrados(productos)
  }, [productos, cia, dispatch])

  return (
    <div className="sectionForm">
      <div className="BackSection" onClick={() => setSection('facturaElectronica/DetallesFactura')}>
        <i className="bi bi-chevron-left"></i> Atrás
      </div>

      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Buscar Productos</h3>
      </div>

      <label htmlFor="BuscarDescripción" className="text" style={{ width: '60%' }}>
        Buscar por Descripción
        <div className="searchForm">
          <input
            ref={refSearchProduct}
            type="text"
            autoComplete="off"
            placeholder="Buscar Descripción o Categoria"
            id="BuscarDescripción"
            value={nameFilter}
            onChange={handleNameFilter}
          />
          <button className="searchPersonaForID">
            <i className="bi bi-search"></i>
          </button>
        </div>
      </label>

      <label htmlFor="BuscarCodigo" className="text" style={{ width: '40%' }}>
        Buscar por Codigo
        <div className="searchForm">
          <input
            type="text"
            autoComplete="off"
            placeholder="Buscar por Codigo"
            id="BuscarCodigo"
            value={codeFilter}
            onChange={handleCodeFilter}
          />
          <button className="searchPersonaForID">
            <i className="bi bi-search"></i>
          </button>
        </div>
      </label>

      <div className="listSection list-noscroll">
        {isLoadingProductos ? (
          <CircularProgress />
        ) : (
          <>
            <div className="headList">
              <ul>
                <li className="col-1">
                  <b>Codigo</b>
                </li>
                <li className="col-4">
                  <b>Nombre</b>
                </li>
                <li className="col-2">
                  <b>Existencia</b>
                </li>
                <li className="col-2">
                  <b>Precio Unitario</b>
                </li>
                <li className="col-1-2">
                  <b></b>
                </li>
              </ul>
            </div>
            <div className="bodyList">
              <ul>
                {productosFiltrados.length > 0 ? (
                  productosFiltrados
                    .slice(50 * currentPage - 50, 50 * currentPage)
                    .map((producto, index) => (
                      <Product
                        key={'product-' + index}
                        producto={producto}
                        selected={producto?.productoPK?.idProducto === factura?.productEnLinea?.productoPK?.idProducto}
                        handleSelectProduct={() => handleSelectProduct(producto)}
                      />
                    ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Productos</span>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
      {!isLoadingProductos && productosFiltrados.length > 50 && (
        <div className="pagination">
          <div className="controllers">
            {currentPage > 1 && (
              <button className="previousPage" onClick={prevPage}>
                <i className="bi bi-chevron-left"></i>
              </button>
            )}
            <span>
              Página {currentPage} de {Math.ceil(productosFiltrados.length / 50)}
            </span>
            {productosFiltrados.length > 50 && !(currentPage * 50 >= productosFiltrados.length) && (
              <button className="nextPage" onClick={nextPage}>
                <i className="bi bi-chevron-right"></i>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default BuscarProductos
