/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import React, { useContext, useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'

// Import Components
import ButtonFilterType from './ButtonFilterType'

// Import Image
import searchImage from '../../../assets/search.png'

// Import Hook
import { filterResults } from '../controllers'
import Global from '../../../contexts/Global/Global'
import useProductos from 'hooks/useProductos'
import useClientes from 'hooks/useClientes'
import useFacturas from 'hooks/useFacturas'
import useDocumentos from 'hooks/useDocumentos'
import useUser from 'hooks/useUser'
import { useDispatch } from 'react-redux'
import { getClients } from 'actions/personas'
import { getProductos } from 'actions/productos'

import {
  getFacturasElectronicas,
  getFacturasElectronicasPorCompra,
  getFacturasElectronicasPorExportacion,
  getNotasDeCredito,
  getNotasDeDebito,
  getProformasElectronicas,
  getTiquetasElectronicas,
} from 'actions/facturas'
import { getDocumentosRecibidos } from 'actions/documentos'
import { useNavigate } from 'react-router-dom'

const Buscador = () => {
  const globalContext = useContext(Global)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { productos } = useProductos()
  const { clientes } = useClientes()
  const {
    facturasElectronicas,
    facturasElectronicasPorCompra,
    facturasElectronicasPorExportacion,
    tiquetasElectronicas,
    notasDeDebito,
    notasDeCredito,
    proformasElectronicas,
  } = useFacturas()
  const { documentosRecibidos } = useDocumentos()

  const [resultadosDeBusqueda, setResultadosDeBusqueda] = useState([])
  const [terminoDeBusqueda, setTerminoDeBusqueda] = useState('')
  const [sectionBuscador, setSectionBuscador] = useState('todo')
  const modalRef = useRef(null)

  const handleToggleSeccionBusquedad = (event) => {
    let target = event.target
    let resultsSeccionBusquedad = target.nextElementSibling
    if (resultsSeccionBusquedad.className.includes('hidden')) {
      resultsSeccionBusquedad.className = 'resultsSeccionBusquedad'
      target.className = 'labelSection'
    } else {
      resultsSeccionBusquedad.className = 'resultsSeccionBusquedad hidden'
      target.className = 'labelSection toggled'
    }
  }

  const toggleSearch = () => {
    let Search = document.querySelector('.Search')
    if (Search.className.includes('toggled')) {
      Search.className = 'Search'
    } else {
      Search.className = 'Search toggled'
    }
  }

  const clearSearch = () => {
    let clearSearch = document.querySelector('.clearSearch')
    clearSearch.className = 'clearSearch hidden'

    setTerminoDeBusqueda('')
    setResultadosDeBusqueda([])
  }

  const handleSearch = (event) => {
    let { value } = event.target
    let clearSearch = document.querySelector('.clearSearch')

    setTerminoDeBusqueda(value)

    if (value.length > 0) {
      clearSearch.className = 'clearSearch'

      setResultadosDeBusqueda(
        filterResults(
          [
            clientes,
            productos,
            facturasElectronicas,
            facturasElectronicasPorCompra,
            facturasElectronicasPorExportacion,
            tiquetasElectronicas,
            notasDeDebito,
            notasDeCredito,
            proformasElectronicas,
            documentosRecibidos,
          ],
          value
        )
      )
    } else {
      clearSearch.className = 'clearSearch hidden'
      setTerminoDeBusqueda('')
      setResultadosDeBusqueda([])
    }
  }

  const actionFacturaSearch = async (r) => {
    switch (+r.vtaMovmPK.idTipoDoctoVta) {
      case 2:
        navigate(`/documentosEmitidos/${r.vtaMovmPK.numDocto}`)
        break
      case 3:
        navigate(`/notasDebito`)
        break
      case 4:
        navigate(`/notasCredito`)
        break
      case 6:
        navigate(`/facturasElectronicasPorExportacion?facturaID=${r.vtaMovmPK.numDocto}`)
        break
      case 7:
        navigate(`/facturasElectronicasDeCompra?facturaID=${r.vtaMovmPK.numDocto}`)
        break
      default:
        toast.error('Ha ocurrido un error, inténtelo más tarde')
        return
    }

    toggleSearch()
    clearSearch()
  }

  const actionDocRecibidoSearch = (r) => {
    navigate(`/documentosRecibidos/${r.docRecibidosPK.id}`)
    toggleSearch()
    clearSearch()
  }

  const actionPersonaSearch = (r) => {
    navigate(`/personas?personaID=${r.clientePK.idCliente}`)
    toggleSearch()
    clearSearch()
  }

  const actionProductoSearch = (r) => {
    navigate(`/productos?productoID=${r.productoPK.idProducto}`)
    toggleSearch()
    clearSearch()
  }

  const cia = savetaxInfo?.cia?.codCia ?? null
  useEffect(() => {
    if (!clientes.length) dispatch(getClients(cia))
  }, [cia, clientes])

  useEffect(() => {
    if (!productos.length) dispatch(getProductos(cia))
  }, [productos, cia])

  useEffect(() => {
    if (!facturasElectronicas.length) dispatch(getFacturasElectronicas(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (!facturasElectronicasPorCompra.length) dispatch(getFacturasElectronicasPorCompra(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (!facturasElectronicasPorExportacion.length) dispatch(getFacturasElectronicasPorExportacion(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (!tiquetasElectronicas.length) dispatch(getTiquetasElectronicas(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (!notasDeDebito.length) dispatch(getNotasDeDebito(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (!notasDeCredito.length) dispatch(getNotasDeCredito(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (!proformasElectronicas.length) dispatch(getProformasElectronicas(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (!documentosRecibidos.length) dispatch(getDocumentosRecibidos(cia))
  }, [dispatch, cia])

  useEffect(() => {
    if (modalRef && modalRef.current) {
      modalRef.current.addEventListener('click', (e) => {
        if (e.target.className.includes('Search')) {
          toggleSearch()
          clearSearch()
        }
      })
    }
  }, [modalRef])

  return (
    <div className="Search toggled" role="button" ref={modalRef}>
      <div className="modalSearch">
        <div className="searchInput">
          <i className="bi bi-search"></i>
          <input value={terminoDeBusqueda} type="text" placeholder="Buscar" onChange={handleSearch} />
          <button className="clearSearch hidden" onClick={clearSearch}>
            <i className="bi bi-x-lg"></i>
          </button>
        </div>

        {resultadosDeBusqueda?.length > 0 && (
          <div className="capsulas">
            <div className="carrusel">
              <ButtonFilterType
                filter="todo"
                label="Todos"
                setSectionBuscador={setSectionBuscador}
                sectionBuscador={sectionBuscador}
              />
              {resultadosDeBusqueda.map(
                (s) =>
                  s.results?.length > 0 && (
                    <ButtonFilterType
                      filter={s.filter}
                      label={s.label}
                      setSectionBuscador={setSectionBuscador}
                      sectionBuscador={sectionBuscador}
                    />
                  )
              )}
            </div>
          </div>
        )}

        {resultadosDeBusqueda?.length > 0 && (
          <div className="resultadosPorSecciones">
            {resultadosDeBusqueda.map(
              (s) =>
                s.results?.length > 0 &&
                (sectionBuscador === s.filter || sectionBuscador === 'todo') && (
                  <div className="seccionBusquedad">
                    <div className="labelSection" onClick={handleToggleSeccionBusquedad}>
                      <h4>{s.label}</h4>
                      <hr />
                      <button className="dropdownButton">
                        <i className="bi bi-chevron-down"></i>
                      </button>
                    </div>

                    <div className="resultsSeccionBusquedad">
                      {s.results.map((r) => (
                        <>
                          {s.filter === 'documentosRecibidos' && (
                            <div className="itemDeSeccion" onClick={() => actionDocRecibidoSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-file-earmark-text"></i>
                              </div>
                              <div className="info">
                                <h5>
                                  {r?.emisor} #{r?.docRecibidosPK?.id}
                                </h5>
                                <span>{r?.tipoDoctoVta?.nombreDoctoVta}</span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.totalFactura && `${r?.idMoneda.signo} ${r?.totalFactura?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision).format('L')}</span>
                                {r?.descripcionSri === 'ACEPTADO' ? (
                                  <span className="aceptadoStatus">ACEPTADO</span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'facturaElectronica' && (
                            <div className="itemDeSeccion" onClick={() => actionFacturaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-receipt-cutoff"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.cliente?.nombreCliente || 'Cliente no definido'}</h5>
                                <span>
                                  {r?.prefijo} {r?.referencia}
                                </span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.total && `${r?.idMoneda?.signo} ${r?.total?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision).format('L')}</span>
                                {r?.descripcionSri ? (
                                  <span className={r?.descripcionSri === 'ACEPTADO' ? 'aceptadoStatus' : 'pendienteStatus'}>
                                    {r?.descripcionSri}
                                  </span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'tiquetaElectronica' && (
                            <div className="itemDeSeccion" onClick={() => actionFacturaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-receipt-cutoff"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.cliente?.nombreCliente || 'Cliente no definido'}</h5>
                                <span>
                                  {r?.prefijo} {r?.referencia}
                                </span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.total && `${r?.idMoneda.signo} ${r?.total?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision).format('L')}</span>
                                {r.descripcionSri ? (
                                  <span className={r?.descripcionSri === 'ACEPTADO' ? 'aceptadoStatus' : 'pendienteStatus'}>
                                    {r?.descripcionSri}
                                  </span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'facturaElectronicaCompra' && (
                            <div className="itemDeSeccion" onClick={() => actionFacturaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-receipt-cutoff"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.cliente?.nombreCliente}</h5>
                                <span>
                                  {r?.prefijo} {r?.referencia}
                                </span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.total && `${r?.idMoneda?.signo} ${r?.total?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision).format('L')}</span>
                                {r.descripcionSri ? (
                                  <span className={r?.descripcionSri === 'ACEPTADO' ? 'aceptadoStatus' : 'pendienteStatus'}>
                                    {r?.descripcionSri}
                                  </span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'notaCredito' && (
                            <div className="itemDeSeccion" onClick={() => actionFacturaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-receipt-cutoff"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.cliente?.nombreCliente}</h5>
                                <span>
                                  {r?.prefijo} {r?.referencia}
                                </span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.total && `${r?.idMoneda?.signo} ${r?.total?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision).format('L')}</span>
                                {r.descripcionSri ? (
                                  <span className={r.descripcionSri === 'ACEPTADO' ? 'aceptadoStatus' : 'pendienteStatus'}>
                                    {r.descripcionSri}
                                  </span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'notaDebito' && (
                            <div className="itemDeSeccion" onClick={() => actionFacturaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-receipt-cutoff"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.cliente?.nombreCliente}</h5>
                                <span>
                                  {r?.prefijo} {r?.referencia}
                                </span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.total && `${r?.idMoneda?.signo} ${r?.total?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision)?.format('L')}</span>
                                {r?.descripcionSri ? (
                                  <span className={r?.descripcionSri === 'ACEPTADO' ? 'aceptadoStatus' : 'pendienteStatus'}>
                                    {r?.descripcionSri}
                                  </span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'proformaElectronica' && (
                            <div className="itemDeSeccion" onClick={() => actionFacturaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-receipt-cutoff"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.cliente?.nombreCliente}</h5>
                                <span>
                                  {r?.prefijo} {r?.referencia}
                                </span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.total && `${r?.idMoneda?.signo} ${r?.total?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision).format('L')}</span>
                                {r?.descripcionSri ? (
                                  <span className={r?.descripcionSri === 'ACEPTADO' ? 'aceptadoStatus' : 'pendienteStatus'}>
                                    {r?.descripcionSri}
                                  </span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'facturaElectronicaExportacion' && (
                            <div className="itemDeSeccion" onClick={() => actionFacturaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-receipt-cutoff"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.cliente?.nombreCliente}</h5>
                                <span>
                                  {r?.prefijo} {r?.referencia}
                                </span>
                              </div>
                              <div className="metadata">
                                <span className="priceFacturaSearch">
                                  {r?.total && `${r?.idMoneda.signo} ${r?.total?.toLocaleString('es')}`}
                                </span>
                                <span className="fechaEmision">{r?.fechaEmision && moment(r?.fechaEmision).format('L')}</span>
                                {r?.descripcionSri ? (
                                  <span className={r?.descripcionSri === 'ACEPTADO' ? 'aceptadoStatus' : 'pendienteStatus'}>
                                    {r?.descripcionSri}
                                  </span>
                                ) : (
                                  <span className="pendienteStatus">PENDIENTE</span>
                                )}
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'personas' && (
                            <div className="itemDeSeccion" onClick={() => actionPersonaSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-people"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.nombreCliente}</h5>
                                <span>{r?.ruc}</span>
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}

                          {s.filter === 'productos' && (
                            <div className="itemDeSeccion" onClick={() => actionProductoSearch(r)}>
                              <div className="iconItem">
                                <i className="bi bi-box-seam"></i>
                              </div>
                              <div className="info">
                                <h5>{r?.nombreProducto}</h5>
                                <span>{r?.cabys?.descripcion}</span>
                              </div>
                              <button className="enterButton">
                                <i className="bi bi-arrow-return-left"></i>
                              </button>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        {resultadosDeBusqueda?.length === 0 && (
          <div className="resultSearch">
            <div className="emptyResult">
              <img src={searchImage} alt="" />
              <h4>No hay resultados</h4>
            </div>
          </div>
        )}

        <div className="commands">
          <div className="keyContainer">
            <span className="key">esc</span>
            <span className="keyIntruction">Cerrar Buscador</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Buscador
