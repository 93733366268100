import {
  GET_FACTURAS_ELECTRONICAS,
  GET_FACTURAS_ELECTRONICAS_POR_COMPRA,
  GET_FACTURAS_ELECTRONICAS_POR_EXPORTACION,
  GET_TIQUETAS_ELECTRONICAS,
  GET_NOTAS_DE_CREDITO,
  GET_NOTAS_DE_DEBITO,
  GET_PROFORMAS_ELECTRONICAS,
  CREATE_FACTURA_ELECTRONICA,
  CREATE_TIQUETA_ELECTRONICA,
  CREATE_FACTURA_EXPORTACION,
  CREATE_FACTURA_COMPRA,
  CREATE_FACTURA_PROFORMA,
  DELETE_FACTURA_ELECTRONICA,
} from '../constants/actionTypes'

const initialState = {
  facturasElectronicas: [],
  facturasElectronicasPorCompra: [],
  facturasElectronicasPorExportacion: [],
  tiquetasElectronicas: [],
  notasDeCredito: [],
  notasDeDebito: [],
  proformasElectronicas: [],
  isLoadingFacturasElectronicas: true,
  isLoadingFacturasElectronicasPorCompra: true,
  isLoadingFacturasElectronicasPorExportacion: true,
  isLoadingTiquetasElectronicas: true,
  isLoadingNotasDeCredito: true,
  isLoadingNotasDeDebito: true,
  isLoadingProformasElectronicas: true,
}

const handles = {
  [GET_FACTURAS_ELECTRONICAS]: (state, { payload = {} }) => {
    return { ...state, isLoadingFacturasElectronicas: false, facturasElectronicas: payload?.facturasElectronicas }
  },
  [GET_FACTURAS_ELECTRONICAS_POR_COMPRA]: (state, { payload = {} }) => {
    return {
      ...state,
      isLoadingFacturasElectronicasPorCompra: false,
      facturasElectronicasPorCompra: payload?.facturasElectronicasPorCompra,
    }
  },
  [GET_FACTURAS_ELECTRONICAS_POR_EXPORTACION]: (state, { payload = {} }) => {
    return {
      ...state,
      isLoadingFacturasElectronicasPorExportacion: false,
      facturasElectronicasPorExportacion: payload?.facturasElectronicasPorExportacion,
    }
  },
  [GET_TIQUETAS_ELECTRONICAS]: (state, { payload = {} }) => {
    return { ...state, isLoadingTiquetasElectronicas: false, tiquetasElectronicas: payload?.tiquetasElectronicas }
  },
  [GET_NOTAS_DE_CREDITO]: (state, { payload = {} }) => {
    return { ...state, isLoadingNotasDeCredito: false, notasDeCredito: payload?.notasDeCredito }
  },
  [GET_NOTAS_DE_DEBITO]: (state, { payload = {} }) => {
    return { ...state, isLoadingNotasDeDebito: false, notasDeDebito: payload?.notasDeDebito }
  },
  [GET_PROFORMAS_ELECTRONICAS]: (state, { payload = {} }) => {
    return { ...state, isLoadingProformasElectronicas: false, proformasElectronicas: payload?.proformasElectronicas }
  },
  [CREATE_FACTURA_ELECTRONICA]: (state, { payload = {} }) => ({
    ...state,
    facturasElectronicas: [payload?.data, ...state.facturasElectronicas],
  }),
  [CREATE_TIQUETA_ELECTRONICA]: (state, { payload = {} }) => ({
    ...state,
    tiquetasElectronicas: [payload?.data, ...state.tiquetasElectronicas],
  }),
  [CREATE_FACTURA_EXPORTACION]: (state, { payload = {} }) => ({
    ...state,
    facturasElectronicasPorExportacion: [payload?.data, ...state.facturasElectronicasPorExportacion],
  }),
  [CREATE_FACTURA_COMPRA]: (state, { payload = {} }) => ({
    ...state,
    facturasElectronicasPorCompra: [payload?.data, ...state.facturasElectronicasPorCompra],
  }),
  [CREATE_FACTURA_PROFORMA]: (state, { payload = {} }) => ({
    ...state,
    proformasElectronicas: [payload?.data, ...state.proformasElectronicas],
  }),
  [DELETE_FACTURA_ELECTRONICA]: (state, { payload = {} }) => {
    const returnFacturas = (facturas) =>
      facturas.map((f) =>
        payload?.factura?.vtaMovmPK?.numDocto === f.vtaMovmPK.numDocto &&
        payload?.factura?.vtaMovmPK?.idSucursal === f.vtaMovmPK.idSucursal
          ? payload?.factura
          : f
      )

    switch (+payload?.factura?.vtaMovmPK?.idTipoDoctoVta) {
      case 1:
        return {
          ...state,
          proformasElectronicas: returnFacturas(state.proformasElectronicas),
        }
      case 2:
        return {
          ...state,
          facturasElectronicas: returnFacturas(state.facturasElectronicas),
        }
      case 5:
        return {
          ...state,
          tiquetasElectronicas: returnFacturas(state.tiquetasElectronicas),
        }
      case 6:
        return {
          ...state,
          facturasElectronicasPorExportacion: returnFacturas(state.facturasElectronicasPorExportacion),
        }
      case 7:
        return {
          ...state,
          facturasElectronicasPorCompra: returnFacturas(state.facturasElectronicasPorCompra),
        }
      default:
        return { ...state }
    }
  },
}

const facturas = (state = initialState, action) => {
  const { type } = action

  return handles[type] ? handles[type](state, action) : state
}

export default facturas
