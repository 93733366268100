const initiazeGlobal = {
  section: '',
  titleSection: '',
  pathHelp: '',
  formAvailable: false,
  HelpPageAvailable: false,
  formData: {
    action: 'create',
    sucursal: {},
  },
  pathNext: false,
  base64PDF: '',
  base64XML: '',
  pathBefore: false,
  indexPDF: 0,
  user: {},
  cabys: [],
  userSavetax: {},
  isLogin: false,
  userTransfer: {},
  conf: {
    schemaColor: localStorage.getItem('schemaColor'),
  },
}

export { initiazeGlobal }
