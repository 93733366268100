/* Import Modules */
import React from 'react'
import {
  WhatsappShareButton,
  TelegramShareButton,
  TwitterShareButton,
  FacebookShareButton,
  LineShareButton,
  WorkplaceShareButton,
  LinkedinShareButton,
  WhatsappIcon,
  TelegramIcon,
  TwitterIcon,
  LineIcon,
  FacebookIcon,
  WorkplaceIcon,
  LinkedinIcon,
} from 'react-share'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// Import Hooks
import useUser from 'hooks/useUser'

/* Import Stylesheet */
import './ShareDocumento.css'

import { getURL } from 'api/apiInstance'


const ShareDocumento = ({ title }) => {
  const { savetaxInfo } = useUser()
  const params = useParams()

  const closeModal = () => {
    const ShareDocumentoModal = document.querySelector('.ShareDocumentoModal')
    ShareDocumentoModal.className = 'ShareDocumentoModal toggled'
  }

  const copyTextToClipboard = () => {
    var text = document.querySelector('.copyContainer span').textContent
    navigator.clipboard.writeText(text).then(
      function () {
        toast.success('URL del documento fue copiado')
      },
      function (err) {
        toast.error('ERROR al copiar la URL del documento')
        console.log(err)
      }
    )
  }

  return (
    <div className="ShareDocumentoModal toggled">
      <div className="cardShare">
        <div className="navCard">
          <h3>Compartir</h3>
          <button className="closeModalBtn" onClick={closeModal}>
            <i className="bi bi-x-lg"></i>
          </button>
        </div>
        <div className="optionsShareContainer">
          
          <WhatsappShareButton
            title={`Compartir ${title}`}
            separator=" "
            url={`${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.usuarioPK.codCia}/${params.idDocumento}`}
          >
            <WhatsappIcon size={48} round={true} />
          </WhatsappShareButton>

          <TelegramShareButton
            title={`Compartir ${title}`}
            url={`${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.usuarioPK.codCia}/${params.idDocumento}`}
          >
            <TelegramIcon size={48} round={true} />
          </TelegramShareButton>

          <TwitterShareButton
            title={`Compartir ${title}`}
            url={`${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.usuarioPK.codCia}/${params.idDocumento}`}
          >
            <TwitterIcon size={48} round={true} />
          </TwitterShareButton>

          <LineShareButton
            title={`Compartir ${title}`}
            url={`${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.usuarioPK.codCia}/${params.idDocumento}`}
          >
            <LineIcon size={48} round={true} />
          </LineShareButton>

          <LinkedinShareButton
            title={`Compartir ${title}`}
            url={`${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.usuarioPK.codCia}/${params.idDocumento}`}
          >
            <LinkedinIcon size={48} round={true} />
          </LinkedinShareButton>

          <FacebookShareButton
            quote={`Compartir ${title}`}
            url={`${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.usuarioPK.codCia}/${params.idDocumento}`}
          >
            <FacebookIcon size={48} round={true} />
          </FacebookShareButton>

          <WorkplaceShareButton
            quote={`Compartir ${title}`}
            url={`${getURL("documentos")}doc-recibido/pdf/${savetaxInfo.usuarioPK.codCia}/${params.idDocumento}`}
          >
            <WorkplaceIcon size={48} round={true} />
          </WorkplaceShareButton>
        </div>
        <div className="urlDocumentContainer">
          <h3>Enlace de documento</h3>
          <div className="copyContainer">
            <span>
              {getURL("documentos")}doc-recibido/pdf/{savetaxInfo.usuarioPK.codCia}/
              {params.idDocumento}
            </span>
            <button className="copyClipboardBtn" onClick={copyTextToClipboard}>
              <i className="bi bi-files"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareDocumento
