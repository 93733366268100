import axios from 'axios'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { motion } from 'framer-motion'

const ValidarCedula = ({ form, handleChange, stepUp, setForm, variants }) => {
  const { persona } = form ?? {}
  const [validando, setValidando] = useState(false)

  const validCedula = async () => {
    setValidando(true)
    try {
      const response = await axios.get(`https://api.hacienda.go.cr/fe/ae?identificacion=${persona.ruc}`)
      if (response.data.tipoIdentificacion === '01') {
        toast.success(`Validación satisfactoria de ${response.data.nombre}`)
        setForm((prev) => ({
          ...prev,
          persona: {
            ...prev.persona,
            nombreCia: response.data.nombre,
            tipoPersona: +response.data.tipoIdentificacion,
          },
        }))
        stepUp()
      } else {
        toast.error('Esta cédula es una persona juridica y por el momento no es valida para adquirir una cuenta')
      }

    } catch (error) {
      console.error(error.message)
      toast.error('Esta cédula no es válida para adquirir una cuenta')
    }
    setValidando(false)
  }

  return (
    <motion.div initial={variants.initial} animate={variants.animate} exit={variants.exit} transition={variants.transition}>
      <div className="Form registroForm">
        <div id="carruselRegistro" className="carrusel step01">
          <div className="slide validcedulaSlide">
            <div className="steps">
              <span>
                Paso <b>1</b> de 2
              </span>
            </div>
            <h4>Validar Número de Cédula</h4>
            <p>Para crear su cuenta ingrese su número de cédula y valídela luego complete los datos que se le solicitarán.</p>

            <label htmlFor="cedula" className="text validCedula" style={{ width: '100%' }}>
              <input
                name="persona.ruc"
                value={persona.ruc}
                onChange={handleChange}
                className="validCedulaInput"
                placeholder="Número de Cedula"
                type="text"
                id="cedula"
              />
            </label>

            <p className="registerLink noneMargin" style={{ width: '100%' }}>
              ¿Ya tienes una cuenta? <Link to="/ingresar">Ingresar</Link>
            </p>
          </div>
        </div>
      </div>

      <div className="actionFixed">
        {validando ? (
          <CircularProgress />
        ) : (
          <button className="btnRegistro" onClick={validCedula} disabled={!persona.ruc}>
            Validar Cédula
          </button>
        )}
      </div>
    </motion.div>
  )
}

export default ValidarCedula
