// Import Modules
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Import Handlers
import { getDocumentosRecibidos } from 'actions/documentos'

// Import Context
import useUser from 'hooks/useUser'
import useDocumentos from 'hooks/useDocumentos'
import { useDispatch } from 'react-redux'
import pushNotification from '../../assets/pushNotification.jpg'

const DocumentosRecibidosResumen = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { documentosRecibidos, isLoadingDocumentosRecibidos } = useDocumentos()
  const [documentosRecibidosFiltrados, setDoucumentosRecibidosFiltrados] = useState([])

  useEffect(() => {
    if (isLoadingDocumentosRecibidos && savetaxInfo) {
      dispatch(getDocumentosRecibidos(savetaxInfo.cia.codCia))
    }

    setDoucumentosRecibidosFiltrados(documentosRecibidos)
  }, [dispatch, documentosRecibidos, savetaxInfo, isLoadingDocumentosRecibidos])

  return (
    <>
      <div className="DocumentosRecibidos DocumentosRecibidosResumen">
        <div className="listSection">
          {isLoadingDocumentosRecibidos ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Documentos Recibidos</span>
            </div>
          ) : (
            <div className="bodyList">
              {documentosRecibidosFiltrados.length > 0 && (
                <h4 style={{ marginBottom: '1em', fontWeight: 'normal' }}>
                  <Link to="/documentosRecibidos">Documentos Recibidos Recientes</Link>
                </h4>
              )}
              <ul>
                {documentosRecibidosFiltrados.length > 0 &&
                  documentosRecibidosFiltrados.slice(0, 6).map((doc, index) => (
                    <li className="itemBodyList" key={`documento-recibido-${index}`}>
                      <Link to={`/documentosRecibidos/${doc.docRecibidosPK.id}`}>
                        <ul className="contentItemBodyList">
                          <li className="col-8 list">
                            <span className={doc.descripcionSri ? 'done' : 'panding'}>
                              {doc.descripcionSri ? doc.descripcionSri : 'PENDIENTE'}
                            </span>
                          </li>
                          <li className="col-2 list">
                            <span>
                              {new Date(doc.fechaEmision).getDate()}/{new Date(doc.fechaEmision).getMonth() + 1}/
                              {new Date(doc.fechaEmision).getFullYear()}
                            </span>
                          </li>
                          <li className="col-9 list" style={{ margin: '0.5em 0em', fontSize: '1em' }}>
                            <b>{doc.emisor}</b>
                          </li>
                          <li className="col-5 list">
                            <span>
                              {doc.idMoneda.nombreCorto} {doc.totalFactura.toLocaleString('es')}
                            </span>
                          </li>
                          <li className="col-5 list">
                            <span>{doc.tipoDoctoVta.nombreDoctoVta}</span>
                          </li>
                        </ul>
                      </Link>
                    </li>
                  ))}

                {!documentosRecibidosFiltrados.length && (
                  <div className="emptyNotifications">
                    <img src={pushNotification} alt="" />
                    <h4>No hay Notificaciones Pendientes</h4>
                  </div>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DocumentosRecibidosResumen
