import React from 'react'
import './Form.css'
import { ReactComponent as Question } from 'assets/question.svg'
import { ReactComponent as Cancel } from 'assets/cancel.svg'
import { Link } from 'react-router-dom'

const Form = ({ title, children, onClose, link }) => {
  return (
    <div className="FormContainer">
      <div className="Form">
        <div className="FormTopBar">
          <div className="FormTitle">
            <h4>{title}</h4>
          </div>

          <div className="optionsFormTopBar">
            <Link to={link} className="helpForm" tabIndex="-1" target="_blank">
              <Question />
            </Link>
            <button className="closeForm" onClick={onClose} tabIndex="-1">
              <Cancel />
            </button>
          </div>
        </div>
        <div className="FormContent">{children}</div>
      </div>
    </div>
  )
}

export default Form
