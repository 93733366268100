/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import { useEffect } from 'react'

// Import Hooks
import { useNavigate } from 'react-router-dom'
import useUser from '../../hooks/useUser'

const AssignRouteIndex = () => {
  const { user, isLoadingUser } = useUser()
  let navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/escritorio')
    } else {
      navigate('/ingresar')
    }
  }, [isLoadingUser])
  return null
}

export default AssignRouteIndex
