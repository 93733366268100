import { DELETE_CIA, GET_MAX_COD_CIA, SAVE_CIA } from 'backend/queries/CiaQuery'
import * as ApolloGraphqlHelper from '../helpers/ApolloGraphqlHelper'

export class CiaService {
  clientApHasura = null

  constructor() {
    this.clientApHasura = ApolloGraphqlHelper.setAuthInitMiddleware(null)
  }

  delete(codCia) {
    return new Promise((resolve, reject) => {
      this.clientApHasura
        .mutate({
          mutation: DELETE_CIA,
          variables: {
            codCia: codCia,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error)
        })
    })
  }

  getMaxCode() {
    return new Promise((resolve, reject) => {
      this.clientApHasura
        .query({
          query: GET_MAX_COD_CIA,
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error)
        })
    })
  }

  save(data) {
    return new Promise((resolve, reject) => {
      this.clientApHasura
        .mutate({
          mutation: SAVE_CIA,
          variables: {
            data: data,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error)
        })
    })
  }
}
