/* Import Modules */
import React, { useState, useEffect } from "react"
import { auth, db } from "./firebase"
import { ToastContainer } from "react-toastify"
import { Outlet } from "react-router-dom"

/* Import Images */
import movilApp from "./assets/appMovil.png"
import logo from "./assets/logo_black.png"
import screen01 from "./assets/appScreen01.webp"
import screen02 from "./assets/appScreen02.webp"
import screen03 from "./assets/appScreen03.webp"
import screen04 from "./assets/appScreen04.webp"
import screen05 from "./assets/appScreen05.webp"
import screen06 from "./assets/appScreen06.webp"

/* Import Handlers */
import { subscribeUser } from "subscription"
import { setUser, getSavetaxInfo } from "./actions/users"
import { getDatosGeograficos } from "actions/misc"
/*  */
/* Import Components */
import SideBar from "./components/SideBar/SideBar"
import TopBar from "./components/TopBar/TopBar.jsx"
import Load from "./components/Load/Load"
import AlertPermissionNotification from "components/TopBar/AlertPermissionNotification/AlertPermissionNotification"

/* Import Context */
import { initiazeGlobal } from "./contexts/Global/initialize"
import Global from "./contexts/Global/Global"

/* Import Hooks */
import useUser from "./hooks/useUser"
import { useDispatch } from "react-redux"

/* Import StyleSheet */
import "bootstrap-icons/font/bootstrap-icons.css"
import "react-toastify/dist/ReactToastify.css"
import "react-toggle/style.css"
import "./App.css"
import CabysLoader from "components/CabysLoader/CabysLoader"

function App() {
  const dispatch = useDispatch()
  const { user, isLoadingUser } = useUser()

  const openForm = () => {
    let FormContainer = document.querySelector(".FormContainer")
    FormContainer.className = "FormContainer"
  }

  const handleUpdateGlobalContext = (name, value) => {
    setGlobal((prevState) => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const [global, setGlobal] = useState({
    ...initiazeGlobal,
    updateGlobalContext: handleUpdateGlobalContext,
    openForm: openForm,
  })

  useEffect(() => {
    dispatch(getDatosGeograficos)
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setUser(user))
          .then(() => {
            return getSavetaxInfo(user.email)
          })
          .then((response) => {
            return response.data
          })
          .then((dataUser) => {
            db.collection("notifications")
              .doc(dataUser.usuarioPK.codCia.toString())
              .onSnapshot((doc) => {
                dispatch(setUser(user))
              })
            if (Notification.permission === "granted")
              subscribeUser(dataUser.usuarioPK.codCia.toString())
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        dispatch(setUser(null))
      }
    })
  }, [dispatch])

  return isLoadingUser ? (
    <Load />
  ) : (
    <Global.Provider value={global}>
      <div
        className={`App 
        ${
          global.conf.schemaColor === null
            ? "schema_predeterminado"
            : global.conf.schemaColor
        }
        ${global.conf.fontSize === null ? "fontSize16" : global.conf.fontSize}`}
      >
        {/* {user && <AlertPermissionNotification />} */}
        {user && <SideBar />}
        <div className={user ? "mainViewer" : "mainViewerLogin"}>
          {user && <TopBar />}
          <Outlet />
        </div>
      </div>
      <CabysLoader />
      <ToastContainer />
      {/* <div className="movilScreen">
        <div className="navRegistro">
          <div className="logo">
            <a href="https://play.google.com/store/apps/details?id=sv.com.savetaxcr&hl=es_CO&gl=US">
              <img src={logo} alt="Logotipo de Savetax" />
            </a>
          </div>
          <a href="ingresar">CONTACTENOS</a>
        </div>
        <div className="mainMovilScreen">
          <img src={movilApp} alt="Movil App" />
          <h3>USA NUESTRO APP MOVIL</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
            Reprehenderit, commodi adipisci. Amet deserunt in assumenda
            necessitatibus repudiandae dolore nesciunt nostrum!
          </p>
          <a href="https://play.google.com/store/apps/details?id=sv.com.savetaxcr&hl=es_CO&gl=US">
            INSTALAR APP MOVIL
            <i className="bi bi-cloud-arrow-down-fill"></i>
          </a>
        </div>
        <div className="gridMovilScreen">
          <img src={screen01} alt="" />
          <img src={screen02} alt="" />
          <img src={screen03} alt="" />
          <img src={screen04} alt="" />
          <img src={screen05} alt="" />
          <img src={screen06} alt="" />
        </div>
      </div> */}
    </Global.Provider>
  )
}

export default App
