// Import Modules
import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { compraPaquete, eliminarPaquete } from 'actions/administracion'
import { useNavigate } from "react-router-dom"


// Import Components
import ToogleShowNotifications from './ToogleShowNotification/ToogleShowNotifications'
import IndexesConf from './IndexesConf/IndexesConf'
import Formats from './Formats/Formats'

// Import Hooks
import useUser from 'hooks/useUser'
import { getTiposDocs } from 'actions/misc'
import { useDispatch } from 'react-redux'
import useMisc from 'hooks/useMisc'

// Import Contexts
import Global from '../../contexts/Global/Global'

// Import StyleSheet
import './Configuraciones.css'
import { getInstance, getURL } from 'api/apiInstance'
import { GET_USER } from 'constants/actionTypes'
import { getSavetaxInfo, setUser } from 'actions/users'

const Configuraciones = () => {
  const globalContext = useContext(Global)
  const { savetaxInfo } = useUser()
  const dispatch = useDispatch()
  const { tiposDocs } = useMisc()
  const navigate = useNavigate()
  const [paises, setPaises] = useState([])
  const [provincias, setProvincias] = useState([])
  const [cantones, setCantones] = useState([])
  const [distritos, setDistritos] = useState([])
  const [barrios, setBarrios] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const setColorTheme = (value) => {
    localStorage.setItem('schemaColor', value)
    globalContext.updateGlobalContext('conf', {
      ...globalContext.conf,
      schemaColor: value,
    })
  }



  useEffect(() => {

    const fetchMetadata = async (user) => {
      const request = await getInstance()

      const endpoints = [
        `${getURL()}pais`,
        `${getURL()}departamento/find-by-pais/${user.cia.pais.idPais}`,
        `${getURL()}municipio/find-by-depto/${user.cia.departamento.idDepartamento}`,
        `${getURL()}distrito/find-by-municipio/${user.cia.idMunicipio.idMunicipio}`,
        `${getURL()}barrio/find-by-distrito/${user.cia.distrito.idDistrito}`,
      ]

      const [paises, provincias, cantones, distritos, barrios] = await axios.all(endpoints?.map((url) => axios.get(url)))

      setPaises(paises.data)
      setProvincias(provincias.data)
      setCantones(cantones.data)
      setDistritos(distritos.data)
      setBarrios(barrios.data)
      console.log('Entro a actualizar info')
      const savetaxInfo = await getSavetaxInfo({
        email: user.cia.ruc,
        ruc: user.cia.ruc,
      })
      //const notifications = await getNotifications(savetaxInfo ? savetaxInfo.data.username : null)
      dispatch({
        type: GET_USER,
        payload: {
          user,
          savetaxInfo: savetaxInfo?.data ?? null,
          //notifications: notifications?.data ?? null,
          notifications: null,
        },
      })
      console.log(savetaxInfo)
      setUser(savetaxInfo)
      setIsLoading(false)
    }
    if (isLoading) {
      fetchMetadata(savetaxInfo)
    }

  }, [dispatch, isLoading, savetaxInfo])

  useEffect(() => {
    if (!tiposDocs.length) {
      dispatch(getTiposDocs(savetaxInfo.cia.codCia))
    }
  }, [dispatch, savetaxInfo, tiposDocs])

  const handleSubmit = async () => {
    const response = await dispatch(
      eliminarPaquete({
        idPaquete: '1',
        codCia: savetaxInfo?.cia?.codCia,
      }))
    if (response) {
      navigate('/escritorio')
    }
  }

  return (
    <div className="Configuraciones">
      <div className="mainConf">
        <div className="sectionMainConf" id="informacionGeneral">
          <h4>Información General</h4>
          <div className="confSection">
            <div className="presentProfile">
              <div className="present">
                <div className="avatar">
                  <i className="bi bi-person-circle"></i>
                </div>
                <div className="infoUser">
                  <h4>{savetaxInfo?.nombreUsuario}</h4>
                  <p>{savetaxInfo?.cia?.correoElectronico}</p>
                </div>
              </div>
              <button className="deleteAccount" hidden={true}>Eliminar cuenta</button>
            </div>
            <div className="labelConf">
              <span>Nombre Completo</span>
              <input defaultValue={savetaxInfo?.nombreUsuario} type="text" placeholder="Nombre Completo" />
            </div>
            <div className="labelConf">
              <span>Tipo de Identificacion</span>
              <select value={savetaxInfo?.cia.idTipoDoctoPersona} name="" id="">
                <option defaultValue="">Seleccionar</option>
                {tiposDocs?.map((t) => (
                  <option
                    value={t.tipoDoctoPersonaPK.idTipoDoctoPersona}>{t.nombreDoctoPersona}</option>
                ))}
              </select>
            </div>
            <div className="labelConf">
              <span>Número de Identificacion</span>
              <input defaultValue={savetaxInfo?.cia.ruc} type="text" placeholder="Número de Identificacion" />
            </div>
            <div className="labelConf">
              <span>Telefono</span>
              <input defaultValue={savetaxInfo?.cia.telefonos} type="text" placeholder="Telefono" />
            </div>
            <div className="labelConf">
              <span>Correo Electronico</span>
              <input defaultValue={savetaxInfo?.cia.correoElectronico} type="text" placeholder="Correo Electrinico" />
            </div>
            <div className="labelConf">
              <span>Pais</span>
              <select value={savetaxInfo?.cia.pais.idPais} name="" id="">
                <option defaultValue="">Seleccionar</option>
                {paises?.map((p) => (
                  <option key={uuidv4()} value={p.idPais}>
                    {p.nombrePais}
                  </option>
                ))}
              </select>
            </div>
            <div className="labelConf">
              <span>Provincia</span>
              <select value={savetaxInfo?.cia.departamento.idDepartamento} name="" id="">
                <option defaultValue="">Seleccionar</option>
                {provincias?.map((p) => (
                  <option key={uuidv4()} value={p.idDepartamento}>
                    {p.nombreDepartamento}
                  </option>
                ))}
              </select>
            </div>
            <div className="labelConf">
              <span>Canton</span>
              <select value={savetaxInfo?.cia.idMunicipio.idMunicipio} name="" id="">
                <option value="">Seleccionar</option>
                {cantones?.map((c) => (
                  <option key={uuidv4()} value={c.idMunicipio}>
                    {c.nombreMunicipio}
                  </option>
                ))}
              </select>
            </div>
            <div className="labelConf">
              <span>Distrito</span>
              <select value={savetaxInfo?.cia.distrito.idDistrito} name="" id="">
                <option value="">Seleccionar</option>
                {distritos?.map((d) => (
                  <option key={uuidv4()} value={d.idDistrito}>
                    {d.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="labelConf">
              <span>Barrio</span>
              <select value={savetaxInfo?.cia.barrio.idBarrio} name="" id="">
                <option value="">Seleccionar</option>
                {barrios?.map((b) => (
                  <option key={uuidv4()} value={b.idBarrio}>
                    {b.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="labelConf">
              <div className="complateLabel">
                <span>Dirección</span>
                <textarea value={`${savetaxInfo?.cia.direccion}`} name="" id="" cols="30" rows="2">
                  {/* {savetaxInfo.cia.direccion} */}
                </textarea>
              </div>
            </div>
            <div className="labelConf">
              <div className="complateLabel">
                <span>Correos Adicionales</span>
                <input value="" type="text" placeholder="Correos Adicionales" />
              </div>
            </div>

            <div className="labelConf">
              <div className="complateLabel">
                <span>Plan</span>
                <input value={`${savetaxInfo?.cia.tipoContribuyente == 1 ? 'Plan Asistido' : 'Plan Gratuito'}`}
                  type="text" placeholder="Correos Adicionales" disabled="true" />
              </div>
            </div>

            <button className="saveConf" hidden={savetaxInfo?.cia.tipoContribuyente != 1}
             onClick={handleSubmit}  >Desactivar Plan</button>
          </div>
        </div>

        <div className="sectionMainConf" id="apariencia">
          <h4>Apariencia</h4>
          <div className="confSection">
            <div className="labelConf">
              <span>Tamaño de Tipografia</span>
              <select
                defaultValue={globalContext.conf.fontSize}
                onChange={(event) => {
                  globalContext.updateGlobalContext('conf', {
                    ...globalContext.conf,
                    fontSize: event.target.value,
                  })
                  localStorage.setItem('fontSize', event.value)
                }}
                name=""
                id=""
              >
                <option key={uuidv4()} defaultValue="fontSize18">
                  18px
                </option>
                <option key={uuidv4()} defaultValue="fontSize16">
                  16px
                </option>
                <option key={uuidv4()} defaultValue="fontSize14">
                  14px
                </option>
                <option key={uuidv4()} defaultValue="fontSize12">
                  12px
                </option>
              </select>
            </div>
            <div className="labelConf">
              <div className="complateLabel">
                <span>Thema Color</span>
                <div className="gridColors">
                  <div
                    onClick={() => setColorTheme('schema_predeterminado')}
                    className={`colorItem ${globalContext.conf.schemaColor === 'schema_predeterminado' && 'active'}`}
                    title="Color Predeterminado"
                    style={{ background: '#eee' }}
                  >
                    <div className="containerPrimary">
                      <div className="primary" style={{ background: '#fff' }}></div>
                    </div>
                    <i className="bi bi-check-circle-fill"></i>
                  </div>

                  <div
                    onClick={() => setColorTheme('schema_negro')}
                    className={`colorItem ${globalContext.conf.schemaColor === 'schema_negro' && 'active'}`}
                    title="Negro"
                    style={{ background: '#363535' }}
                  >
                    <div className="containerPrimary">
                      <div className="primary" style={{ background: '#000' }}></div>
                    </div>
                    <i className="bi bi-check-circle-fill"></i>
                  </div>

                  <div
                    onClick={() => setColorTheme('schema_azul_noche')}
                    className={`colorItem ${globalContext.conf.schemaColor === 'schema_azul_noche' && 'active'}`}
                    title="Azul Noche"
                    style={{ background: '#48565F' }}
                  >
                    <div className="containerPrimary">
                      <div className="primary" style={{ background: '#2D373C' }}></div>
                    </div>
                    <i className="bi bi-check-circle-fill"></i>
                  </div>

                  <div
                    onClick={() => setColorTheme('schema_azul_oscuro')}
                    className={`colorItem ${globalContext.conf.schemaColor === 'schema_azul_oscuro' && 'active'}`}
                    title="Azul Oscuro"
                    style={{ background: '#285295' }}
                  >
                    <div className="containerPrimary">
                      <div className="primary" style={{ background: '#1A345F' }}></div>
                    </div>
                    <i className="bi bi-check-circle-fill"></i>
                  </div>

                  <div
                    onClick={() => setColorTheme('schema_purpura_oscura')}
                    className={`colorItem ${globalContext.conf.schemaColor === 'schema_purpura_oscura' && 'active'}`}
                    title="Púrpura Oscuro"
                    style={{ background: '#67439C' }}
                  >
                    <div className="containerPrimary">
                      <div className="primary" style={{ background: '#422B64' }}></div>
                    </div>
                    <i className="bi bi-check-circle-fill"></i>
                  </div>

                  <div
                    onClick={() => setColorTheme('schema_verde_oscura')}
                    className={`colorItem ${globalContext.conf.schemaColor === 'schema_verde_oscura' && 'active'}`}
                    title="Verde Oscuro"
                    style={{ background: '#365C31' }}
                  >
                    <div className="containerPrimary">
                      <div className="primary" style={{ background: '#20381D' }}></div>
                    </div>
                    <i className="bi bi-check-circle-fill"></i>
                  </div>

                  <div
                    onClick={() => setColorTheme('schema_rojo_oscura')}
                    className={`colorItem ${globalContext.conf.schemaColor === 'schema_rojo_oscura' && 'active'}`}
                    title="Rojo Oscuro"
                    style={{ background: '#992B25' }}
                  >
                    <div className="containerPrimary">
                      <div className="primary" style={{ background: '#641B17' }}></div>
                    </div>
                    <i className="bi bi-check-circle-fill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Formats />

        <div className="sectionMainConf" id="pagos" hidden={true}>
          <h4>Historial y Metodos de pago</h4>
          <div className="confSection">
            <div className="labelConf">
              <Link to="/historialPagos">
                <span>Ver Historial de Pagos</span>
                <i className="bi bi-chevron-right"></i>
              </Link>
            </div>
            <div className="labelConf">
              <Link to="/metodosPago">
                <span>Ver Metodos de Pago</span>
                <i className="bi bi-chevron-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <IndexesConf />
    </div>
  )
}

export default Configuraciones
