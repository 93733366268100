import { toast } from 'react-toastify'

const handleListSidebar = () => {
  let linkSubMenuActive = document.querySelector('.linkSubMenuActive')
  if (linkSubMenuActive) {
    if (!linkSubMenuActive.parentElement.parentElement.parentElement.className.includes('submenuToggled')) {
      linkSubMenuActive.parentElement.parentElement.previousElementSibling.click()
    }
  }
}

const setTitleSection = (title) => {
  let sectionTitle = document.querySelector('.titleSection')
  if (sectionTitle) {
    sectionTitle.innerHTML = title
  }
}

const changeForm = (title) => {
  let FormContainer = document.querySelector('.FormContainer')
  if (FormContainer.className.includes('toggled')) {
    FormContainer.className = 'FormContainer'
  } else {
    FormContainer.className = 'FormContainer toggled'
    setTimeout(() => {
      FormContainer.className = 'FormContainer'
    }, 1000)
  }

  if (title) {
    setTitleSection(title)
  }
}

const listenAccesoDirecto = (keyboard, formAvailable, event) => {
  if (keyboard['Control']) {
    event.preventDefault()
  }
  if (keyboard['Control'] && keyboard['a'] && keyboard['1']) {
    let btn = document.querySelector('.btnCompaniaAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + A + 1', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['a'] && keyboard['2']) {
    let btn = document.querySelector('.btnActividadesEconomicasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + A + 2', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['a'] && keyboard['3']) {
    let btn = document.querySelector('.btnUsuariosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + A + 3', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['a'] && keyboard['4']) {
    let btn = document.querySelector('.btnCajasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + A + 4', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['a'] && keyboard['5']) {
    let btn = document.querySelector('.btnCabysAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + A + 5', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['a'] && keyboard['6']) {
    let btn = document.querySelector('.btnSucursalesAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + A + 6', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['a'] && keyboard['7']) {
    let btn = document.querySelector('.btnConsecutivosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + A + 7', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['f'] && keyboard['1']) {
    let btn = document.querySelector('.btnfacturasElectronicasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + F + 1', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['f'] && keyboard['2']) {
    let btn = document.querySelector('.btnTiquetasElectronicasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + F + 2', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['f'] && keyboard['3']) {
    let btn = document.querySelector('.btnFacturasElectronicasPorExportacionAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + F + 3', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['f'] && keyboard['4']) {
    let btn = document.querySelector('.btnFacturasElectronicasDeCompraAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + F + 4', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['f'] && keyboard['5']) {
    let btn = document.querySelector('.btnProformasElectronicasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + F + 5', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['f'] && keyboard['6']) {
    let btn = document.querySelector('.btnNotasCreditoAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + F + 6', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['f'] && keyboard['7']) {
    let btn = document.querySelector('.btnNotasDebitoAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + F + 7', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['p'] && keyboard['1']) {
    let btn = document.querySelector('.btnProductosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + P + 2', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['p'] && keyboard['2']) {
    let btn = document.querySelector('.btnPersonasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + P + 2', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['d'] && keyboard['1']) {
    let btn = document.querySelector('.btnDocumentosEmitidosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + D + 1', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['d'] && keyboard['2']) {
    let btn = document.querySelector('.btnDocumentosRecibidosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + D + 2', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['n']) {
    let btn = document.querySelector('.btnNotifications')
    toast('Ejecutando combinación de teclado Ctrl + N', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['b']) {
    let btn = document.querySelector('.toggleSidebar')
    toast('Ejecutando combinación de teclado Ctrl + B', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['s']) {
    let btn = document.querySelector('.btnConfiguracionesAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + S', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r']) {
    let btn = document.querySelector('.searchGeneral')
    toast('Ejecutando combinación de teclado Ctrl + R', { type: 'dark', autoClose: 2000 })
    btn.click()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['1']) {
    let btn = document.querySelector('.btnPropiedadAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 1', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['2']) {
    let btn = document.querySelector('.btnComprasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 2', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['3']) {
    let btn = document.querySelector('.btnSalariosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 3', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['4']) {
    let btn = document.querySelector('.btnPedidaFiscalAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 4', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['5']) {
    let btn = document.querySelector('.btnSaldoAFavorAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 5', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['6']) {
    let btn = document.querySelector('.btnPagoParcialAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 6', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['7']) {
    let btn = document.querySelector('.btnCreditosBancariosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 7', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['r'] && keyboard['8']) {
    let btn = document.querySelector('.btnCreditosFamiliaresAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + R + 8', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['1']) {
    let btn = document.querySelector('.btnBodegasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 1', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['2']) {
    let btn = document.querySelector('.btnComprasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 2', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['3']) {
    let btn = document.querySelector('.btnEgresosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 3', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['4']) {
    let btn = document.querySelector('.btnIngresosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 4', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['5']) {
    let btn = document.querySelector('.btnAjustesPositivosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 5', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['6']) {
    let btn = document.querySelector('.btnAjustesNegativosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + N + 6', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['7']) {
    let btn = document.querySelector('.btnMovimientosAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 7', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['8']) {
    let btn = document.querySelector('.btnExistenciasAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 8', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  } else if (keyboard['Control'] && keyboard['t'] && keyboard['9']) {
    let btn = document.querySelector('.btnKardexAccesoDirecto')
    toast('Ejecutando combinación de teclado Ctrl + T + 9', { type: 'dark', autoClose: 2000 })
    btn.click()
    handleListSidebar()
  }
  // else if (keyboard['Control'] && keyboard['l']) {
  //   let btn = document.querySelector('.btnFilters')
  //   toast('Ejecutando combinación de teclado Ctrl + L', { type: 'dark', autoClose: 2000 })
  //   btn.click()
  // }
  else if (keyboard['Control'] && keyboard['Shift'] && keyboard['A'] && keyboard['1']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + A + 1', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirCompaniaAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['A'] && keyboard['2']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + A + 2', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirActividadesEconomicasAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['A'] && keyboard['3']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + A + 3', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirUsuariosAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['A'] && keyboard['6']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + A + 6', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirSucursalesAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['A'] && keyboard['4']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + A + 4', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirCajasAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['A'] && keyboard['5']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + A + 5', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirCabysAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['F'] && keyboard['1']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + F + 1', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirfacturasElectronicasAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['P'] && keyboard['1']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + P + 1', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirPersonasAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['P'] && keyboard['2']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + P + 2', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirProductosAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['T'] && keyboard['1']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + T + 1', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirBodegaAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['R'] && keyboard['2']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + R + 2', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirRetencionesDePagosAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['R'] && keyboard['3']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + R + 3', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirCreditosBancariosAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['R'] && keyboard['6']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + R + 6', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirPagoParcialAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['R'] && keyboard['8']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + R + 8', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirCreditoFamiliarAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['T'] && keyboard['2']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + T + 2', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirCompraAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['T'] && keyboard['3']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + T + 3', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirEgresosAccesoDirecto')
    btn.click()
  } else if (keyboard['Escape']) {
    let Search = document.querySelector('.Search')
    toast('Saliendo del buscado', { type: 'dark', autoClose: 2000 })
    Search.className = 'Search toggled'
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['T'] && keyboard['5']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + T + 5', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirAjustesPositivosAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['Shift'] && keyboard['T'] && keyboard['6']) {
    toast('Ejecutando combinación de teclado Ctrl + Shift + T + 6', { type: 'dark', autoClose: 2000 })
    let btn = document.querySelector('.btnAbrirAjustesNegativosAccesoDirecto')
    btn.click()
  } else if (keyboard['Control'] && keyboard['o']) {
    let formavalaible = document.querySelector('.TopBar').attributes['formavalaible'].value
    if (formavalaible === 'yes') {
      toast('Ejecutando combinación de teclado Ctrl + O', { type: 'dark', autoClose: 2000 })
      let FormContainer = document.querySelector('.FormContainer')
      if (FormContainer.className.includes('toggled')) {
        FormContainer.className = 'FormContainer'
      } else {
        let closeForm = document.querySelector('.closeForm')
        closeForm.click()
      }
    } else if (formavalaible === 'no') {
      toast('Esta seccion no tiene Formulario', { type: 'dark', autoClose: 4000 })
    }
  }
}

const filterResults = (content, value) => {
  const [
    personas,
    productos,
    facturasElectronicas,
    facturasElectronicasPorCompras,
    facturasElectronicasPorExportacions,
    TiquetasElectronicas,
    NotasDebito,
    NotasCredito,
    ProformasElectronicas,
    responseDocRecibidos,
  ] = content

  let personasResultados = personas.filter(
    (p) => p.nombreCliente.toUpperCase().includes(value.toUpperCase()) || p.ruc.includes(value)
  )

  let productosResultados = productos.filter(
    (p) => p.nombreProducto.toUpperCase().includes(value.toUpperCase()) || p.productoPK.idProducto.includes(value)
  )

  let facturasElectronicasResultados = facturasElectronicas.filter((f) => {
    if (f.referencia) {
      if (f.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || f.referencia.includes(value)) {
        return true
      }

      let lineasMatch = f.vtaDetmList.filter(
        (l) =>
          l.descripcion?.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion3.toUpperCase().includes(value.toUpperCase())
      )

      console.log('items facturas ', f.vtaDetmList)

      if (lineasMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    return false
  })

  let facturasElectronicasPorCompra = facturasElectronicasPorCompras.filter((f) => {
    if (f.referencia) {
      if (f.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || f.referencia.includes(value)) {
        return true
      }

      let lineasMatch = f.vtaDetmList.filter(
        (l) =>
          l.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion3.toUpperCase().includes(value.toUpperCase())
      )

      if (lineasMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    return false
  })

  let TiquetasElectronica = TiquetasElectronicas.filter((f) => {
    if (f.referencia) {
      if (f.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || f.referencia.includes(value)) {
        return true
      }

      let lineasMatch = f.vtaDetmList.filter(
        (l) =>
          l.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion3.toUpperCase().includes(value.toUpperCase())
      )

      if (lineasMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    return false
  })

  let notasDebito = NotasDebito.filter((f) => {
    if (f.referencia) {
      if (f.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || f.referencia.includes(value)) {
        return true
      }

      let lineasMatch = f.vtaDetmList.filter(
        (l) =>
          l.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion3.toUpperCase().includes(value.toUpperCase())
      )

      if (lineasMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    return false
  })

  let notasCredito = NotasCredito.filter((f) => {
    if (f.referencia) {
      if (f.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || f.referencia.includes(value)) {
        return true
      }

      let lineasMatch = f.vtaDetmList.filter(
        (l) =>
          l.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion3.toUpperCase().includes(value.toUpperCase())
      )

      if (lineasMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    return false
  })

  let facturasElectronicasPorExportacion = facturasElectronicasPorExportacions.filter((f) => {
    if (f.referencia) {
      if (f.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || f.referencia.includes(value)) {
        return true
      }

      let lineasMatch = f.vtaDetmList.filter(
        (l) =>
          l.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion3.toUpperCase().includes(value.toUpperCase())
      )

      if (lineasMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    return false
  })

  let proformasElectronicas = ProformasElectronicas.filter((f) => {
    if (f.referencia) {
      if (f.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) || f.referencia.includes(value)) {
        return true
      }

      let lineasMatch = f.vtaDetmList.filter(
        (l) =>
          l.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion1.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion2.toUpperCase().includes(value.toUpperCase()) ||
          l.cabys.descripcion3.toUpperCase().includes(value.toUpperCase())
      )

      if (lineasMatch.length > 0) {
        return true
      } else {
        return false
      }
    }

    return false
  })

  let DocumentosResultados = responseDocRecibidos.filter(
    (d) =>
      d.emisor.toUpperCase().includes(value.toUpperCase()) ||
      d.nitEmisor.includes(value) ||
      d.docRecibidosPK.id.toString().includes(value) ||
      d.clave.includes(value)
  )

  return [
    {
      label: 'Facturas Electrónicas',
      results: facturasElectronicasResultados,
      filter: 'facturaElectronica',
    },
    {
      label: 'Tiquetas Electrónicas',
      results: TiquetasElectronica,
      filter: 'tiquetaElectronica',
    },
    {
      label: 'Facturas Electrónicas Por Exportacion',
      results: facturasElectronicasPorExportacion,
      filter: 'facturaElectronicaExportacion',
    },
    {
      label: 'Facturas Electrónicas Por Compra',
      results: facturasElectronicasPorCompra,
      filter: 'facturaElectronicaCompra',
    },
    {
      label: 'Proformas Electrónicas',
      results: proformasElectronicas,
      filter: 'proformaElectronica',
    },
    {
      label: 'Notas de Credito',
      results: notasCredito,
      filter: 'notaCredito',
    },
    {
      label: 'Notas de Debito',
      results: notasDebito,
      filter: 'notaDebito',
    },
    {
      label: 'Personas',
      results: personasResultados,
      filter: 'personas',
    },
    {
      label: 'Productos',
      results: productosResultados,
      filter: 'productos',
    },
    {
      label: 'Documentos Recibidos',
      results: DocumentosResultados,
      filter: 'documentosRecibidos',
    },
  ]
}

export { listenAccesoDirecto, changeForm, filterResults }
