import {
  CREATE_ACTIVIDAD_ECONOMICA,
  CREATE_ADMIN_USUARIO,
  CREATE_CAJA,
  CREATE_CONSECUTIVO,
  CREATE_SUCURSALES,
  DELETE_ACTIVIDAD_ECONOMICA,
  DELETE_ADMIN_USUARIO,
  DELETE_CAJA,
  DELETE_CONSECUTIVO,
  DELETE_SUCURSALES,
  GET_ACTIVIDADES_ECONOMICAS,
  GET_CABYS,
  GET_PAQUETE,
  GET_CAJAS,
  GET_CONSECUTIVOS,
  GET_ROLES,
  GET_SUCURSALES,
  GET_USUARIOS,
  GET_CIA,
  LOAD_CIA,
  LOAD_USUARIOS,
  LOAD_CAJAS,
  UPDATE_ACTIVIDAD_ECONOMICA,
  UPDATE_ADMIN_USUARIO,
  UPDATE_CAJA,
  UPDATE_CONSECUTIVO,
  UPDATE_SUCURSALES,
  COMPRA_PAQUETE,
  ELIMINAR_PAQUETE,
  LOAD_PAQUETE,
} from '../constants/actionTypes'

const initialState = {
  roles: [],
  sucursales: [],
  cajas: [],
  usuarios: [],
  consecutivos: [],
  cabys: [],
  actividadesEconomicas: [],
  isLoadingCajas: true,
  isLoadingSucursales: true,
  isLoadingUsuarios: true,
  isLoadingConsecutivos: true,
  isLoadingCabys: true,
  isLoadingActividadesEconomicas: true,
  isLoadingPaquete: true,
}

const handles = {
  [GET_ROLES]: (state, { payload = {} }) => ({ ...state, roles: payload?.roles ?? [] }),
  [GET_SUCURSALES]: (state, { payload = {} }) => {
    return { ...state, isLoadingSucursales: false, sucursales: payload?.sucursales }
  },
  [LOAD_USUARIOS]: (state, { payload = {} }) => {
    return { ...state, isLoadingUsuarios: true }
  },
  [LOAD_PAQUETE]: (state, { payload = {} }) => {
    return { ...state, isLoadingPaquete: true }
  },
  [LOAD_CIA]: (state, { payload = {} }) => {
    return { ...state, isLoadingUsuarios: true }
  },
  [CREATE_SUCURSALES]: (state, { payload = {} }) => {
    return {
      ...state,
      sucursales: [...state.sucursales, payload?.sucursal],
    }
  },
  [UPDATE_SUCURSALES]: (state, { payload = {} }) => {
    return {
      ...state,
      sucursales: state.sucursales.map((sucursal) => {
        if (sucursal.sucursalPK.idSucursal === payload?.sucursal?.sucursalPK?.idSucursal) {
          return payload?.sucursal
        } else {
          return sucursal
        }
      }),
    }
  },
  [DELETE_SUCURSALES]: (state, { payload = {} }) => {
    return {
      ...state,
      sucursales: state.sucursales.filter((sucursal) => {
        return sucursal.sucursalPK.idSucursal !== payload?.sucursal?.sucursalPK?.idSucursal
      }),
    }
  },
  [LOAD_CAJAS]: (state, { payload = {} }) => {
    return { ...state, isLoadingCajas: true }
  },
  [GET_CAJAS]: (state, { payload = {} }) => {
    return { ...state, isLoadingCajas: false, cajas: payload?.cajas }
  },
  [CREATE_CAJA]: (state, { payload = {} }) => {
    return { ...state, cajas: [payload?.caja, ...state.cajas] }
  },
  [UPDATE_CAJA]: (state, { payload = {} }) => {
    return {
      ...state,
      cajas: state.cajas.map((c) =>
        c.cajaPK.idCaja === payload.caja.cajaPK.idCaja && c.cajaPK.idSucursal === payload.caja.cajaPK.idSucursal
          ? payload.caja
          : c
      ),
    }
  },
  [DELETE_CAJA]: (state, { payload = {} }) => ({
    ...state,
    cajas: state.cajas.filter(
      (c) => c.cajaPK.idCaja !== payload?.caja?.cajaPK?.idCaja && c.cajaPK.idSucursal === payload?.caja?.cajaPK?.idSucursal
    ),
  }),
  [GET_USUARIOS]: (state, { payload = {} }) => {
    return { ...state, isLoadingUsuarios: false, usuarios: payload?.usuarios }
  },
  [GET_CIA]: (state, { payload = {} }) => {
    return { ...state, isLoadingUsuarios: false, usuarios: payload?.usuarios }
  },
  [CREATE_ADMIN_USUARIO]: (state, { payload = {} }) => {
    return { ...state, usuarios: [payload?.usuario, ...state.usuarios] }
  },
  [UPDATE_ADMIN_USUARIO]: (state, { payload = {} }) => {
    return {
      ...state,
      usuarios: state.usuarios.map((u) => (u.codCia === payload?.usuario.codCia ? payload?.usuario : u)),
    }
  },
  [COMPRA_PAQUETE]: (state, { payload = {} }) => {
    return { ...state, paqueteRequest: [payload?.paqueteRequest] }
  },
  [ELIMINAR_PAQUETE]: (state, { payload = {} }) => {
    return { ...state, paqueteRequest: [payload?.paqueteRequest] }
  },
  [DELETE_ADMIN_USUARIO]: (state, { payload = {} }) => ({
    ...state,
    usuarios: state.usuarios.filter((u) => u.cia?.codCia !== payload?.usuario.cia?.codCia),
  }),
  [GET_CONSECUTIVOS]: (state, { payload = {} }) => {
    return { ...state, isLoadingConsecutivos: false, consecutivos: payload?.consecutivos }
  },
  [CREATE_CONSECUTIVO]: (state, { payload = {} }) => ({ ...state, consecutivos: [payload?.consecutivo, ...state.consecutivos] }),
  [UPDATE_CONSECUTIVO]: (state, { payload = {} }) => ({
    ...state,
    consecutivos: state.consecutivos.map((c) =>
      c.mantoFormularioPK.idMantoForm === payload.consecutivo.mantoFormularioPK.idMantoForm ? payload.consecutivo : c
    ),
  }),
  [DELETE_CONSECUTIVO]: (state, { payload = {} }) => ({
    ...state,
    consecutivos: state.consecutivos.filter(
      (c) => c.mantoFormularioPK.idMantoForm !== payload?.consecutivo?.mantoFormularioPK.idMantoForm
    ),
  }),
  [GET_CABYS]: (state, { payload = {} }) => {
    return { ...state, isLoadingCabys: false, cabys: payload?.cabys }
  },
  [GET_PAQUETE]: (state, { payload = {} }) => {
    return { ...state, isLoadingPaquete: false, paquete: payload?.paquete }
  },
  [GET_ACTIVIDADES_ECONOMICAS]: (state, { payload = {} }) => {
    return { ...state, isLoadingActividadesEconomicas: false, actividadesEconomicas: payload?.actividadesEconomicas }
  },
  [CREATE_ACTIVIDAD_ECONOMICA]: (state, { payload = {} }) => ({
    ...state,
    actividadesEconomicas: [payload?.actividadEconomica, ...state.actividadesEconomicas],
  }),
  [UPDATE_ACTIVIDAD_ECONOMICA]: (state, { payload = {} }) => ({
    ...state,
    actividadesEconomicas: state.actividadesEconomicas.map((a) =>
      a.actividadEconomicaCiaPK.correlativo === payload?.actividadEconomica?.actividadEconomicaCiaPK?.correlativo &&
      a.actividadEconomicaCiaPK.codActividad === payload?.actividadEconomica?.actividadEconomicaCiaPK?.codActividad
        ? payload?.actividadEconomica
        : a
    ),
  }),
  [DELETE_ACTIVIDAD_ECONOMICA]: (state, { payload = {} }) => ({
    ...state,
    actividadesEconomicas: state.actividadesEconomicas.filter(
      (a) =>
        !(
          a.actividadEconomicaCiaPK.correlativo === payload?.actividadEconomica?.actividadEconomicaCiaPK?.correlativo &&
          a.actividadEconomicaCiaPK.codActividad === payload?.actividadEconomica?.actividadEconomicaCiaPK?.codActividad
        )
    ),
  }),
}

const administracion = (state = initialState, action) => {
  const { type } = action

  return handles[type] ? handles[type](state, action) : state
}

export default administracion
