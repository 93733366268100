import React from 'react'
import './TerminosCondiciones.css'
import { Link } from 'react-router-dom'
import PageTerminosCondiciones from '../../assets/404.png'

const TerminosCondiciones = () => {
  return (
    <div className="terminosCondiciones">

      <h2>Política de Privacidad para SaveTax</h2>
      <p>&Uacute;ltima actualizaci&oacute;n: 15/11/2023</p>

<p>Bienvenido a SaveTax. </p>
  <p>Esta Pol&iacute;tica de Privacidad describe c&oacute;mo recopilamos, utilizamos y compartimos la informaci&oacute;n que obtendremos a trav&eacute;s de tu uso de nuestra aplicaci&oacute;n. Al utilizar SaveTax, aceptas los t&eacute;rminos de esta pol&iacute;tica.</p>

<p>1. Informaci&oacute;n que Recopilamos</p>
   <p>1.1 Informaci&oacute;n de Registro Cuando te registras en SaveTax, recopilamos informaci&oacute;n personal, que puede incluir, pero no se limita a:</p>

<p>Nombre y apellidos</p> 
<p>Direcci&oacute;n de correo electr&oacute;nico </p>
<p>N&uacute;mero de tel&eacute;fono</p>
<p> Informaci&oacute;n fiscal necesaria para la facturaci&oacute;n electr&oacute;nica</p>
   
<p>   1.2 Informaci&oacute;n de Uso</p> 
<p>     Recopilamos informaci&oacute;n sobre c&oacute;mo utilizas nuestra aplicaci&oacute;n, incluidas las funciones que usas, el tiempo que pasas en la aplicaci&oacute;n y cualquier error que puedas experimentar. Esto nos ayuda a mejorar y personalizar tu experiencia.</p>

<p>2. Uso de la Informaci&oacute;n </p>
  
<p> 2.1 Procesamiento de Facturas </p>
<p> Utilizamos la informaci&oacute;n proporcionada para procesar tus facturas electr&oacute;nicas, gestionar tu cuenta y proporcionarte servicios relacionados con nuestra aplicaci&oacute;n.</p>

<p>2.2 Mejora de la Experiencia del Usuario Analizamos los datos de uso para mejorar la funcionalidad de la aplicaci&oacute;n, desarrollar nuevas caracter&iacute;sticas y personalizar tu experiencia.</p>

<p>2.3 Comunicaci&oacute;n Podemos utilizar tu informaci&oacute;n para enviarte comunicaciones relacionadas con la aplicaci&oacute;n, como actualizaciones, cambios en los t&eacute;rminos de servicio o informaci&oacute;n importante sobre tu cuenta.</p>

<p>3. Compartir Informaci&oacute;n No vendemos, alquilamos ni intercambiamos tu informaci&oacute;n personal con terceros, excepto seg&uacute;n lo permita la ley o cuando sea necesario para cumplir con nuestros servicios (por ejemplo, compartir datos con procesadores de pagos para procesar transacciones).</p>

<p>4. Seguridad Tomamos medidas razonables para proteger la informaci&oacute;n personal contra p&eacute;rdida, uso indebido y acceso no autorizado. Implementamos protocolos de seguridad para garantizar la integridad y confidencialidad de tus datos.</p>

<p>5. Cambios en la Pol&iacute;tica de Privacidad Nos reservamos el derecho de modificar esta Pol&iacute;tica de Privacidad en cualquier momento. Te notificaremos sobre cambios significativos a trav&eacute;s de la aplicaci&oacute;n o por otros medios antes de que dichos cambios entren en vigencia.</p>

<p>6. Tus Derechos y Elecciones Tienes el derecho de acceder, corregir o eliminar tu informaci&oacute;n personal. Puedes gestionar tus preferencias de comunicaci&oacute;n en la configuraci&oacute;n de la aplicaci&oacute;n.</p>

<p>7. Contacto Si tienes preguntas sobre esta Pol&iacute;tica de Privacidad, puedes contactarnos en info@savetaxcr.com</p>

<p>8. Cumplimiento Legal Nos comprometemos a cumplir con todas las leyes y regulaciones de privacidad aplicables.</p>

<p>SaveTax</p>

<p>Este es un modelo m&aacute;s detallado. Aseg&uacute;rate de adaptar cada secci&oacute;n a las necesidades espec&iacute;ficas de tu aplicaci&oacute;n y de cumplir con las leyes locales de privacidad y protecci&oacute;n de datos. Adem&aacute;s, te recomiendo buscar asesoramiento legal para garantizar el cumplimiento normativo.</p>
    </div >
  )
}

export default TerminosCondiciones
