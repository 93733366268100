// Import Modules
import React from 'react'
import { Link } from 'react-router-dom'

// Import Controllers
import { changeForm } from '../controllers'

const ShortCutsModal = ({ onToggle }) => {
  const setTitleSection = (title) => {
    let sectionTitle = document.querySelector('.titleSection')
    if (sectionTitle) {
      sectionTitle.innerHTML = title
    }
  }

  return (
    <div className="overloadShortcut hidden" role="button" onClick={onToggle}>
      <div className="modalShortcut toggled">
        <h4>Accesos Directos</h4>
        <ul>
          <li>
            <Link
              to="/companias"
              className="btnCompaniaAccesoDirecto"
              onClick={(event) => setTitleSection('Mantenimiento de Compañias')}
            >
              Compañia
              <span>Ctrl + A + 1</span>
            </Link>
          </li>
          <li>
            <Link
              to="/actividadesEconomicas"
              className="btnActividadesEconomicasAccesoDirecto"
              onClick={(event) => setTitleSection('Actividades Economicas')}
            >
              Actividad Economica
              <span>Ctrl + A + 2</span>
            </Link>
          </li>
          <li>
            <Link to="/usuarios" className="btnUsuariosAccesoDirecto" onClick={(event) => setTitleSection('Usuarios')}>
              Usuarios
              <span>Ctrl + A + 3</span>
            </Link>
          </li>
          <li>
            <Link to="/cajas" className="btnCajasAccesoDirecto" onClick={(event) => setTitleSection('Cajas')}>
              Cajas
              <span>Ctrl + A + 4</span>
            </Link>
          </li>
          <li>
            <Link to="/cabys" className="btnCabysAccesoDirecto" onClick={(event) => setTitleSection('Cabys')}>
              Cabys
              <span>Ctrl + A + 5</span>
            </Link>
          </li>
          <li>
            <Link to="/sucursales" className="btnSucursalesAccesoDirecto" onClick={(event) => setTitleSection('Sucursales')}>
              Sucursal
              <span>Ctrl + A + 6</span>
            </Link>
          </li>
          <li>
            <Link
              to="/consecutivos"
              className="btnConsecutivosAccesoDirecto"
              onClick={(event) => setTitleSection('Consecutivos')}
            >
              Consecutivos
              <span>Ctrl + A + 7</span>
            </Link>
          </li>
          <li>
            <Link
              to="/facturasElectronicas"
              className="btnfacturasElectronicasAccesoDirecto"
              onClick={(event) => setTitleSection('Facturas Electronicas')}
            >
              Facturas Electónicas
              <span>Ctrl + F + 1</span>
            </Link>
          </li>
          <li>
            <Link
              to="/tiquetasElectronicas"
              className="btnTiquetasElectronicasAccesoDirecto"
              onClick={(event) => setTitleSection('Tiquetas Electrónicas')}
            >
              Tiquetas Electrónicas
              <span>Ctrl + F + 2</span>
            </Link>
          </li>
          <li>
            <Link
              to="/facturasElectronicasPorExportacion"
              className="btnFacturasElectronicasPorExportacionAccesoDirecto"
              onClick={(event) => setTitleSection('Facturas Electrónicas de Exportación')}
            >
              Factura Electrónica de Exportación
              <span>Ctrl + F + 3</span>
            </Link>
          </li>
          <li>
            <Link
              to="/facturasElectronicasDeCompra"
              className="btnFacturasElectronicasDeCompraAccesoDirecto"
              onClick={(event) => setTitleSection('Factura Electrónica de Compra')}
            >
              Factura Electrónica de Compra
              <span>Ctrl + F + 4</span>
            </Link>
          </li>
          <li>
            <Link
              to="/proformasElectronicas"
              className="btnProformasElectronicasAccesoDirecto"
              onClick={(event) => setTitleSection('Proforma Electrónica')}
            >
              Proforma Electrónica
              <span>Ctrl + F + 5</span>
            </Link>
          </li>
          <li>
            <Link
              to="/notasCredito"
              className="btnNotasCreditoAccesoDirecto"
              onClick={(event) => setTitleSection('Nota Credito')}
            >
              Nota Credito
              <span>Ctrl + F + 6</span>
            </Link>
          </li>
          <li>
            <Link to="/notasDebito" className="btnNotasDebitoAccesoDirecto" onClick={(event) => setTitleSection('Nota Debito')}>
              Nota Debito
              <span>Ctrl + F + 7</span>
            </Link>
          </li>
          <li>
            <Link
              to="/documentosEmitidos"
              className="btnDocumentosEmitidosAccesoDirecto"
              onClick={(event) => setTitleSection('Documentos Emitidos')}
            >
              Documentos Emitidos
              <span>Ctrl + D + 1</span>
            </Link>
          </li>
          <li>
            <Link
              to="/documentosRecibidos"
              className="btnDocumentosRecibidosAccesoDirecto"
              onClick={(event) => setTitleSection('Documentos Recibidos')}
            >
              Documentos Recibidos
              <span>Ctrl + D + 1</span>
            </Link>
          </li>
          <li>
            <Link to="/productos" className="btnProductosAccesoDirecto" onClick={(event) => setTitleSection('Productos')}>
              Producto
              <span>Ctrl + P + 1</span>
            </Link>
          </li>
          <li>
            <Link to="/personas" className="btnPersonasAccesoDirecto" onClick={(event) => setTitleSection('Personas')}>
              Persona
              <span>Ctrl + P + 2</span>
            </Link>
          </li>
          <li>
            <Link
              to="/propiedad"
              className="btnPropiedadAccesoDirecto"
              onClick={(event) => setTitleSection('Propiedades, Plantas y Equipos')}
            >
              Propiedad, Planta y Equipo
              <span>Ctrl + R + 1</span>
            </Link>
          </li>
          <li>
            <Link
              to="/retencionDePago"
              className="btnRetencionDePagoAccesoDirecto"
              onClick={(event) => setTitleSection('Retenciones de Pago')}
            >
              Retenciones de Pago
              <span>Ctrl + R + 2</span>
            </Link>
          </li>
          <li>
            <Link to="/salarios" className="btnSalariosAccesoDirecto" onClick={(event) => setTitleSection('Salarios')}>
              Salarios
              <span>Ctrl + R + 3</span>
            </Link>
          </li>
          <li>
            <Link
              to="/perdidasFiscales"
              className="btnPedidaFiscalAccesoDirecto"
              onClick={(event) => setTitleSection('Perdidas Fiscales')}
            >
              Pedidas Fiscales
              <span>Ctrl + R + 4</span>
            </Link>
          </li>
          <li>
            <Link
              to="/saldoAFavor"
              className="btnSaldoAFavorAccesoDirecto"
              onClick={(event) => setTitleSection('Saldos a Favor')}
            >
              Saldos a Favor
              <span>Ctrl + R + 5</span>
            </Link>
          </li>
          <li>
            <Link
              to="/pagoParcial"
              className="btnPagoParcialAccesoDirecto"
              onClick={(event) => setTitleSection('Pagos Parciales')}
            >
              Pagos Parciales
              <span>Ctrl + R + 6</span>
            </Link>
          </li>
          <li>
            <Link
              to="/creditosBancarios"
              className="btnCreditosBancariosAccesoDirecto"
              onClick={(event) => setTitleSection('Creditos Bancarios')}
            >
              Creditos Bancarios
              <span>Ctrl + R + 7</span>
            </Link>
          </li>
          <li>
            <Link
              to="/creditosFamiliares"
              className="btnCreditosFamiliaresAccesoDirecto"
              onClick={(event) => setTitleSection('Creditos Familiares')}
            >
              Créditos Familiares
              <span>Ctrl + R + 8</span>
            </Link>
          </li>
          <li>
            <Link to="/bodegas" className="btnBodegasAccesoDirecto" onClick={(event) => setTitleSection('Bodegas')}>
              Bodegas
              <span>Ctrl + T + 1</span>
            </Link>
          </li>
          <li>
            <Link to="/compras" className="btnComprasAccesoDirecto" onClick={(event) => setTitleSection('Compras')}>
              Compras
              <span>Ctrl + T + 2</span>
            </Link>
          </li>
          <li>
            <Link to="/egresos" className="btnEgresosAccesoDirecto" onClick={(event) => setTitleSection('Egresos')}>
              Egresos
              <span>Ctrl + T + 3</span>
            </Link>
          </li>
          <li>
            <Link to="/ingresos" className="btnIngresosAccesoDirecto" onClick={(event) => setTitleSection('Ingresos')}>
              Ingresos
              <span>Ctrl + T + 4</span>
            </Link>
          </li>
          <li>
            <Link
              to="/ajustesPositivos"
              className="btnAjustesPositivosAccesoDirecto"
              onClick={(event) => setTitleSection('Ajustes Positivos')}
            >
              Ajustes Positivos
              <span>Ctrl + T + 5</span>
            </Link>
          </li>
          <li>
            <Link
              to="/ajustesNegativos"
              className="btnAjustesNegativosAccesoDirecto"
              onClick={(event) => setTitleSection('Ajustes Negativos')}
            >
              Ajustes Negativos
              <span>Ctrl + T + 6</span>
            </Link>
          </li>
          <li>
            <Link to="/movimientos" className="btnMovimientosAccesoDirecto" onClick={(event) => setTitleSection('Movimientos')}>
              Movimientos
              <span>Ctrl + T + 7</span>
            </Link>
          </li>
          <li>
            <Link to="/existencias" className="btnExistenciasAccesoDirecto" onClick={(event) => setTitleSection('Existencias')}>
              Existencias
              <span>Ctrl + T + 8</span>
            </Link>
          </li>
          <li>
            <Link to="/kardex" className="btnKardexAccesoDirecto" onClick={(event) => setTitleSection('Kardex')}>
              Kardex
              <span>Ctrl + T + 9</span>
            </Link>
          </li>
          <li>
            <Link
              to="/creditosFamiliares"
              className="btnCreditosFamiliaresAccesoDirecto"
              onClick={(event) => setTitleSection('Creditos Familiares')}
            >
              Créditos Familiares
              <span>Ctrl + N + 9</span>
            </Link>
          </li>
          <li>
            <Link
              to="/prorrataTemporal"
              className="btnProrrataTemporalAccesoDirecto"
              onClick={(event) => setTitleSection('Prorratas Temporales')}
            >
              Prorrata Temporal
              <span>Ctrl + I + 1</span>
            </Link>
          </li>
          <li>
            <Link
              to="/declarACionDelIVA"
              className="btnDeclarACionDelIVAAccesoDirecto"
              onClick={(event) => setTitleSection('Declaraciones del IVA')}
            >
              Declaracion del IVA
              <span>Ctrl + I + 2</span>
            </Link>
          </li>
          <li>
            <Link
              to="/configuraciones"
              className="btnConfiguracionesAccesoDirecto"
              onClick={(event) => setTitleSection('Configuraciones')}
            >
              Configuraciones
              <span>Ctrl + S</span>
            </Link>
          </li>
          <li>
            <Link to="/configuraciones" className="btnConfiguracionesAccesoDirecto">
              Abrir el formulario de la Seccion
              <span>Ctrl + O</span>
            </Link>
          </li>
          <li>
            <Link
              to="/companias"
              className="btnAbrirCompaniaAccesoDirecto"
              onClick={(event) => changeForm('Mantenimiento de Compañia')}
            >
              Agregar Compañia
              <span>Ctrl + Shift + A + 1</span>
            </Link>
          </li>
          <li>
            <Link
              to="/actividadesEconomicas"
              className="btnAbrirActividadesEconomicasAccesoDirecto"
              onClick={(event) => changeForm('Actividad Economica')}
            >
              Agregar Actividad Economica
              <span>Ctrl + Shift + A + 2</span>
            </Link>
          </li>
          <li>
            <Link to="/usuarios" className="btnAbrirUsuariosAccesoDirecto" onClick={(event) => changeForm('Usuarios')}>
              Agregar Usuario
              <span>Ctrl + Shift + A + 3</span>
            </Link>
          </li>
          <li>
            <Link to="/cajas" className="btnAbrirCajasAccesoDirecto" onClick={(event) => changeForm('Cajas')}>
              Agregar Caja
              <span>Ctrl + Shift + A + 4</span>
            </Link>
          </li>
          <li>
            <Link to="/cabys" className="btnAbrirCabysAccesoDirecto" onClick={(event) => changeForm('Cabys')}>
              Agregar Cabys
              <span>Ctrl + Shift + A + 5</span>
            </Link>
          </li>
          <li>
            <Link to="/sucursales" className="btnAbrirSucursalesAccesoDirecto" onClick={(event) => changeForm('Sucursales')}>
              Agregar Sucursal
              <span>Ctrl + Shift + A + 6</span>
            </Link>
          </li>
          <li>
            <Link
              to="/facturasElectronicas"
              className="btnAbrirfacturasElectronicasAccesoDirecto"
              onClick={(event) => changeForm('Facturas Electrónicas')}
            >
              Agregar Factura Electrónica
              <span>Ctrl + Shift + F + 1</span>
            </Link>
          </li>
          <li>
            <Link to="/personas" className="btnAbrirPersonasAccesoDirecto" onClick={(event) => changeForm('Personas')}>
              Agregar Persona
              <span>Ctrl + Shift + P + 1</span>
            </Link>
          </li>
          <li>
            <Link to="/productos" className="btnAbrirProductosAccesoDirecto" onClick={(event) => changeForm('Productos')}>
              Agregar Producto
              <span>Ctrl + Shift + P + 2</span>
            </Link>
          </li>
          <li>
            <Link to="/bodegas" className="btnAbrirBodegaAccesoDirecto" onClick={(event) => changeForm('Bodegas')}>
              Agregar Bodega
              <span>Ctrl + Shift + T + 1</span>
            </Link>
          </li>
          <li>
            <Link to="/compras" className="btnAbrirCompraAccesoDirecto" onClick={(event) => changeForm('Compras')}>
              Agregar Compra
              <span>Ctrl + Shift + T + 2</span>
            </Link>
          </li>
          <li>
            <Link to="/egresos" className="btnAbrirEgresosAccesoDirecto" onClick={(event) => changeForm('Egresos')}>
              Agregar Egreso
              <span>Ctrl + Shift + T + 3</span>
            </Link>
          </li>
          <li>
            <Link
              to="/ajustesPositivos"
              className="btnAbrirAjustesPositivosAccesoDirecto"
              onClick={(event) => changeForm('Ajustes Positivos')}
            >
              Agregar Ajuste Positivo
              <span>Ctrl + Shift + T + 5</span>
            </Link>
          </li>
          <li>
            <Link
              to="/ajustesNegativos"
              className="btnAbrirAjustesNegativosAccesoDirecto"
              onClick={(event) => changeForm('Ajustes Negativos')}
            >
              Agregar Ajuste Negativo
              <span>Ctrl + Shift + T + 6</span>
            </Link>
          </li>
          <li>
            <Link
              to="/propiedad"
              className="btnAbrirSucursalesAccesoDirecto"
              onClick={(event) => changeForm('Propiedades, Plantas y Equipos')}
            >
              Agregar Propiedad
              <span>Ctrl + Shift + R + 1</span>
            </Link>
          </li>
          <li>
            <Link
              to="/retencionDePago"
              className="btnAbrirRetencionesDePagosAccesoDirecto"
              onClick={(event) => changeForm('Retenciones de Pagos')}
            >
              Agregar Retencion
              <span>Ctrl + Shift + R + 2</span>
            </Link>
          </li>
          <li>
            <Link
              to="/creditosBancarios"
              className="btnAbrirCreditosBancariosAccesoDirecto"
              onClick={(event) => changeForm('Pagos Bancarios')}
            >
              Agregar Credito Bancario
              <span>Ctrl + Shift + R + 3</span>
            </Link>
          </li>
          <li>
            <Link
              to="/creditosFamiliares"
              className="btnAbrirCreditoFamiliarAccesoDirecto"
              onClick={(event) => changeForm('Criditos Familiares')}
            >
              Agregar Credito Familiar
              <span>Ctrl + Shift + R + 8</span>
            </Link>
          </li>
          <li>
            <Link
              to="/perdidasFiscales"
              className="btnAbrirPerdidasFiscalesAccesoDirecto"
              onClick={(event) => changeForm('Perdidas Fiscales')}
            >
              Agregar Perdida Fiscal
              <span>Ctrl + Shift + R + 4</span>
            </Link>
          </li>
          <li>
            <Link
              to="/pagoParcial"
              className="btnAbrirPagoParcialAccesoDirecto"
              onClick={(event) => changeForm('Pagos Parciales')}
            >
              Agregar Pago parcial
              <span>Ctrl + Shift + R + 6</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ShortCutsModal
