/* eslint-disable react-hooks/exhaustive-deps */
// Import Modules
import React, { createContext, useState, useEffect } from 'react'

// Import components
import ControllerChartsDashboard from './ControllerChartsDashboard/ControllerChartsDashboard'
import ChartDashboard from './ChartDashboard/ChartDashboard'

// Import Hooks
import useDocumentos from 'hooks/useDocumentos'

// Import StyleSheet
import './styles.css'

// Context Initialize
export const initializeChartsDashboardContext = createContext({})

const ChartsDashboard = () => {
  const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ]
  const { documentosEmitidos, documentosRecibidos, isLoadingDocumentosEmitidos, isLoadingDocumentosRecibidos } = useDocumentos()
  const [metricType, setMetricType] = useState('compras')
  const [periodType, setPeriodType] = useState('anual')
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(months[new Date().getMonth()])
  const [years, setYears] = useState([])
  const handleChangeMetricType = (event) => setMetricType(event.target.value)
  const handleChangePeriodType = (event) => setPeriodType(event.target.value)
  const handleChangeYear = (event) => setYear(event.target.value)
  const handleChangeMonth = (event) => setMonth(event.target.value)
  const removeDuplicatesInArray = (previousValue, currentValue) => {
    if (previousValue.indexOf(currentValue) !== -1) return previousValue
    return previousValue.concat([currentValue])
  }

  const getYearsAvailable = (data) => {
    let allYears = data?.map((d) => new Date(d?.fechaEmision)?.getFullYear())
    let yearsMatch = allYears?.reduce(removeDuplicatesInArray, [])
    setYear(yearsMatch[yearsMatch.length - 1])
    return yearsMatch
  }
  const processAvailableYearsByMetrics = (metricType) => {
    if (metricType === 'ventas') {
      if (isLoadingDocumentosEmitidos) return
      setYears(getYearsAvailable(documentosEmitidos))
    } else if (metricType === 'compras') {
      if (isLoadingDocumentosRecibidos) return
      setYears(getYearsAvailable(documentosRecibidos))
    } else if (metricType === 'iva') {
      if (isLoadingDocumentosEmitidos || isLoadingDocumentosRecibidos) return
      setYears(getYearsAvailable(documentosRecibidos.concat(documentosEmitidos)))
    }
  }
  useEffect(() => {
    processAvailableYearsByMetrics(metricType)
  }, [metricType, isLoadingDocumentosEmitidos, isLoadingDocumentosRecibidos])
  return (
    <initializeChartsDashboardContext.Provider
      value={{
        metricType,
        periodType,
        year,
        month,
        years,
        months,
        handleChangeMetricType,
        handleChangePeriodType,
        handleChangeYear,
        handleChangeMonth,
      }}
    >
      <div className="ChartsDashboard">
        <ControllerChartsDashboard />
        <ChartDashboard />
      </div>
    </initializeChartsDashboardContext.Provider>
  )
}

export default ChartsDashboard
