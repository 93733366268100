// Import Modules
import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

// Import Handlers
import { auth } from '../../firebase'
import firebase from 'firebase/app'

// Import Context
import Global from 'contexts/Global/Global'
// Import Hooks
import { useDispatch } from 'react-redux'
import { setUser } from '../../actions/users'

// Import StyleSheet
import './Ingresar.css'

// Import Images
import logo from '../../assets/logo_white.png'
import logoBlack from '../../assets/logo_black.png'
import ilustratorIngresar from '../../assets/ilustrator_ingresar.jpg'

const Ingresar = () => {
  const globalContext = useContext(Global)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [remember, setRemember] = useState(false)

  const hadleKeyboards = (event) => {
    if (event.key === 'Enter') {
      let btnIngresar = document.querySelector('.btnIngresar')
      btnIngresar.click()
    }
  }

  const handleLogin = async () => {
    if (remember) await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

    const email = document.querySelector('input.email').value + '@savetax.com'
    const password = document.querySelector('input.password').value

    try {
      const user = await auth.signInWithEmailAndPassword(email, password)

      console.log(user)
      toast.success('Bienvenido estimado, autenticacion satisfactoria ')
      navigate('/escritorio')

    } catch (error) {
      console.error(error.message)

      switch (error.code) {
        case 'auth/wrong-password':
          toast.error('Contraseña Incorrecta')
          return
        case 'auth/invalid-email':
          toast.error('Correo Electrónico con formato invalido')
          return
        case 'auth/user-not-found':
          toast.error('Este usuario no esta asignado a ningún miembro de la plataforma')
          return
        default:
          return
      }
    }
  }

  const viewPassword = (event) => {
    let target = event.target
    let password = document.querySelector('.password')
    if (password.type === 'password') {
      password.type = 'text'
      target.className = 'desactive'
    } else {
      password.type = 'password'
      target.className = 'active'
    }
  }

  return (
    <div className="Ingresar" onKeyUp={hadleKeyboards}>
      <div className="FormLoginContainer">
        <div className="logo">
          <Link to="/" tabIndex="-1">
            <img
              src={
                globalContext.conf.schemaColor === 'schema_predeterminado' || globalContext.conf.schemaColor === null
                  ? logoBlack
                  : logo
              }
              alt="Logotipo de Savetax"
            />
          </Link>
        </div>
        <div className="Form">
          <h4>Ingresar credenciales</h4>
          <p> </p>

          <label htmlFor="">
            Usuario
            <div className="field">
              <input type="text" placeholder="Usuario" className="email" />
            </div>
          </label>
          <label htmlFor="">
            Contraseña
            <div className="field">
              <input type="password" placeholder="Contraseña" className="password" />
              <div className="options">
                <button className="desactive" onClick={viewPassword} tabIndex="-1">
                  <i className="bi bi-eye-fill"></i>
                </button>
              </div>
            </div>
          </label>
          <div className="optionsSecondaryForm">
            <label htmlFor="recuerdamePassword">
              <input
                type="checkbox"
                id="recuerdamePassword"
                tabIndex="-1"
                value={remember}
                checked={remember}
                onChange={() => setRemember((prev) => !prev)}
              />
              Recuérdame
            </label>
            <Link to="/recuperar" tabIndex="-1">
              Olvidaste tu contraseña?
            </Link>
          </div>
          <button className="btnIngresar" onClick={handleLogin}>
            Ingresar
          </button>
          <p className="registerLink">
            ¿No tienes una cuenta? <Link to="/registro">Registrate</Link>
          </p>
        </div>
      </div>
      <div className="illustrationIngresar">
        <img src={ilustratorIngresar} alt="" />
      </div>
    </div>
  )
}

export default Ingresar
