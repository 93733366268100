import { gql } from '@apollo/client'

export const DELETE_CIA = gql`
  mutation DeleteCia($codCia: smallint) {
    delete_cia(where: { cod_cia: { _eq: $codCia } }) {
      affected_rows
    }
  }
`

export const SAVE_CIA = gql`
  mutation SaveCia($data: [cia_insert_input!]!) {
    insert_cia(objects: $data) {
      returning {
        id
      }
    }
  }
`

export const GET_MAX_COD_CIA = gql`
  query GetMaxCodCia {
    cia(limit: 1, order_by: { fecha_registro: desc }) {
      cod_cia
      fecha_registro
    }
  }
`
