import React from 'react'
import { ReactComponent as Search } from 'assets/search.svg'

const CabysSidebar = ({ product, handleChange }) => {
  return (
    <>
      <label htmlFor="BuscarDescripción" className="text">
        Buscar Descripción o Categoria
        <div className="searchForm">
          <input
            type="text"
            placeholder="Buscar Descripción o Categoria"
            id="BuscarDescripción"
            onChange={handleChange}
            value={product?.filtros?.search ?? ''}
            name="filtros.search"
          />
          <button className="searchPersonaForID">
            <Search />
          </button>
        </div>
      </label>

      <label htmlFor="BuscarCodigo" className="text">
        Buscar por Codigo
        <div className="searchForm">
          <input
            type="text"
            placeholder="Buscar por Codigo"
            id="BuscarCodigo"
            onChange={handleChange}
            name="filtros.codigo"
            value={product?.filtros?.codigo ?? ''}
          />
          <button className="searchPersonaForID">
            <Search />
          </button>
        </div>
      </label>

      <label htmlFor="BuscarCodigo" className="text">
        Caby Seleccionado
        <ul className="cabySelecionadoList">
          <li>
            <b>Código</b>
            <span>{product?.cabysSeleccionado?.codigo ?? product?.cabys?.codigo ?? 'No definido'}</span>
          </li>
          <li>
            <b>Descripción</b>
            <span>{product?.cabysSeleccionado?.descripcion ?? product?.cabys?.descripcion ?? 'No definido'}</span>
          </li>
          <li>
            <b>Tarifa</b>
            <span>{product?.cabysSeleccionado?.impuesto ?? product?.cabys?.impuesto ?? 'No definido'}</span>
          </li>
          <li>
            <b>Categoria 1</b>
            <span>{product?.cabysSeleccionado?.descripcion1 ?? product?.cabys?.descripcion1 ?? 'No definido'}</span>
          </li>
          <li>
            <b>Categoria 2</b>
            <span>{product?.cabysSeleccionado?.descripcion2 ?? product?.cabys?.descripcion2 ?? 'No definido'}</span>
          </li>
          <li>
            <b>Categoria 3</b>
            <span>{product?.cabysSeleccionado?.descripcion3 ?? product?.cabys?.descripcion3 ?? 'No definido'}</span>
          </li>
        </ul>
      </label>
    </>
  )
}

export default CabysSidebar
