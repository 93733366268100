import React, { useState } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import useFacturas from '../../../hooks/useFacturas'

const Filters = ({ setter }) => {
  const { facturasElectronicas } = useFacturas()
  const [dateState, setDateState] = useState(null)
  const [filtros, setFiltros] = useState({
    search: '',
    estadoDocumento: '',
    estadoHacienda: '',
  })

  const clearFilters = () => {
    setFiltros({
      search: '',
      estadoDocumento: '',
      estadoHacienda: '',
    })
    setDateState(null)
    setter(facturasElectronicas)
  }

  const handleDropDownFilter = (event) => {
    let dropDownFilter = document.querySelector('.dropDownFilter')
    let target = event.target
    if (dropDownFilter.className.includes('toggled')) {
      dropDownFilter.className = 'dropDownFilter'
      target.className = 'toggledFilters toggled'
    } else {
      dropDownFilter.className = 'dropDownFilter toggled'
      target.className = 'toggledFilters'
    }
  }

  const filterDocumentos = (name, value) => {
    if (facturasElectronicas.length) {
      if (name === 'search') {
        return filterForSearch(facturasElectronicas, value)
      } else if (name === 'estadoDocumento') {
        return filterEstadoDocumento(facturasElectronicas, value)
      } else if (name === 'estadoHacienda') {
        return filterEstadoHacienda(facturasElectronicas, value)
      } else if (name === 'clear') {
        return facturasElectronicas
      } else if (name === 'date') {
        return filterForDate(facturasElectronicas, value)
      }
    } else {
      return []
    }
  }

  const handleFilter = (event) => {
    const { value, name } = event.target
    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
    setter(filterDocumentos(name, value))
  }

  const filterForDate = (documentosMatched, value) => {
    let result = documentosMatched
    if (value) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(value[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(value[1]).getTime()
      )
    }

    if (result.length && filtros?.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros?.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros?.search))
      )
    }

    if (result.length && filtros?.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros?.estadoDocumento
      })
    }

    if (result.length && filtros?.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros?.estadoHacienda
        }
      })
    }

    return result
  }

  const filterForSearch = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      console.log(result)
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(value)) ||
          doc.cliente.ruc.includes(value)
      )
    }

    if (result.length && filtros?.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros?.estadoDocumento
      })
    }

    if (result.length && filtros?.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros?.estadoHacienda
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterEstadoDocumento = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        return doc.status === value
      })
    }

    if (result.length && filtros?.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros?.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros?.search))
      )
    }

    if (result.length && filtros?.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros?.estadoHacienda
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterEstadoHacienda = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === value
        }
      })
    }

    if (result.length && filtros?.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros?.estadoDocumento
      })
    }

    if (result.length && filtros?.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros?.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros?.search))
      )
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const updateRangeDate = (date) => {
    setDateState(date)
    setter(filterDocumentos('date', date))
  }

  return (
    <>
      <button className="toggledFilters" onClick={handleDropDownFilter}>
        <i className="bi bi-chevron-down"></i>
      </button>
      <div className="dropDownFilter toggled" style={{ width: '65%' }}>
        <label htmlFor="" style={{ width: '35%' }}>
          Nombre Cliente o Referencia
          <div className="search">
            <input
              value={filtros?.search.toUpperCase()}
              type="text"
              placeholder="Nombre o cedula de Emisor"
              onChange={handleFilter}
              name="search"
            />
            <button>
              <i className="bi bi-search"></i>
            </button>
          </div>
        </label>
        <label htmlFor="" style={{ width: '25%' }}>
          Estado de Receptor
          <select name="estadoDocumento" id="" value={filtros?.estadoDocumento} onChange={handleFilter}>
            <option value="">Todos los Estados</option>
            <option value="G">Generado</option>
            <option value="R">Registrado</option>
            <option value="A">Anulado</option>
          </select>
        </label>
        <label htmlFor="" style={{ width: '25%' }}>
          Estado Hacienda
          <select name="estadoHacienda" id="" value={filtros?.estadoHacienda} onChange={handleFilter}>
            <option value="">Todos los Estados</option>
            <option value="PENDIENTE">Pendiente</option>
            <option value="ACEPTADO">Aceptado</option>
            <option value="RECHAZADO">Rechazado</option>
          </select>
        </label>
        <label htmlFor="" className="togglebled">
          Rango de fecha
          <DateRangePicker onChange={(date) => updateRangeDate(date)} value={dateState} />
        </label>
        <label htmlFor="" style={{ width: '15%' }} onClick={clearFilters}>
          Limpiar Filtros
          <button className="clearFilterButton">Limpiar</button>
        </label>
      </div>
    </>
  )
}

export default Filters
