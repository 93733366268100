/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
// Import Modules
import React, { useState, useEffect } from 'react'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import FormFacturasElectronicas from 'components/Facturacion/FormFactura/FormFacturasElectronicas'

// Import Hooks
import useFacturas from 'hooks/useFacturas'
import { useDispatch } from 'react-redux'
import useUser from 'hooks/useUser'
import { getNotasDeCredito } from 'actions/facturas'
import useFacturacion from '../hooks/useFacturacion'
import { ReactComponent as Create } from 'assets/create.svg'
import logo from 'assets/logo_black.png'

// Import StyleSheet
import './NotaCredito.css'
import { CREATE_FACTURA_ELECTRONICA } from 'constants/actionTypes'
import TableItem from '../FacturaElectronicas/TableItem'

const NotaCredito = () => {
  const [dateState, setDateState] = useState(null)
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { isLoadingNotasDeCredito, notasDeCredito } = useFacturas()
  const [facturasElectronicasFiltradas, setFacturasElectronicasFiltradas] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filtros, setFiltros] = useState({
    search: '',
    estadoDocumento: '',
    estadoHacienda: '',
  })
  const { onOpen, onClose, onEdit, open, editFactura, setOpen, setEditFactura } = useFacturacion(
    isLoadingNotasDeCredito,
    getNotasDeCredito,
    {
      open: false,
      currentType: 4,
      factura: null,
    }
  )
  const [modal, setModal] = useState({
    boolean: false,
    data: {},
  })

  const handleDropDownFilter = (event) => {
    let dropDownFilter = document.querySelector('.dropDownFilter')
    let target = event.target
    if (dropDownFilter.className.includes('toggled')) {
      dropDownFilter.className = 'dropDownFilter'
      target.className = 'toggledFilters toggled'
    } else {
      dropDownFilter.className = 'dropDownFilter toggled'
      target.className = 'toggledFilters'
    }
  }

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  const filterForDate = (documentosMatched, value) => {
    let result = documentosMatched
    if (value) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(value[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(value[1]).getTime()
      )
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros.search))
      )
    }

    if (result.length && filtros.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros.estadoDocumento
      })
    }

    if (result.length && filtros.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estadoHacienda
        }
      })
    }
    return result
  }

  const filterForSearch = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(value.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(value))
      )
    }

    if (result.length && filtros.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros.estadoDocumento
      })
    }

    if (result.length && filtros.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estadoHacienda
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterEstadoDocumento = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        return doc.status == value
      })
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros.search))
      )
    }

    if (result.length && filtros.estadoHacienda.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === filtros.estadoHacienda
        }
      })
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterEstadoHacienda = (documentosMatched, value) => {
    let result = documentosMatched
    if (value.length) {
      result = result.filter((doc) => {
        if (value === 'PENDIENTE') {
          return !doc.descripcionSri
        } else {
          return doc.descripcionSri === value
        }
      })
    }

    if (result.length && filtros.estadoDocumento.length) {
      result = result.filter((doc) => {
        return doc.status === filtros.estadoDocumento
      })
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (doc) =>
          doc.cliente.nombreCliente.toUpperCase().includes(filtros.search.toUpperCase()) ||
          (doc.referencia && doc.referencia.includes(filtros.search))
      )
    }

    if (dateState) {
      result = result.filter(
        (doc) =>
          new Date(doc.fechaEmision).getTime() >= new Date(dateState[0]).getTime() &&
          new Date(doc.fechaEmision).getTime() <= new Date(dateState[1]).getTime()
      )
    }

    return result
  }

  const filterDocumentos = (name, value) => {
    let documentosMatched = notasDeCredito
    if (documentosMatched.length) {
      if (name === 'search') {
        return filterForSearch(documentosMatched, value)
      } else if (name === 'estadoDocumento') {
        return filterEstadoDocumento(documentosMatched, value)
      } else if (name === 'estadoHacienda') {
        return filterEstadoHacienda(documentosMatched, value)
      } else if (name === 'clear') {
        return documentosMatched
      } else if (name === 'date') {
        return filterForDate(documentosMatched, value)
      }
    } else {
      return []
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
    setFacturasElectronicasFiltradas(filterDocumentos(name, value))
  }

  const updateRangeDate = (date) => {
    setDateState(date)
    setFacturasElectronicasFiltradas(filterDocumentos('date', date))
  }

  const Actualizar = () => {
    setFacturasElectronicasFiltradas(dispatch(getNotasDeCredito(savetaxInfo?.cia?.codCia)))
  }

  const clearFilters = () => {
    setFiltros({
      search: '',
      estadoDocumento: '',
      estadoHacienda: '',
    })
    setDateState(null)
    setFacturasElectronicasFiltradas(notasDeCredito)
  }

  useEffect(() => {
    if (!notasDeCredito.length) {
      dispatch(getNotasDeCredito(savetaxInfo.cia.codCia))
    }

    setFacturasElectronicasFiltradas(notasDeCredito)
  }, [savetaxInfo, dispatch])
  /*const lookedItem = useMemo(() => {
    const item = facturasElectronicas[Number(_.findKey(facturasElectronicas, (i) => i.vtaMovmPK.numDocto === +facturaID))] ?? null
    if (item) return { ...item, isEdit: true }
    else return undefined
  }, [facturasElectronicas, facturaID])*/
  //const [setEditFactura] = useState(lookedItem ?? {})
  const seeFactura = (factura) => {
    setOpen(true)
    setEditFactura({ ...factura, isEdit: true })
  }

  const createAlertConfirm = (title, description, callback) => {
    let TitleAlert = document.querySelector('.TitleAlert')
    let descriptionAlert = document.querySelector('.descriptionAlert')
    let ConfirmAlert = document.querySelector('.ConfirmAlert')
    let alertContainer = document.querySelector('.alertContainer')
    alertContainer.className = 'alertContainer'
    TitleAlert.innerHTML = title
    descriptionAlert.innerHTML = description
    let controller = () => {
      callback()
      closeAlert()
      ConfirmAlert.removeEventListener('click', controller)
    }

    ConfirmAlert.addEventListener('click', controller)
  }

  const closeAlert = (event) => {
    let alertContainer = document.querySelector('.alertContainer')
    alertContainer.className = 'alertContainer toggled'
  }

  return (
    <>
      <div className="NotaCredito" onScroll={hadleScrollSection}>
        <div className="navSection">
          <button className="toggledFilters" onClick={handleDropDownFilter}>
            <i className="bi bi-chevron-down"></i>
          </button>
          <div className="dropDownFilter toggled">
            <label htmlFor="" style={{ width: '35%' }}>
              Nombre Cliente o Referencia
              <div className="search">
                <input
                  value={filtros.search.toUpperCase()}
                  type="text"
                  placeholder="Nombre o cedula de Emidor"
                  onChange={hadleFilter}
                  name="search"
                />
                <button>
                  <i className="bi bi-search"></i>
                </button>
              </div>
            </label>
            <label htmlFor="" style={{ width: '25%' }}>
              Estado de Receptor
              <select name="estadoDocumento" id="" value={filtros.estadoDocumento} onChange={hadleFilter}>
                <option value="">Todos los Estados</option>
                <option value="G">Generado</option>
                <option value="R">Registrado</option>
                <option value="A">Anulado</option>
              </select>
            </label>
            <label htmlFor="" style={{ width: '25%' }}>
              Estado Hacienda
              <select name="estadoHacienda" id="" value={filtros.estadoHacienda} onChange={hadleFilter}>
                <option value="">Todos los Estados</option>
                <option value="PENDIENTE">Pendiente</option>
                <option value="ACEPTADO">Aceptado</option>
                <option value="RECHAZADO">Rechazado</option>
              </select>
            </label>
            <label htmlFor="" className="togglebled">
              Rango de fecha
              <DateRangePicker onChange={(date) => updateRangeDate(date)} value={dateState} />
            </label>
            <label htmlFor="" style={{ width: '15%' }} onClick={clearFilters}>
              Limpiar Filtros
              <button className="clearFilterButton">Limpiar</button>
            </label>
          </div>
          <button className="addContent" onClick={Actualizar} style={{ width: '20%' }}>
            Actualizar
            <i className="bi bi-arrow-clockwise"></i>
          </button>
          <button className="addContent" onClick={onOpen}>
            Agregar Nota de crédito
            <Create />
          </button>
        </div>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-2">
                <b>Cliente</b>
              </li>
              <li className="col-1">
                <b>Prefijo</b>
              </li>
              <li className="col-1">
                <b>Factura</b>
              </li>
              <li className="col-1">
                <b>Moneda</b>
              </li>
              <li className="col-1">
                <b>Monto</b>
              </li>
              <li className="col-1">
                <b>Emisión</b>
              </li>
              <li className="col-1">
                <b>Est Hacienda</b>
              </li>
              <li className="col-1">
                <b>Est Doc</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>

          {isLoadingNotasDeCredito ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Notas de Crédito</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {facturasElectronicasFiltradas.length > 0 &&
                  facturasElectronicasFiltradas
                    //.sort((a, b) => b?.vtaMovmPK?.numDocto - a?.vtaMovmPK?.numDocto)
                    .slice(50 * currentPage - 50, 50 * currentPage)
                    .map((factura, index) => (
                      <TableItem
                        key={'factura-' + index}
                        factura={factura}
                        createAlertConfirm={createAlertConfirm}
                        onClickSee={() => seeFactura(factura)}
                        //onCopy={() => copyFactura(factura)}
                      />
                    ))}
                {!facturasElectronicasFiltradas.length && (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>

        {!isLoadingNotasDeCredito && facturasElectronicasFiltradas.length > 0 && (
          <div className="paginationTable">
            <div className="controllers">
              <button className="previousPage hidden">
                <i className="bi bi-chevron-left"></i>
              </button>
              <span>
                Página {currentPage} de {Math.ceil(facturasElectronicasFiltradas.length / 50)}
              </span>
              {facturasElectronicasFiltradas.length > 50 && (
                <button className="nextPage">
                  <i className="bi bi-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {modal.boolean && (
        <section className="modal-factura">
          <button onClick={() => setModal(false)} className="close-modal-fac">
            X
          </button>
          <div className="container-title-modal">
            <h2 className="title-modal-fac">Gracias por usar</h2>
            <img src={logo} alt="logo" width={200} />
          </div>
          <p className="text-modal-fac">Su Nota de Credito fue cargada con exito!</p>
          <button
            className="btn-modal-fac"
            onClick={() => {
              const newUrl = `/documentosEmitidos/${modal.data?.vtaMovmPK?.numDocto}`
              window.location.href = newUrl
            }}
          >
            Ver documento
          </button>
        </section>
      )}
      {open && (
        <FormFacturasElectronicas
          tipoFactura={4}
          facturaActionType={CREATE_FACTURA_ELECTRONICA}
          editFactura={editFactura}
          handleClose={onClose}
          setModal={setModal}
          title="Nota de crédito"
          isNota
        />
      )}
    </>
  )
}

export default NotaCredito
