import { getRoles } from 'actions/administracion'
import { createUsuario, updateUsuario } from 'actions/users'
import CircularProgress from 'components/CircularProgress/CircularProgress'
import Text from 'components/FormField/Text'
import KeyView from 'components/KeyView/KeyView'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const UsuariosKeyview = ({ usuario: U, onClose, actualizarUser }) => {
  const dispatch = useDispatch()
  const { isEdit } = U ?? {}
  const { savetaxInfo } = useUser()
  const { roles } = useAdmin()
  const [user, setUser] = useState(
    U ?? {
      correo: '',
      direccion: '',
      estado: '',
      identificacion: '',
      nombreUsuario: '',
      password: '',
      telefono: '',
      username: '',
      usuarioPK: {
        codCia: savetaxInfo.cia.codCia,
      },
      usuarioRolList: [],
      cia: savetaxInfo.cia,
    }
  )
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)

  const determineValue = () => {

    if (user?.usuarioRolList.length > 0) {
      var possibleValue = user?.usuarioRolList[0]?.rolPK?.codRol
      if (possibleValue == undefined) {
        var possibleValue = user?.usuarioRolList[0].usuarioRolPK.codRol
      }
      return possibleValue ?? ''
    } else {
      return ''
    }
  }

  const selectValue = determineValue()


  const handleSubmit = async (e) => {

    e.preventDefault()

    if (!user?.username) {
      toast.error('El usuario es obligatorio')
      return
    } else if (!user?.nombreUsuario) {
      toast.error('Nombre y apellido es obligatorio')
      return
    } else if (user.usuarioRolList.length == 0) {
      toast.error('El rol es obligatorio')
      return
    } else if (!user?.identificacion) {
      toast.error('El número de identificación es obligatorio')
      return
    } else if (!user?.estado) {
      toast.error('El estado es obligatorio')
      return
    }

    console.log(user)
    var userSend = { ...user }
    userSend.codRolUser = userSend.usuarioRolList[0].rolPK.codRol
    userSend.usuarioRolList = null
    if (isEdit) {
      setLoading(true)
      dispatch(updateUsuario(userSend)).then((r) => {
        if (r) {
          onClose()
        }
        actualizarUser()
        setLoading(false)
      })
    } else {
      setLoading(true)
      dispatch(createUsuario(userSend)).then((r) => {
        if (r) {
          onClose()
        }
        actualizarUser()
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (!roles.length) {
      dispatch(getRoles(savetaxInfo.cia.codCia))
    }
  }, [roles, savetaxInfo, dispatch])

  return (
    <KeyView title="Gestión usuarios" onClose={onClose} className="keyViewUser">
      <div className="containerMain">
        <div class="inner-div">
          <Text
            className="textForm"
            label="* Usuario"
            placeholder="Usuario123"
            value={user?.username ?? ''}
            required={true}
            onChange={(e) => setUser((p) => ({ ...p, username: e.target.value }))}
          />

          <Text disabled={true} required={true} label="Compañía" value={user?.cia?.nombreCia ?? savetaxInfo?.cia?.nombreCia} />

          <Text
            label="* Nombre y Apellido"
            value={user?.nombreUsuario ?? ''}
            required={true}
            placeholder="James Gonzalez"
            onChange={(e) => setUser((p) => ({ ...p, nombreUsuario: e.target.value }))}
          />
          <Text
            label="* Número de identificación"
            value={user?.identificacion ?? ''}
            placeholder="15975345"
            onChange={(e) => setUser((p) => ({ ...p, identificacion: e.target.value }))}
          />


        </div>
        <div class="inner-div">
          <Text
            label="Correo"
            required={true}
            value={user?.correo ?? ''}
            placeholder="juan@domain.com"
            onChange={(e) => setUser((p) => ({ ...p, correo: e.target.value }))}
          />

          <Text
            label="Teléfono"
            value={user?.telefono ?? ''}
            placeholder="15975346"
            onChange={(e) => setUser((p) => ({ ...p, telefono: e.target.value }))}
          />

          <label className="text">
            Rol
            <select
              value={selectValue}
              onChange={(e) => {
                console.log(e.target.value)
                if (!e.target.value)
                  return setUser((p) => ({
                    ...p,
                    usuarioRolList: [],
                  }))

                const rol = JSON.parse(e.target[e.target.selectedIndex].dataset.rol)
                setUser((p) => ({
                  ...p,
                  usuarioRolList: [rol],
                }))
              }}
            >
              <option value="">Seleccionar</option>
              {roles?.map((r) => (
                <option key={r.rolPK.codRol} value={r.rolPK.codRol} data-rol={JSON.stringify(r)}>
                  {r.nombreRol}
                </option>
              ))}
            </select>
          </label>

          <label className="text">
            * Estado
            <select
              required
              value={user?.estado ?? ''}
              onChange={(e) =>
                setUser((p) => ({
                  ...p,
                  estado: e.target.value,
                }))
              }
            >
              <option value="">Seleccionar</option>
              {[
                { label: 'Activo', value: 'A' },
                { label: 'Inactivo', value: 'I' },
              ]?.map((s) => (
                <option key={s.value} value={s.value}>
                  {s.label}
                </option>
              ))}
            </select>
          </label>
        </div>

      </div>








      {loading ? <CircularProgress /> : <button onClick={handleSubmit}>{isEdit ? 'Actualizar' : 'Guardar'}</button>}
    </KeyView>
  )
}

export default UsuariosKeyview
