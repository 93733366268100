import React, { useEffect, useState } from 'react'
import useAdmin from 'hooks/useAdmin'
import { ReactComponent as Down } from 'assets/down-chevron.svg'
import { useDispatch } from 'react-redux'
import { getCabys } from 'actions/administracion'

const BuscadorCabys = ({ product, setProduct, setSection }) => {
  const { cabys } = useAdmin()
  const dispatch = useDispatch()
  const { cabysSeleccionado, filtros } = product
  const { search, codigo } = filtros
  const [cabysFiltrados, setCabysFiltrados] = useState(cabys ?? [])
  const [currentPage, setCurrentPage] = useState(0)


  const handleSelectCabys = (cabys) => {
    setSection('productos')
    setProduct((prev) => ({
      ...prev,
      cabys: cabys,
    }))
  }

  useEffect(() => {
    console.log('demdiem')
    dispatch(getCabys)
    setCabysFiltrados(cabys)
  }, [cabys, dispatch])

  return (
    <div className="buscadorCabys sectionForm">
      <div className="titleSectionForm" style={{ width: '100%' }}>
        <h3>Buscador de Cabys</h3>
        <span>{cabys.length.toLocaleString('es')} Cabys disponibles</span>
      </div>

      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-1-2">
              <b></b>
            </li>
            <li className="col-3">
              <b>Descripción</b>
            </li>
            <li className="col-2-2">
              <b>Código</b>
            </li>
            <li className="col-5">
              <b>Categorias</b>
            </li>
            <li className="col-1-2">
              <b>Tarifa</b>
            </li>
            <li className="col-1-2">
              <b></b>
            </li>
          </ul>
        </div>
        <div className="bodyList">
          <ul>
            {cabysFiltrados.length ? (
              cabysFiltrados
                .filter((caby) => caby.descripcion.toLowerCase().includes(search.toLowerCase()))
                .filter((caby) => caby.codigo.includes(codigo))
                .slice(currentPage * 100, currentPage * 100 + 100)
                .map((caby, index) => (
                  <li key={caby.codigo} className="itemBodyList" onDoubleClick={() => handleSelectCabys(caby)}>
                    <ul
                      className={
                        caby?.codigo === (cabysSeleccionado?.codigo ?? product?.cabys?.codigo)
                          ? 'contentItemBodyList selected'
                          : 'contentItemBodyList'
                      }
                      inhover="out"
                    >
                      <li className="col-1-2">
                        <span>{index + 1}</span>
                      </li>
                      <li className="col-3">
                        <span>{caby.descripcion}</span>
                      </li>
                      <li className="col-2-2 noAction">
                        <b>{caby.codigo}</b>
                      </li>
                      <li className="col-5 noAction">
                        <span>
                          {caby.descripcion1}, {caby.descripcion2}, {caby.descripcion3}, {caby.descripcion4}
                        </span>
                      </li>
                      <li className="col-1-2 noAction">
                        <span>{caby.impuesto}</span>
                      </li>
                    </ul>
                  </li>
                ))
            ) : (
              <li className="emptyResultTable">
                <span>No hay Resultados</span>
              </li>
            )}
          </ul>
        </div>
      </div>

      <div className="pagination">
        <div className="controllers">
          <button
            className="previousPage"
            disabled={!currentPage}
            onClick={() => {
              document.querySelector('.buscadorCabys').scrollTop = 0
              setCurrentPage((prev) => prev - 1)
            }}
          >
            <Down />
          </button>
          <span>
            {currentPage + 1} de{' '}
            {Math.ceil(
              cabysFiltrados
                .filter((caby) => caby.descripcion.toLowerCase().includes(search.toLowerCase()))
                .filter((caby) => caby.codigo.includes(codigo)).length / 100
            )}{' '}
            Páginas
          </span>
          <button
            className="nextPage"
            disabled={
              currentPage ===
              Math.ceil(
                cabysFiltrados
                  .filter((caby) => caby.descripcion.toLowerCase().includes(search.toLowerCase()))
                  .filter((caby) => caby.codigo.includes(codigo)).length / 100
              ) -
                1
            }
            onClick={() => {
              document.querySelector('.buscadorCabys').scrollTop = 0
              setCurrentPage((prev) => prev + 1)
            }}
          >
            <Down />
          </button>
        </div>
      </div>
    </div>
  )
}

export default BuscadorCabys
