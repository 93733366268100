/* eslint-disable no-unused-vars */
// Import Modules
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

// Import Components
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'

// Import StyleSheet
import './History.css'

// Import Hooks
import useUser from 'hooks/useUser'

const HistorialPages = () => {
  const { savetaxInfo } = useUser()
  const [filtros, setFiltros] = useState({
    search: '',
    fechaVencimiento: '',
  })
  const meses = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
  const [payments, setPayments] = useState([])
  const [isLoadingPayments, setIsLoadingPayments] = useState(true)

  const handleSetPayments = async (cia) => {
    let response = await axios.get(`https://test.facturacionsavetaxcr.com/savetax/api/v1/pago/find-by-cod-cia/${cia}`)
    if (!response?.data?.length) {
      setIsLoadingPayments(false)
      return
    }
    setPayments(response.data)
    setIsLoadingPayments(false)
  }

  useEffect(() => {
    if (savetaxInfo?.usuarioPK?.codCia) {
      handleSetPayments(savetaxInfo.usuarioPK.codCia)
    }
  }, [savetaxInfo])
  return (
    <div className="HistorialPages">
      <div className="navSection">
        <div className="dropDownFilter toggled" style={{ width: '100%' }}>
          <label style={{ width: '30%' }}>
            Buscar Metodo de Pago
            <div className="search">
              <input
                // value={filtros.search.toUpperCase()}
                type="text"
                placeholder="Buscar"
                // onChange={hadleFilter}
                name="search"
              />
              <button>
                <i className="bi bi-search"></i>
              </button>
            </div>
          </label>
          <label style={{ width: '30%' }}>
            Rango de fecha de vencimiento
            <DateRangePicker value={null} />
          </label>

          <label style={{ width: '25%' }}>
            Estado
            <select name="" id="">
              <option value="">Todos los Estados</option>
              <option value="">Pagado</option>
              <option value="">Pendiente</option>
            </select>
          </label>
          <label
            style={{ width: '15%' }}
            // onClick={clearFilters}
          >
            Limpiar Filtros
            <button className="clearFilterButton">Limpiar</button>
          </label>
        </div>
      </div>
      {payments.filter((p) => p.estado === 'Pendiente').length > 0 && (
        <div className="pandingAlert">
          <h3>Hay pagos pendientes</h3>
          <p>
            Hay {payments.filter((p) => p.estado === 'Pendiente').length}{' '}
            {payments.filter((p) => p.estado === 'Pendiente').length === 1 ? 'pago' : 'pagos'} pendientes, es necesario que se
            cancelen todos los saldos en mora para que puedas disfrutar de los beneficios ofrecidos por tu plan
          </p>
          <Link to="/metodosPago">Ver metodos de pago</Link>
        </div>
      )}
      <div className="listSection">
        <div className="headList">
          <ul>
            <li className="col-1">
              <b>ID</b>
            </li>
            <li className="col-3">
              <b>Asunto</b>
            </li>
            <li className="col-1">
              <b>Emitido</b>
            </li>
            <li className="col-1">
              <b>Monto</b>
            </li>
            <li className="col-3">
              <b>Metodo</b>
            </li>
            <li className="col-1">
              <b>Estado</b>
            </li>
          </ul>
        </div>

        {isLoadingPayments ? (
          <div className="loadContentTable">
            <div className="snippet"></div>
            <span>Cargando Historial de pagos</span>
          </div>
        ) : (
          <div className="bodyList">
            <ul>
              {payments.length > 0 &&
                payments.slice(0, 50).map((payment, index) => (
                  <li className="itemBodyList">
                    <ul className="contentItemBodyList">
                      <li className="col-1">
                        <span>{payment.id}</span>
                      </li>
                      <li className="col-3">
                        <b>Mensualidad de {meses[payment.mes - 1]}</b>
                      </li>
                      <li className="col-1">
                        <span>{moment(payment.fechaGenerado).format('l')}</span>
                      </li>
                      <li className="col-1">
                        <NumberFormat value={payment.monto} displayType={'text'} thousandSeparator={true} prefix={'₡ '} />
                      </li>
                      <li className="col-3">
                        <span>{payment.pagadoCon || 'No definido'}</span>
                      </li>
                      <li className="col-1">
                        <b className={payment.estado}>{payment.estado}</b>
                      </li>
                    </ul>
                  </li>
                ))}

              {!payments.length && (
                <li className="emptyResultTable">
                  <span>No hay Resultados</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

export default HistorialPages
