import React from 'react'
import logo from '../../assets/logo_black.png'
import './Load.css'

const Load = () => {
  return (
    <div className="loadCompleteScreen">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
    </div>
  )
}

export default Load
