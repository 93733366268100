import React, { useState } from 'react'
import Form from 'components/Form/Form'
import { ReactComponent as Search } from 'assets/search.svg'
import _ from 'lodash'
import useUser from 'hooks/useUser'
import Productos from './Productos'
import BuscadorCabys from './BuscadorCabys'
import CabysSidebar from './CabysSidebar'
import ProductoSidebar from './ProductoSidebar'

const initializeState = (cia) => ({
  cabysSeleccionado: {},
  filtros: {
    search: '',
    codigo: '',
  },
  codigoInterno: '',
  costo: 0,
  estado: '',
  exento: 'N',
  existenciaGlobal: 0,
  fechaIngreso: new Date(Date.now()),
  impuesto: {},
  manejaInventario: false,
  nombreProducto: '',
  partidaArancelaria: '',
  precioVenta: 0,
  productoPK: {
    codCia: cia,
  },
  stockMaximo: 0,
  stockMinimo: 0,
  unidadMedida: {},
})

const FormProducto = ({ producto = undefined, onClose }) => {
  const { savetaxInfo } = useUser()

  const [section, setSection] = useState('productos')
  const [product, setProduct] = useState(producto ?? initializeState(savetaxInfo.cia.codCia))

  const handleChange = (e) => {
    setProduct((prev) => ({ ..._.set(prev, e.target.name, e.target.value) }))
  }

  return (
    <Form title="Productos" onClose={onClose}>
      <div className="FormProducto workspace">
        <div className="mainForm">
          <div className="navigationForm" style={{ width: '100%' }}>
            <button className={`btnSection ${section === 'productos' && 'active'}`} onClick={() => setSection('productos')}>
              Detalles del producto
            </button>
            <div
              className={`searchGeneral btnSection ${section === 'productos/cabys' && 'active'}`}
              style={{ width: '35%' }}
              onClick={() => setSection('productos/cabys')}
            >
              <div className="optionsSearchGeneral">
                <button className="option btnSearch">
                  <Search />
                </button>
              </div>
              <input type="text" placeholder="Buscar Cabys" style={{ width: '40%' }} />
              <div className="optionsSearchGeneral">
                <span>Ctrl + Shift + R + C</span>
              </div>
            </div>
          </div>

          {section === 'productos' ? (
            <Productos product={product} handleChange={handleChange} setProduct={setProduct} />
          ) : (
            <BuscadorCabys product={product} setProduct={setProduct} setSection={setSection} />
          )}
        </div>

        <div className="sidebarForm">
          {section === 'productos' ? (
            <ProductoSidebar product={product} setProduct={setProduct} onClose={onClose} />
          ) : (
            <CabysSidebar product={product} handleChange={handleChange} />
          )}
        </div>
      </div>
    </Form>
  )
}

export default FormProducto
