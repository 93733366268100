import React from 'react'

const Text = ({
  required,
  label,
  value = null,
  onChange,
  placeholder,
  name,
  disabled,
  type = 'text',
  className = '',
  defaultValue,
}) => {
  return (
    <label htmlFor="nombreDescripcion" className={`text ${className}`}>
      {label}
      <input
        defaultValue={defaultValue}
        disabled={disabled}
        type={type}
        onChange={onChange ?? null}
        value={value}
        name={name}
        placeholder={placeholder}
        id={label}
        autoComplete="off"
        required={required}
      />
    </label>
  )
}

export default Text
