import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import './Registro.css'
import logo from 'assets/logo_black.png'
import 'react-credit-cards/es/styles-compiled.css'
import ValidarCedula from './Pasos/ValidarCedula'
import Planes from './Pasos/Planes'
import InfoCliente from './Pasos/InfoCliente'
import Payment from './Pasos/Payment'
import { AnimatePresence } from 'framer-motion'

const variants = (direction) => ({
  initial: { x: direction < 0 ? -300 : 300 },
  animate: { x: 0 },
  exit: { x: direction < 0 ? 300 : -300 },
  transition: { duration: 0.3, type: 'tween' },
})

const Registro = () => {
  const [form, setForm] = useState({
    persona: {
      pais: {
        idPais: 1,
      },
    },
    plan: {},
    pago: {},
  })
  const [step, setStep] = useState(0)
  const [direction, setDirection] = useState(0)

  const getStep = (step) => {
    switch (step) {
      case 0:
        return (
          <ValidarCedula
            variants={variants(direction)}
            setForm={setForm}
            handleChange={handleChange}
            stepUp={handleStepUp}
            form={form}
          />
        )
      case 1:
        return (
          <InfoCliente
            variants={variants(direction)}
            handleChange={handleChange}
            stepUp={handleStepUp}
            stepDown={handleStepDown}
            form={form}
            setForm={setForm}
          />
        )
      case 2:
        return (
          <Planes
            variants={variants(direction)}
            handleChange={handleChange}
            stepUp={handleStepUp}
            stepDown={handleStepDown}
            form={form}
            setForm={setForm}
          />
        )
      case 3:
        return (
          <Payment
            variants={variants(direction)}
            handleChange={handleChange}
            stepUp={handleStepUp}
            stepDown={handleStepDown}
            form={form}
            setForm={setForm}
          />
        )
      default:
        return null
    }
  }

  const handleChange = useCallback((e) => {
    setForm((prev) => ({ ..._.set(prev, e.target.name, e.target.value) }))
  }, [])

  const handleStepUp = () => {
    setDirection(1)
    setStep((prev) => prev + 1)
  }
  const handleStepDown = () => {
    setDirection(-1)
    setStep((prev) => prev - 1)
  }

  return (
    <div className="Registro">
      <div className="navRegistro">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <Link to="/">Iniciar Sesion</Link>
      </div>

      <AnimatePresence initial={false}>
        <div className="FormLoginContainer">{getStep(step)}</div>
      </AnimatePresence>
    </div>
  )
}

export default Registro
