import { getProductos } from 'actions/productos'
import { ReactComponent as Create } from 'assets/create.svg'
import { ReactComponent as Edit } from 'assets/edit.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import Pagination from 'components/common/Pagination'
import useProductos from 'hooks/useProductos'
import useUser from 'hooks/useUser'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FormProducto from './FormProducto/FormProducto'
import './Productos.css'

const Productos = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { productos, isLoadingProductos } = useProductos()
  const { search } = useLocation()
  const searchParams = Object.fromEntries(new URLSearchParams(search))
  const { productoID } = searchParams

  const lookedItem = useMemo(() => {
    const item = productos[Number(_.findKey(productos, (i) => i.productoPK.idProducto === productoID))] ?? null
    if (item)
      return {
        ...item,
        isEdit: true,
        filtros: {
          search: '',
          codigo: '',
        },
      }
    else return undefined
  }, [productos, productoID])

  const [active, setActive] = useState(lookedItem ? true : false)
  const [productoSeleccionado, setProductoSeleccionado] = useState(lookedItem ?? undefined)
  const [productosFiltrados, setProductosFiltrados] = useState([])
  const [filtros, setFiltros] = useState({
    search: '',
    estado: '',
  })
  const [currentPage, setCurrentPage] = useState(1)

  const clearFilters = () => {
    setProductosFiltrados(productos)
    setFiltros({
      search: '',
      estado: '',
    })
  }

  const handleOnMouseOverItem = (event) => {
    event.preventDefault()
    let target = event.target
    if (target.attributes['inhover']) {
      target.attributes['inhover'].value = 'hover'
    }
  }

  const handleOnMouseLeaveItem = (event) => {
    event.preventDefault()
    let target = event.target
    if (target.attributes['inhover']) {
      target.attributes['inhover'].value = 'out'
    }
  }

  const filterForSearch = (productosFiltrados, value) => {
    let result = productosFiltrados
    if (value.length) {
      result = result.filter(
        (producto) =>
          producto.nombreProducto.toUpperCase().includes(value.toUpperCase()) || producto.productoPK.idProducto.includes(value)
      )
    }

    if (result.length && filtros.estado.length) {
      if (filtros.estado === 'I') {
        result = result.filter((producto) => !producto.estado)
      } else {
        result = result.filter((producto) => producto.estado === filtros.estado)
      }
    }
    return result
  }

  const filterForEstado = (productosMatch, value) => {
    let result = productosMatch
    if (value.length) {
      result = result.filter((producto) => producto.estado === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (producto) =>
          producto.nombreProducto.toUpperCase().includes(filtros.search.toUpperCase()) ||
          producto.productoPK.idProducto.includes(filtros.search)
      )
    }
    return result
  }

  const filterProducto = (name, value) => {
    let productosMatch = productos
    if (productosMatch.length) {
      if (name === 'estado') {
        return filterForEstado(productosMatch, value)
      } else if (name === 'search') {
        return filterForSearch(productosMatch, value)
      } else if (name === 'clear') {
        return productosMatch
      }
    } else {
      return []
    }
  }

  const hadleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setProductosFiltrados(filterProducto(name, value))
    setFiltros((prev) => ({ ...prev, [name]: value }))
  }

  const hadleScrollSection = (event) => {
    let target = event.target
    let scrollTop = target.scrollTop
    let navSection = target.querySelector('.navSection')
    if (scrollTop > 100) {
      navSection.className = 'navSection float'
    } else {
      navSection.className = 'navSection'
    }
  }

  const onClose = () => {
    setActive(false)
    setProductoSeleccionado(undefined)
  }

  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      let key = event.key
      if (key === 'e') {
        if (document.querySelector('.contentItemBodyList[inhover="hover"]')) {
          document.querySelector('.contentItemBodyList[inhover="hover"]').querySelector('.btnEditItem').click()
        }
      }
    })
  }, [])

  useEffect(() => {
    if (isLoadingProductos) {
      dispatch(getProductos(savetaxInfo.cia.codCia))
    }

    setProductosFiltrados(productos)
  }, [dispatch, productos, savetaxInfo, isLoadingProductos])

  return (
    <>
      <div className="Productos" onScroll={hadleScrollSection}>
        <div className="navSection">
          <b className="title">Filtra Por:</b>
          <label htmlFor="nombre" style={{ width: '300px' }}>
            Nombre de Producto
            <div className="search">
              <input
                id="nombre"
                value={filtros.search.toUpperCase()}
                type="text"
                placeholder="Nombre de Producto"
                onChange={hadleFilter}
                name="search"
              />
              <button>
                <Search />
              </button>
            </div>
          </label>
          <div className="sortBy">
            <label htmlFor="estado" style={{ width: '150px' }}>
              Estado
              <select id="estado" value={filtros.estado} onChange={hadleFilter} name="estado">
                <option value="">Todos los Estados</option>
                <option value="A">Activo</option>
                <option value="I">Inactivo</option>
              </select>
            </label>
            <label htmlFor="" style={{ width: '150px' }}>
              Limpiar Filtros
              <button onClick={clearFilters}>Limpiar</button>
            </label>
          </div>
          <button className="addContent" onClick={() => setActive(true)}>
            Agregar Producto
            <Create />
          </button>
        </div>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1-2">
                <b>ID</b>
              </li>
              <li className="col-2">
                <b>Nombre Producto</b>
              </li>
              <li className="col-2">
                <b>Unidad Medida</b>
              </li>
              <li className="col-2">
                <b>Cabys</b>
              </li>
              <li className="col-2">
                <b>Tipo IVA </b>
              </li>
              <li className="col-1">
                <b>Tarifa IVA </b>
              </li>
              <li className="col-1">
                <b>Estado</b>
              </li>
              <li className="col-1-2">
                <b></b>
              </li>
            </ul>
          </div>

          {isLoadingProductos ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando prductos</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {productosFiltrados.length ? (
                  productosFiltrados
                    .sort((a, b) => a.productoPK.idProducto - b.productoPK.idProducto)
                    .slice(50 * currentPage - 50, 50 * currentPage)
                    .map((producto, index) => (
                      <li key={producto.productoPK.idProducto} className="itemBodyList">
                        <ul
                          className="contentItemBodyList"
                          inhover="out"
                          onMouseOver={handleOnMouseOverItem}
                          onMouseOut={handleOnMouseLeaveItem}
                        >
                          <li className="col-1-2 noAction">
                            <span>{producto.productoPK.idProducto}</span>
                          </li>
                          <li className="col-2 noAction">
                            <b>{producto.nombreProducto.toUpperCase()}</b>
                          </li>
                          <li className="col-2 noAction">
                            <span>{producto.unidadMedida.nombreUnidad}</span>
                          </li>
                          <li className="col-2 noAction">
                            <span>{producto?.cabys?.descripcion ?? ''}</span>
                          </li>
                          <li className="col-2 noAction">
                            <span>{producto.impuesto ? producto.impuesto.tipoImpuesto.descripcion : 'No definido'}</span>
                          </li>
                          <li className="col-1 noAction">
                            <span>{producto.impuesto ? producto.impuesto.descripcion : 'No definido'}</span>
                          </li>
                          <li className="col-1 noAction">
                            <span className={producto.estado === 'A' ? 'activo' : 'desactivo'}>
                              {producto.estado === 'A' ? 'Activo' : 'Inactivo'}
                            </span>
                          </li>
                          <li className="col-1-2">
                            <button
                              className="btnEditItem"
                              onClick={() => {
                                setActive(true)
                                setProductoSeleccionado({
                                  isEdit: true,
                                  filtros: {
                                    search: '',
                                    codigo: '',
                                  },
                                  ...producto,
                                })
                              }}
                            >
                              <Edit />
                            </button>
                          </li>
                        </ul>
                      </li>
                    ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        {!isLoadingProductos && <Pagination currentPage={currentPage} setPage={setCurrentPage} data={productosFiltrados} />}
      </div>
      {active && <FormProducto producto={productoSeleccionado} onClose={onClose} />}
    </>
  )
}

export default Productos
