/* eslint-disable no-unused-vars */
import { deleteActividadEconomica, getActividadesEconomicas } from 'actions/administracion'
import { ReactComponent as Create } from 'assets/create.svg'
import { ReactComponent as Delete } from 'assets/delete.svg'
import { ReactComponent as Edit } from 'assets/edit.svg'
import { ReactComponent as Search } from 'assets/search.svg'
import Pagination from 'components/common/Pagination'
import useAdmin from 'hooks/useAdmin'
import useUser from 'hooks/useUser'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './ActividadesEconomicas.css'
import ActividadesEconomicasKeyview from './ActividadesEconomicasKeyview'
import { createAlertConfirm } from 'components/TopBar/UserModal/UserModal'
import axios from 'axios'
import { getInstance } from 'api/apiInstance'

const ActividadesEconomicas = () => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()
  const { actividadesEconomicas, isLoadingActividadesEconomicas } = useAdmin()

  const [actividadesEconomicasFiltradas, setActividadesEconomicasFiltradas] = useState([])
  const [filtros, setFiltros] = useState({
    estado: '',
    search: '',
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [actividadSeleccionada, setActividadSeleccionada] = useState(undefined)
  const [active, setActive] = useState(false)

  const clearFilters = () => {
    setFiltros({
      estado: '',
      search: '',
    })
    setActividadesEconomicasFiltradas(actividadesEconomicas)
  }

  const filterForEstado = (actividadesEconomicasMatch, value) => {
    let result = actividadesEconomicasMatch
    if (value.length) {
      result = result.filter((actividad) => actividad.estado === value)
    }

    if (result.length && filtros.search.length) {
      result = result.filter(
        (actividad) =>
          actividad.descripcion.toUpperCase().includes(filtros.search.toUpperCase()) ||
          actividad.actividadEconomicaCiaPK.codActividad.includes(filtros.search)
      )
    }
    return result
  }

  const filterForSearch = (actividadesEconomicasMatch, value) => {
    let result = actividadesEconomicasMatch
    if (value.length) {
      result = result.filter(
        (actividad) =>
          actividad.descripcion.toUpperCase().includes(value.toUpperCase()) ||
          actividad.actividadEconomicaCiaPK.codActividad.toString().includes(value)
      )
    }

    if (result.length && filtros.estado.length) {
      result = result.filter((actividad) => actividad.estado === actividad.filtros.estado)
    }
    return result
  }

  const filterActividadesEconomicas = (name, value) => {
    if (actividadesEconomicas.length) {
      if (name === 'estado') {
        return filterForEstado(actividadesEconomicas, value)
      } else if (name === 'search') {
        return filterForSearch(actividadesEconomicas, value)
      } else if (name === 'clear') {
        return actividadesEconomicas
      }
    } else {
      return []
    }
  }

  const handleFilter = (event) => {
    let target = event.target
    let { value, name } = target
    setActividadesEconomicasFiltradas(filterActividadesEconomicas(name, value))
    setFiltros((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const filtrarPorHacienda = async () => {
    const request = await getInstance()
    const response = await request.get(`actividadeconomica/find-by-identidad/3101831722`)

    setActividadesEconomicasFiltradas(response.data)
  }

  const handleOnClose = () => {  
    setActive(false)
    setActividadSeleccionada(undefined)
    dispatch(getActividadesEconomicas(savetaxInfo.cia.codCia))
  }

  useEffect(() => {
    if (isLoadingActividadesEconomicas) {
      dispatch(getActividadesEconomicas(savetaxInfo.cia.codCia))
    }

    setActividadesEconomicasFiltradas(actividadesEconomicas)
  }, [dispatch, actividadesEconomicas, savetaxInfo.cia.codCia, isLoadingActividadesEconomicas])

  return (
    <>
      <div className="ActividadesEconomicas">
        <div className="navSection">
          <b className="title">Filtra Por:</b>
          <label htmlFor="" style={{ width: '300px' }}>
            Nombre o Codigo
            <div className="search">
              <input type="text" value={filtros.search} name="search" placeholder="Nombre o Codigo" onChange={handleFilter} />
              <button>
                <Search />
              </button>
            </div>
          </label>
          <div className="sortBy">
            <label htmlFor="estado" style={{ width: '150px' }}>
              Estado
              <select id="estado" value={filtros.estado} onChange={handleFilter} name="estado">
                <option value="">Todos los Estados</option>
                <option value="A">Activo</option>
                <option value="I">Inactivo</option>
              </select>
            </label>
            <label htmlFor="" style={{ width: '150px' }}>
              Limpiar Filtros
              <button onClick={clearFilters}>Limpiar</button>
            </label>
          </div>
          <button className="addContent" onClick={() => setActive(true)}>
            Agregar Actividad Economica
            <Create />
          </button>
        </div>
        <div className="listSection">
          <div className="headList">
            <ul>
              <li className="col-1">
                <b>Correlativo</b>
              </li>
              <li className="col-1">
                <b>Código</b>
              </li>
              <li className="col-2">
                <b>Nombre Comercial </b>
              </li>
              <li className="col-4">
                <b>Descripción</b>
              </li>
              <li className="col-1">
                <b>Estado</b>
              </li>
              <li className="col-1">
                <b></b>
              </li>
            </ul>
          </div>
          {isLoadingActividadesEconomicas ? (
            <div className="loadContentTable">
              <div className="snippet"></div>
              <span>Cargando Actividades Economicas</span>
            </div>
          ) : (
            <div className="bodyList">
              <ul>
                {actividadesEconomicasFiltradas.length ? (
                  actividadesEconomicasFiltradas.slice(50 * currentPage - 50, 50 * currentPage).map((actividad) => (
                    <li className="itemBodyList" key={actividad?.actividadEconomicaCiaPK?.codActividad}>
                      <ul className="contentItemBodyList">
                        <li className="col-1">
                          <span>{actividad?.actividadEconomicaCiaPK?.correlativo ?? '-'}</span>
                        </li>
                        <li className="col-1">
                          <b>{actividad.actividadEconomica.codigo}</b>
                        </li>
                        <li className="col-2">
                          <span>{actividad.descripcion}</span>
                        </li>
                        <li className="col-4">
                          <span>{actividad.actividadEconomica.nombre}</span>
                        </li>
                        <li className="col-1">
                          <span className={actividad.estado === 'A' ? 'activo' : 'desactivo'}>
                            {actividad.estado === 'A' ? 'Activo' : 'Inactivo'}
                          </span>
                        </li>
                        <li className="col-1">
                          <button
                            className="btnEditItem"
                            onClick={() => {
                              setActive(true)
                              setActividadSeleccionada({ ...actividad, isEdit: true })
                            }}
                          >
                            <Edit />
                          </button>
                          <button
                            className="btnDelete"
                            onClick={() => {
                              createAlertConfirm(
                                'Eliminar Actividad Económica',
                                '¿Está seguro de querer eliminar esta actividad económica?',
                                () => dispatch(deleteActividadEconomica(actividad))
                              )
                            }}
                          >
                            <Delete />
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))
                ) : (
                  <li className="emptyResultTable">
                    <span>No hay Resultados</span>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
        {!isLoadingActividadesEconomicas && (
          <Pagination currentPage={currentPage} setPage={setCurrentPage} data={actividadesEconomicasFiltradas} />
        )}
      </div>
      {active && <ActividadesEconomicasKeyview onClose={handleOnClose} actividad={actividadSeleccionada} />}
    </>
  )
}

export default ActividadesEconomicas
