import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import './Paquetes.css'
import logo from 'assets/logo_black.png'
import 'react-credit-cards/es/styles-compiled.css'
import Planes from './Pasos/Planes'
import Payment from './Pasos/Payment'
import { AnimatePresence } from 'framer-motion'

const variants = (direction) => ({
  initial: { x: direction < 0 ? -300 : 300 },
  animate: { x: 0 },
  exit: { x: direction < 0 ? 300 : -300 },
  transition: { duration: 0.3, type: 'tween' },
})

const Paquetes = () => {
  const [form, setForm] = useState({
    persona: {
      pais: {
        idPais: 1,
      },
    },
    plan: {},
    pago: {},
  })
  const [step, setStep] = useState(0)
  const [direction, setDirection] = useState(0)

  const getStep = (step) => {
    switch (step) {
      case 0:
        return (
          <Planes
            variants={variants(direction)}
            handleChange={handleChange}
            stepUp={handleStepUp}
            stepDown={handleStepDown}
            form={form}
            setForm={setForm}
          />
        )
      case 1:
        return (
          <Payment
            variants={variants(direction)}
            handleChange={handleChange}
            stepUp={handleStepUp}
            stepDown={handleStepDown}
            form={form}
            setForm={setForm}
          />
        )
      default:
        return null
    }
  }

  const handleChange = useCallback((e) => {
    setForm((prev) => ({ ..._.set(prev, e.target.name, e.target.value) }))
  }, [])

  const handleStepUp = () => {
    setDirection(1)
    setStep((prev) => prev + 1)
  }
  const handleStepDown = () => {
    setDirection(-1)
    setStep((prev) => prev - 1)
  }

  return (
    <div className="Registro">

      <AnimatePresence initial={false}>
        <div className="FormLoginContainer">{getStep(step)}</div>
      </AnimatePresence>
    </div>
  )
}

export default Paquetes
