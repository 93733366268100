/* eslint-disable no-unused-vars */
// Import Modules
import React, { useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

// Import StyleSheet
import './styles.css'

// Import Context
import { initializeChartsDashboardContext } from '../ChartsDashboard'

const ControllerChartsDashboard = () => {
  const {
    metricType,
    periodType,
    year,
    month,
    years,
    months,
    handleChangeMetricType,
    handleChangePeriodType,
    handleChangeYear,
    handleChangeMonth,
  } = useContext(initializeChartsDashboardContext)

  return (
    <div className="ControllerChartsDashboard">
      <label htmlFor="metricType" className="metricTypeContainer">
        Metrica
        <select name="metricType" className="metricType" value={metricType} onChange={handleChangeMetricType} id="metricType">
          <option value="ventas">Ventas</option>
          <option value="compras">Compras</option>
          <option value="iva">IVA Soportado vs IVA Devengado</option>
        </select>
      </label>
      <label htmlFor="periodType" className="periodTypeContainer">
        Periodo
        <select name="periodType" id="periodType" value={periodType} className="periodType" onChange={handleChangePeriodType}>
          <option value="anual">Anual</option>
          <option value="mensual">Mensual</option>
        </select>
      </label>
      {periodType === 'mensual' && (
        <label htmlFor="MonthInput">
          Mes
          <select
            name="MonthInput"
            id="MonthInput"
            value={month || months[months.length - 1]}
            className="MonthInput"
            onChange={handleChangeMonth}
          >
            {months.map((m, index) => (
              <option value={m} key={'month' + index}>
                {m}
              </option>
            ))}
          </select>
        </label>
      )}
      <label htmlFor="yearInput">
        Año
        <select
          name="yearInput"
          id="yearInput"
          value={year || years[years.length - 1]}
          className="yearInput"
          onChange={handleChangeYear}
        >
          {years.map((y, index) => (
            <option value={y} key={'year' + index}>
              {y}
            </option>
          ))}
        </select>
      </label>
    </div>
  )
}

export default ControllerChartsDashboard
