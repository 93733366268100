import { DateTime } from 'luxon'

export const parseDates = (dateArray) => {
  const [inicio, final] = dateArray
  const isoInicio = inicio.toISOString()
  const isoFinal = final.toISOString()

  const parsedInicio = DateTime.fromISO(isoInicio).toFormat('dd-MM-yyyy')
  const parsedFinal = DateTime.fromISO(isoFinal).toFormat('dd-MM-yyyy')

  return [parsedInicio, parsedFinal]
}
