/* eslint-disable react-hooks/exhaustive-deps */
// Import Hooks
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useUser from '../../hooks/useUser'
import { toast } from 'react-toastify'
import { setUser, getSavetaxInfo } from '../../actions/users'

const PublicRoute = ({ element }) => {
  const { user, isLoadingUser } = useUser()
  let navigate = useNavigate()
  useEffect(async () => {
    if (!isLoadingUser && user) {
      const savetaxInfo = await getSavetaxInfo(user)
      if (savetaxInfo?.data?.cia.primeraVez) {
        toast.success('Usuario primera vez ')
        navigate('/configuracionInicial')
      } else {
        navigate('/escritorio')
      }
    }
  }, [user, isLoadingUser])

  return element
}

export default PublicRoute
