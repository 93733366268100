import { GET_CLIENTES, GET_TIPOS_CLIENTES, UPLOAD_CLIENTE, UPDATE_CLIENTE } from '../constants/actionTypes'

const initialState = {
  clientes: [],
  tiposClientes: [],
  isLoadingClientes: true,
  wasAdded: false,
}

const handles = {
  [GET_CLIENTES]: (state, { payload = {} }) => {
    return { ...state, isLoadingClientes: false, clientes: payload?.clientes }
  },
  [UPLOAD_CLIENTE]: (state, { payload = {} }) => {
    return { ...state, clientes: [payload.cliente, ...state.clientes], wasAdded: true }
  },
  [UPDATE_CLIENTE]: (state, { payload = {} }) => {
    const clienteId = payload?.cliente?.clientePK.idCliente

    return {
      ...state,
      clientes: state.clientes.map((c) => (c.clientePK.idCliente === clienteId ? payload?.cliente : c)),
      wasAdded: false,
    }
  },
  [GET_TIPOS_CLIENTES]: (state, { payload = {} }) => {
    return { ...state, tiposClientes: payload?.tiposClientes }
  },
}

const clientes = (state = initialState, action) => {
  const { type } = action

  return handles[type] ? handles[type](state, action) : state
}

export default clientes
