import { getCabys } from 'actions/administracion'
import useAdmin from 'hooks/useAdmin'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const CabysLoader = () => {
  const dispatch = useDispatch()
  const { isLoadingCabys } = useAdmin()

  useEffect(() => {
    dispatch(getCabys)
  }, [dispatch])

  /*return isLoadingCabys ? (
    <div className="loadContent">
      <div className="snippet"></div>
      <p className="report">Cargando Cabys...</p>
    </div>
  ) : null*/

  return null
}

export default CabysLoader
