import useAdmin from 'hooks/useAdmin'
import useMisc from 'hooks/useMisc'
import useUser from 'hooks/useUser'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

const useFacturacion = (isLoading, fetchAction, initialState = { open: false, currentType: null, factura: {} }) => {
  const dispatch = useDispatch()
  const { savetaxInfo } = useUser()

  const { cajas, sucursales, actividadesEconomicas } = useAdmin()
  const { monedas } = useMisc()
  const initialFactura = useMemo(
    () => ({
      actividadEconomicaCia: actividadesEconomicas[0] ?? {},
      cliente: {},
      caja: cajas?.find((caja) => caja?.cajaPK?.idSucursal === sucursales[0]?.sucursalPK?.idSucursal) ?? {},
      idMoneda: monedas.find((moneda) => moneda.idMoneda === 1) ?? {},
      sucursal: sucursales[0] ?? {},
      vtaMovmPK: {
        codCia: savetaxInfo.cia.codCia,
        idSucursal: '',
        idTipoDoctoVta: initialState?.currentType,
        numDocto: 0,
      },
      condicionVenta: {
        codigo: '01',
        condicionVentaPK: {
          codCia: savetaxInfo.cia.codCia,
          idCondicionVenta: 1,
        },
        nombre: 'CONTADO',
      },
      formaPagoList: [
        {
          value: '02',
          label: 'TARJETA',
          codigoSri: '02',
          formaPagoPK: {
            codCia: savetaxInfo.cia.codCia,
            idFormaPago: 2,
          },
          nombre: 'TARJETA',
        },
      ],
      comentario: '',
      consecutivoContingencia: '',
      descuento: 0,
      estadoFactura: '',
      motivoContingencia: '',
      nota: '',
      status: '',
      tiempo: '',
      tipoVenta: '',
      fechaContingencia: '',
      iva: 0,
      tasaCambio: 1,
      fechaCreacion: DateTime.now().setLocale('es').toLocaleString(),
      vtaDetmList: [],
      otroCargoVtaMovmList: [],
      cajas: [],
      tarifas: [],
      otroImpuestoVtaDetmList: [],
      descuentoVtaDemList: [],
      openTasaCambio: false,
      total: 0,
      totalExento: 0,
      totalExonerado: 0,
      totalGravados: 0,
      totalIvaDevuelto: 0,
      totalMercExento: 0,
      totalMercExonerado: 0,
      totalMercGravados: 0,
      totalOtrosCargos: 0,
      totalOtrosImpuestos: 0,
      totalSrvExento: 0,
      totalSrvExonerado: 0,
      totalSrvGravados: 0,
      subtotal: 0,
      subtotalExento: 0,
      // Propiedades que se usan para la editar la factura (en linea)
      actionEnLinea: 'add',
      actionEnLineaOtrosCargos: 'add',
      actionEnLineaDescuentos: 'add',
      productEnLinea: {},
      tipoDoctoVta: {},
      impuestoEnLinea: {
        factor: 0,
        monto: 0,
        montoPorcentaje: '',
        valorPorcentaje: 'M',
        otroImpuestoVtaDetmPK: {},
        tipoImpuesto: {},
      },
      descuentoEnLinea: {
        descripcion: '',
        descuentoVtaDemPK: {},
        monto: 0,
        porcentaje: 0,
        valorPorcentaje: 'M',
      },
      otroCargoEnLinea: {
        detalle: '',
        identificacion: '',
        monto: 0.0,
        nombre: '',
        otroCargoVtaMovmPK: {},
        porcentaje: 0.0,
        tipoDoctoOtrosCargos: {},
      },
    }),
    [actividadesEconomicas, cajas, initialState, monedas, savetaxInfo.cia.codCia, sucursales]
  )

  const [open, setOpen] = useState(initialState.open)
  const [editFactura, setEditFactura] = useState(initialState.factura ?? initialFactura)

  const onOpen = () => {
    setOpen(true)
  }

  const onEdit = (factura) => {
    setOpen(true)
    setEditFactura({ ...factura, isEdit: factura?.status !== 'R' })
  }

  const onClose = () => {
    setOpen(false)
    setEditFactura(undefined)
  }

  useEffect(() => {
    if (isLoading) {
      dispatch(fetchAction(savetaxInfo.cia.codCia))
    }
  }, [isLoading, savetaxInfo, fetchAction, dispatch])

  return {
    open,
    editFactura,
    setOpen,
    setEditFactura,
    onOpen,
    onEdit,
    onClose,
  }
}

export default useFacturacion
