import {
  DELETE_FACTURA_ELECTRONICA,
  GET_FACTURAS_ELECTRONICAS,
  GET_FACTURAS_ELECTRONICAS_POR_COMPRA,
  GET_FACTURAS_ELECTRONICAS_POR_EXPORTACION,
  GET_NOTAS_DE_CREDITO,
  GET_NOTAS_DE_DEBITO,
  GET_PROFORMAS_ELECTRONICAS,
  GET_TIQUETAS_ELECTRONICAS,
  UPDATE_FACTURA,
} from '../constants/actionTypes'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getInstance } from 'api/apiInstance'

export const getFacturasElectronicas = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("fe")
    const response = await request.get(`vta-movm/find-by-cod-cia/${cia}/2`)
    
    dispatch({
      type: GET_FACTURAS_ELECTRONICAS,
      payload: {
        facturasElectronicas: response?.data,
      },
    })
  }
}

export const getFacturasElectronicasPorCompra = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("fe")
    const response = await request.get(`vta-movm/find-by-cod-cia/${cia}/7`)

    dispatch({
      type: GET_FACTURAS_ELECTRONICAS_POR_COMPRA,
      payload: {
        facturasElectronicasPorCompra: response?.data,
      },
    })
  }
}

export const getFacturasElectronicasPorExportacion = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("fe")
    const response = await request.get(`vta-movm/find-by-cod-cia/${cia}/6`)
    dispatch({
      type: GET_FACTURAS_ELECTRONICAS_POR_EXPORTACION,
      payload: {
        facturasElectronicasPorExportacion: response?.data,
      },
    })
  }
}

export const getTiquetasElectronicas = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("fe")
    const response = await request.get(`vta-movm/find-by-cod-cia/${cia}/5`)

    dispatch({
      type: GET_TIQUETAS_ELECTRONICAS,
      payload: {
        tiquetasElectronicas: response?.data,
      },
    })
  }
}

export const getNotasDeCredito = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("fe")
    const response = await request.get(`vta-movm/find-by-cod-cia/${cia}/4`)

    dispatch({
      type: GET_NOTAS_DE_CREDITO,
      payload: {
        notasDeCredito: response?.data,
      },
    })
  }
}

export const getNotasDeDebito = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("fe")
    const response = await request.get(`vta-movm/find-by-cod-cia/${cia}/3`)

    dispatch({
      type: GET_NOTAS_DE_DEBITO,
      payload: {
        notasDeDebito: response?.data,
      },
    })
  }
}

export const getProformasElectronicas = (cia) => {
  return async (dispatch) => {
    const request = await getInstance("fe")
    const response = await request.get(`vta-movm/find-by-cod-cia/${cia}/1`)

    dispatch({
      type: GET_PROFORMAS_ELECTRONICAS,
      payload: {
        proformasElectronicas: response?.data,
      },
    })
  }
}

export const uploadFactura = (action, data, onClose, setModal) => async (dispatch) => {
  try {
    const request = await getInstance("fe")
    const response = await request.post(`vta-movm/emitir-vta-movm`,JSON.stringify(data))

    dispatch({ type: action, payload: { data: response?.data } })
    toast.success('Factura creada exitosamente')
    setModal({ boolean: true, data: response.data })
    onClose()
  } catch (e) {
    console.log(e)
    toast.error('Hubo un error al crear su factura. Intentelo más tarde')
  }
}

export const deleteFactura = (factura) => async (dispatch) => {
  try {
    toast.info('Anulando factura...')

    const request = await getInstance("fe")
    const response = await request.post(`vta-movm/anula-docto-vta/${factura.vtaMovmPK.codCia}/${factura.vtaMovmPK.idTipoDoctoVta}/${factura.vtaMovmPK.idSucursal}/${factura.vtaMovmPK.numDocto}`)

    dispatch({
      type: DELETE_FACTURA_ELECTRONICA,
      payload: {
        factura: response.data,
      },
    })
    toast.success('Factura anulada correctamente')
  } catch (error) {
    console.error(error.message)
    toast.error('Hubo un error anulando la factura')
  }
}

export const updateFactura = (factura, onClose) => async (dispatch) => {
  try {
    toast.info('Actualizando factura...')
    // const response = await request

    dispatch({
      type: UPDATE_FACTURA,
      payload: {
        factura: factura, // response.data
      },
    })
    toast.success('Factura actualizada!')
    onClose()
  } catch (error) {
    console.error(error.message)
    toast.error('Hubo un error anulando la factura')
  }
}
