// Import Modules
import React from 'react'

const EmptyFile = () => {
  return (
    <div className="loadFile">
      <i className="bi bi-cloud-upload"></i>
      <h3>No hay Archivos</h3>
      <button>Subir Archivo</button>
      <input type="file" className="hidden" />
    </div>
  )
}

export default EmptyFile
